import { Transactions } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'repaymentBatch';
const exportFileName = 'Repayment Batch';

const PermissionKey = Transactions.RepaymentBatch;

interface Ifields {
  programName: string[];
  customerNumber: string;
  batchOrderId: string;
  step: string;
  asset: string;
  status: string;
}

const initFields: Ifields = {
  programName: [],
  customerNumber: '',
  batchOrderId: '',
  step: '',
  asset: '',
  status: '',
};

interface IsearchParam extends IdateFilter {
  programNames: string[];
  customerNumber: string;
  batchOrderId: string;
  step: string;
  assetCurrency: string;
  status: string;
}

const translateKeyObj = {
  batchOrderId: 'batch_order_id',
  programName: 'program_name',
  customerNumber: 'customer_number',
  asset: 'asset',
  assetCollateralAmount: 'asset_collateral_amount',
  assetRepaymentAmount: 'asset_repayment_amount',
  releaseCreditAmount: 'release_credit_amount',
  repaymentAmount: 'repayment_amount',
  status: 'status',
  step: 'step',
  createdBy: 'created_by',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
} as const;

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
} = {
  export: api.Transaction.repaymentBatch.getExport,
  table: api.Transaction.repaymentBatch.getAll,
} as const;

interface ItableApiRes {
  id: number;
  merchantId: number;
  programName: string;
  batchOrderId: string;
  customerNumber: string;
  assetCurrency: string;
  assetCurrencyDecimals: number;
  assetCollateralAmount: string;
  assetRepaymentAmount: string;
  creditCurrency: string;
  creditCurrencyDecimals: number;
  creditReleasedAmount: string;
  creditRepaymentAmount: string;
  status: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  step: number;
  mode: number;
}
export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
};
