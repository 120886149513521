import {
  EptCustomerManagement,
  EptQuery
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';
import {
  CardInfo,
  CustomerLevel,
  IprogramList,
  Network,
  RewardRate
} from '../types';

const endpointQuery = { ...EptQuery.query };
// const endpointQuery = { ...{ merchant: '', partnerName: '', merchantProgram: '', currency: '' } }; // TODO: use real endpoint

// const getMerchantList = async () => {
//   const res = await axios.get<never, ImerchantList>(endpointQuery.merchant);
//   return res;
// };
// const getPartnerList = async () => {
//   const res = await axios.get<never, IpartnerList>(endpointQuery.partnerName);
//   return res;
// };
const getProgramList = async () => {
  const res = await axios.get<never, IprogramList[]>(endpointQuery.merchantPrograms);
  return res;
};
const getAssetList = async () => {
  const res = await axios.get<never, any[]>(endpointQuery.asset);
  return res;
};
const getExchangeRate = async (
  params: {
    programName: string;
    fromCurrency: string;
    toCurrency: string;
  },
  config?: any
) => {
  const input = { params, ...config };

  const res = await axios.get<never, any[]>(endpointQuery.exchangeRate, input);
  return res;
};

const getCustomerLevels = async (programName: string | string[]) => {
  const res = await axios.get<never, CustomerLevel[]>(EptQuery.customerLevel.customerLevel, {
    params: {
      programName,
    },
  });
  return res;
};

const getRewardRateByProgramName = ({ programName }: { programName: string | string[] }) => {
  return axios.get<never, RewardRate[]>(EptQuery.rewardRateConfig.rewardRate, {
    params: {
      programName,
    },
  });
};

const getRewardBalance = async (params: { customerNumber: string }, config?: any) => {
  const input = { params, ...config };
  return await axios.get<any, any>(endpointQuery.rewardBalance, input);
};

interface IGetRewardCurrencyByProgramName {
  id: number;
  merchantId: number;
  programName: string;
  rewardCurrency: string;
  wmMerchantId: number;
  wmRewardClientId: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}

const getRewardCurrencyByProgramName = ({ programName }: { programName: string }) => {
  return axios.get<never, IGetRewardCurrencyByProgramName[]>(EptQuery.rewardConfig.rewardCurrency, {
    params: {
      programName,
    },
  });
};

const getProgramNameAndCardDetails = ({ customerNumber }: { customerNumber: string }) => {
  return axios.get<never, CardInfo[]>(EptCustomerManagement.card.getCardList, {
    params: {
      customerNumber,
    },
  });
};

const getNetworkList = ({ programName }: { programName: string }) => {
  return axios.get<never, Network[]>(EptQuery.query.network, { params: { programName } });
};

// const getCurrencyList = async () => {
//   const res = await axios.get<never, ICurrencyList[]>(endpointQuery.currency);
//   return res;
// };

export default {
  getProgramList,
  getAssetList,
  getExchangeRate,
  getCustomerLevels,
  getRewardRateByProgramName,
  getRewardCurrencyByProgramName,
  getProgramNameAndCardDetails,
  getNetworkList,
  getRewardBalance,
};
