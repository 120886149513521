import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { EptCobrandManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';


const getRewardRateConfigs = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptCobrandManagement.RewardRateConfig.getAll, input);
};

const exportTable = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(EptCobrandManagement.RewardRateConfig.export, input);
};

const editConfig = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(EptCobrandManagement.RewardRateConfig.edit, input);
};

const createConfig = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(EptCobrandManagement.RewardRateConfig.create, input);
};

export default { getRewardRateConfigs, exportTable, createConfig, editConfig };
