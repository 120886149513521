import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import {} from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumRewardStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

import { LoadingDialog } from '../../../components';
import { ExportBtn } from '../../../components/Button';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import { displayAmountWithCurrency } from '../../../helper';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../hooks';
import { useGetProgram } from '../../../hooks/useProgram';
import {
  displayAmountCurrying,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from '../../../utils';
import { useGenGridCol } from '../../../utils/ComponentHelper';
import { dateInFormat } from '../../../utils/config';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerLevel, {
      minWidth: 120,
    }),
    useGenGridCol(TK.month, {
      minWidth: 70,
    }),

    useGenGridCol(TK.totalRebatableAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.rewardCurrency, {
      minWidth: 120,
    }),
    useGenGridCol(TK.rewardCurrencyExchangeRate, {
      minWidth: 120,
    }),
    useGenGridCol(TK.statementPeriodFrom, {
      minWidth: 150,
    }),
    useGenGridCol(TK.statementPeriodTo, {
      minWidth: 150,
    }),
    useGenGridCol(TK.paymentDueDate, {
      minWidth: 150,
    }),
    useGenGridCol(TK.rewardStatus, {
      minWidth: 120,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);
  const { EnumProgramCurrency } = useGetProgram();

  const listMapping = (array: any, omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const displayAmount = displayAmountCurrying(0, 2);

      const displayMonth = item.month && item.month.slice(0, 4) + '-' + item.month.slice(4);

      const currency = EnumProgramCurrency[item.programName];
      const mappedResult = [
        [TK.programName, item.programName],
        [TK.customerNumber, item.customerNumber],
        [TK.customerLevel, item.customerLevel],
        [TK.month, displayMonth],
        [
          TK.totalRebatableAmount,
          displayAmountWithCurrency(displayAmount(item.totalRebateAmount), currency),
        ],
        [TK.rewardCurrency, item.rewardCurrency],
        [TK.rewardCurrencyExchangeRate, item.rewardCurrencyExchangeRate],
        [TK.statementPeriodFrom, toDisplayTime(item.statementPeriodFrom, dateInFormat)],
        [TK.statementPeriodTo, toDisplayTime(item.statementPeriodTo, dateInFormat)],
        [TK.paymentDueDate, toDisplayTime(item.paymentDueDate, dateInFormat)],
        [TK.rewardStatus, t(EnumRewardStatus[item.rewardStatus])],
        [TK.creationTime, toDisplayTime(item.creationTime)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedTime)],
      ].filter(([key, _value]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
