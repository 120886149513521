import { useEffect } from 'react';

import { FilterTableWithTab } from '../../../components/Layout';
import { useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import {
  translatePrefix as requestTranslatePrefix,
  useZusParams as useRequestZusParams,
} from './TabRequest/config';
import TabRequestFilter from './TabRequest/TabRequestFilter';
import TabRequestTable from './TabRequest/TabRequestTable';
import {
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix as viewTranslatePrefix,
  useZusParams as useViewZusParams,
} from './TabView/config';
import TabViewFilter from './TabView/TabViewFilter';
import TabViewTable from './TabView/TabViewTable';

export default function CustomerAccount() {
  const { t } = useTranslation(viewTranslatePrefix);

  const zusViewParams = useViewZusParams();
  const zusRequestParams = useRequestZusParams();
  const zusDialog = useZusDialogStore();

  useEffect(() => {
    return () => {
      zusViewParams.clear();
      zusRequestParams.clear();
      zusDialog.close();
    };
  }, []);

  return (
    <FilterTableWithTab
      filterArr={[
        { translatePrefix: viewTranslatePrefix, element: <TabViewFilter /> },
        { translatePrefix: requestTranslatePrefix, element: <TabRequestFilter /> },
      ]}
      tableArr={[
        { translatePrefix: viewTranslatePrefix, element: <TabViewTable /> },
        { translatePrefix: requestTranslatePrefix, element: <TabRequestTable /> },
      ]}
      tabsArr={[
        {
          name: t(TK.customerAccount),
          value: viewTranslatePrefix,
          permissionCode: PermissionKey.CustomerAccountTab.prefix,
        },
        {
          name: t(TK.requestRecord),
          value: requestTranslatePrefix,
          permissionCode: PermissionKey.RequestRecordTab.prefix,
        },
      ]}
    />
  );
}
