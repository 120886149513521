export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',
  programNames = 'programNames',
  from = 'from',
  adjustmentType = 'adjustmentType',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.programNames]: string[]
  [FilterFieldsKeys.from]: string;
  [FilterFieldsKeys.adjustmentType]: string;
}
