import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import { useGetProgram } from '../../../../../hooks/useProgram';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const ProgramNameSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { EnumAllProgram } = useGetProgram();

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('programName') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={EnumAllProgram}
      nameFn={(item) => item}
    />
  );
};

export default ProgramNameSingleSelection;
