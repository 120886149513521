/* Usage
    import AlerterOnStart, { AlerterOnStartFace } from "../components/AlerterOnStart";
    
    const routerState = useLocation()?.state || {};
    const { message, severity } = routerState as AlerterOnStartFace;

    <AlerterOnStart
    message={message}
    severity={severity}
    config={{ anchorOrigin: { vertical: "bottom", horizontal: "center" } }}
    />
*/

import { useState } from 'react';

import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

interface AlerterOnStartFace {
  severity: AlertColor | undefined;
  message: string | undefined;
  config?: SnackbarProps;
}
export default function AlerterOnStart({ message = '', severity, config }: AlerterOnStartFace) {
  const [open, setOpen] = useState(message?.length > 0);
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={8000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...config}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}
export type { AlerterOnStartFace };
