import { SOURCE_OF_FUNDS } from '../constants/SourceOfFunds';
import { getBitWiseRepresentation, joinArrayToString } from '../utils/bitWiseRepresentation';

export const getSourceOfFundsDisplayValue = (
  bitWiseValue: number,
  translateFunc: (key: string) => string
) => {
  const sourceOfFundsWithTranslate = SOURCE_OF_FUNDS.map((item) => {
    return translateFunc(`tcspAccountApplication.sourceOfFunds.${item}`);
  });

  const representationArr = getBitWiseRepresentation(sourceOfFundsWithTranslate, bitWiseValue);

  return joinArrayToString(representationArr);
};
