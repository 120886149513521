import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import {
  EnumApprovalStatus,
  EnumTcspAccountApplicationStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';
import { TCSP } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { ApiResult } from '../../../api/types';
import { ExportBtn } from '../../../components/Button';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import ApprovalProgressDialog from '../../../features/approval/components/ApprovalProgressDialog';
import ApprovalProgressTableCell from '../../../features/approval/components/ApprovalProgressTableCell';
import ApproveRejectCell from '../../../features/approval/components/ApproveRejectCell';
import ApproveRejectDialog from '../../../features/approval/components/ApproveRejectDialog';
import OperationDetailCell from '../../../features/approval/components/OperationDetailCell';
import ViewDetailDialog from '../../../features/approval/components/ViewDetailDialog';
import Table from '../../../features/common/filterTable/components/Table';
import { convertToExportRows } from '../../../features/common/filterTable/helpers/convertToExportRows';
import FilterContainer from '../../../features/common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../features/common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../features/common/filterTable/layout/TableContainer';
import FilterSection from '../../../features/tcsp/tcspAccountApplication/components/FilterSection';
import TcspApplicationDetail from '../../../features/tcsp/tcspAccountApplication/components/TcspApplicationDetail';
import useGetListParams from '../../../features/tcsp/tcspAccountApplication/helpers/useGetListParams';
import useTableColumns from '../../../features/tcsp/tcspAccountApplication/helpers/useTableColumns';
import useTableRows from '../../../features/tcsp/tcspAccountApplication/helpers/useTableRows';
import {
  FilterFields,
  FilterFieldsKeys,
} from '../../../features/tcsp/tcspAccountApplication/types/FilterFields';
import { GetListRes } from '../../../features/tcsp/tcspAccountApplication/types/GetListRes';
import { TableRow } from '../../../features/tcsp/tcspAccountApplication/types/TableRow';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useTabs from '../../../hooks/useTabs';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import { downloadFiles, getFullApiResponse } from '../../../utils';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../zustand/store';
import FilterBarCreateRecordButton from '../../../components/Button/FilterBarCreateRecordButton';
import CreateApplicationDialog from '../../../features/tcsp/tcspAccountApplication/components/CreateApplicationDialog';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.lastModifiedTimeFrom]: '',
  [FilterFieldsKeys.lastModifiedTimeTo]: '',
  [FilterFieldsKeys.CustomerId]: '',
  [FilterFieldsKeys.ProgramNames]: [],
  [FilterFieldsKeys.ApplicationNumber]: '',
  [FilterFieldsKeys.CustomerNumber]: '',
  [FilterFieldsKeys.TcspCustomerApplicationType]: '',
  [FilterFieldsKeys.Status]: '',
};

const translatePrefix = 'tcspAccountApplication';

const TcspAccountApplication = () => {
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [applicationDetailRes, setApplicationDetailRes] = useState<any>({});

  const [isApprovalProgressDialogOpen, setIsApprovalProgressDialogOpen] = useState(false);
  const [isViewDetailDialogOpen, setIsViewDetailDialogOpen] = useState(false);
  const [isApproveRejectDialogOpen, setIsApproveRejectDialogOpen] = useState(false);
  const [isCreateApplicationDialogOpen, setIsCreateApplicationDialogOpen] = useState(false);

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const profile = useAppSelector(selectProfile);

  const columns = useTableColumns({ renderApprovalProgressCell, renderOperationsCell });
  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  console.log('222 fields :', fields);
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { t, tc } = useTranslation(translatePrefix);
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  const isShowExportBtn = hasPermission(FeatureCode.TCSP.TcspAccountApplication.Export);
  const hasApprovePermission = hasPermission(TCSP.TcspAccountApplication.Details.Approve.prefix);
  const hasRejectPermission = hasPermission(TCSP.TcspAccountApplication.Details.Reject);
  const hasViewDetailPermission = hasPermission(TCSP.TcspAccountApplication.Details.prefix);
  const hasCreateApplicationPermission = hasPermission(
    TCSP.TcspAccountApplication.CreateApplication
  );

  const getApproveHistory = () => {
    if (!selectedRow?.rawData) return [];

    const isTxRejected = selectedRow.rawData.status === EnumTcspAccountApplicationStatus.Rejected;

    if (isTxRejected) {
      const { tcsp_account_application_approvals } = selectedRow.rawData;

      return tcsp_account_application_approvals.filter((item: any) => {
        return item.status !== EnumApprovalStatus.Pending;
      });
    }

    return selectedRow?.rawData?.tcsp_account_application_approvals;
  };

  const approveHistory = getApproveHistory();
  const approvalRequired = selectedRow?.rawData?.approvalsRequired;
  const approvalCount = selectedRow?.rawData?.approvalsCount;

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  function renderApprovalProgressCell({ row }: { row: TableRow }) {
    return (
      <ApprovalProgressTableCell
        approvalsCount={row.rawData.approvalsCount}
        approvalsRequired={row.rawData.approvalsRequired}
        applicationStatus={row.rawData.status}
        onButtonClick={() => {
          setSelectedRow(row);
          setIsApprovalProgressDialogOpen(true);
        }}
      />
    );
  }

  function renderOperationsCell({ row }: { row: TableRow }) {
    const isDisabled = row.rawData.status !== EnumTcspAccountApplicationStatus.Pending;

    const handleApproveRejectCellClick = async () => {
      const { isSuccess } = await fetchApplicationDetail(row.applicationNumber);

      if (!isSuccess) return;

      setSelectedRow(row);
      setIsApproveRejectDialogOpen(true);
    };

    const handleDetailCellClick = async () => {
      const { isSuccess } = await fetchApplicationDetail(row.applicationNumber);

      if (!isSuccess) return;

      setSelectedRow(row);
      setIsViewDetailDialogOpen(true);
    };

    return (
      <>
        <ApproveRejectCell
          onClick={handleApproveRejectCellClick}
          isDisabled={isDisabled}
          isHide={!hasApprovePermission && !hasRejectPermission}
        />
        <OperationDetailCell
          onClick={handleDetailCellClick}
          isDisabled={false}
          isHide={!hasViewDetailPermission}
        />
      </>
    );
  }

  const handleSettled = () => zusDialog.closeExtra();

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;
    setList(data);
  };

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { currentTime, ...cleanParams } = listParams;
    return api.tcsp.tcspAccountApplication.getTcspAccountApplications(cleanParams);
  };

  const onExport = async () => {
    const params = getListParams(fields);
    const { currentTime, ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.tcsp.tcspAccountApplication.getExport({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`TCSP Account Application`, exportRows, {});
  };

  const fetchApplicationDetail = async (applicationNumber: string) => {
    const result = await api.tcsp.tcspAccountApplication.getTcspApplicationDetails({
      applicationNumber,
    });

    let applicationResult = null;
    let isSuccess = false;

    if (result) {
      applicationResult = result;
      isSuccess = true;
    }

    setApplicationDetailRes(applicationResult);

    return { isSuccess, applicationResult };
  };

  useQuery(['getTcspAccountApplications', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.createdDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.createdDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.lastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.lastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const refreshTable = () => {
    setListParams({ ...listParams, currentTime: Date.now() });
  };

  const Dialogs = () => {
    return (
      <>
        <CreateApplicationDialog
          isOpen={isCreateApplicationDialogOpen}
          selectedRow={selectedRow}
          closeDialog={() => setIsCreateApplicationDialogOpen(false)}
          refreshTable={refreshTable}
        />

        <ApprovalProgressDialog
          isOpen={isApprovalProgressDialogOpen}
          closeDialog={() => setIsApprovalProgressDialogOpen(false)}
          approveHistory={approveHistory}
        />

        <ViewDetailDialog
          isOpen={isViewDetailDialogOpen}
          closeDialog={() => setIsViewDetailDialogOpen(false)}
          customerNumber={selectedRow?.rawData?.customerNumber}
        >
          <TcspApplicationDetail data={applicationDetailRes?.answers} />
        </ViewDetailDialog>

        <ApproveRejectDialog
          isOpen={isApproveRejectDialogOpen}
          closeDialog={() => setIsApproveRejectDialogOpen(false)}
          refreshTable={refreshTable}
          data={{
            operator: profile.email,
            createdBy: selectedRow.createdBy,
            customerNumber: selectedRow?.rawData?.customerNumber,
            applicationNumber: selectedRow.applicationNumber,
            approvalsRequired: approvalRequired,
            approvalsCount: approvalCount,
            approveHistory: approveHistory,
          }}
          actions={{
            approve: {
              apiFunc: api.tcsp.tcspAccountApplication.approve,
              permission: TCSP.TcspAccountApplication.Details.Approve.prefix,
            },
            reject: {
              apiFunc: api.tcsp.tcspAccountApplication.reject,
              permission: TCSP.TcspAccountApplication.Details.Reject,
            },
          }}
        >
          <TcspApplicationDetail data={applicationDetailRes?.answers} />
        </ApproveRejectDialog>
      </>
    );
  };

  return (
    <FilterTableLayoutContainer>
      <Dialogs />

      <FilterContainer>
        <FilterSectionActionRow>
          <FilterBarCreateRecordButton
            onClick={() => setIsCreateApplicationDialogOpen(true)}
            isShow={hasCreateApplicationPermission}
          />
          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>

        <FilterSection
          fields={fields}
          setFields={setFields}
          onSearch={onFilterSearch}
          onReset={onFilterReset}
          DateObj={DateObj}
          isCollapse={isCollapse}
        />
      </FilterContainer>

      <DivideLine />

      <TableContainer>
        <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
        <Tabs>
          <Table
            columns={columns}
            rows={rows}
            rowCount={totalCounts}
            page={page}
            onPageChange={onPageChange}
          />
        </Tabs>
      </TableContainer>
    </FilterTableLayoutContainer>
  );
};

export default TcspAccountApplication;
