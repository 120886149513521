import React, { useEffect, useState } from 'react';

import APIs from '../../../../../../api';
import { DialogInOne } from '../../../../../../components';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import useExchangeRateConfigCreateRecordValidation from '../../../helpers/useExchangeRateConfigCreateRecordValidation';
import Content from './Content';
import { GetListRes } from '../../../types/GetListRes';
import { CreateRecordRowKeys, ICreateRecordFields } from './types';
import { IdialogInOneProps } from '../../../../../../components/DialogInOne';
import useBaseExchangeRateQuery from '../../../helpers/useBaseExchangeRateQuery';
import { getAdjustedExchangeRate } from '../../../helpers/getAdjustedExchangeRate';
import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

interface IProps {
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  allRecords: GetListRes[];
}

const initFields = {
  [CreateRecordRowKeys.ProgramName]: '',
  [CreateRecordRowKeys.From]: '',
  [CreateRecordRowKeys.To]: 'HKD',
  [CreateRecordRowKeys.AdjustmentType]: '',
  [CreateRecordRowKeys.AdjustmentAmount]: '',
};

const createConfigRecordApiFunc = APIs.CobrandManagement.exchangeRateConfiguration.createConfig;

const CreateRecordDialog = (props: IProps) => {
  const { isOpen, closeDialog, refreshTable, allRecords } = props;

  const [createRecordFields, setCreateRecordFields] = useState<ICreateRecordFields>(initFields);

  const { tc, translate } = useTranslation();
  const { alerting } = useAlerting();
  const { validate } = useExchangeRateConfigCreateRecordValidation({ fields: createRecordFields });
  const { exchangeRateOfCurrentDate } = useBaseExchangeRateQuery({ createRecordFields });

  const adjustedExchangeRate = getAdjustedExchangeRate({
    exchangeRateOfCurrentDate,
    type: Number(createRecordFields[CreateRecordRowKeys.AdjustmentType]),
    adjustAmount: createRecordFields[CreateRecordRowKeys.AdjustmentAmount],
  });

  useEffect(() => {
    setCreateRecordFields(initFields);
  }, [isOpen]);

  const handleCloseDialog = () => {
    closeDialog();
  };

  const getIsAlreadyExistRecord = () => {
    const isDuplicateRecord = allRecords.some((record) => {
      const isDuplicateProgramName = record.programName === createRecordFields.programName;
      const isDuplicateFrom = record.fromCurrency === createRecordFields.from;
      const isDuplicateTo = record.toCurrency === createRecordFields.to;

      return isDuplicateProgramName && isDuplicateFrom && isDuplicateTo;
    });

    return isDuplicateRecord;
  };

  const checkIsCanProceed = () => {
    const isAlreadyExistRecord = getIsAlreadyExistRecord();
    const { allErrors } = validate();
    const isValidationFailed = allErrors.length > 0;

    const isAbsoluteAdjustment =
      Number(createRecordFields[CreateRecordRowKeys.AdjustmentType]) ===
      EnumMerchantExchangeRateAdjustType.AbsoluteValue;

    const isInvalidExchangeRateAfterAdjust =
      isAbsoluteAdjustment && Number(adjustedExchangeRate) <= 0;

    if (isValidationFailed) {
      alerting('warning', allErrors[0]);
      return false;
    }

    if (isInvalidExchangeRateAfterAdjust) {
      alerting(
        'warning',
        translate('exchangeRateConfiguration.createRecordDialog.negativeAdjustedExchangeRateAlert')
      );
      return false;
    }

    if (isAlreadyExistRecord) {
      alerting('warning', translate('exchangeRateConfiguration.createRecordDialog.duplicateAlert'));
      return false;
    }

    return true;
  };

  const onConfirm = async () => {
    const isCanProceed = checkIsCanProceed();

    if (!isCanProceed) return;

    // call api
    const res = await createConfigRecordApiFunc({
      programName: createRecordFields.programName,
      fromCurrency: createRecordFields.from,
      toCurrency: createRecordFields.to,
      adjustType: Number(createRecordFields.adjustmentType),
      adjustAmount: createRecordFields.adjustmentAmount,
    });

    if (!res) return;

    // alert success + refresh
    alerting('success', translate('exchangeRateConfiguration.createRecordDialog.successAlert'));
    handleCloseDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('createRecord'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: (
      <Content
        createRecordFields={createRecordFields}
        setCreateRecordFields={setCreateRecordFields}
        exchangeRateOfCurrentDate={exchangeRateOfCurrentDate}
        adjustedExchangeRate={adjustedExchangeRate}
      />
    ),
    onConfirm: () => {
      onConfirm();
    },
    onCancel: handleCloseDialog,
    size: 'sm',

    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
};

export default CreateRecordDialog;
