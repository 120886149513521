export enum TableColumnKey {
  programName = 'programName',
  rewardBatchId = 'rewardBatchId',
  rewardOrderId = 'rewardOrderId',
  customerNumber = 'customerNumber',
  month = 'month',
  rewardCurrency = 'rewardCurrency',
  rewardAmount = 'rewardAmount',
  paymentDueDate = 'paymentDueDate',
  qualifiedSpendingAmount = 'qualifiedSpendingAmount',
  qualifiedRepaymentAmount = 'qualifiedRepaymentAmount',
  rewardRate = 'rewardRate',
  exchangeRate = 'exchangeRate',
  rewardAmountInBillingCurrency = 'rewardAmountInBillingCurrency',
  creationTime = 'creationTime',
  lastModifiedTime = 'lastModifiedTime',
}
