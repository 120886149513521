export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',
  ProgramNames = 'programNames',
  OrderID = 'orderId',
  CustomerNumber = 'customerNumber',
  TransactionType = 'transactionType',
  RepaymentCurrency = 'repaymentCurrency',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.ProgramNames]: string[];
  [FilterFieldsKeys.OrderID]: string;
  [FilterFieldsKeys.CustomerNumber]: string;
  [FilterFieldsKeys.TransactionType]: string;
  [FilterFieldsKeys.RepaymentCurrency]: string;
}
