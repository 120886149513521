import { useEffect } from 'react';

import { FilterTable } from '../../../components/Layout';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../zustand/store';
import { translatePrefix, useZusParams } from './config';
import ViewFilter from './ViewFilter';
import ViewTable from './ViewTable';

export default function ReferralCode() {
  const { setTranslatePrefix } = useZusTranslatePrefixStore();

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  useEffect(() => {
    setTranslatePrefix(translatePrefix);
    return () => {
      zusParams.clear();
      zusDialog.close();
    };
  }, []);

  return <FilterTable filter={<ViewFilter />} table={<ViewTable />} />;
}
