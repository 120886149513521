import { OpRedirect } from '../../../assets/icons';
import { DialogInOne, OpIconButton } from '../../../components';
import DialogCloseBtn from '../../../components/Button/DialogCloseBtn';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box } from '../../../components/MuiGenerals';
import { useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import { IkycRef, translateKeyObj as TK, translatePrefix } from './config';

export default function DialogReferenceCode() {
  const zusDialog = useZusDialogStore();

  const { t, te, tc } = useTranslation(translatePrefix);

  const { applicantId, workflowRunId } = zusDialog.meta as IkycRef;

  const redirectHandler = () =>
    window.open(
      `${process.env.REACT_APP_ONFIDO_REDIRECT_URL}${workflowRunId}`,
      '_blank',
      'noopener,noreferrer'
    );

  const labelElePairArr = [
    [TK.applicantId, <Box>{applicantId}</Box>],
    [
      TK.workflowRunId,
      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <Box>{workflowRunId}</Box>
        {workflowRunId && (
          <OpIconButton
            title={''}
            svgUrl={OpRedirect}
            size="1.2rem"
            sxBox={{ marginLeft: '4px' }}
            onClick={redirectHandler}
          />
        )}
      </Box>,
    ],
  ] as Array<[string, JSX.Element]>;

  const dialogContent = (
    <GridBox
      labelElePairArr={labelElePairArr}
      // containerSx={{ padding: '16px 24px', backgroundColor: '#ECEEF0' }}
      // dividingLineColor={'#DDE1E3'}
      columnCount={1}
    />
  );

  const handleCloseDialog = () => {
    zusDialog.close();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.kycReferenceCode),
    self: {
      open: zusDialog.match('referenceCodeDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: () => {},
    onCancel: handleCloseDialog,

    size: 'sm',

    dialogActionStyling: { padding: '8px' },

    isCancelHidden: true,
    isConfirmHidden: true,

    actionButtons: <DialogCloseBtn handleClose={handleCloseDialog} />,
  };

  return <DialogInOne {...dialogConfig} />;
}
