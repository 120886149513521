export enum TableColumnKey {
  operation = 'operation',
  customerId = 'customerId',
  programName = 'programName',
  customerNumber = 'customerNumber',
  status = 'status',
  latestVersion = 'latestVersion',
  createdBy = 'createdBy',
  createdDate = 'creationTime',
  lastModifiedBy = 'lastModifiedBy',
  lastModifiedTime = 'lastModifiedTime',
}
