export enum TableColumnKey {
  programName = 'programName',
  rewardCurrency = 'rewardCurrency',
  customerLevel = 'customerLevel',
  rewardRate = 'rewardRate',
  createdBy = 'createdBy',
  creationTime = 'creationTime',
  lastModifiedBy = 'lastModifiedBy',
  lastModifiedTime = 'lastModifiedTime',
}
