export enum TableColumnKey {
  operation = 'operation',
  programName = 'programName',
  from = 'from',
  to = 'to',
  exchangeRateOfCurrentDate = 'exchangeRateOfCurrentDate',
  adjustmentType = 'adjustmentType',
  adjustmentAmount = 'adjustmentAmount',
  exchangeRateAfterAdjustment = 'exchangeRateAfterAdjustment',
  createdBy = 'createdBy',
  createdDate = 'creationTime',
  lastModifiedBy = 'lastModifiedBy',
  lastModifiedTime = 'lastModifiedTime',
}
