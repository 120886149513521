import { ChangeEvent, useState } from 'react';

import { EnumCardBlockStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';
import { EnumCardBlockType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master/Enum/CardBlockType';

import { MpTextField, SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import { Button, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import { usePermission, useTranslation } from '../../../hooks';
import { useGetProgram } from '../../../hooks/useProgram';
import { useZusDialogStore } from '../../../zustand/store';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogCreateRequest from './DialogCreateRequest';
import ProgramNameMultipleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { EnumAllProgram } = useGetProgram();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    ReleaseTime: useDatePicker(),
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const getParams: () => IsearchParam = () => {
    const { cardBlockId: id, cardBlockType: type, panLastFour, programName: programNames, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: releaseDateFrom, end: releaseDateTo } = DateObj.ReleaseTime;

    return {
      ...initZusParams,
      ...rest,
      cardBlockId: id,
      cardBlockType: type,
      creationTimeFrom: createdDateFrom,
      creationTimeTo: createdDateTo,
      releasedTimeFrom: releaseDateFrom,
      releasedTimeTo: releaseDateTo,
      panLast4: panLastFour,
      programNames
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.ReleaseTime.clearDate();
  };

  const filters: Filter[] = [
    { labelKey: TK.creationTime, filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: TK.releaseTime, filter: <DateObj.ReleaseTime.Picker type="dateTime" /> },
    {
      labelKey: TK.cardBlockId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.cardBlockId) })}
          value={fields.cardBlockId}
          onChange={onChange('cardBlockId')}
        />
      ),
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) => setFields((fields) => ({ ...fields, programName: value }))}
          onClear={() => setFields((f) => ({ ...f, programName: [] }))}
        />
      ),
    },
    {
      labelKey: TK.cardBlockType,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.cardBlockType) })}
          value={fields.cardBlockType}
          onChange={onChange('cardBlockType')}
          enumData={EnumCardBlockType}
          clearSelect={() => setFields((f) => ({ ...f, cardBlockType: '' }))}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.status) })}
          value={fields.status}
          onChange={onChange('status')}
          enumData={EnumCardBlockStatus}
          clearSelect={() => setFields((f) => ({ ...f, status: '' }))}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange('customerNumber')}
        />
      ),
    },
    {
      labelKey: TK.cardNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.cardNumber) })}
          value={fields.cardNumber}
          onChange={onChange('cardNumber')}
        />
      ),
    },
    {
      labelKey: TK.panLastFour,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.panLastFour) })}
          value={fields.panLastFour}
          onChange={onChange('panLastFour')}
        />
      ),
    },
  ];

  const openCreateRequestDialog = () => zusDialog.open('createRequestDialog');

  return (
    <Container disableGutters maxWidth={false}>
      <DialogCreateRequest />

      <FilterSectionActionRow>
        {hasPermission(PermissionKey.CreateRequest) && (
          <Button variant="outlined" onClick={openCreateRequestDialog}>
            {tc('create_request')}
          </Button>
        )}

        <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
      </FilterSectionActionRow>

      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
