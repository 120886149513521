import { useTranslation } from '../../hooks';
import { Button } from '../MuiGenerals';

interface Iprops {
  handleClose: () => void;
}

export default function DialogCloseBtn({ handleClose }: Iprops) {
  const { tc } = useTranslation();
  return (
    <Button
      onClick={handleClose}
      className={'inheritColor'}
      color={'secondary'}
      variant={'contained'}
    >
      {tc('close')}
    </Button>
  );
}
