export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',
  customerNumber = 'customerNumber',
  clientId = 'clientId',
  txHash = 'txHash',
  fromAddress = 'fromAddress',
  toAddress = 'toAddress',
  referenceNumber = 'referenceNumber',
  programNames = 'programNames',
  currency = 'currency',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.customerNumber]: string;
  [FilterFieldsKeys.clientId]: string;
  [FilterFieldsKeys.fromAddress]: string;
  [FilterFieldsKeys.toAddress]: string;
  [FilterFieldsKeys.txHash]: string;
  [FilterFieldsKeys.referenceNumber]: string;
  [FilterFieldsKeys.programNames]: string[];
  [FilterFieldsKeys.currency]: string;
}
