import { Navigate, Route, Routes } from 'react-router-dom';

import App from './App';
import { Alerter } from './components';
import { useReduxSync } from './hooks';
import { useAppSelector } from './reducer/hooks';
import { selectProfile } from './reducer/profileSlice';
import { Login, Redirect } from './views/Auth';

const LoginRouter = () => {
  useReduxSync();
  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;

  return (
    <>
      <Alerter />
      <Routes>
        <Route path="redirect" element={<Redirect />} />
        <Route path="login" element={<Login />} />
        {!isLogin && <Route path="*" element={<Navigate replace to="/login" />} />}
        {isLogin && <Route path="*" element={<App />} />}
      </Routes>
    </>
  );
};

export default LoginRouter;
