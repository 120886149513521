import { useState } from 'react';

import DialogInOne, { IdialogInOneProps } from '../../../../components/DialogInOne';
import { Box } from '../../../../components/MuiGenerals';
import { useAlerting, useTranslation } from '../../../../hooks';
import { validateEmail } from '../../../../utils/validators/emailValidation';
import { useZusDialogStore } from '../../../../zustand/store';
import { apiObj, translateKeyObj as TK, translatePrefix } from '../config';
import DialogEmailUpdateContent from './DialogEmailUpdateContent';
import DialogPhoneUpdateContent from './DialogPhoneUpdateContent';

const DialogUpdation = () => {
  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const initFields = {
    newCountryCode: '',
    newPhone: '',
  };
  const [fields, setFields] = useState<any>(initFields);

  const onUpdateEmailConfirm = async () => {
    if (!fields?.newEmail) {
      return alerting('warning', tc('phInputField', { fieldName: t(TK.newEmailAddress) }));
    }

    if (!validateEmail(fields?.newEmail)) {
      return alerting('warning', t('invalid_email_warning'));
    }

    const result = await apiObj.updateEmailAddress({
      customerId: zusDialog.meta.customerId,
      email: fields?.newEmail,
    });

    if (!result) {
      return;
    }

    alerting('success', t('create_application_successful_message'));
    await zusDialog.close();
  };

  const onUpdatePhoneConfirm = async () => {
    if (!fields?.newCountryCode) {
      return alerting('warning', tc('phInputField', { fieldName: t(TK.newCountryCode) }));
    }
    if (!fields?.newPhone) {
      return alerting('warning', tc('phInputField', { fieldName: t(TK.newPhoneNumber) }));
    }

    const result = await apiObj.updatePhoneNo({
      customerId: zusDialog.meta.customerId,
      phoneCountryCode: fields?.newCountryCode,
      phoneNumber: fields?.newPhone,
    });

    if (!result) {
      return;
    }

    alerting('success', t('create_application_successful_message'));
    await zusDialog.close();
  };

  const onCancel = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const dialogConfig: IdialogInOneProps = {
    title: '',
    self: {
      open: zusDialog.match('updateRecordDialog'),
      onClose: onCancel,
    },
    content: (
      <Box>
        {zusDialog.meta?.type === 'email' ? (
          <DialogEmailUpdateContent fields={fields} setFields={setFields} />
        ) : (
          <DialogPhoneUpdateContent fields={fields} setFields={setFields} />
        )}
      </Box>
    ),
    onConfirm: () => {
      if (zusDialog.meta?.type === 'email') {
        onUpdateEmailConfirm();
      } else if (zusDialog.meta?.type === 'phone') {
        onUpdatePhoneConfirm();
      }
    },
    onCancel,
    size: 'sm',
  };

  return <DialogInOne {...dialogConfig} />;
};

export default DialogUpdation;
