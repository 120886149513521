import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import APIs from '../../api';
import { useAppSelector } from '../../reducer/hooks';
import { login, selectProfile } from '../../reducer/profileSlice';
import { store } from '../../reducer/store';
import { readAccessToken, setClientDateTimeDiff, writeTokens } from '../../utils/';

const dispatch = store.dispatch;
const Redirect = () => {
  const profile = useAppSelector(selectProfile);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isInit, setIsInit] = useState(false);
  const authToken = searchParams.get('accessToken') || '';
  const noToken = !authToken || authToken.length === 0;
  const localAccessToken = readAccessToken();
  const isHaveAccessToken = localAccessToken && localAccessToken.length > 0;

  const writeTokenAndLoginInfo = (info: any) => {
    const { accessToken, refreshToken, portalName, features, appUuid } = info;

    writeTokens({ accessToken, refreshToken });

    const featureArr = Object.keys(features)
      .map((f) => f.toLowerCase())
      .sort();

    const jwt = jwt_decode<any>(accessToken);
    const { email, exp, iat } = jwt;

    setClientDateTimeDiff(iat);

    const loginParams = {
      featureList: featureArr,
      appName: portalName,
      email,
      expSecond: exp,
      appUuid,
    };

    dispatch(login(loginParams));
    setIsInit(true);
  };

  useEffect(() => {
    async function fetch() {
      if (noToken) {
        return;
      }
      try {
        const res = await APIs.MerchantPortal.auth.getAccessToken(authToken, { ignoreToken: true });
        const { accessToken } = res;

        if (!accessToken) {
          return;
        }

        const jwt = jwt_decode<any>(accessToken);
        const { email, appUuid } = jwt;

        if (isHaveAccessToken) {
          const isDiffUser = email !== profile.email;
          const isDiffMerchant = appUuid !== profile.appUuid;

          if (isDiffUser || isDiffMerchant) {
            return writeTokenAndLoginInfo({ ...res, appUuid });
          }

          setIsInit(true);
        } else {
          // fresh login
          writeTokenAndLoginInfo({ ...res, appUuid });
        }
      } catch (err) {
        navigate('/login');
      }
    }

    fetch();
  }, []);

  useEffect(() => {
    if (profile.appName && localAccessToken) {
      if (isInit) {
        navigate('/');
      }
    }
  }, [profile.appName, localAccessToken, isInit]);

  if (noToken) return null;

  return <>redirecting</>;
};
export default Redirect;
