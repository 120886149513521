import { GridRenderCellParams } from '@mui/x-data-grid';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = () => {
  const { hasPermission } = usePermission();

  const columns = [
    useGenGridCol(TableColumnKey.programName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.orderId),
    useGenGridCol(TableColumnKey.customerNumber, {
      minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.transactionType),
    useGenGridCol(TableColumnKey.repaymentCurrency),
    useGenGridCol(TableColumnKey.repaymentAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TableColumnKey.repaymentRate, {
      minWidth: 150,
    }),
    useGenGridCol(TableColumnKey.rewardAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TableColumnKey.postBalance, {
      minWidth: 150,
    }),

    useGenGridCol(TableColumnKey.creationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
