import { useQuery } from 'react-query';

import api from '../api';
import { IprogramList } from '../api/types';
import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import { selectProgramList, setProgramList } from '../reducer/programListSlice';

export async function useFetchProgram() {
  const dispatch = useAppDispatch();

  useQuery('programList', api.MerchantPortal.query.getProgramList, {
    staleTime: 60 * 60 * 12,
    onSuccess: (dbRes) => {
      dispatch(setProgramList(dbRes as (IprogramList & { customerLevel: string })[]));
    },
  });
}

export function useGetProgram() {
  const {
    allProgramList,
    programCurrencyList,
    allCurrencyList,
    programCustomerLvList,
    programMaxCreditLimitList,
    programCreditLimitList,
  } = useAppSelector(selectProgramList);

  return {
    EnumAllProgram: allProgramList,
    EnumProgramCurrency: programCurrencyList,
    EnumAllCurrencyList: allCurrencyList,
    EnumProgramCustomerLvList: programCustomerLvList,
    EnumProgramMaxCreditLimitList: programMaxCreditLimitList,
    EnumProgramCreditLimitList: programCreditLimitList,
  };
}
