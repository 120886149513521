interface IProps {
  data: Record<string, any>[];
  omitKeys?: string[];
  translateFunc: (key: string) => string;
}

export const convertToExportRows = (props: IProps) => {
  const { data, omitKeys, translateFunc } = props;

  return data.map((row: Record<string, any>) => {
    let result: Record<string, any> = {};

    Object.keys(row).forEach((key) => {
      if (omitKeys && omitKeys.length > 0 && omitKeys.includes(key)) return;

      result[translateFunc(key)] = row[key];
    });

    return result;
  });
};
