import { ChangeEvent, useEffect, useState } from 'react';

import {
  EnumCardBlockType,
  EnumE6BlockReason,
  EnumReissueCardReason
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../api';
import { DialogInOne, MpTextField, SingleSelection } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../components/MuiGenerals';
import { useAlerting, useTranslation } from '../../../hooks';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { useZusDialogStore } from '../../../zustand/store';
import {
  ItableApiRes,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';
import { EnumOperationDialogMode } from './ViewTable';

export default function DialogOperation() {
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const { validateGridBoxInput } = useGridBoxInputValidation();

  const { rawData = {}, dialogMode = '' } = zusDialog.meta || {};

  const typedRawData = rawData as ItableApiRes;
  const typedMode = dialogMode as EnumOperationDialogMode;

  const isReleaseCardBlock = typedMode === EnumOperationDialogMode.ReleaseCardBlock;

  const initFields = { cardBlockId: '', cardNumber: '', releaseMemo: '', reissueNewCardReason: '' };

  const [fields, setFields] = useState<{
    cardBlockId: string;
    cardNumber: string;
    releaseMemo: string;
    // reissue new card
    reissueNewCardReason: string;
  }>(initFields);

  useEffect(() => {
    if (typedRawData.cardBlockType === EnumCardBlockType.LostCard) {
      setFields((f) => ({ ...f, reissueNewCardReason: String(EnumReissueCardReason.Lost) }));
    }
    if (typedRawData.E6BlockReason === EnumE6BlockReason.fraud) {
      setFields((f) => ({ ...f, reissueNewCardReason: String(EnumReissueCardReason.Fraud) }));
    }
  }, [typedRawData.cardBlockType, typedRawData.E6BlockReason]);

  const closeDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const releaseCardBlockLabelElePairArr: [string, JSX.Element][] = [
    [TK.customerNumber, <Box>{typedRawData.customerNumber}</Box>],
    [TK.cardBlockId, <Box>{typedRawData.cardBlockId || '-'}</Box>],
    [TK.panLastFour, <Box>{typedRawData.panLast4 || '-'}</Box>],
    [
      TK.releaseMemo,
      <MpTextField
        label={tc('phInputField', { fieldName: t(TK.releaseMemo) })}
        value={fields.releaseMemo}
        onChange={onChange('releaseMemo')}
      />,
    ],
  ];
  const reissueNewCardLabelElePairArr: [string, JSX.Element][] = [
    [TK.customerNumber, <Box>{typedRawData.customerNumber}</Box>],
    [TK.programName, <Box>{typedRawData.programName}</Box>],
    [
      TK.reissueNewCardReason,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.reissueNewCardReason) })}
        value={fields.reissueNewCardReason}
        onChange={onChange('reissueNewCardReason')}
        enumData={EnumReissueCardReason}
        disabled={
          typedRawData.cardBlockType === EnumCardBlockType.LostCard ||
          typedRawData.E6BlockReason === EnumE6BlockReason.fraud
        }
        clearSelect={() => {
          setFields((f) => ({ ...f, reissueNewCardReason: '' }));
        }}
      />,
    ],
  ];

  const labelElePairArr = isReleaseCardBlock
    ? releaseCardBlockLabelElePairArr
    : reissueNewCardLabelElePairArr;

  const getFlowDetails = () => {
    let apiFunc;
    let apiPayload: any;
    let alertMsg;

    if (isReleaseCardBlock) {
      apiFunc = APIs.CustomerManagement.cardBlockRequest.releaseCardBlock;
      apiPayload = {
        customerNumber: typedRawData.customerNumber,
        cardBlockId: Number(typedRawData.cardBlockId),
        releaseMemo: fields.releaseMemo,
      };
      alertMsg = t('release_card_block_success_alert');
    } else {
      apiFunc = APIs.CustomerManagement.cardBlockRequest.reissueNewCard;
      apiPayload = {
        customerNumber: typedRawData.customerNumber,
        cardBlockId: Number(typedRawData.cardBlockId),
        reissueReason: Number(fields.reissueNewCardReason),
      };
      alertMsg = t('reissue_card_success_alert');
    }

    return { apiFunc, apiPayload, alertMsg };
  };

  const onConfirm = async () => {
    const { apiFunc, apiPayload, alertMsg } = getFlowDetails();

    const invalidMessage = validateGridBoxInput(labelElePairArr);

    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const res = await apiFunc(apiPayload);

    if (!res) return;

    alerting('success', alertMsg);

    await closeDialog();
    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    title: isReleaseCardBlock ? t(TK.releaseCardBlock) : t(TK.reissueNewCardTitle),
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: closeDialog,
    },
    content: <GridBox labelElePairArr={labelElePairArr} columnCount={1} />,
    onConfirm: onConfirm,
    onCancel: closeDialog,
    size: 'sm',
    dialogActionStyling: {
      marginTop: '12px',
    },
  };

  return <DialogInOne {...dialogConfig} />;
}
