import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import {
  EnumRepaymentStatus,
  EnumRepaymentStep,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { OpRedirect } from '../../../assets/icons';
import { LoadingDialog } from '../../../components';
import { ExportBtn, OpIconButton } from '../../../components/Button';
import CopyableText from '../../../components/CopyableText';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../components/Layout';
import { Box, Container } from '../../../components/MuiGenerals';
import { displayAmountWithCurrency } from '../../../helper';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../hooks';
import {
  displayAmountCurrying,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from '../../../utils';
import { useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { hasPermission } = usePermission();

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const redirectPermission = FeatureCode.Transactions.RepaymentSchedule.prefix;

  const batchOrderIdCell = (params: GridRenderCellParams<any, any, any>) => {
    const batchOrderId = params.row[TK.batchOrderId];
    const redirectHandler = () =>
      window.open(
        `/repaymentSchedule?batchOrderId=${batchOrderId}`,
        '_blank',
        'noopener,noreferrer'
      );

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: hasPermission(redirectPermission) ? 'space-between' : 'center',
          width: '100%',
        }}
      >
        <CopyableText text={batchOrderId} sx={{ width: '80%' }} />
        {hasPermission(redirectPermission) && (
          <OpIconButton title={''} size="1rem" svgUrl={OpRedirect} onClick={redirectHandler} />
        )}
      </Box>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.batchOrderId, {
      minWidth: 150,
      renderCell: batchOrderIdCell,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.asset, {
      minWidth: 150,
    }),
    useGenGridCol(TK.assetCollateralAmount),
    useGenGridCol(TK.assetRepaymentAmount),
    useGenGridCol(TK.releaseCreditAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.repaymentAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.status, {
      minWidth: 150,
    }),
    useGenGridCol(TK.step, {
      minWidth: 150,
    }),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const displayAssetAmount = displayAmountCurrying(item.assetCurrencyDecimals);
      const displayCreditAmount = displayAmountCurrying(0, 2);

      const displayCreditAmountWithCurrency = (value: string | number) =>
        displayAmountWithCurrency(displayCreditAmount(value), item.creditCurrency);

      const mappedResult = [
        [TK.batchOrderId, item.batchOrderId],
        [TK.programName, item.programName],
        [TK.customerNumber, item.customerNumber],
        [TK.asset, item.assetCurrency],
        [TK.assetCollateralAmount, displayAssetAmount(item.assetCollateralAmount)],
        [TK.assetRepaymentAmount, displayAssetAmount(item.assetRepaymentAmount)],
        [TK.releaseCreditAmount, displayCreditAmountWithCurrency(item.creditReleasedAmount)],
        [TK.repaymentAmount, displayCreditAmountWithCurrency(item.creditRepaymentAmount)],
        [TK.status, t(EnumRepaymentStatus[item.status])],
        [TK.step, t(EnumRepaymentStep[item.step])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
