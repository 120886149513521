export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedDateFrom',
  lastModifiedTimeTo = 'lastModifiedDateTo',
  programNames = 'programNames',
  customerLevel = 'customerLevel',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.programNames]: string[];
  [FilterFieldsKeys.customerLevel]: string;
}
