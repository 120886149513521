import { EnumRiskLevel } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

export const getRiskLevelDisplayValue = (
  riskLevel: string | number,
  translateFunc: (val: any) => string
) => {
  const riskLevelValue = EnumRiskLevel[Number(riskLevel)];

  if (!riskLevelValue) return '';

  // example: 0 No Risk Detected / 1 Info
  return `${Number(riskLevel)} ${translateFunc(riskLevelValue)}`;
};
