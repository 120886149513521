import { CustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'cardBlockRequest';
const exportFileName = 'Card Block Request';

const PermissionKey = CustomerManagement.CardBlockRequest;

interface Ifields {
  cardBlockId: string;
  programName: string[];
  cardBlockType: string;
  status: string;
  customerNumber: string;
  cardNumber: string;
  panLastFour: string;
}

const initFields: Ifields = {
  cardBlockId: '',
  programName: [],
  cardBlockType: '',
  status: '',
  customerNumber: '',
  cardNumber: '',
  panLastFour: '',
};

interface IsearchParam {
  cardBlockId: string;
  cardBlockType: string;
  programNames: string[];
  status: string;
  customerNumber: string;
  cardNumber: string;
  releasedTimeFrom: string;
  releasedTimeTo: string;
  creationTimeFrom: string;
  creationTimeTo: string;
  panLast4: string;
}

const translateKeyObj = {
  operation: 'operation',
  creationTime: 'creation_time',
  programName: 'program_name',
  cardBlockId: 'card_block_id',
  cardBlockType: 'card_block_type',
  customerNumber: 'customer_number',
  cardNumber: 'card_number',
  status: 'status',
  releaseTime: 'release_time',
  releasedBy: 'released_by',
  createdBy: 'created_by',
  remarks: 'remarks',
  memo: 'memo',
  releaseMemo: 'release_memo',
  e6BlockReason: 'e6_block_reason',
  releasable: 'releasable',
  customerRemarks: 'customer_remarks',
  backOfficeRemarks: 'back_office_remarks',
  releaseCardBlock: 'release_card_block',
  reissueNewCard: 'reissue_new_card',
  reissueNewCardTitle: 'reissue_new_card_title',
  reissueNewCardReason: 'reissue_new_card_reason',
  panLastFour: 'pan_last_four',
  createCardBlockReason: 'create_card_block_reason',
} as const;

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  createRecord: (params: any, config?: any) => Promise<any>;
} = {
  export: api.CustomerManagement.cardBlockRequest.getExport,
  table: api.CustomerManagement.cardBlockRequest.getAll,
  createRecord: api.CustomerManagement.cardBlockRequest.createCardBlockRequest,
} as const;

interface ItableApiRes {
  cardBlockId: string;
  programName: string;
  cardBlockType: number;
  customerNumber: string;
  cardNumber: string;
  E6BlockReason: string;
  status: number;
  customerReleasable: boolean;
  releaseMemo: string;
  memo: string;
  customerRemark?: any;
  backofficeRemark?: any;
  creationTime: string;
  createdBy: string;
  releaseTime: string;
  releasedBy: string;
  panLast4: string;
}
export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
};
