import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { TableCell, TableHead, TableRow } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { EnumCustomerAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { OpEdit } from '../../../../assets/icons';
import { LoadingDialog } from '../../../../components';
import { ExportBtn, OpIconButton } from '../../../../components/Button';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../../components/Layout';
import { Container } from '../../../../components/MuiGenerals';
import { displayAmountWithCurrency } from '../../../../helper';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../../hooks';
import { useGetProgram } from '../../../../hooks/useProgram';
import {
  displayAmountCurrying,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from '../../../../utils';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../../utils/constant';
import { customSx } from '../../../../utils/styling';
import { useZusDialogStore } from '../../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogEdit from './DialogEdit';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn
        isShow={hasPermission(PermissionKey.CustomerAccountTab.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const renderOperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const apiRes = params.row.rawData as ItableApiRes;

    const clickHandler = () => zusDialog.open('operationDialog', { apiRes });

    // const isDisabled = apiRes.status === EnumCustomerAccountStatus.Pending

    return <OpIconButton title={t(TK.edit)} svgUrl={OpEdit} onClick={clickHandler} />;
  };

  const visibilityConfigArr: {
    fieldName: string;
    hasPermission: boolean;
  }[] = [
      {
        fieldName: TK.operation,
        hasPermission: hasPermission(PermissionKey.CustomerAccountTab.Edit),
      },
    ];

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      minWidth: 150,
      renderCell: renderOperationCell,
    }),
    useGenGridCol(TK.programAgentId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.distributorAgentId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerLevel, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerAccountStatus),
    useGenGridCol(TK.maxCreditLimit),
    useGenGridCol(TK.creditLimit),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  const header = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={2}>
            Country
          </TableCell>
          <TableCell align="center" colSpan={7}>
            Details
          </TableCell>
        </TableRow>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell
              key={index}
              align={column.align}
              style={{ top: 0, minWidth: column.minWidth, borderRight: '1px solid black' }}
            ></TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DialogEdit />
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
          // Header: header,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);
  const { EnumProgramCurrency } = useGetProgram();

  const listMapping = (array: any, omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const displayAmount = displayAmountCurrying(0, 2);
      const currency = EnumProgramCurrency[item.programName];
      const mappedResult = [
        ['rawData', item],
        [TK.programAgentId, item.programAgentId],
        [TK.distributorAgentId, item.distributorAgentId],
        [TK.customerId, item.customerId],
        [TK.customerNumber, item.customerNumber],
        [TK.customerLevel, item.customerLevel],
        [TK.programName, item.programName],
        [TK.customerAccountStatus, t(EnumCustomerAccountStatus[item.status])],
        [
          TK.maxCreditLimit,
          displayAmountWithCurrency(displayAmount(item.maxCreditLimit), currency),
        ],
        [TK.creditLimit, displayAmountWithCurrency(displayAmount(item.creditLimit), currency)], // TODO change to item.creditLimit
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key, _value]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
