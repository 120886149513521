import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { EptTcsp } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

const getTcspAccounts = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(MerchantPortal.EptTcsp.tcspAccount.getAll, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptTcsp.tcspAccount.export, input);
};

const postUpdateStatus = async (
  params: { customerNumber: string; status: number },
  config?: any
) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(MerchantPortal.EptTcsp.tcspAccount.updateStatus, input);
};

const getWalletAddressInfo = async (params: any, config?: any) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<never, { address: string }>(EptTcsp.tcspAccount.qrCode, input);
};

export default { getTcspAccounts, getExport, postUpdateStatus, getWalletAddressInfo };
