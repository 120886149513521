import React from 'react';

import { Box } from '../../../../components/MuiGenerals';
import { customSx } from '../../../../utils/styling';
import { COLOR_THEME, PALETTE } from '../../../../style/colorTheme';
import { useTranslation } from '../../../../hooks';

const FilterSectionActionRow = ({ children }: { children: React.ReactNode }) => {
  const { tc } = useTranslation();

  return (
    <Box
      sx={{
        '& .MuiButton-root': {
          color: COLOR_THEME.CustomSx.DatagridContainer.Text,
          borderColor: COLOR_THEME.CustomSx.DatagridContainer.Text,
        },

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 0',
      }}
    >
      <div
        style={{
          color: PALETTE.ButtonPrimary,
          fontSize: '20px',
          fontWeight: 'bold',
        }}
      >
        {tc('filter')}
      </div>

      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        {children}{' '}
      </div>
    </Box>
  );
};

export default FilterSectionActionRow;
