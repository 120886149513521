import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import {
  EnumCardBlockStatus,
  EnumCardBlockType,
  EnumE6BlockReason,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { LoadingDialog } from '../../../components';
import { ExportBtn } from '../../../components/Button';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../hooks';
import { downloadFiles, getFullApiResponse, toDisplayTime } from '../../../utils';
import { dataGridColumnConfig, useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogOperation from './DialogOperation';

export enum EnumOperationDialogMode {
  ReleaseCardBlock,
  ReissueNewCard,
}

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <DialogOperation />
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const { hasPermission, hasPermissionMultipleEither } = usePermission();

  const content: GridRowsProp = listMapping(rows, omitKeys);

  const renderOperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const rawData = params.row['rawData'];

    const isBlockStatus = String(rawData.status) === String(EnumCardBlockStatus.blocked);

    const isSystemBlocked =
      isBlockStatus && String(rawData.cardBlockType) === String(EnumCardBlockType.SystemBlock);

    const isLostCard =
      isBlockStatus && String(rawData.cardBlockType) === String(EnumCardBlockType.LostCard);

    const isFraud =
      isBlockStatus && String(rawData.E6BlockReason) === String(EnumE6BlockReason.fraud);

    const isReleaseCardBlockEnabled = isBlockStatus && !isSystemBlocked && !isLostCard;

    const isReissueCardEnabled = isLostCard || isFraud;

    const handleOpenOperationDialog = (dialogMode: EnumOperationDialogMode) => () =>
      zusDialog.open('operationDialog', { rawData, dialogMode });

    return (
      <ButtonMenu
        title={t(TK.operation)}
        options={[
          {
            name: t(TK.releaseCardBlock),
            onClickFn: handleOpenOperationDialog(EnumOperationDialogMode.ReleaseCardBlock),
            noShow: !hasPermission(PermissionKey.ReleaseCardBlock),
            isDisabled: !isReleaseCardBlockEnabled,
          },
          {
            name: t(TK.reissueNewCard),
            onClickFn: handleOpenOperationDialog(EnumOperationDialogMode.ReissueNewCard),
            noShow: !hasPermission(PermissionKey.ReissueNewCard),
            isDisabled: !isReissueCardEnabled,
          },
        ]}
      />
    );
  };

  useEffect(() => {
    setCount(count);
  }, [listRes.data]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      minWidth: 150,
      renderCell: renderOperationCell,
    }),
    useGenGridCol(TK.cardBlockId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.cardBlockType, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.cardNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.panLastFour, {
      minWidth: 150,
    }),
    useGenGridCol(TK.e6BlockReason, {
      minWidth: 150,
    }),
    useGenGridCol(TK.status, {
      minWidth: 150,
    }),
    useGenGridCol(TK.releasable, {
      minWidth: 150,
    }),
    useGenGridCol(TK.releaseMemo, {
      minWidth: 150,
    }),
    useGenGridCol(TK.memo, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerRemarks, {
      minWidth: 150,
    }),
    useGenGridCol(TK.backOfficeRemarks, {
      minWidth: 150,
    }),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.releasedBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.releaseTime, {
      minWidth: 150,
    }),
  ];

  const visibilityConfigArr = [
    {
      fieldName: TK.operation,
      hasPermission: hasPermissionMultipleEither([
        PermissionKey.ReleaseCardBlock,
        PermissionKey.ReissueNewCard,
      ]),
    },
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <DataGrid
      {...dataGridDefaults}
      rows={content}
      rowCount={count}
      columns={columns}
      page={zusParams.body.page}
      onPageChange={zusParams.setPage}
      components={{
        NoRowsOverlay,
        Footer: CustomPagination,
      }}
      componentsProps={{
        footer: { totalRecords: count },
      }}
      initialState={dataGridColumnConfig(visibilityConfigArr)}
    />
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc, tb } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const getSafeCustomerRemark = () => {
        if (typeof item.customerRemark !== 'string') return '';

        return item.customerRemark;
      };

      // have safe check because api sometimes return object with weird data
      const safeCustomerRemark = getSafeCustomerRemark();

      const mappedResult = [
        ['rawData', item],
        [TK.cardBlockId, item.cardBlockId],
        [TK.programName, item.programName],
        [TK.cardBlockType, t(EnumCardBlockType[item.cardBlockType])],
        [TK.customerNumber, item.customerNumber],
        [TK.cardNumber, item.cardNumber],
        [TK.panLastFour, item.panLast4],
        [TK.e6BlockReason, t(item.E6BlockReason)],
        [TK.status, t(EnumCardBlockStatus[item.status])],
        [TK.releasable, tb(item.customerReleasable)],
        [TK.releaseMemo, item.releaseMemo],
        [TK.memo, item.memo],
        [TK.customerRemarks, safeCustomerRemark],
        [TK.backOfficeRemarks, item.backofficeRemark],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.creationTime)],
        [TK.releasedBy, item.releasedBy],
        [TK.releaseTime, toDisplayTime(item.releaseTime)],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
