import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface Chain {
  id: string;
  chain_type: number;
  chain_id: string;
  chain_code: string;
  native_asset_name: string;
  created_date: string;
  last_modified_date: string;
  latest_block_number: string;
  latest_pending_block_number: string;
}

interface InitState {
  list: Chain[];
}

const initialState: InitState = { list: [] };

export const chainsSlice = createSlice({
  name: 'chainsSlice',
  initialState,
  reducers: {
    setChainsList: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setChainsList } = chainsSlice.actions;

export const selectChainList = (state: RootState) => state.chains;

export default chainsSlice.reducer;
