import { getSourceOfFundsDisplayValue } from '../../../../../../helper/getSourceOfFundsDisplayValue';
import { getSourceOfWealthDisplayValue } from '../../../../../../helper/getSourceOfWealthDisplayValue';
import { getTcspExpectedTxAmountPerMonthDisplayValue } from '../../../../../../helper/getTcspExpectedTxAmountPerMonthDisplayValue';
import { getTcspExpectedTxPerMonthDisplayValue } from '../../../../../../helper/getTcspExpectedTxPerMonthDisplayValue';
import { getTcspTotalVolumeOfAssetDisplayValue } from '../../../../../../helper/getTcspTotalVolumeOfAssetDisplayValue';
import { useTranslation } from '../../../../../../hooks';
import Column from '../../../../../approval/components/DetailView/Column';
import OptionalValue from '../../../../../approval/components/DetailView/OptionalValue';
import RowItem from '../../../../../approval/components/DetailView/RowItem';
import Section from '../../../../../approval/components/DetailView/Section';
import { TcspApplicationAnswer } from '../../../types/GetListRes';

const LevelOfActivityAnticipated = ({ data }: { data: TcspApplicationAnswer }) => {
  const { t, translate } = useTranslation('tcspAccountApplication');

  const sourceOfWealth = getSourceOfWealthDisplayValue(data.sourceOfWealth, translate);

  const sourceOfFunds = getSourceOfFundsDisplayValue(data.sourceOfFunds, translate);

  const totalVolumeOfAsset = getTcspTotalVolumeOfAssetDisplayValue(data.totalAssetHKD, translate);

  const expectedTxPerMonth = getTcspExpectedTxPerMonthDisplayValue(
    data.expectedTxPerMonth,
    translate
  );

  const expectedTxAmountPerMonth = getTcspExpectedTxAmountPerMonthDisplayValue(
    data.expectedTxAmountPerMonth,
    translate
  );

  return (
    <Section>
      <Column>
        <RowItem title={t('initialAndOngoingSOurceOfCustomerWealth')}>
          {sourceOfWealth}
          <OptionalValue value={data.sourceOfWealthOthers || ''} />
        </RowItem>

        <RowItem title={t('sourceAndOriginOfFunds')}>
          {sourceOfFunds}
          <OptionalValue value={data.sourceOfFundsOthers || ''} />
        </RowItem>

        <RowItem title={t('totalVolumeOfAsset')}>{totalVolumeOfAsset}</RowItem>
        <RowItem title={t('levelOfActivityAnticipatedTitle')}>{expectedTxPerMonth}</RowItem>
        <RowItem title={t('expectedTransactionAmount')}>{expectedTxAmountPerMonth}</RowItem>
        <RowItem title={t('remarks')}>{data.remarks}</RowItem>
      </Column>
    </Section>
  );
};

export default LevelOfActivityAnticipated;
