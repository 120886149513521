import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const ToCurrencyEnum = {
  HKD: 'HKD',
};

const ExchangeRateConfigToCurrencySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { t, tc } = useTranslation('exchangeRateConfiguration');

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('to') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={ToCurrencyEnum}
      nameFn={(item) => item}
    />
  );
};

export default ExchangeRateConfigToCurrencySingleSelection;
