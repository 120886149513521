import { EPtAuth } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';
import { IloginResult } from '../types';

const endpointAuth = { ...EPtAuth.auth };
const getAccessToken = async (authToken: string, config = {}) => {
  const params = { accessToken: authToken };
  const res = await axios.post<never, IloginResult>(endpointAuth.verifyAccessToken, params, config);
  return res;
};

const getNewTokens = async (refreshToken: string, config = {}) => {
  return await axios.post<never, IloginResult>(endpointAuth.refreshToken, { refreshToken }, {
    ...config,
    ignoreToken: true,
  } as any);
};

export default { getAccessToken, getNewTokens };
