export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedDateFrom',
  lastModifiedTimeTo = 'lastModifiedDateTo',
  partnerName = 'partnerName',
  programNames = 'programNames',
  homeCurrency = 'homeCurrency',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.partnerName]: string;
  [FilterFieldsKeys.programNames]: string[];
  [FilterFieldsKeys.homeCurrency]: string;
}
