import { ChangeEvent, useState } from 'react';

import { EnumCreditCardState } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum';
import {
  EnumCardBlockType,
  EnumE6BlockReason,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../api';
import { CardInfo } from '../../../api/types';
import { DialogInOne, MpTextField, SingleSelection } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../components/MuiGenerals';
import { useAlerting, useTranslation } from '../../../hooks';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { useZusDialogStore } from '../../../zustand/store';
import { apiObj as api, translateKeyObj as TK, translatePrefix, useZusParams } from './config';

interface Ifields {
  customerNumber: string;
  programName: string;
  cardBlockType: string;
  createCardBlockReason?: string;
  cardNumber: string;
  backOfficeRemarks: string;
}

const initFields: Ifields = {
  customerNumber: '',
  programName: '',
  cardBlockType: '',
  createCardBlockReason: '',
  cardNumber: '',
  backOfficeRemarks: '',
};

const availableCardBlockType = {
  BackofficeBlock: EnumCardBlockType.BackofficeBlock,
  LostCard: EnumCardBlockType.LostCard,
};

interface CustomerCardDetails {
  programName: string;
  cardNumberListEnum: Record<string, string>;
  cardNumberList: CardInfo[];
}

export default function DialogCreateRequest() {
  const [fields, setFields] = useState(initFields);
  const [customerCardDetails, setCustomerCardDetails] = useState<CustomerCardDetails>();
  const [selectedCardNumberHash, setSelectedCardNumberHash] = useState<string>('');

  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { validateGridBoxInput } = useGridBoxInputValidation();
  const { alerting } = useAlerting();
  const { t, te, tc } = useTranslation(translatePrefix);

  const isDisabledCardNumSelection =
    !customerCardDetails || Object.keys(customerCardDetails?.cardNumberListEnum)?.length === 0;

  const isBackOfficeBlock =
    String(fields.cardBlockType) === String(EnumCardBlockType.BackofficeBlock);

  const isRequiredCreateCardBlockReason = isBackOfficeBlock;

  const getIsLostCard = (cardBlockType: string) => {
    return String(cardBlockType) === String(EnumCardBlockType.LostCard);
  };

  const resetFields = () => {
    setFields(initFields);
    setCustomerCardDetails(undefined);
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const getCardNumberListEnum = (list: CardInfo[]) => {
    const result = {} as Record<string, string>;

    for (let i = 0; i < list.length; i++) {
      const cardInfo = list[i];
      const isValidCard = cardInfo.state !== EnumCreditCardState.invalid;

      if (isValidCard) {
        result[cardInfo.panLast4] = cardInfo.cardNumber;
      }
    }

    return result;
  };

  const getCustomerCardDetails = async (customerNumber: string) => {
    const res = await APIs.MerchantPortal.query.getProgramNameAndCardDetails({
      customerNumber,
    });

    if (!res) {
      return;
    }

    const validCardList = res.filter((card) => card.state !== EnumCreditCardState.invalid);

    const programName = validCardList[0] ? validCardList[0].programName : '';

    if (validCardList.length === 1) {
      setFields((f) => ({ ...f, cardNumber: validCardList[0].cardNumber }));
      setSelectedCardNumberHash(validCardList[0].cardNumberHash);
    }

    const cardNumberListEnum = getCardNumberListEnum(res);

    return {
      programName: programName,
      cardNumberListEnum,
      cardNumberList: res,
    };
  };

  const handleCustomerNumberInputBlur = async () => {
    const { customerNumber } = fields;

    if (!customerNumber) {
      return;
    }

    zusDialog.openExtra('loadingDialog');

    const customerCardDetails = await getCustomerCardDetails(customerNumber);

    const isHaveCustomerCardDetails =
      customerCardDetails && Object.keys(customerCardDetails).length > 0;

    if (isHaveCustomerCardDetails) {
      setCustomerCardDetails(customerCardDetails);
    }

    zusDialog.closeExtra();
  };

  const getCardNumberHash = (cardNumber: string) => {
    const selectedCard = customerCardDetails?.cardNumberList.find(
      (card) => card.cardNumber === cardNumber
    );

    return selectedCard?.cardNumberHash;
  };

  const handlePanLastFourOnChange = (e: any) => {
    const cardNumber = e.target.value;
    const cardNumberHash = getCardNumberHash(cardNumber);

    setSelectedCardNumberHash(cardNumberHash || '');
    onChange('cardNumber')(e);
  };

  const handleCardBlockTypeOnChange = (e: any) => {
    const cardBlockType = e.target.value;
    const isLostCard = getIsLostCard(cardBlockType);

    if (isLostCard) {
      setFields((f) => ({ ...f, createCardBlockReason: EnumE6BlockReason.loststolen }));
    }

    onChange('cardBlockType')(e);
  };

  const getBlockReasonEnum = () => {
    const isLostCard = getIsLostCard(fields.cardBlockType);

    if (isLostCard) {
      return { lostStolen: EnumE6BlockReason.loststolen };
    }

    return { suspended: EnumE6BlockReason.suspended, fraud: EnumE6BlockReason.fraud };
  };

  const BlockReasonEnum = getBlockReasonEnum();

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.customerNumber,
      <MpTextField
        label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
        value={fields.customerNumber}
        onChange={onChange('customerNumber')}
        onBlur={handleCustomerNumberInputBlur}
      />,
    ],
    [TK.programName, <Box>{customerCardDetails?.programName || '-'}</Box>],
    [
      TK.cardBlockType,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.cardBlockType) })}
        value={fields.cardBlockType}
        onChange={handleCardBlockTypeOnChange}
        enumData={availableCardBlockType}
        clearSelect={() => {
          setFields((f) => ({ ...f, cardBlockType: '', createCardBlockReason: '' }));
        }}
      />,
    ],
    [
      TK.createCardBlockReason,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.createCardBlockReason) })}
        value={fields.createCardBlockReason || ''}
        onChange={onChange('createCardBlockReason')}
        enumData={BlockReasonEnum}
        clearSelect={() => {
          setFields((f) => ({ ...f, createCardBlockReason: '' }));
        }}
        disabled={!isRequiredCreateCardBlockReason}
      />,
    ],
    [
      TK.panLastFour,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.panLastFour) })}
        value={fields.cardNumber}
        onChange={handlePanLastFourOnChange}
        enumData={customerCardDetails?.cardNumberListEnum || {}}
        disabled={isDisabledCardNumSelection}
        clearSelect={() => {
          setFields((f) => ({ ...f, cardNumber: '' }));
        }}
        nameFn={(name) => name}
      />,
    ],
    [
      TK.backOfficeRemarks,
      <MpTextField
        label={tc('phInputField', { fieldName: t(TK.backOfficeRemarks) })}
        value={fields.backOfficeRemarks}
        onChange={onChange('backOfficeRemarks')}
      />,
    ],
  ];

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />;

  const handleCloseDialog = async () => {
    await zusDialog.close();
    resetFields();
  };

  const handleSubmit = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr, [
      { fieldName: TK.backOfficeRemarks, isSkipped: true },
      { fieldName: TK.createCardBlockReason, isSkipped: !isRequiredCreateCardBlockReason },
    ]);

    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const payload = {
      customerNumber: fields.customerNumber,
      cardBlockType: Number(fields.cardBlockType),
      reason: fields.createCardBlockReason,
      cardNumber: fields.cardNumber,
      cardNumberHash: selectedCardNumberHash,
      backofficeRemark: fields.backOfficeRemarks,
      programName: customerCardDetails?.programName || '',
    };

    const res = await api.createRecord(payload);

    if (!res) {
      return;
    }

    await handleCloseDialog();
    zusParams.refetch();
    alerting('success', tc('create_request_success'));
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('create_request'),
    self: {
      open: zusDialog.match('createRequestDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,

    size: 'sm',
    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
