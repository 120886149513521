import { Properties } from 'csstype';
import { ChangeEvent, CSSProperties } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { FormControl } from '../MuiGenerals';

type RadioItem = { label: string; value: string | number; isDisabled?: boolean };

type RadioSelectionProps = {
  radioItems: RadioItem[];
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  flexDirection?: Properties['flexDirection'];
  sx?: { group: CSSProperties; icon: CSSProperties; label: CSSProperties };
};

export default function RadioSelection({
  radioItems,
  value,
  onChange,
  flexDirection = 'row',
  sx,
}: RadioSelectionProps) {
  const isFlexColumn = flexDirection === 'column';

  return (
    <FormControl>
      <RadioGroup value={value} onChange={onChange} sx={{ flexDirection, ...sx?.group }}>
        {radioItems.map(({ label, value: itemValue, isDisabled }, index) => (
          <FormControlLabel
            key={index}
            value={itemValue}
            control={<Radio sx={{ svg: { width: '16px', height: '16px', ...sx?.icon } }} />}
            label={label}
            disabled={isDisabled}
            sx={{
              span: {
                fontSize: '0.6875rem',
                paddingTop: isFlexColumn ? '0.8px' : 0,
                paddingBottom: isFlexColumn ? '0.8px' : 0,
                userSelect: 'none',
                opacity: isDisabled ? 0.5 : undefined,
                ...sx?.label,
              },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
