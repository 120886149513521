import { useTranslation } from '../../../../../../hooks';
import useValidation from '../../../../../../hooks/useValidation';
import { ValidationConfigProps, ValidationType } from '../../../../../../hooks/useValidation/types';
import { requiredValidator } from '../../../../../../utils/validators/requiredValidator';
import { Answer } from '../types';

interface IProps {
  answer: Answer;
  customerNumber: string | undefined;
}

const useAnswerValidation = (props: IProps) => {
  const { answer, customerNumber } = props;

  const { t, translate } = useTranslation('tcspAccountApplication');

  const createSelectionRequiredMessage = (fieldNameTranslateKey: string) => {
    return translate('validation.selectRequired', {
      fieldName: t(fieldNameTranslateKey),
    });
  };

  const createInputFieldRequiredMessage = (fieldNameTranslateKey: string) => {
    return translate('validation.inputRequired', {
      fieldName: t(fieldNameTranslateKey),
    });
  };

  const validationConfig: ValidationConfigProps = [
    {
      key: 'customerNumber',
      value: customerNumber,
      validators: [requiredValidator(createInputFieldRequiredMessage('customerNumber'))],
    },
    {
      key: 'trustAccountReason',
      value: answer.trustAccountReason,
      validators: [requiredValidator(createSelectionRequiredMessage('reasonForSettingUpTrustAcc'))],
    },
    {
      key: 'trustAccountReasonOthers',
      value: answer.trustAccountReasonOthers,
      validators: [
        requiredValidator(
          createInputFieldRequiredMessage('reasonForSettingUpTrustAcc'),
          answer.trustAccountReason === 1
        ),
      ],
    },
    {
      key: 'personPurportingToActOn',
      value: answer.authorizedToThirdParty,
      validators: [requiredValidator(createSelectionRequiredMessage('personPurportingToActOn'))],
    },
    {
      key: 'personPurportingToActOnOthers',
      value: answer.authorizedToThirdPartyReason,
      validators: [
        requiredValidator(
          createInputFieldRequiredMessage('personPurportingToActOn'),
          answer.authorizedToThirdParty
        ),
      ],
    },
    {
      key: 'sourceOfWealth',
      value: answer.sourceOfWealth,
      validators: [
        requiredValidator(
          createSelectionRequiredMessage('initialAndOngoingSOurceOfCustomerWealth')
        ),
      ],
    },
    {
      key: 'sourceOfWealthOthers',
      value: answer.sourceOfWealthOthers,
      validators: [
        requiredValidator(
          createInputFieldRequiredMessage('initialAndOngoingSOurceOfCustomerWealth'),
          answer.sourceOfWealth === 1
        ),
      ],
    },
    {
      key: 'sourceOfFund',
      value: answer.sourceOfFunds,
      validators: [requiredValidator(createSelectionRequiredMessage('relationshipWithCustomer'))],
    },
    {
      key: 'sourceOfFundOthers',
      value: answer.sourceOfFundsOthers,
      validators: [
        requiredValidator(
          createInputFieldRequiredMessage('relationshipWithCustomer'),
          answer.sourceOfFunds === 1
        ),
      ],
    },
    {
      key: 'totalVolumeOfAsset',
      value: answer.totalAssetHKD,
      validators: [requiredValidator(createSelectionRequiredMessage('totalVolumeOfAsset2'))],
    },
    {
      key: 'levelOfActivityAnticipated',
      value: answer.expectedTxPerMonth,
      validators: [
        requiredValidator(createSelectionRequiredMessage('levelOfActivityAnticipatedHeader')),
      ],
    },
    {
      key: 'expectedTxAmountPerMonth',
      value: answer.expectedTxAmountPerMonth,
      validators: [requiredValidator(createSelectionRequiredMessage('expectedTransactionAmount'))],
    },
    {
      key: 'wishToEnableAdvancePayment',
      value: answer.enableAdvancePayment,
      validators: [requiredValidator(createSelectionRequiredMessage('wishToEnableAdvancePayment'))],
    },
    {
      key: 'wishToEnablePrePayment',
      value: answer.enablePrePayment,
      validators: [requiredValidator(createSelectionRequiredMessage('wishToEnablePrePayment'))],
    },
  ];

  const validationTypeSortingKeys = [ValidationType.Required, ValidationType.LargerThanZero];

  return useValidation(validationConfig, validationTypeSortingKeys);
};

export default useAnswerValidation;
