import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

const getTcspAccountApplications = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(MerchantPortal.EptTcsp.tcspAccountApplication.getAll, input);
};

const getTcspApplicationDetails = async (params: any, config?: any) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get(MerchantPortal.EptTcsp.tcspAccountApplication.getAnswer, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptTcsp.tcspAccountApplication.export, input);
};
const approve = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(MerchantPortal.EptTcsp.tcspAccountApplication.approve, input);
};

const reject = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(MerchantPortal.EptTcsp.tcspAccountApplication.reject, input);
};

const createApplication = async (params: any, config?: any) => {
  const payload = {
    questionVersion: 1,
    ...params,
  };

  const input = {
    ...payload,
    ...config,
  };

  return await axios.post(MerchantPortal.EptTcsp.tcspAccountApplication.create, input);
};

const checkCustomerNumber = async (params: any, config?: any) => {
  const input = {
    params: {
      ...params,
    },
    ...config,
  };

  return await axios.get<
    never,
    { customerAccountStatus: number; tcspAccountStatus: null | number }
  >(MerchantPortal.EptQuery.query.customerNumber, input);
};

export default {
  getTcspAccountApplications,
  getTcspApplicationDetails,
  getExport,
  approve,
  reject,
  createApplication,
  checkCustomerNumber,
};
