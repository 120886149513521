import React, { ReactNode, SetStateAction } from 'react';

import { useTranslation } from '../../../../hooks';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import CustomerNumberTextField from '../../../common/filterTable/components/Filters/CustomerNumberTextField';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';
import RewardBatchIdTextField from '../../../common/filterTable/components/Filters/RewardBatchIdTextField';
import RewardMonthPicker from '../../../common/filterTable/components/Filters/RewardMonthPicker';
import RewardOrderIdTextField from '../../../common/filterTable/components/Filters/RewardOrderIdTextField';
import { Filter } from '../../../common/filterTable/types';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;
  const { t, tc } = useTranslation('rewardBatch');

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    {
      labelKey: 'creationTime',
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: 'lastModifiedTime',
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: FilterFieldsKeys.ProgramNames,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.ProgramNames]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.ProgramNames]: [] }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.ProgramNames, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.CustomerNumber,
      filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.CustomerNumber]}
          onChange={(e) => {
            updateField(FilterFieldsKeys.CustomerNumber, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.RewardBatchID,
      filter: (
        <RewardBatchIdTextField
          value={fields[FilterFieldsKeys.RewardBatchID]}
          onChange={(e) => {
            updateField(FilterFieldsKeys.RewardBatchID, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.RewardOrderID,
      filter: (
        <RewardOrderIdTextField
          value={fields[FilterFieldsKeys.RewardOrderID]}
          onChange={(e) => {
            updateField(FilterFieldsKeys.RewardOrderID, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.Month,
      filter: (
        <RewardMonthPicker
          value={fields[FilterFieldsKeys.Month]}
          onChange={(value) => {
            updateField(FilterFieldsKeys.Month, value);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
