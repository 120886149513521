import TimePicker from '../../../../../components/DatePicker';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
}

const RewardMonthPicker = (props: IProps) => {
  const { label, value, onChange } = props;
  const { t, tc } = useTranslation('rewardBatch');

  return (
    <TimePicker
      label={label || tc('phSelection', { fieldName: t('month') })}
      timeValue={value}
      views={['year', 'month']}
      setTimeValue={onChange}
      type="date"
      minEndTime={new Date('2010-01-01')}
      maxStartTime={new Date()}
      customInputFormat={'yyyy-MM'}
    />
  );
};

export default RewardMonthPicker;
