import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp
} from '@mui/x-data-grid';
import {} from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumEditCustomerAccountRequestStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { OpApprove, OpRedirect, OpView } from '../../../../assets/icons';
import { LoadingDialog } from '../../../../components';
import { ExportBtn, OpIconButton } from '../../../../components/Button';
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab
} from '../../../../components/Layout';
import { Box, Container } from '../../../../components/MuiGenerals';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation
} from '../../../../hooks';
import {
  downloadFiles,
  getFullApiResponse,
  toDisplayTime
} from '../../../../utils';
import {
  dataGridColumnConfig,
  useGenGridCol
} from '../../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../../utils/constant';
import { customSx } from '../../../../utils/styling';
import { useZusDialogStore } from '../../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';
import DialogApproval from './DialogApproval';
import DialogApprovalProgress from './DialogApprovalProgress';
import DialogDetails from './DialogDetails';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { hasPermission, hasPermissionMultipleEither } = usePermission();
  const { t } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const renderOperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const { id, status } = params.row.rawData as ItableApiRes;

    const detailBtnClickHandler = async () => {
      const details = await api.details({ id });

      if (!details) {
        return;
      }

      zusDialog.open('operationDialog', { details });
    };
    const approvalBtnClickHandler = async () => {
      const approvalsArr = await api.approvalDetails({ id });
      const details = await api.details({ id });

      if (!approvalsArr || !details) {
        return;
      }

      zusDialog.open('approvalDialog', { approvalsArr, details });
    };

    const isDisabled = status !== EnumEditCustomerAccountRequestStatus.Pending;

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '70%' }}>
        <OpIconButton title={t(TK.details)} svgUrl={OpView} onClick={detailBtnClickHandler} />
        <OpIconButton
          title={t(TK.approveOrReject)}
          svgUrl={OpApprove}
          onClick={approvalBtnClickHandler}
          isDisabled={isDisabled}
          isHidden={
            !hasPermissionMultipleEither([
              PermissionKey.Details.Approve.prefix,
              PermissionKey.Details.Reject,
            ])
          }
        />
      </Box>
    );
  };

  const renderApprovalProgress = (params: GridRenderCellParams<any, any, any>) => {
    const { row } = params;

    const approvalProgress = row[TK.approvalProgress];
    const { id } = row['rawData'];

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '36%' }}>
        {approvalProgress}
        <OpIconButton
          title={''}
          svgUrl={OpRedirect}
          size={'1rem'}
          onClick={async () => {
            const approvalsArr = await api.approvalDetails({ id });

            if (!approvalsArr) {
              return;
            }

            zusDialog.open('approvalProgressDialog', { approvalsArr });
          }}
        />
      </Box>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      minWidth: 150,
      renderCell: renderOperationCell,
    }),
    useGenGridCol(TK.requestId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.approvalProgress, {
      renderCell: renderApprovalProgress,
      minWidth: 150,
    }),

    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber),
    useGenGridCol(TK.status),
    useGenGridCol(TK.remark),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  const visibilityConfigArr: {
    fieldName: string;
    hasPermission: boolean;
  }[] = [
    {
      fieldName: TK.operation,
      hasPermission: hasPermission(PermissionKey.Details.prefix),
    },
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DialogDetails />
      <DialogApproval />
      <DialogApprovalProgress />
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
        initialState={dataGridColumnConfig(visibilityConfigArr)}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any, omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes & { remarks: string }) => {
      const displayApprovalProgress = isNil(item.approvalsRequired)
        ? ''
        : !item.approvalsRequired
        ? tc('auto')
        : `${item.approvalsCount}/${item.approvalsRequired}`;

      const mappedResult = [
        ['rawData', item],
        [TK.requestId, item.id],
        [TK.approvalProgress, displayApprovalProgress],
        [TK.programName, item.programName],
        [TK.customerNumber, item.customerNumber],
        [TK.status, t(EnumEditCustomerAccountRequestStatus[item.status])],
        [TK.remark, item.remarks],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key, _value]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
