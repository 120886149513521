import { Transactions } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { CustomerWallet } from '../../../api/MerchantPortal/customer';
import { CreditAdjustmentCreateRequestParams } from '../../../api/Transaction/creditAdjustmentRequest';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'creditAdjustmentRequest';
const exportFileName = 'Credit Adjustment Request';

const PermissionKey = Transactions.CreditAdjustmentRequest;

interface Ifields {
  programName: string[];
  customerNumber: string;
  adjustmentMode: string;
  adjustmentType: string;
  asset: string;
  status: string;
}

const initFields: Ifields = {
  programName: [],
  customerNumber: '',
  adjustmentMode: '',
  adjustmentType: '',
  asset: '',
  status: '',
};

interface IsearchParam extends IdateFilter {
  programNames: string[];
  customerNumber: string;
  mode: string;
  type: string;
  assetCurrency: string;
  status: string;
}

const translateKeyObj = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  operation: 'operation',
  orderId: 'orderId',
  adjustmentMode: 'adjustment_mode',
  adjustmentType: 'adjustment_type',
  approvalProgress: 'approval_progress',
  programName: 'program_name',
  customerNumber: 'customer_number',
  riskLevel: 'risk_level',
  assetCustodyId: 'asset_custody_id',
  asset: 'asset',
  assetBalanceWhenRequesting: 'asset_balance_when_requesting',
  assetCollateralAmount: 'asset_collateral_amount',
  exchangeRate: 'exchange_rate',
  realTimeCreditWhenRequesting: 'real_time_credit_when_requesting',
  releaseCreditAmount: 'release_credit_amount',
  estimatedReleaseCreditAmount: 'estimated_release_credit_amount',
  status: 'status',
  manualApprovalReason: 'manual_approval_reason',
  createdBy: 'created_by',
  details: 'details',
  createRequest: 'create_request',
} as const;

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  createRequest: (params: CreditAdjustmentCreateRequestParams, config?: any) => Promise<any>;
  approve: (
    params: { id: string; customerNumber?: string; remarks?: string },
    config?: any
  ) => Promise<any>;
  reject: (
    params: { id: string; customerNumber?: string; remarks?: string },
    config?: any
  ) => Promise<any>;
  exchangeRate: (
    params: {
      customerNumber: string;
      programName: string;
      fromCurrency: string;
      toCurrency: string;
    },
    config?: any
  ) => Promise<any[]>;
  customerProgram: (
    params: {
      customerNumber: string;
    },
    config?: any
  ) => Promise<string>;
  assetBalances: (
    params: {
      customerNumber: string;
      includeReward: boolean;
    },
    config?: any
  ) => Promise<CustomerWallet[]>;
} = {
  export: api.Transaction.creditAdjustmentRequest.getExport,
  table: api.Transaction.creditAdjustmentRequest.getAll,
  createRequest: api.Transaction.creditAdjustmentRequest.postCreateRequest,
  approve: api.Transaction.creditAdjustmentRequest.postApprove,
  reject: api.Transaction.creditAdjustmentRequest.postReject,
  exchangeRate: api.MerchantPortal.query.getExchangeRate,
  customerProgram: api.MerchantPortal.customer.getCustomerProgram,
  assetBalances: api.MerchantPortal.customer.getAssetBalances,
} as const;

interface ItableApiRes {
  id: number;
  merchantId: number;
  assetCustodyId?: any;
  creditRealtimeAmount: number;
  creditReleasedAmount: number;
  orderId: string;
  type: number;
  riskLevel?: any;
  customerNumber: string;
  assetCurrency?: any;
  assetCurrencyDecimals?: any;
  assetBalance?: any;
  assetCollateralAmount?: any;
  exchangeRate?: any;
  creditCurrency: string;
  creditCurrencyDecimals: number;
  status: number;
  manualApprovalReason?: any;
  approvalsRequired?: any;
  approvalsCount?: any;
  createdBy: string;
  createdDate: string;
  lastModifiedDy: string;
  lastModifiedDate: string;
  programName: string;
  mode: number;
}
export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
};
