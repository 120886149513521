import { useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../api';
import { CustomerLevel } from '../../api/types';
import { useZusDialogStore } from '../../zustand/store';
import useAlerting from '../useAlerting';
import useTranslation from '../useTranslation';

interface IProps {
  programName: string | string[];
  showNoCustomerLevelFoundAlert?: boolean;
}

const getCustomerLevelEnum = (customerLevels: CustomerLevel[]) => {
  if (!customerLevels || customerLevels?.length === 0) return {};

  const sortedCustomerLevels = customerLevels.sort((a, b) => b.seq - a.seq);

  const result = sortedCustomerLevels.reduce((acc, { customerLevel }) => {
    return { ...acc, [customerLevel]: customerLevel };
  }, {});

  return result;
};

const useCustomerLevels = (props: IProps) => {
  const { programName, showNoCustomerLevelFoundAlert = false } = props;

  const [customerLevels, setCustomerLevels] = useState<CustomerLevel[]>([]);

  const { alerting } = useAlerting();
  const zusDialog = useZusDialogStore();
  const { t } = useTranslation('customerApplication');

  const customerLevelEnum = getCustomerLevelEnum(customerLevels);

  const queryFunc = async () => {
    if (!programName || programName?.length === 0) return;

    zusDialog.openExtra('loadingDialog');

    return await APIs.MerchantPortal.query.getCustomerLevels(programName);
  };

  const handleQuerySuccess = (res: CustomerLevel[]) => {
    const shouldShowAlert = showNoCustomerLevelFoundAlert && res?.length === 0;

    if (shouldShowAlert) {
      setCustomerLevels([]);
      return alerting('warning', t('noCustomerLevelFoundAlert'));
    }

    setCustomerLevels(res);
  };

  useQuery({
    queryKey: ['getCustomerLevels', programName],
    queryFn: queryFunc,
    onSettled: () => zusDialog.closeExtra(),
    onSuccess: handleQuerySuccess,
  });

  return { CustomerLevelEnum: customerLevelEnum };
};

export default useCustomerLevels;
