import { useCallback, useEffect, useState } from 'react';

import APIs from '../../../../../../api';
import { Network } from '../../../../../../api/types';
import { DialogInOne } from '../../../../../../components';
import { IdialogInOneProps } from '../../../../../../components/DialogInOne';
import { useTranslation } from '../../../../../../hooks';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../../../../zustand/store';
import { TableRow } from '../../../types/TableRow';
import Content from './Content';

type ViewWalletAddressDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
  selectedRow?: TableRow;
};

export default function ViewWalletAddressDialog(props: ViewWalletAddressDialogProps) {
  const { isOpen, closeDialog, selectedRow } = props;

  const [network, setNetwork] = useState<Network>({} as Network);
  const [walletAddress, setWalletAddress] = useState('');

  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const resetState = () => {
    setNetwork({} as Network);
    setWalletAddress('');
  };

  const onDialogClose = () => {
    resetState();
    closeDialog();
  };

  const fetchWalletAddressInfo = useCallback(async () => {
    zusDialog.openExtra('loadingDialog');

    const res = await APIs.tcsp.tcspAccount.getWalletAddressInfo({
      customerNumber: selectedRow?.customerNumber || '',
      chainCode: network?.chainCode,
      tokenName: network?.tokenName,
    });

    zusDialog.closeExtra();

    if (!res) return;

    const walletAddressResponse = res.address;

    setWalletAddress(walletAddressResponse);
  }, [network, selectedRow?.customerNumber]);

  useEffect(() => {
    if (network && Object.keys(network).length > 0) {
      fetchWalletAddressInfo();
    }
  }, [network, fetchWalletAddressInfo]);

  const dialogConfig: IdialogInOneProps = {
    title: t('viewWalletAddressCell'),
    self: {
      open: isOpen,
      onClose: onDialogClose,
    },
    content: (
      <Content
        programName={selectedRow?.programName || ''}
        network={network}
        setNetwork={setNetwork}
        walletAddress={walletAddress}
      />
    ),
    onConfirm: () => {},
    isConfirmHidden: true,
    onCancel: onDialogClose,
    cancelButtonText: tc('close'),
    size: 'sm',
  };

  return <DialogInOne {...dialogConfig} />;
}
