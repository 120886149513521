import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

type APIList = Array<{ id: string; name: string }>;

const initialState: { list: Record<string, string | number> } = {
  list: {},
};

export const merchantListSlice = createSlice({
  name: 'merchantList',
  initialState,
  reducers: {
    setMerchantList: (state, action: PayloadAction<APIList>) => {
      if (!action.payload) {
        return;
      }
      state.list = action.payload.reduce(
        (acc: Record<string, string | number>, { id, name }) => ({
          ...acc,
          [id]: name,
          [name]: Number(id),
        }),
        {}
      );
    },
  },
});

export const { setMerchantList } = merchantListSlice.actions;

export const selectMerchantList = (state: RootState) => state.merchantList;
export default merchantListSlice.reducer;
