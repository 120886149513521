import { useEffect, useState } from 'react';

import APIs from '../../../../../api';
import { RewardRate } from '../../../../../api/types';
import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import useCustomerLevels from '../../../../../hooks/useCustomerLevels';
import { safeMultiply } from '../../../../../utils/math';

interface IProps {
  label?: string;
  value: any;
  programName: string | string[]
  showNoCustomerLevelFoundAlert?: boolean;
  handleNonExistInCustomerLevelListValue?: boolean;
  showOptionsWithRewardRate?: boolean;
  onChange: (e: any) => void;
  onClear: () => void;
}

let enumData = {};

const CustomerLevelSingleSelection = (props: IProps) => {
  const {
    label,
    value,
    programName,
    showNoCustomerLevelFoundAlert,
    showOptionsWithRewardRate,
    onChange,
    onClear,
    handleNonExistInCustomerLevelListValue,
  } = props;

  const [programRewardRateInfos, setProgramRewardRateInfos] = useState<RewardRate[]>([]);

  const { t, tc } = useTranslation('customerApplication');

  const { CustomerLevelEnum } = useCustomerLevels({ programName, showNoCustomerLevelFoundAlert });

  // handle value that not exist in the customer level list
  useEffect(() => {
    return () => {
      if (handleNonExistInCustomerLevelListValue) {
        enumData = {};
      }
    };
  }, []);

  // display reward rate in options - fetch and update state
  useEffect(() => {
    if (!showOptionsWithRewardRate) return;

    const updateRewardRates = async () => {
      const rewardRatesRes = await APIs.MerchantPortal.query.getRewardRateByProgramName({
        programName,
      });

      if (!rewardRatesRes) {
        return;
      }

      setProgramRewardRateInfos(rewardRatesRes);
    };

    updateRewardRates();
  }, [programName]);

  enumData = { ...CustomerLevelEnum, ...(value !== '' && { [value]: value }) };

  const enumForSingleSelection = handleNonExistInCustomerLevelListValue
    ? enumData
    : CustomerLevelEnum;

  const addRewardRateToDisplayName = (name: string) => {
    const rewardRateInfo = programRewardRateInfos.find((info) => {
      return info.customerLevel === name;
    });

    const rewardRate = rewardRateInfo?.rewardRate;

    if (!rewardRate) return name;

    return `${name} (${safeMultiply(rewardRate || '', 100)}%)`;
  };

  const selectionNameFunc = (name: string) => {
    if (showOptionsWithRewardRate) {
      return addRewardRateToDisplayName(name);
    }

    return name;
  };

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('customerLevel') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={enumForSingleSelection}
      nameFn={selectionNameFunc}
      isNoSorting
    />
  );
};

export default CustomerLevelSingleSelection;
