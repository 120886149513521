import React from 'react';

import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { useTranslation } from '../../../../../../hooks';
import ExchangeRateConfigAdjustmentAmountTextField from '../../../../../common/filterTable/components/Filters/ExchangeRateConfigAdjustmentAmountTextField';
import ExchangeRateConfigAdjustmentTypeSingleSelection from '../../../../../common/filterTable/components/Filters/ExchangeRateConfigAdjustmentTypeSingleSelection';
import { TableColumnKey } from '../../../types/TableColumnKeys';
import { TableRow } from '../../../types/TableRow';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../common/dialogs/layout/contentLayout';
import { ConfigFieldsKeys, IConfigFields } from './types';

interface IProps {
  data: TableRow;
  configFields: IConfigFields;
  setConfigFields: React.Dispatch<React.SetStateAction<IConfigFields>>;
  adjustedExchangeRate: string;
}

const Content = (props: IProps) => {
  const { data, configFields, setConfigFields, adjustedExchangeRate } = props;

  const adjustedExchangeRateDisplay = adjustedExchangeRate ?? '-';

  const { t } = useTranslation('exchangeRateConfiguration');

  const isAdjustmentAmountAllowSignedNumber =
    Number(configFields[ConfigFieldsKeys.AdjustmentType]) ===
    EnumMerchantExchangeRateAdjustType.AbsoluteValue;

  return (
    <Container>
      <Row>
        <RowHeader>{t(TableColumnKey.programName)}</RowHeader>
        <RowContent>{data.programName}</RowContent>
      </Row>

      <Row>
        <RowHeader>{t(TableColumnKey.from)}</RowHeader>
        <RowContent>{data.from}</RowContent>
      </Row>

      <Row>
        <RowHeader>{t(TableColumnKey.to)}</RowHeader>
        <RowContent>{data.to}</RowContent>
      </Row>

      <Row>
        <RowHeader>{t(TableColumnKey.exchangeRateOfCurrentDate)}</RowHeader>
        <RowContent>{data.exchangeRateOfCurrentDate}</RowContent>
      </Row>

      <Row>
        <RowHeader>{t(TableColumnKey.adjustmentType)}</RowHeader>
        <RowContent>
          <ExchangeRateConfigAdjustmentTypeSingleSelection
            value={configFields.adjustmentType}
            onChange={(e) =>
              setConfigFields({
                ...configFields,
                [ConfigFieldsKeys.AdjustmentType]: e.target.value,
                [ConfigFieldsKeys.AdjustmentAmount]: '',
              })
            }
            onClear={() => {
              setConfigFields({ ...configFields, [ConfigFieldsKeys.AdjustmentType]: '' });
            }}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t(TableColumnKey.adjustmentAmount)}</RowHeader>
        <RowContent>
          <ExchangeRateConfigAdjustmentAmountTextField
            value={configFields.adjustmentAmount}
            onChange={(e) =>
              setConfigFields({
                ...configFields,
                [ConfigFieldsKeys.AdjustmentAmount]: e.target.value,
              })
            }
            isAllowSignedNumber={isAdjustmentAmountAllowSignedNumber}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t(TableColumnKey.exchangeRateAfterAdjustment)}</RowHeader>
        <RowContent>{adjustedExchangeRateDisplay}</RowContent>
      </Row>
    </Container>
  );
};

export default Content;
