import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import { toDisplayTime, toDisplayDate, toDisplayMonth } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { t } = useTranslation('rewardRecord');

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const createDate = toDisplayTime(row.creationTime);
      const lastModifiedTime = toDisplayTime(row.lastModifiedTime);
      const paymentDueDate = toDisplayDate(row.paymentDueDate);
      const displayMonth = toDisplayMonth(row.month);

      return {
        id: index,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.customerNumber]: row.customerNumber,
        [TableColumnKey.rewardBatchId]: row.rewardBatchId,
        [TableColumnKey.rewardCurrency]: row.rewardCurrency,
        [TableColumnKey.rewardOrderId]: row.rewardOrderId,
        [TableColumnKey.rewardAmount]: row.rewardAmount,
        [TableColumnKey.month]: displayMonth,
        [TableColumnKey.creationTime]: createDate,
        [TableColumnKey.lastModifiedTime]: lastModifiedTime,
        [TableColumnKey.paymentDueDate]: paymentDueDate,
        [TableColumnKey.qualifiedSpendingAmount]: row.qualifiedSpendingAmount + ' ' + t('hkd'),
        [TableColumnKey.qualifiedRepaymentAmount]: row.qualifiedRepaymentAmount + ' ' + t('hkd'),
        [TableColumnKey.rewardRate]: row.rewardRate,
        [TableColumnKey.exchangeRate]: row.exchangeRate,
        [TableColumnKey.rewardAmountInBillingCurrency]:
          row.rewardAmountInBillingCurrency + ' ' + t('hkd'),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
