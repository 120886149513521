import { styled } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import { useTranslation } from '../../../hooks';

const StyledInput = styled(OutlinedInput)(
  () => `
    flex: 1;
    height: 38px !important;
    font-size: 16px;
    border-radius: 4px;
    background-color: #F9F9F9 !important;
    border: 1px solid #F9F9F9;

  
    &:hover {
      border: 1px solid #F9F9F9 !important;
      outline: 0 !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #DDE1E3 !important;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #DDE1E3 !important;
    }
  
    // firefox
    &:focus-visible {
      outline: 0 !important;
    }
  );
  `
);

const RemarksTextArea = ({ value, onChange }: { value: string; onChange: (e: any) => void }) => {
  const { tc } = useTranslation();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flex: '1',
          paddingRight: '24px',
        }}
      >
        <div style={{ marginRight: '24px', fontSize: '16px' }}>{tc('remarks')}</div>
        <StyledInput value={value} onChange={onChange} />
      </div>
    </>
  );
};

export default RemarksTextArea;
