import React from 'react';

import { MpTextFieldNumberOnly } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import PhoneCodeSelection from '../../../../components/Selection/PhoneCodeSelection';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../features/common/dialogs/layout/contentLayout';
import { useTranslation } from '../../../../hooks';
import { useZusDialogStore } from '../../../../zustand/store';
import { translateKeyObj as TK, translatePrefix } from '../config';

interface IDialogPhoneUpdateContent {
  fields: any;
  setFields: any;
}

const DialogPhoneUpdateContent: React.FC<IDialogPhoneUpdateContent> = ({ fields, setFields }) => {
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  return (
    <Container>
      <Row>
        <RowHeader>{t(TK.customerId)}</RowHeader>
        <RowContent>{zusDialog.meta.customerId}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(TK.originalPhoneNumber)}</RowHeader>
        <RowContent>{`${zusDialog.meta.phoneCountryCode} ${zusDialog.meta.phoneNumber}`}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(TK.newCountryCode)}</RowHeader>
        <RowContent>
          <PhoneCodeSelection
            label={tc('phSelection', { fieldName: t(TK.newCountryCode) })}
            value={fields.newCountryCode}
            onChange={(e: SelectChangeEvent) => {
              setFields({ ...fields, newCountryCode: e.target.value });
            }}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t(TK.newPhoneNumber)}</RowHeader>
        <RowContent>
          <MpTextFieldNumberOnly
            value={fields.newPhone}
            label={`${tc('phInputField', { fieldName: t(TK.newPhoneNumber) })}`}
            onChange={(e) => {
              setFields({ ...fields, newPhone: e.target.value });
            }}
            noCheckMaxNumber
            integerOnly
          />
        </RowContent>
      </Row>
    </Container>
  );
};

export default DialogPhoneUpdateContent;
