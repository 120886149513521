import './index.css';

import { useTranslation } from '../../../../../../hooks';
import { useZusTranslatePrefixStore } from '../../../../../../zustand/store';
import { Filter } from '../../../types';
import CollapseAllFilterView from './components/CollapseAllFilterView';
import CollapseSomeFilterView from './components/CollapseSomeFilterView';
import FilterWithLabel from './components/FilterWithLabel';

export interface ICollapsibleFiltersConfig {
  // Since collapsible filter and required filter are using two different grid layout, label width config can handle cases where two grid in not consistent in width
  leftLabelWidth?: string;
  rightLabelWidth?: string;
}

interface IProps {
  isCollapse?: boolean;
  filters: Filter[];
  onSearch: () => void;
  onReset: () => void;
  config?: ICollapsibleFiltersConfig;
}

const CollapsibleFilters = (props: IProps) => {
  const { filters, isCollapse, onSearch, onReset, config } = props;

  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);

  if (!filters) return <></>;

  function createFilterElements(filterInfos: Filter[]) {
    return filterInfos.map((item, index) => {
      const { labelKey, filter } = item;
      const isFullWidth = filterInfos.length === 1;

      return (
        <FilterWithLabel
          key={index}
          label={t(labelKey)}
          filter={filter}
          isFullWidth={isFullWidth}
        />
      );
    });
  }

  function createRequiredFiltersElements() {
    const requiredFiltersInfo = filters.filter((filter) => {
      return filter.isNotCollapsible;
    });

    return createFilterElements(requiredFiltersInfo);
  }

  function createCollapsibleFiltersElements() {
    const collapsibleFiltersInfo = filters.filter((filter) => !filter.isNotCollapsible);

    return createFilterElements(collapsibleFiltersInfo);
  }

  function createAllFiltersElements() {
    return createFilterElements(filters);
  }

  const requiredFilters = createRequiredFiltersElements();

  const collapsibleFilters = createCollapsibleFiltersElements();

  const allFilters = createAllFiltersElements();

  const isHaveRequiredFilter = requiredFilters.length > 0;

  return (
    <>
      {isHaveRequiredFilter ? (
        <CollapseSomeFilterView
          isCollapse={isCollapse}
          requiredFilters={requiredFilters}
          collapsibleFilters={collapsibleFilters}
          onSearch={onSearch}
          onReset={onReset}
          config={config}
        />
      ) : (
        <CollapseAllFilterView
          isCollapse={isCollapse}
          allFilters={allFilters}
          onSearch={onSearch}
          onReset={onReset}
        />
      )}
    </>
  );
};

export default CollapsibleFilters;
