import { EptCobrandManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';

const endPoint = { ...EptCobrandManagement.ReferralCode };

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}

async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

async function postCreateRecord(
  params: { referralCode: string; programName: string; kycIdvMethod: number; status: number },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.create, input);
}
async function postEditRecord(
  params: { referralCode: string; programName: string; kycIdvMethod: number; status: number },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.edit, input);
}

export default {
  getAll,
  getExport,
  postCreateRecord,
  postEditRecord,
};
