import { useState } from 'react';

import usePermission from './usePermission';

const initIdNumberObject = {
  value: '',
  isHidden: true,
};

export default function useDecryptIdNumber(permissionKey: string, apiFn: () => Promise<any>) {
  const [idNumberObject, setIdNumberObject] = useState(initIdNumberObject);
  const { hasPermission } = usePermission();

  const toggleIdNumberVisibility = async () => {
    if (idNumberObject.value) {
      setIdNumberObject((prev) => ({ ...prev, isHidden: !prev.isHidden }));
      return;
    }
    const res = await apiFn();
    if (!res) {
      return;
    }
    setIdNumberObject((prev) => ({
      ...prev,
      isHidden: false,
      value: res.idNumber,
    }));
  };

  const resetIdNumberObj = () => setIdNumberObject(initIdNumberObject);

  return {
    toggleIdNumberVisibility,
    idNumberObject,
    resetIdNumberObj,
    hasDecryptPermission: hasPermission(permissionKey),
  };
}
