import { useEffect } from 'react';

import api from '../../api';
import { Chain, selectChainList, setChainsList } from '../../reducer/chainsSlice';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import { ChainDisplayNames } from '../../constants/ChainDisplayNames';

interface IProps {
  shouldFetch?: boolean;
}

const dummyChains = [
  {
    id: '10',
    chain_type: 2,
    chain_id: '80001',
    chain_code: 'MUM',
    native_asset_name: 'MATIC',
    created_date: '2022-09-29T06:17:31.865Z',
    last_modified_date: '2023-11-01T06:12:13.350Z',
    latest_block_number: '41886604',
    latest_pending_block_number: '41886624',
  },
  {
    id: '9',
    chain_type: 2,
    chain_id: '97',
    chain_code: 'BSCT',
    native_asset_name: 'BNB',
    created_date: '2022-09-21T03:44:35.319Z',
    last_modified_date: '2023-11-01T06:12:14.320Z',
    latest_block_number: '34707242',
    latest_pending_block_number: '34707262',
  },
  {
    id: '12',
    chain_type: 4,
    chain_id: '2',
    chain_code: 'XRPT',
    native_asset_name: 'XRP',
    created_date: '2022-10-18T10:33:38.833Z',
    last_modified_date: '2023-10-25T04:30:39.307Z',
    latest_block_number: '83453417',
    latest_pending_block_number: '36325888',
  },
  {
    id: '100',
    chain_type: 5,
    chain_id: '2',
    chain_code: 'SOLD',
    native_asset_name: 'SOL',
    created_date: '2022-12-28T07:15:03.236Z',
    last_modified_date: '2022-12-28T07:22:39.182Z',
    latest_block_number: '183288256',
    latest_pending_block_number: '183282326',
  },
  {
    id: '34',
    chain_type: 2,
    chain_id: '65',
    chain_code: 'OKCT',
    native_asset_name: 'OKT',
    created_date: '2022-11-14T05:46:50.412Z',
    last_modified_date: '2023-11-01T06:12:14.479Z',
    latest_block_number: '25098332',
    latest_pending_block_number: '25098343',
  },
  {
    id: '8',
    chain_type: 3,
    chain_id: '1',
    chain_code: 'TRX',
    native_asset_name: 'TRX',
    created_date: '2022-09-20T03:19:28.082Z',
    last_modified_date: '2023-07-28T03:31:18.683Z',
    latest_block_number: '53297367',
    latest_pending_block_number: '30703175',
  },
  {
    id: '232',
    chain_type: 9,
    chain_id: '2',
    chain_code: 'DOGET',
    native_asset_name: 'DOGE',
    created_date: '2023-03-02T04:02:32.529Z',
    last_modified_date: '2023-06-16T08:07:13.255Z',
    latest_block_number: '4916887',
    latest_pending_block_number: '4916902',
  },
  {
    id: '133',
    chain_type: 2,
    chain_id: '43113',
    chain_code: 'FUJIC',
    native_asset_name: 'AVAX',
    created_date: '2023-01-31T11:16:43.573Z',
    last_modified_date: '2023-11-01T06:12:12.271Z',
    latest_block_number: '27308744',
    latest_pending_block_number: '27308713',
  },
  {
    id: '5',
    chain_type: 2,
    chain_id: '11155111',
    chain_code: 'SEP',
    native_asset_name: 'ETH',
    created_date: '2022-08-29T06:58:24.503Z',
    last_modified_date: '2023-11-01T06:12:13.233Z',
    latest_block_number: '4605963',
    latest_pending_block_number: '4606048',
  },
  {
    id: '101',
    chain_type: 8,
    chain_id: '2',
    chain_code: 'ADAT',
    native_asset_name: 'ADA',
    created_date: '2022-12-28T07:32:33.553Z',
    last_modified_date: '2023-11-01T06:11:53.223Z',
    latest_block_number: '1544589',
    latest_pending_block_number: '1544591',
  },
  {
    id: '13',
    chain_type: 6,
    chain_id: '2',
    chain_code: 'BCHT',
    native_asset_name: 'BCH',
    created_date: '2022-10-31T06:18:38.968Z',
    last_modified_date: '2023-11-01T05:49:17.812Z',
    latest_block_number: '1577223',
    latest_pending_block_number: '1577226',
  },
  {
    id: '1',
    chain_type: 1,
    chain_id: '1',
    chain_code: 'BTC',
    native_asset_name: 'BTC',
    created_date: '2022-08-17T02:43:51.054Z',
    last_modified_date: '2023-11-01T06:05:25.897Z',
    latest_block_number: '2536318',
    latest_pending_block_number: '2536320',
  },
  {
    id: '67',
    chain_type: 7,
    chain_id: '2',
    chain_code: 'LTCT',
    native_asset_name: 'LTC',
    created_date: '2022-11-21T03:22:57.764Z',
    last_modified_date: '2023-11-01T06:11:14.549Z',
    latest_block_number: '3002835',
    latest_pending_block_number: '3002847',
  },
];
const useChains = (props: IProps = {}) => {
  const { shouldFetch } = props;

  const dispatch = useAppDispatch();
  const { list: chainsList } = useAppSelector(selectChainList);

  const createChains = (chainsList: Chain[]) => {
    return chainsList.reduce((acc: any, { chain_code, chain_type, chain_id }) => {
      return {
        ...acc,
        [chain_code]: {
          chain_code,
          chain_type,
          chain_id,
        },
      };
    }, {});
  };

  const createChainsEnum = (chainsList: Chain[]) => {
    return chainsList.reduce((acc: Record<string, Chain | {}>, { chain_code }) => {
      const chainDisplayName = ChainDisplayNames[chain_code as keyof typeof ChainDisplayNames].name;

      return {
        ...acc,
        [chainDisplayName]: chain_code,
      };
    }, {});
  };

  const getChainTypeAndId = (chainName: string) => {
    const chain = chains[chainName];

    if (!chain) {
      return {
        chain_type: '',
        chain_id: '',
      };
    }

    const { chain_type, chain_id } = chain;

    return {
      chainType: chain_type,
      chainId: chain_id,
    };
  };

  const chains = createChains(chainsList);

  const chainsEnum = createChainsEnum(chainsList);

  useEffect(() => {
    // TODO: call api
    const updateChainsList = async () => {
      //   const res = await api.CreditPortal.getCurrencyList();
      const res = dummyChains;

      dispatch(setChainsList(res));
    };

    if (shouldFetch) {
      updateChainsList();
    }
  }, [dispatch, shouldFetch]);

  return {
    ChainsEnum: chainsEnum,
    getChainTypeAndId,
  };
};

export default useChains;
