import { EnumAdjustmentMode } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';
import { EptTransaction } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';

const endPoint = {
  ...EptTransaction.creditAdjustmentRequest,
};

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

export type CreditAdjustmentCreateRequestParams =
  | {
      mode: EnumAdjustmentMode.TCSP;
      customerNumber: string;
      assetCurrency: string;
      assetCurrencyDecimals: number;
      assetCollateralAmount: string;
    }
  | {
      mode: EnumAdjustmentMode.Classic;
      customerNumber: string;
      creditCurrency: string;
      creditReleasedAmount: string;
    };

async function postCreateRequest(params: CreditAdjustmentCreateRequestParams, config?: any) {
  const { mode, ...others } = params;

  const input = {
    ...others,
    ...config,
  };
  return await axios.post(
    mode === EnumAdjustmentMode.TCSP ? endPoint.createTcspRequest : endPoint.createClassicRequest,
    input
  );
}
async function postApprove(
  params: { id: string; customerNumber?: string; remarks?: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.approve, input);
}
async function postReject(
  params: { id: string; customerNumber?: string; remarks?: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.reject, input);
}

export default {
  getAll,
  getExport,
  postCreateRequest,
  postApprove,
  postReject,
};
