import React, { ReactNode, SetStateAction } from 'react';

import { useTranslation } from '../../../../hooks';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import CustomerNumberTextField from '../../../common/filterTable/components/Filters/CustomerNumberTextField';
import OrderIdTextField from '../../../common/filterTable/components/Filters/OrderIdTextField';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';
import RewardRepaymentCurrencySingleSelection from '../../../common/filterTable/components/Filters/RewardRepaymentCurrencySingleSelection';
import RewardTransactionTypeSingleSelection from '../../../common/filterTable/components/Filters/RewardTransactionTypeSingleSelection';
import { Filter } from '../../../common/filterTable/types';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const { tc } = useTranslation();

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    {
      labelKey: 'creationTime',
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: 'lastModifiedTime',
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: FilterFieldsKeys.ProgramNames,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.ProgramNames]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.ProgramNames]: [] }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.ProgramNames, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.OrderID,
      filter: (
        <OrderIdTextField
          value={fields[FilterFieldsKeys.OrderID]}
          onChange={(e) => {
            updateField(FilterFieldsKeys.OrderID, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.CustomerNumber,
      filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.CustomerNumber]}
          onChange={(e) => {
            updateField(FilterFieldsKeys.CustomerNumber, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.TransactionType,
      filter: (
        <RewardTransactionTypeSingleSelection
          value={fields[FilterFieldsKeys.TransactionType]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.TransactionType]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.TransactionType, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.RepaymentCurrency,
      filter: (
        <RewardRepaymentCurrencySingleSelection
          value={fields[FilterFieldsKeys.RepaymentCurrency]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.RepaymentCurrency]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.RepaymentCurrency, e.target.value);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
