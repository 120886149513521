import { useTranslation } from '../../../../hooks';
import useValidation from '../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../hooks/useValidation/types';
import { requiredValidator } from '../../../../utils/validators/requiredValidator';
import {
  CreateRecordRowKeys,
  ICreateRecordFields,
} from '../components/dialogs/CreateRecordDialog/types';

interface IProps {
  fields: ICreateRecordFields;
}

const useCreateRecordDialogValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('rewardRateConfiguration');

  const validationConfig: ValidationConfigProps = [
    {
      key: CreateRecordRowKeys.ProgramName,
      value: fields[CreateRecordRowKeys.ProgramName],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('programName'),
          })
        ),
      ],
    },
    {
      key: CreateRecordRowKeys.CustomerLevel,
      value: fields[CreateRecordRowKeys.CustomerLevel],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('customerLevel'),
          })
        ),
      ],
    },
    {
      key: CreateRecordRowKeys.RewardRate,
      value: fields[CreateRecordRowKeys.RewardRate],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('rewardRate'),
          })
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useCreateRecordDialogValidation;
