import { useEffect, useState } from 'react';

import APIs from '../../../../../api';
import { SingleSelection } from '../../../../../components';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import { useZusDialogStore } from '../../../../../zustand/store';
import { Network } from '../../../../../api/types';

interface IProps {
  label?: string;
  value: Network;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
  programName: string;
}

const useNetwork = (programName: string) => {
  const [apiRes, setApiRes] = useState<any>();

  const zusDialog = useZusDialogStore();

  const getNetworkEnum = () => {
    if (!apiRes) return {};

    return apiRes.reduce((acc: any, curr: any) => {
      const isExisted = acc[curr.networkDisplayName];

      if (isExisted) return acc;

      return {
        ...acc,
        [curr.networkDisplayName]: JSON.stringify(curr),
      };
    }, {});
  };

  const fetchNetworkList = async () => {
    zusDialog.openExtra('loadingDialog');

    const res = await APIs.MerchantPortal.query.getNetworkList({ programName });

    zusDialog.closeExtra();

    if (!res) return;

    setApiRes(res);
  };

  const networkEnum = getNetworkEnum();

  useEffect(() => {
    fetchNetworkList();
  }, []);

  return {
    NetworkEnum: networkEnum,
  };
};

const NetworkSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear, programName } = props;

  const { NetworkEnum } = useNetwork(programName);

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('network') })}
      value={JSON.stringify(value)}
      clearSelect={onClear}
      onChange={onChange}
      enumData={NetworkEnum}
      nameFn={(item) => item}
    />
  );
};

export default NetworkSingleSelection;
