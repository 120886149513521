import React, { useEffect, useState } from 'react';

import { useTranslation } from '../../../../../hooks';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../common/dialogs/layout/contentLayout';
import CustomerNumberTextField from '../../../../common/filterTable/components/Filters/CustomerNumberTextField';
import AdditionalInputField from './components/AdditionalInputField';
import ExpectedTransactionAmountPerMonthSingleSelection from './components/ExpectedTransactionAmountPerMonthSingleSelection';
import LevelOfActivityAnticipatedSingleSelection from './components/LevelOfActivityAnticipatedSingleSelection';
import ReasonForTrustAccountSingleSelection from './components/ReasonForTrustAccountSingleSelection';
import RemarksTextField from './components/RemarksTextField';
import SourceOfFundCheckboxGroup from './components/SourceOfFundCheckboxGroup';
import SourceOfWealthCheckboxGroup from './components/SourceOfWealthCheckboxGroup';
import TotalVolumeOfAssetSingleSelection from './components/TotalVolumeOfAssetSingleSelection';
import YesNoSingleSelection from './components/YesNoSingleSelection';
import { TCSP_DEFAULT_SIGNATURE } from './constants/tcspDefaultSignature';
import { createSourceOfFundBitWiseRepresentation } from './helpers/createSourceOfFundBitWiseRepresentation';
import { createSourceOfWealthBitWiseRepresentation } from './helpers/createSourceOfWealthBitWiseRepresentation';
import { Answer, SourceOfFundCheckedStatus, SourceOfWealthCheckedStatus } from './types';

interface IProps {
  answer: Answer;
  setAnswer: (answer: any) => void;
  sourceOfWealth: SourceOfWealthCheckedStatus;
  setSourceOfWealth: (sourceOfWealth: any) => void;
  sourceOfFund: SourceOfFundCheckedStatus;
  setSourceOfFund: (sourceOfWealth: any) => void;
  customerNumber: string | undefined;
  setCustomerNumber: (customerNumber: string) => void;
  isCustomerNumberValid: boolean;
}

const Content = (props: IProps) => {
  const {
    answer,
    setAnswer,
    sourceOfWealth,
    setSourceOfWealth,
    sourceOfFund,
    setSourceOfFund,
    customerNumber,
    setCustomerNumber,
    isCustomerNumberValid,
  } = props;

  const { t, tc } = useTranslation('tcspAccountApplication');

  const currentDate = new Date().toISOString().split('T')[0];

  console.log('222 Answer ', answer);

  const updateAnswer = (fieldName: string, value: any) => {
    setAnswer({
      [fieldName]: value,
    });
  };

  //   update answer with sourceOfWealth
  useEffect(() => {
    const bitWiseRepresentation = createSourceOfWealthBitWiseRepresentation(sourceOfWealth);

    const value = Number(bitWiseRepresentation) === 0 ? undefined : bitWiseRepresentation;

    updateAnswer('sourceOfWealth', value);
  }, [sourceOfWealth]);

  //   update answer with sourceOfFund
  useEffect(() => {
    const bitWiseRepresentation = createSourceOfFundBitWiseRepresentation(sourceOfFund);
    const value = Number(bitWiseRepresentation) === 0 ? undefined : bitWiseRepresentation;

    updateAnswer('sourceOfFunds', value);
  }, [sourceOfFund]);

  const onSelectionClear = (fieldName: string, value?: any) => {
    updateAnswer(fieldName, undefined);
  };

  const onReasonForTrustAccountSingleSelectionChange = (e: any) => {
    updateAnswer('trustAccountReason', Number(e.target.value));
    updateAnswer('trustAccountReasonOthers', undefined);
  };

  const onPersonPurportingToActOnSelectionChange = (e: any) => {
    updateAnswer('authorizedToThirdParty', e.target.value);
    updateAnswer('authorizedToThirdPartyReason', '');
  };

  return (
    <Container>
      <Row>
        <RowHeader>{t('customerNumber')}</RowHeader>
        <RowContent>
          <CustomerNumberTextField
            value={customerNumber}
            onChange={(e: any) => setCustomerNumber(String(e.target.value).toUpperCase())}
          />
        </RowContent>
      </Row>

      {isCustomerNumberValid && (
        <>
          {/* Reason for setting up a trust account in Hong Kong */}
          <Row>
            <RowHeader>{t('reasonForSettingUpTrustAcc')}</RowHeader>
            <RowContent>
              <ReasonForTrustAccountSingleSelection
                value={answer.trustAccountReason}
                onChange={onReasonForTrustAccountSingleSelectionChange}
                onClear={() => onSelectionClear('trustAccountReason')}
              />

              <AdditionalInputField
                isShow={answer.trustAccountReason === 1}
                fieldName={t('reasonForSettingUpTrustAcc')}
                value={answer.trustAccountReasonOthers}
                onChange={(value: string) => updateAnswer('trustAccountReasonOthers', value)}
                maxLength={256}
              />
            </RowContent>
          </Row>

          {/* Person purporting to act on behalf of you – PPTA* */}
          <Row>
            <RowHeader>{t('personPurportingToActOn')}</RowHeader>
            <RowContent>
              <YesNoSingleSelection
                label={tc('phSelection', { fieldName: t('personPurportingToActOn') })}
                value={answer.authorizedToThirdParty}
                onChange={onPersonPurportingToActOnSelectionChange}
                onClear={() => onSelectionClear('authorizedToThirdParty')}
              />

              <AdditionalInputField
                isShow={answer.authorizedToThirdParty}
                fieldName={t('personPurportingToActOn')}
                value={answer.authorizedToThirdPartyReason}
                onChange={(value: string) => updateAnswer('authorizedToThirdPartyReason', value)}
              />
            </RowContent>
          </Row>

          {/* Senior Public Figure Declaration */}
          <Row>
            <RowHeader>{t('seniorPublicFigureDeclarationTitle')}</RowHeader>
            <RowContent>{t('noSeniorPublicFigureDeclaration')}</RowContent>
          </Row>

          {/* Initial and Ongoing Sources of Customer’s Wealth or income(SoW) */}
          <Row>
            <RowHeader>{t('initialAndOngoingSOurceOfCustomerWealth')}</RowHeader>
            <RowContent>
              <SourceOfWealthCheckboxGroup
                sourceOfWealth={sourceOfWealth}
                setSourceOfWealth={setSourceOfWealth}
              />

              <AdditionalInputField
                isShow={sourceOfWealth.others}
                fieldName={t('initialAndOngoingSOurceOfCustomerWealth')}
                value={answer.sourceOfWealthOthers}
                onChange={(value: string) => updateAnswer('sourceOfWealthOthers', value)}
              />
            </RowContent>
          </Row>

          {/* Source and Origins of Funds to be used in the relationship (SoF) */}
          <Row>
            <RowHeader>{t('relationshipWithCustomer')}</RowHeader>
            <RowContent>
              <SourceOfFundCheckboxGroup
                sourceOfFund={sourceOfFund}
                setSourceOfFund={setSourceOfFund}
              />

              <AdditionalInputField
                isShow={sourceOfFund.others}
                fieldName={t('relationshipWithCustomer')}
                value={answer.sourceOfFundsOthers}
                onChange={(value: string) => updateAnswer('sourceOfFundsOthers', value)}
              />
            </RowContent>
          </Row>

          {/* Total Volume of Asset in (HKD)* */}
          <Row>
            <RowHeader>{t('totalVolumeOfAsset2')}</RowHeader>
            <RowContent>
              <TotalVolumeOfAssetSingleSelection
                value={answer.totalAssetHKD}
                onChange={(e: any) => updateAnswer('totalAssetHKD', e.target.value)}
                onClear={() => onSelectionClear('totalAssetHKD')}
              />
            </RowContent>
          </Row>

          {/* Level of Activity Anticipated */}
          <Row>
            <RowHeader>{t('levelOfActivityAnticipatedHeader')}</RowHeader>
            <RowContent>
              <LevelOfActivityAnticipatedSingleSelection
                value={answer.expectedTxPerMonth}
                onChange={(e: any) => updateAnswer('expectedTxPerMonth', e.target.value)}
                onClear={() => onSelectionClear('expectedTxPerMonth')}
              />
            </RowContent>
          </Row>

          {/* Expected Transaction Amount Per Month */}
          <Row>
            <RowHeader>{t('expectedTransactionAmount')}</RowHeader>
            <RowContent>
              <ExpectedTransactionAmountPerMonthSingleSelection
                value={answer.expectedTxAmountPerMonth}
                onChange={(e: any) => updateAnswer('expectedTxAmountPerMonth', e.target.value)}
                onClear={() => onSelectionClear('expectedTxAmountPerMonth')}
              />
            </RowContent>
          </Row>

          {/* Remarks */}
          <Row>
            <RowHeader>{t('remarks')}</RowHeader>
            <RowContent>
              <RemarksTextField
                value={answer.remarks}
                onChange={(value: string) => updateAnswer('remarks', value)}
              />
            </RowContent>
          </Row>

          {/* I do not wish to enable Advance Payment. */}
          <Row>
            <RowHeader>{t('wishToEnableAdvancePayment')}</RowHeader>
            <RowContent>
              <YesNoSingleSelection
                label={tc('phSelection', { fieldName: t('wishToEnableAdvancePayment') })}
                value={answer.enableAdvancePayment}
                onChange={(e: any) => updateAnswer('enableAdvancePayment', e.target.value)}
                onClear={() => onSelectionClear('enableAdvancePayment')}
                revertEnum
              />
            </RowContent>
          </Row>

          {/* I do not wish to enable Pre-Payment. */}
          <Row>
            <RowHeader>{t('wishToEnablePrePayment')}</RowHeader>
            <RowContent>
              <YesNoSingleSelection
                label={tc('phSelection', { fieldName: t('wishToEnablePrePayment') })}
                value={answer.enablePrePayment}
                onChange={(e: any) => updateAnswer('enablePrePayment', e.target.value)}
                onClear={() => onSelectionClear('enablePrePayment')}
                revertEnum
              />
            </RowContent>
          </Row>

          {/* Applicant’s Signature */}
          <Row>
            <RowHeader>{t('applicantSignature')}</RowHeader>
            <RowContent>
              <img src={TCSP_DEFAULT_SIGNATURE} style={{ width: '250px' }} alt="signature" />
            </RowContent>
          </Row>

          {/* Applicant’s Signature */}
          <Row>
            <RowHeader>{t('signatureDate')}</RowHeader>
            <RowContent>{currentDate}</RowContent>
          </Row>
        </>
      )}
    </Container>
  );
};

export default Content;
