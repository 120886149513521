import React, { ReactNode, SetStateAction } from 'react';

import { useGenFilterField } from '../../../../utils/ComponentHelper';
import ResetButton from '../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../common/filterTable/components/Buttons/SearchButton';
import CustomerIdTextField from '../../../common/filterTable/components/Filters/CustomerIdTextField';
import CustomerNumberTextField from '../../../common/filterTable/components/Filters/CustomerNumberTextField';
import Filters from '../../../common/filterTable/components/Filters/Filters';
import ProgramNameSingleSelection from '../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import TcspAccountStatusSingleSelection from '../../../common/filterTable/components/Filters/TcspAccountStatusSingleSelection';
import FilterActions from '../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../common/filterTable/layout/FilterGrid';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import { Filter } from '../../../common/filterTable/types';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    { labelKey: 'creationTime', filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: 'lastModifiedTime', filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: FilterFieldsKeys.ProgramNames,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.ProgramNames]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.ProgramNames]: [] }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.ProgramNames, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.CustomerId,
      filter: (
        <CustomerIdTextField
          value={fields[FilterFieldsKeys.CustomerId]}
          onChange={(e) => updateField(FilterFieldsKeys.CustomerId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.CustomerNumber,
      filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.CustomerNumber]}
          onChange={(e) => updateField(FilterFieldsKeys.CustomerNumber, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.Status,
      filter: (
        <TcspAccountStatusSingleSelection
          value={fields[FilterFieldsKeys.Status]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.Status]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.Status, e.target.value);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
