import { Reward } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'statement';
const exportFileName = 'Statement';

const PermissionKey = Reward.Statement;

interface Ifields {
  customerNumber: string;
  programName: string[];
  rewardStatus: string;
  month: Date | null;
}

const initFields: Ifields = {
  customerNumber: '',
  programName: [],
  rewardStatus: '',
  month: null,
};

interface IsearchParam extends IdateFilter {
  customerNumber: string;
  programNames: string[];
  rewardStatus: string;
  month: string;
}

const translateKeyObj = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  programName: 'program_name',
  customerNumber: 'customer_number',
  rewardStatus: 'reward_status',
  month: 'month',
  totalRebatableAmount: 'total_rebatable_amount',
  statementPeriodFrom: 'statement_period_from',
  statementPeriodTo: 'statement_period_to',
  paymentDueDate: 'payment_due_date',
  customerLevel: 'customer_level',
  rewardCurrency: 'reward_currency',
  rewardCurrencyExchangeRate: 'reward_currency_exchange_rate',
} as const;

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
} = Object.freeze({
  export: api.Reward.statement.getExport,
  table: api.Reward.statement.getAll,
});

interface ItableApiRes {
  statementId: string;
  programName: string;
  customerNumber: string;
  month: string;
  totalRebateAmount: string;
  statementPeriodFrom: string;
  statementPeriodTo: string;
  paymentDueDate: string;
  rewardStatus: number;
  creationTime: string;
  lastModifiedTime: string;
  customerLevel: string;
  rewardCurrency: string;
  rewardCurrencyExchangeRate: string;
}

export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
};
