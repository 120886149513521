import CustomerManagement from './CustomerManagement';
import MerchantPortal from './MerchantPortal';
import tcsp from './tcsp';
import Transaction from './Transaction';
import CobrandManagement from './CobrandManagement';
import Reward from './Reward';

const APIs = { CustomerManagement, tcsp, Transaction, MerchantPortal, CobrandManagement, Reward };

export default APIs;
