import CircularProgress from '@mui/material/CircularProgress';

import { useZusDialogStore } from '../../zustand/store';
import DialogInOne from '../DialogInOne';

//for query loading spin, without progress
export default function LoadingDialog(p = { forceOpen: false }) {
  const zusDialog = useZusDialogStore();
  const isOpen = p.forceOpen ? true : zusDialog.matchExtra('loadingDialog');
  return (
    <DialogInOne
      {...{
        title: '',
        self: {
          open: isOpen,
          onClose: () => {},
        },
        content: <CircularProgress color={'primary'} />,
        onCancel: zusDialog.close,
        onConfirm: () => {},
        isConfirmHidden: true,
        isCancelHidden: true,
        rest: { fullWidth: false },
        isLoadingDialog: true,
      }}
    />
  );
}
