import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { EnumTcspAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { GridBox } from '../../../../../../components/Layout';
import { Box } from '../../../../../../components/MuiGenerals';
import RadioSelection from '../../../../../../components/Selection/RadioSelection';
import useTranslation from '../../../../../../hooks/useTranslation';
import { EditRecordRowKeys, IEditRecordFields } from './type';

type EditRecordDialogContent = {
  previousStatus: EnumTcspAccountStatus | '';
  status: EnumTcspAccountStatus | '';
  setStatus: Dispatch<SetStateAction<number | ''>>;
  displayValues: IEditRecordFields | undefined;
};

export default function Content({
  previousStatus,
  status,
  setStatus,
  displayValues,
}: EditRecordDialogContent) {
  const { t } = useTranslation('enumConstants');

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => setStatus(Number(e.target.value));

  const isEnabledBtnDisabled =
    previousStatus === EnumTcspAccountStatus.Rejected ||
    previousStatus === EnumTcspAccountStatus.Blacklisted;

  const isDisabledBtnDisabled =
    previousStatus === EnumTcspAccountStatus.Blacklisted ||
    previousStatus === EnumTcspAccountStatus.Rejected;

  const isBlacklistedBtnDisabled = previousStatus === EnumTcspAccountStatus.Enabled;

  const isRejectedBtnDisabled = previousStatus === EnumTcspAccountStatus.Disabled;

  const labelElePairArr: [string, JSX.Element][] = [
    [EditRecordRowKeys.CustomerId, <Box>{displayValues?.[EditRecordRowKeys.CustomerId]}</Box>],
    [EditRecordRowKeys.ProgramName, <Box>{displayValues?.[EditRecordRowKeys.ProgramName]}</Box>],
    [
      EditRecordRowKeys.CustomerNumber,
      <Box>{displayValues?.[EditRecordRowKeys.CustomerNumber]}</Box>,
    ],
    [
      EditRecordRowKeys.LatestVersion,
      <Box>{displayValues?.[EditRecordRowKeys.LatestVersion]}</Box>,
    ],
    [
      EditRecordRowKeys.Status,
      <RadioSelection
        radioItems={[
          {
            label: t(EnumTcspAccountStatus[1]),
            value: EnumTcspAccountStatus.Enabled,
            isDisabled: isEnabledBtnDisabled,
          },
          {
            label: t(EnumTcspAccountStatus[2]),
            value: EnumTcspAccountStatus.Disabled,
            isDisabled: isDisabledBtnDisabled,
          },
          {
            label: t(EnumTcspAccountStatus[3]),
            value: EnumTcspAccountStatus.Blacklisted,
            isDisabled: isBlacklistedBtnDisabled,
          },
          {
            label: t(EnumTcspAccountStatus[4]),
            value: EnumTcspAccountStatus.Rejected,
            isDisabled: isRejectedBtnDisabled,
          },
        ]}
        value={status}
        onChange={handleRadioChange}
      />,
    ],
  ];

  return (
    <GridBox
      labelElePairArr={labelElePairArr}
      columnCount={1}
      containerSx={{ paddingBottom: '12px' }}
    />
  );
}
