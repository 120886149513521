import { ChangeEvent, cloneElement, useState } from 'react';

import { GeneralBtn, SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import { Box, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import { MpTextField } from '../../../components/TextField';
import { useTranslation } from '../../../hooks';
import useAssets from '../../../hooks/useAssets/useAssets';
import { useGetProgram } from '../../../hooks/useProgram';
import { useGenFilterField } from '../../../utils/ComponentHelper';
import { customSx } from '../../../utils/styling';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import { EnumWalletType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import { Filter } from '../../../features/common/filterTable/types';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ProgramNameMultipleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { EnumAllProgram, EnumAllCurrencyList } = useGetProgram();
  const { AllAssetCurrenciesEnum } = useAssets();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const getParams: () => IsearchParam = () => {
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    const {programName: programNames, ...rest} = fields;

    return {
      ...initZusParams,
      ...rest,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };


  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) => setFields((fields) => ({ ...fields, programName: value }))}
          onClear={() => setFields((fields) => ({ ...fields, programName: [] }))}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange('customerNumber')}
        />
      ),
    },
    {
      labelKey: TK.clientId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.clientId) })}
          value={fields.clientId}
          onChange={onChange('clientId')}
        />
      ),
    },
    {
      labelKey: TK.currency,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.currency) })}
          value={fields.currency}
          onChange={onChange('currency')}
          enumData={AllAssetCurrenciesEnum}
          clearSelect={() => setFields((fields) => ({ ...fields, currency: '' }))}
          nameFn={(name) => name}
        />
      ),
    },
    {
      labelKey: TK.lastLedgerTransactionId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.lastLedgerTransactionId) })}
          value={fields.lastLedgerTransactionId}
          onChange={onChange('lastLedgerTransactionId')}
        />
      ),
    },
    {
      labelKey: TK.walletType,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.walletType) })}
          value={fields.walletType}
          onChange={onChange('walletType')}
          enumData={EnumWalletType}
          clearSelect={() => setFields((fields) => ({ ...fields, walletType: '' }))}
          isNoSorting
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      {/* <Box sx={customSx.gridFilter} className="gridFilter">
        {fieldEleArr.map((ele, index) => cloneElement(ele, { key: index }))}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box> */}

      <FilterSectionActionRow>
        <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
