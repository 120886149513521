export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',
  ProgramNames = 'programNames',
  RewardBatchID = 'rewardBatchId',
  CustomerNumber = 'customerNumber',
  Status = 'status',
  Month = 'month',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.ProgramNames]: string[];
  [FilterFieldsKeys.RewardBatchID]: string;
  [FilterFieldsKeys.CustomerNumber]: string;
  [FilterFieldsKeys.Status]: string;
  [FilterFieldsKeys.Month]: string;
}
