import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import api from '../../../api';
import { ApiResult } from '../../../api/types';
import { ExportBtn } from '../../../components/Button';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import OperationDetailCell from '../../../features/approval/components/OperationDetailCell';
import Table from '../../../features/common/filterTable/components/Table';
import { convertToExportRows } from '../../../features/common/filterTable/helpers/convertToExportRows';
import FilterContainer from '../../../features/common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../features/common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../features/common/filterTable/layout/TableContainer';
import EditRecordDialog from '../../../features/tcsp/tcspAccount/components/Dialog/EditRecordDialog';
import ViewWalletAddressDialog from '../../../features/tcsp/tcspAccount/components/Dialog/ViewWalletAddressDialog';
import EditConfigCell from '../../../features/tcsp/tcspAccount/components/EditConfigCell';
import FilterSection from '../../../features/tcsp/tcspAccount/components/FilterSection';
import useGetListParams from '../../../features/tcsp/tcspAccount/helpers/useGetListParams';
import useTableColumns from '../../../features/tcsp/tcspAccount/helpers/useTableColumns';
import useTableRows from '../../../features/tcsp/tcspAccount/helpers/useTableRows';
import {
  FilterFields,
  FilterFieldsKeys,
} from '../../../features/tcsp/tcspAccount/types/FilterFields';
import { GetListRes } from '../../../features/tcsp/tcspAccount/types/GetListRes';
import { TableRow } from '../../../features/tcsp/tcspAccount/types/TableRow';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useTabs from '../../../hooks/useTabs';
import { downloadFiles, getFullApiResponse } from '../../../utils';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../zustand/store';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.lastModifiedTimeFrom]: '',
  [FilterFieldsKeys.lastModifiedTimeTo]: '',
  [FilterFieldsKeys.ProgramNames]: [],
  [FilterFieldsKeys.CustomerId]: '',
  [FilterFieldsKeys.CustomerNumber]: '',
  [FilterFieldsKeys.Status]: '',
};

const translatePrefix = 'tcspAccount';

const TcspAccount = () => {
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });
  const [isViewWalletAddressDialogOpen, setIsViewWalletAddressDialogOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState<TableRow>();

  const [isEditConfigDialogOpen, setIsEditConfigDialogOpen] = useState(false);

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  useEffect(() => {
    if (!isEditConfigDialogOpen) {
      setSelectedRow(undefined);
    }
  }, [isEditConfigDialogOpen]);

  const columns = useTableColumns({ renderOperationsCell });
  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  console.log('222 fields :', fields);
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { t, tc } = useTranslation(translatePrefix);
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const isShowExportBtn = hasPermission(FeatureCode.TCSP.TcspAccount.Export);

  const isShowEditRecordBtn = hasPermission(FeatureCode.TCSP.TcspAccount.Edit);

  const isShowViewAddressBtn = hasPermission(FeatureCode.TCSP.TcspAccount.ViewAddress);

  function renderOperationsCell({ row }: { row: TableRow }) {
    const viewWalletAddressCellOnClick = () => {
      setSelectedRow(row);
      setIsViewWalletAddressDialogOpen(true);
    };

    const editConfigCellOnClick = () => {
      setSelectedRow(row);
      setIsEditConfigDialogOpen(true);
    };

    return (
      <>
        <div style={{ padding: '0 12px' }}>
          <EditConfigCell
            onClick={editConfigCellOnClick}
            isDisabled={row.rawData.tcspAccountApplicationPending}
            isHide={!isShowEditRecordBtn}
          />
        </div>

        <OperationDetailCell
          title={t('viewWalletAddressCell')}
          onClick={viewWalletAddressCellOnClick}
          isDisabled={false}
          isHide={!isShowViewAddressBtn}
        />
      </>
    );
  }

  const handleSettled = () => zusDialog.closeExtra();

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;
    setList(data);
  };

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { currentTime, ...cleanParams } = listParams;
    return api.tcsp.tcspAccount.getTcspAccounts(cleanParams);
  };

  useQuery(['getTcspAccounts', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  const onExport = async () => {
    const params = getListParams(fields);
    const { currentTime, ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.tcsp.tcspAccount.getExport({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`TCSP Account`, exportRows, {});
  };

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.createdDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.createdDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.lastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.lastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const refreshTable = () => {
    setListParams({ ...listParams, currentTime: Date.now() });
  };

  const closeEditRecordDialog = () => {
    setIsEditConfigDialogOpen(false);
  };

  const closeViewWalletAddressDialog = () => {
    setIsViewWalletAddressDialogOpen(false);
  };

  return (
    <FilterTableLayoutContainer>
      <FilterContainer>
        <FilterSectionActionRow>
          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>

        <FilterSection
          fields={fields}
          setFields={setFields}
          onSearch={onFilterSearch}
          onReset={onFilterReset}
          DateObj={DateObj}
          isCollapse={isCollapse}
        />
      </FilterContainer>

      <DivideLine />

      <TableContainer>
        <EditRecordDialog
          isOpen={isEditConfigDialogOpen}
          closeDialog={closeEditRecordDialog}
          selectedRow={selectedRow}
          refreshTable={refreshTable}
        />

        <ViewWalletAddressDialog
          isOpen={isViewWalletAddressDialogOpen}
          closeDialog={closeViewWalletAddressDialog}
          selectedRow={selectedRow}
        />

        <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
        <Tabs>
          <Table
            columns={columns}
            rows={rows}
            rowCount={totalCounts}
            page={page}
            onPageChange={onPageChange}
          />
        </Tabs>
      </TableContainer>
    </FilterTableLayoutContainer>
  );
};

export default TcspAccount;
