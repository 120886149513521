import { SingleSelection } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
  revertEnum?: boolean;
}

const YesNoSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t } = useTranslation('tcspAccountApplication');

  const NormalEnum = {
    [tc('yes')]: true,
    [tc('no')]: false,
  };

  const RevertedEnum = {
    [tc('yes')]: false,
    [tc('no')]: true,
  };

  const Enum = props.revertEnum ? RevertedEnum : NormalEnum;

  return (
    <SingleSelection
      label={label || ''}
      value={isNaN(Number(value)) ? '' : value}
      onChange={onChange}
      clearSelect={onClear}
      enumData={Enum}
      nameFn={(name) => name}
      isNoSorting
    />
  );
};

export default YesNoSingleSelection;
