import React from 'react';
import { FormControl, Box } from '../../../../../../components/MuiGenerals';
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { useTranslation } from '../../../../../../hooks';
import { SourceOfWealthCheckedStatus } from '../types';

interface IProps {
  sourceOfWealth: SourceOfWealthCheckedStatus;
  setSourceOfWealth: (sourceOfWealth: any) => void;
}

const SourceOfWealthCheckboxGroup = (props: IProps) => {
  const { sourceOfWealth, setSourceOfWealth } = props;

  const { t } = useTranslation('tcspAccountApplication');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSourceOfWealth({ [event.target.name]: event.target.checked });
  };

  const withTranslation = (key: string) => {
    return t(`sourceOfWealth.${key}`);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={sourceOfWealth.others} onChange={handleChange} name="others" />
            }
            label={withTranslation('Others')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.selfEmployment}
                onChange={handleChange}
                name="selfEmployment"
              />
            }
            label={withTranslation('SelfEmployment')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.loansGrant}
                onChange={handleChange}
                name="loansGrant"
              />
            }
            label={withTranslation('LoansGrant')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.inheritance}
                onChange={handleChange}
                name="inheritance"
              />
            }
            label={withTranslation('Inheritance')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.investment}
                onChange={handleChange}
                name="investment"
              />
            }
            label={withTranslation('Investment')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.personalRealEstate}
                onChange={handleChange}
                name="personalRealEstate"
              />
            }
            label={withTranslation('PersonalRealEstate')}
          />
        </FormGroup>
      </FormControl>

      <FormControl variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.employment}
                onChange={handleChange}
                name="employment"
              />
            }
            label={withTranslation('Employment')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.businessOwnership}
                onChange={handleChange}
                name="businessOwnership"
              />
            }
            label={withTranslation('BusinessOwnership')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.spouseParentPartner}
                onChange={handleChange}
                name="spouseParentPartner"
              />
            }
            label={withTranslation('SpouseParentPartner')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.trustFund}
                onChange={handleChange}
                name="trustFund"
              />
            }
            label={withTranslation('TrustFund')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfWealth.salesOfVirtualAssets}
                onChange={handleChange}
                name="salesOfVirtualAssets"
              />
            }
            label={withTranslation('SalesOfVirtualAssets')}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default SourceOfWealthCheckboxGroup;
