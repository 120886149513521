import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { t } = useTranslation('redeemHistory');

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const toDisplayAmount = displayAmountCurrying(row.assetCurrencyDecimals);
      const redeemAmount = toDisplayAmount(row.assetRepaymentAmount);

      const createDate = toDisplayTime(row.createdDate);

      return {
        id: index,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.CustomerNumber]: row.customerNumber,
        [TableColumnKey.Currency]: row.assetCurrency,
        [TableColumnKey.RedeemAmount]: redeemAmount,
        [TableColumnKey.CreationTime]: createDate,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
