import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';
import axios from '../axiosInstance';

const getTcspTransactions = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptTransaction.tcspTransaction.getAll, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptTransaction.tcspTransaction.export, input);
};

export default { getTcspTransactions, getExport };
