export enum TableColumnKey {
  Operations = 'operations',
  ApplicationNumber = 'applicationNumber',
  ProgramName = 'programName',
  CustomerId = 'customerId',
  CustomerNumber = 'customerNumber',
  TcspCustomerApplicationType = 'tcspCustomerApplicationType',
  CustomerApplicationVersion = 'customerApplicationVersion',
  Version = 'version',
  IsLatestVersion = 'isLatestVersion',
  QuestionVersion = 'questionVersion',
  CreatedBy = 'createdBy',
  CreatedDate = 'creationTime',
  LastModifiedDate = 'lastModifiedTime',
  ApprovalProgress = 'approvalProgress',
  Status = 'status',
}
