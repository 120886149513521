import { defaultTheme } from 'react-admin';

import { COLOR_THEME, PALETTE } from '../style/colorTheme';
import { hexToRGB, importantStyle } from '../utils';

import type { RaThemeOptions } from 'react-admin';

export const theme: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,

    primary: {
      main: COLOR_THEME.MUI.Primary.Main,
      contrastText: COLOR_THEME.MUI.Primary.Text,
    },
    secondary: {
      main: COLOR_THEME.MUI.Secondary.Main,
      contrastText: COLOR_THEME.MUI.Secondary.Text,
    },

    warning: {
      main: COLOR_THEME.MUI.Warning.Main,
      contrastText: COLOR_THEME.MUI.Warning.Text,
    },

    info: {
      main: COLOR_THEME.MUI.Info.Main,
      contrastText: COLOR_THEME.MUI.Info.Text,
    },
  },
  typography: {
    fontSize: 11,
  },
  sidebar: {
    width: 320,
    closedWidth: 320,
  },
  components: {
    ...defaultTheme.components,
    // @ts-ignore react-admin doesn't add its own components
    RaLocalesMenuButton: {
      styleOverrides: {
        root: {
          '.css-azl7ko-MuiButtonBase-root-MuiButton-root': {
            fontSize: '0.5rem',
          },
          svg: {
            '&.MuiSvgIcon-fontSizeMedium': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiMenu-list': {
            backgroundColor: COLOR_THEME.MUI.MuiList.ListItem.Background,
            color: COLOR_THEME.MUI.MuiList.ListItem.Text,

            '& .Mui-focusVisible': {
              color: COLOR_THEME.MUI.MuiList.ListItem.Text,
              '&.Mui-selected': {
                color: COLOR_THEME.MUI.MuiList.ListItem.Text,
              },
            },

            li: {
              backgroundColor: COLOR_THEME.MUI.MuiList.ListItem.Background,
              color: COLOR_THEME.MUI.MuiList.ListItem.Text,

              '&.languageSelection': {
                backgroundColor: COLOR_THEME.MUI.MuiList.ListItem.Background,
                color: COLOR_THEME.MUI.MuiList.ListItem.Text,
              },

              ':hover': {
                color: COLOR_THEME.MUI.MuiList.ListItem.Text,
                backgroundColor: `rgba(${hexToRGB(COLOR_THEME.MUI.MuiList.ListItem.Text)}, 0.1)`,
              },

              '&.Mui-selected': {
                color: COLOR_THEME.MUI.MuiList.Text,
                backgroundColor: COLOR_THEME.MUI.MuiList.ListItem.Background,

                '&.languageSelection': {
                  backgroundColor: COLOR_THEME.MUI.MuiList.ListItem.Background,
                },

                ':hover': {
                  backgroundColor: `rgba(${hexToRGB(COLOR_THEME.MUI.MuiList.ListItem.Text)}, 0.15)`,
                },
              },
            },
            ul: {},
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: importantStyle(COLOR_THEME.MUI.MuiInputBase.Background),
          // "&.MuiFilledInput-root": {
          //   backgroundColor: COLOR.background.secondary.hex,
          // },
          color: COLOR_THEME.MUI.MuiInputBase.Text,
          ':before': {
            borderBottom: importantStyle(`1px solid ${COLOR_THEME.MUI.MuiInputBase.Text}`),
            opacity: 0.5,
          },
          ':not(:focus)': {
            ':after': {
              borderBottom: importantStyle(`1px solid ${COLOR_THEME.MUI.MuiInputBase.Text}`),
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: { color: importantStyle(COLOR_THEME.MUI.MuiSvgIcon.Text), opacity: 0.8 },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          button: {
            '&.MuiTypography-root': {
              color: importantStyle('revert'),
              '&.Mui-selected': { color: importantStyle(PALETTE.White) },
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: `rgba(${hexToRGB(COLOR_THEME.MUI.MuiInputLabel.Text)}, 0.7)`,
          ':hover': {
            color: COLOR_THEME.MUI.MuiInputLabel.Text,
          },
          maxWidth: 'calc(100% - 40px)',
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          button: {
            color: importantStyle(`rgba(${hexToRGB(COLOR_THEME.MUI.MuiTabs.SecondaryText)}, 0.87)`),
            ':hover': { color: importantStyle(COLOR_THEME.MUI.MuiTabs.SecondaryText) },
          },
          '& .Mui-selected': {
            color: importantStyle(COLOR_THEME.MUI.MuiTabs.PrimaryText),
            ':hover': { color: importantStyle(COLOR_THEME.MUI.MuiTabs.PrimaryText) },
          },
          '& .MuiTabs-indicator': {
            backgroundColor: `${COLOR_THEME.MUI.MuiTabs.PrimaryText}`,
          },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: { color: COLOR_THEME.MUI.MuiInputAdornment.Text, opacity: 0.8 },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          '&.RaMenuItemLink-active': {
            color: COLOR_THEME.MUI.RaMenuItemLink.Text,
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiFormControl-root': {
            marginTop: 0,
            marginBottom: 0,
            ':hover': { '>label:not(&.Mui-disabled)': { color: COLOR_THEME.MUI.MuiDialog.Text } },
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0px 20px 10px 20px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '.MuiTableCell-head': {
            padding: '15px 5px 5px 5px',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: COLOR_THEME.MUI.MuiPaginationItem.Text,
          '&.Mui-selected': {
            backgroundColor: `rgba(${hexToRGB(COLOR_THEME.MUI.MuiPaginationItem.Background)}, 0.5)`,
            ':hover': {
              backgroundColor: COLOR_THEME.MUI.MuiPaginationItem.Background,
            },
          },
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: importantStyle(COLOR_THEME.MUI.MuiCalendarPicker.Background),
          color: `rgba(${hexToRGB(COLOR_THEME.MUI.MuiCalendarPicker.SecondaryText)}, 0.87)`,
          '& .MuiPickersDay-root': {
            backgroundColor: COLOR_THEME.MUI.MuiCalendarPicker.MuiPickerDayRoot.Background,
            color: COLOR_THEME.MUI.MuiCalendarPicker.SecondaryText,
            '&.Mui-selected': {
              color: COLOR_THEME.MUI.MuiCalendarPicker.Text,
              backgroundColor: importantStyle(
                `rgba(${hexToRGB(
                  COLOR_THEME.MUI.MuiCalendarPicker.MuiPickerDayRoot.Selected.Background
                )}, 0.5)`
              ),
              ':hover': {
                backgroundColor: importantStyle(
                  COLOR_THEME.MUI.MuiCalendarPicker.MuiPickerDayRoot.Selected.Background
                ),
              },
            },
            '&.MuiPickersDay-today': {
              border: importantStyle(
                `1px solid ${COLOR_THEME.MUI.MuiCalendarPicker.SecondaryText}`
              ),
              ':hover': { borderColor: importantStyle(COLOR_THEME.MUI.MuiCalendarPicker.Text) },
              '&.Mui-selected': {
                borderColor: importantStyle(COLOR_THEME.MUI.MuiCalendarPicker.Text),
              },
            },
            ':hover': {
              backgroundColor: `rgba(${hexToRGB(COLOR_THEME.Page.Background)}, 0.14)`,
            },
            '&.Mui-disabled': {
              color: importantStyle(
                `rgba(${hexToRGB(COLOR_THEME.MUI.MuiCalendarPicker.SecondaryText)}, 0.4)`
              ),
            },
          },
          '& .MuiDayPicker-weekDayLabel:first-of-type': {
            color: importantStyle(COLOR_THEME.MUI.MuiCalendarPicker.Text),
          },
        },
      },
    },
    MuiDateTimePickerTabs: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: importantStyle(
              `rgba(${hexToRGB(COLOR_THEME.MUI.MuiDateTimePickerTabs.SecondaryText)}, 0.7)`
            ),
          },
          '& .Mui-selected > .MuiSvgIcon-root': {
            color: importantStyle(COLOR_THEME.MUI.MuiDateTimePickerTabs.Text),
          },
          ':hover': {
            '& .MuiSvgIcon-root': {
              color: importantStyle(COLOR_THEME.MUI.MuiDateTimePickerTabs.SecondaryText),
            },
            '& .Mui-selected > .MuiSvgIcon-root': {
              color: importantStyle(COLOR_THEME.MUI.MuiDateTimePickerTabs.Text),
            },
          },
        },
      },
    },

    MuiPopper: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-panelWrapper': {
            background: COLOR_THEME.MUI.MuiPopper.Background,
          },
          '& .MuiFormLabel-root': {
            zIndex: 99,
          },
          '& .MuiDataGrid-panelFooter': {
            button: {
              color: COLOR_THEME.MUI.MuiPopper.SecondaryText,
              ':last-of-type': {
                color: COLOR_THEME.MUI.MuiPopper.Text,
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR_THEME.MUI.MuiPaper.Background,
          color: COLOR_THEME.MUI.MuiPaper.Text,
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root:not(.inheritColor)': {
            color: COLOR_THEME.MUI.MuiDialogActions.SecondaryText,
            ':last-of-type': {
              color: COLOR_THEME.MUI.MuiDialogActions.Text,
            },
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': { color: COLOR_THEME.MUI.MuiFormLabel.Text },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: importantStyle(
            `rgba(${hexToRGB(COLOR_THEME.MUI.MuiTypography.SecondaryText)}, 0.87)`
          ),
          '&.PrivatePickersToolbarText-root': {
            color: importantStyle(
              `rgba(${hexToRGB(COLOR_THEME.MUI.MuiTypography.SecondaryText)}, 0.7)`
            ),
            ':hover': {
              color: importantStyle(COLOR_THEME.MUI.MuiTypography.SecondaryText),
            },
            '&.MuiDateTimePickerToolbar-separator': {
              color: importantStyle(COLOR_THEME.MUI.MuiTypography.SecondaryText),
            },
          },
          '&.Mui-selected': {
            color: importantStyle(COLOR_THEME.MUI.MuiTypography.Text),

            ':hover': {
              color: importantStyle(COLOR_THEME.MUI.MuiTypography.Text),
            },
          },
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {
        root: {
          '& .MuiClock-clock': {
            background: `rgba(${hexToRGB(COLOR_THEME.MUI.MuiClockPicker.Clock.Background)}, 0.5)`,
            '& .MuiClockNumber-root': {
              color: importantStyle(COLOR_THEME.MUI.MuiClockPicker.SecondaryText),

              '&.Mui-selected': {
                color: importantStyle(COLOR_THEME.MUI.MuiClockPicker.Text),
              },
              '&.Mui-disabled': {
                color: importantStyle('rgba(0, 0, 0, 0.38)'),
              },
            },
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiButton-outlined': {
            ':hover': {
              backgroundColor: `rgba(${hexToRGB(
                COLOR_THEME.Components.ExportBtn.HoverBackground
              )}, 0.3)`,
              borderColor: COLOR_THEME.Components.ExportBtn.HoverBorderColor,
            },
          },
          '& .MuiAccordionSummary-content': {
            h6: { color: importantStyle(COLOR_THEME.Menu.Collapsed.Text) },
            '&.Mui-expanded': {
              h6: { color: importantStyle(COLOR_THEME.Menu.Expanded.Text) },
            },
          },
          '&.Mui-checked': {
            color: importantStyle(COLOR_THEME.MUI.MuiButtonBase.Text),
          },
          '&.MuiClock-amButton, &.MuiClock-pmButton': {
            '& .MuiTypography-root': { '&.Mui-selected': { color: importantStyle(PALETTE.White) } },
          },
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        root: {
          border: 'none',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: COLOR_THEME.MUI.MuiDataGrid.Column.Headers.Background,
            color: COLOR_THEME.MUI.MuiDataGrid.Text,
            boxShadow: 'none',
            height: '40px',
            '& .MuiDataGrid-columnHeader': {
              outline: importantStyle('none'),
            },
          },
          '& .MuiDataGrid-row': {
            backgroundColor: importantStyle(
              `rgba(${hexToRGB(COLOR_THEME.MUI.MuiDataGrid.Row.Background)}, 0.5)`
            ),
            color: COLOR_THEME.MUI.MuiDataGrid.Text,
            ':hover': {
              backgroundColor: importantStyle(
                `rgba(${hexToRGB(COLOR_THEME.MUI.MuiDataGrid.Row.Background)}, 0.8)`
              ),
            },
            '& .MuiDataGrid-cell': {
              borderTop: `0.5px solid ${COLOR_THEME.MUI.MuiDataGrid.Row.Cell.Border}`,
              borderBottom: `0.5px solid ${COLOR_THEME.MUI.MuiDataGrid.Row.Cell.Border}`,
              ':focus': {
                outline: 'none',
                border: importantStyle(`1px solid ${COLOR_THEME.MUI.MuiDataGrid.Row.Cell.Border}`),
              },
            },
            // ":first-of-type": {
            //   "& .MuiDataGrid-cell": {
            //     borderTop: 0,
            //   },
            // },
          },

          '& .MuiDataGrid-footerContainer': {
            backgroundColor: COLOR_THEME.MUI.MuiDataGrid.Footer.Background,
          },

          '& .MuiDataGrid-virtualScrollerContent': {
            marginBottom: '1.2px',
          },

          svg: {
            '&.MuiDataGrid-iconSeparator': {
              display: 'none',
            },
          },
        },
      },
    },
  },
};
