import { useEffect } from 'react';

import { usePermission, useTabs } from '../../hooks';
import { Iprefix } from '../../hooks/useTranslation';
import { COLOR_THEME } from '../../style/colorTheme';
import { useZusTranslatePrefixStore } from '../../zustand/store';
import { Box } from '../MuiGenerals';
import DivideLine from './DivideLine';

function FilterTableWithTab(props: {
  filterArr: { translatePrefix: Iprefix; element: JSX.Element }[];
  tableArr: { translatePrefix: Iprefix; element: JSX.Element }[];
  tabsArr: {
    name: string;
    value: Iprefix;
    permissionCode: string;
  }[];
}) {
  const { filterArr, tableArr, tabsArr } = props;

  const { hasPermission } = usePermission();

  const filteredTabArr = tabsArr.filter(({ permissionCode }) => hasPermission(permissionCode));

  const { Tabs, tabValue } = useTabs(filteredTabArr);
  const { setTranslatePrefix } = useZusTranslatePrefixStore();

  useEffect(() => {
    setTranslatePrefix(tabValue as Iprefix);
  }, [tabValue]);

  return (
    <Box
      sx={{
        backgroundColor: COLOR_THEME.Layout.FilterTable.Background,
        paddingTop: '2rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        position: 'relative',
      }}
    >
      <Tabs sx={{ margin: '0 1.5rem', borderBottom: 'solid #E1E4EA 1px' }}>
        {filterArr.find(({ translatePrefix }) => translatePrefix === tabValue)?.element}
        <DivideLine />
        {tableArr.find(({ translatePrefix }) => translatePrefix === tabValue)?.element}
      </Tabs>
    </Box>
  );
}

export default FilterTableWithTab;
