import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { EnumCreditAdjustmentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Access';
import {
  EnumAdjustmentMode,
  EnumCreditAdjustmentStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { OpApprove, OpRedirect } from '../../../assets/icons';
import { LoadingDialog } from '../../../components';
import { ExportBtn, OpIconButton } from '../../../components/Button';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../components/Layout';
import { Box, Container } from '../../../components/MuiGenerals';
import { displayAmountWithCurrency } from '../../../helper';
import { getRiskLevelDisplayValue } from '../../../helper/getRiskLevelDisplayValue';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../hooks';
import {
  displayAmountCurrying,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from '../../../utils';
import { dataGridColumnConfig, useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogApprovalProgress from './DialogApprovalProgress';
import DialogOperation from './DialogOperation';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const operationCell = (params: GridRenderCellParams<any, any, any>) => {
    const adjustmentMode = params.row[TK.adjustmentMode];
    const programName = params.row[TK.programName];
    const customerNumber = params.row[TK.customerNumber];
    const asset = params.row[TK.asset];
    const createdBy = params.row[TK.createdBy];
    const assetBalanceWhenRequesting = params.row[TK.assetBalanceWhenRequesting];
    const assetCollateralAmount = params.row[TK.assetCollateralAmount];
    const releaseCreditAmount = params.row[TK.releaseCreditAmount];
    const { credit_adjustment_request_approvals: customer_application_approvals } =
      params.row.rawData || {};

    const { status, id } = params.row.rawData;

    const isPending = status === EnumCreditAdjustmentStatus.Pending;

    return (
      <OpIconButton
        title={tc('approve_reject_request_btn')}
        svgUrl={OpApprove}
        onClick={() => {
          zusDialog.open('operationDialog', {
            id,
            adjustmentMode,
            programName,
            customerNumber,
            asset,
            assetBalanceWhenRequesting,
            assetCollateralAmount,
            releaseCreditAmount,
            customer_application_approvals,
            createdBy,
          });
        }}
        isDisabled={!isPending}
      />
    );
  };
  const approvalProgressCell = (params: GridRenderCellParams<any, any, any>) => {
    const approvalProgress = params.row[TK.approvalProgress];
    const { credit_adjustment_request_approvals: customer_application_approvals } =
      params.row.rawData || {};
    const isAutoApprove = approvalProgress === tc('auto');

    if (!approvalProgress) {
      return <></>;
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box>{approvalProgress}</Box>
        {!isAutoApprove && (
          <OpIconButton
            size={'1.2rem'}
            title={t(TK.approvalProgress)}
            svgUrl={OpRedirect}
            onClick={() =>
              zusDialog.open('approvalProgressDialog', { customer_application_approvals })
            }
            sxBox={{ marginLeft: '5px' }}
          />
        )}
      </Box>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      minWidth: 150,
      renderCell: operationCell,
    }),
    useGenGridCol(TK.orderId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.approvalProgress, {
      minWidth: 150,
      renderCell: approvalProgressCell,
    }),
    useGenGridCol(TK.adjustmentMode, {
      minWidth: 150,
    }),
    useGenGridCol(TK.adjustmentType),
    useGenGridCol(TK.programName),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.riskLevel, {
      minWidth: 150,
    }),
    useGenGridCol(TK.assetCustodyId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.asset, {
      minWidth: 150,
    }),
    useGenGridCol(TK.assetBalanceWhenRequesting, {
      minWidth: 150,
    }),
    useGenGridCol(TK.assetCollateralAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.exchangeRate, {
      minWidth: 150,
    }),
    useGenGridCol(TK.realTimeCreditWhenRequesting, {
      minWidth: 150,
    }),
    useGenGridCol(TK.releaseCreditAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.status, {
      minWidth: 150,
    }),
    useGenGridCol(TK.manualApprovalReason, {
      minWidth: 150,
    }),

    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  const { hasPermissionMultipleEither } = usePermission();

  const hasOperationColPermission = hasPermissionMultipleEither([
    PermissionKey.Approve.prefix,
    PermissionKey.Reject,
  ]);

  const visibilityConfigArr = [
    {
      fieldName: TK.operation,
      hasPermission: hasOperationColPermission,
    },
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DialogOperation />
      <DialogApprovalProgress />
      <DataGrid
        {...dataGridDefaults}
        initialState={dataGridColumnConfig(visibilityConfigArr)}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const isAutoApprove = Number(item.approvalsRequired) === 0;

      const invalidApprovalProgressVal =
        isNil(item.approvalsRequired) || isNil(item.approvalsCount);

      const approvalProgressOrNoShow = !invalidApprovalProgressVal
        ? item.approvalsCount + '/' + item.approvalsRequired
        : '';

      const displayApprovalProgress = isAutoApprove ? tc('auto') : approvalProgressOrNoShow;

      const displayAssetAmount = displayAmountCurrying(item.assetCurrencyDecimals);
      const displayCreditAmount = displayAmountCurrying(0, 2);

      const displayCreditAmountWithCurrency = (value: string | number) =>
        displayAmountWithCurrency(displayCreditAmount(value), item.creditCurrency);

      const mappedResult = [
        ['rawData', item],
        [TK.orderId, item.orderId],
        [TK.approvalProgress, displayApprovalProgress],
        [TK.adjustmentMode, t(EnumAdjustmentMode[item.mode])],
        [TK.adjustmentType, t(EnumCreditAdjustmentType[item.type])],
        [TK.programName, item.programName],
        [TK.customerNumber, item.customerNumber],
        [TK.riskLevel, getRiskLevelDisplayValue(item.riskLevel, t)],
        [TK.assetCustodyId, item.assetCustodyId],
        [TK.asset, item.assetCurrency],
        [TK.assetBalanceWhenRequesting, displayAssetAmount(item.assetBalance)],
        [TK.assetCollateralAmount, displayAssetAmount(item.assetCollateralAmount)],
        [TK.exchangeRate, item.exchangeRate],
        [
          TK.realTimeCreditWhenRequesting,
          displayCreditAmountWithCurrency(item.creditRealtimeAmount),
        ],
        [TK.releaseCreditAmount, displayCreditAmountWithCurrency(item.creditReleasedAmount)],
        [TK.status, t(EnumCreditAdjustmentStatus[item.status])],
        [TK.manualApprovalReason, item.manualApprovalReason],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
