import { SourceOfWealthCheckedStatus } from '../types';

const SourceOfWealthBinaryRepresentation = {
  others: 1,
  employment: 2,
  selfEmployment: 4,
  businessOwnership: 8,
  loansGrant: 16,
  spouseParentPartner: 32,
  inheritance: 64,
  trustFund: 128,
  investment: 256,
  salesOfVirtualAssets: 512,
  personalRealEstate: 1024,
};

export const createSourceOfWealthBitWiseRepresentation = (
  sourceOfWealth: SourceOfWealthCheckedStatus
) => {
  let result = 0;

  Object.keys(sourceOfWealth).forEach((key: string) => {
    if (sourceOfWealth[key as keyof SourceOfWealthCheckedStatus]) {
      const binaryValue =
        SourceOfWealthBinaryRepresentation[key as keyof typeof SourceOfWealthBinaryRepresentation];

      result += binaryValue;
    }
  });

  return result;
};
