import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import {
  EnumIdvMethod,
  EnumReferralCodeStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { OpEdit } from '../../../assets/icons';
import { LoadingDialog } from '../../../components';
import { ExportBtn, OpIconButton } from '../../../components/Button';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../hooks';
import { downloadFiles, getFullApiResponse, toDisplayTime } from '../../../utils';
import { dataGridColumnConfig, useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogOperation from './DialogOperation';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => {
    setCount(count);
  }, [listRes.data]);

  const operationCell = (params: GridRenderCellParams<any, any, any>) => {
    const programName = params.row[TK.programName];
    const referralCode = params.row[TK.referralCode];
    const kycIdvMethod = params.row.rawData.kycIdvMethod;
    const status = params.row.rawData.status;
    return (
      <OpIconButton
        title={tc('edit')}
        svgUrl={OpEdit}
        onClick={() => {
          zusDialog.open('operationDialog', {
            programName,
            referralCode,
            kycIdvMethod,
            status,
          });
        }}
      />
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      minWidth: 150,
      renderCell: operationCell,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.referralCode, {
      minWidth: 150,
    }),
    useGenGridCol(TK.status, {
      minWidth: 150,
    }),
    useGenGridCol(TK.idvMethod),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  const { hasPermission } = usePermission();

  const hasOperationColPermission = hasPermission(PermissionKey.EditRecord);

  const visibilityConfigArr = [
    {
      fieldName: TK.operation,
      hasPermission: hasOperationColPermission,
    },
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DialogOperation />
      <DataGrid
        {...dataGridDefaults}
        initialState={dataGridColumnConfig(visibilityConfigArr)}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const mappedResult = [
        ['rawData', item],
        [TK.programName, item.programName],
        [TK.referralCode, item.referralCode],
        [TK.status, t(EnumReferralCodeStatus[item.status])],
        [TK.idvMethod, t(EnumIdvMethod[item.kycIdvMethod])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.lastModifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
