export enum CreateRecordRowKeys {
  ProgramName = 'programName',
  CustomerLevel = 'customerLevel',
  RewardRate = 'rewardRate',
}

export interface ICreateRecordFields {
  [CreateRecordRowKeys.ProgramName]: string;
  [CreateRecordRowKeys.CustomerLevel]: string;
  [CreateRecordRowKeys.RewardRate]: string;
}
