import React from 'react';
import { FormControl, Box } from '../../../../../../components/MuiGenerals';
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { useTranslation } from '../../../../../../hooks';
import { SourceOfFundCheckedStatus } from '../types';

interface IProps {
  sourceOfFund: SourceOfFundCheckedStatus;
  setSourceOfFund: (sourceOfFund: any) => void;
}

const SourceOfFundCheckboxGroup = (props: IProps) => {
  const { sourceOfFund, setSourceOfFund } = props;

  const { t } = useTranslation('tcspAccountApplication');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSourceOfFund({ [event.target.name]: event.target.checked });
  };

  const withTranslation = (key: string) => {
    return t(`sourceOfFunds.${key}`);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={sourceOfFund.others} onChange={handleChange} name="others" />
            }
            label={withTranslation('Others')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.personalSaving}
                onChange={handleChange}
                name="personalSaving"
              />
            }
            label={withTranslation('PersonalSaving')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.rentalIncome}
                onChange={handleChange}
                name="rentalIncome"
              />
            }
            label={withTranslation('RentalIncome')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.salesOfProperty}
                onChange={handleChange}
                name="salesOfProperty"
              />
            }
            label={withTranslation('SalesOfProperty')}
          />
          <FormControlLabel
            control={
              <Checkbox checked={sourceOfFund.pension} onChange={handleChange} name="pension" />
            }
            label={withTranslation('Pension')}
          />
        </FormGroup>
      </FormControl>

      <FormControl variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.businessIncome}
                onChange={handleChange}
                name="businessIncome"
              />
            }
            label={withTranslation('BusinessIncome')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.inheritance}
                onChange={handleChange}
                name="inheritance"
              />
            }
            label={withTranslation('Inheritance')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.investment}
                onChange={handleChange}
                name="investment"
              />
            }
            label={withTranslation('Investment')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.salesOfVirtualAssets}
                onChange={handleChange}
                name="salesOfVirtualAssets"
              />
            }
            label={withTranslation('SalesOfVirtualAssets')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sourceOfFund.wagesDepositedFromEmployer}
                onChange={handleChange}
                name="wagesDepositedFromEmployer"
              />
            }
            label={withTranslation('WagesDepositedFromEmployer')}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default SourceOfFundCheckboxGroup;
