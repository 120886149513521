import { ApiResult } from '../../../../api/types';
import { getExchangeRateConfigurationAdjustmentTypeDisplayValue } from '../../../../helper/getExchangeRateConfigurationAdjustmentTypeDisplayValue';
import { useTranslation } from '../../../../hooks';
import { toDisplayTime } from '../../../../utils';
import { roundDown } from '../../../../utils/math';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const adjustmentType = getExchangeRateConfigurationAdjustmentTypeDisplayValue(
        row.adjustType,
        translate
      );

      const exchangeRate = roundDown(row.exchangeRate, 6);
      const adjustmentAmount = roundDown(row.adjustAmount, 6);
      const adjustedExchangeRate = roundDown(row.adjustedExchangeRate, 6);

      return {
        id: index,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.from]: row.fromCurrency,
        [TableColumnKey.to]: row.toCurrency,
        [TableColumnKey.exchangeRateOfCurrentDate]: exchangeRate,
        [TableColumnKey.adjustmentType]: adjustmentType,
        [TableColumnKey.adjustmentAmount]: adjustmentAmount,
        [TableColumnKey.exchangeRateAfterAdjustment]: adjustedExchangeRate,
        [TableColumnKey.createdBy]: row.merchantExchangeRate.createdBy,
        [TableColumnKey.createdDate]: toDisplayTime(row.merchantExchangeRate.createdDate),
        [TableColumnKey.lastModifiedBy]: row.merchantExchangeRate.lastModifiedBy,
        [TableColumnKey.lastModifiedTime]: toDisplayTime(row.merchantExchangeRate.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
