import React from 'react';
import { MpTextField } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';

interface IProps {
  isShow?: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
  fieldName: string;
  maxLength?: number;
}
const AdditionalInputField = (props: IProps) => {
  const { isShow, value, onChange, fieldName, maxLength } = props;

  const { tc } = useTranslation();

  if (!isShow) return <></>;

  return (
    <MpTextField
      label={tc('phInputField', { fieldName })}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{ marginTop: '12px !important' }}
      inputProps={{ maxLength }}
    />
  );
};

export default AdditionalInputField;
