export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',

  orderId = 'orderId',
  programNames = 'programNames',
  transactionType = 'transactionType',
  customerNumber = 'customerNumber',
  currency = 'currency',
  sendingAddress = 'sendingAddress',
  receivingAddress = 'receivingAddress',
  transactionHash = 'transactionHash',
  adjustmentMode = 'adjustmentMode',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.orderId]: string;
  [FilterFieldsKeys.programNames]: string[];
  [FilterFieldsKeys.transactionType]: string;
  [FilterFieldsKeys.adjustmentMode]: string;
  [FilterFieldsKeys.customerNumber]: string;
  [FilterFieldsKeys.currency]: string;
  [FilterFieldsKeys.sendingAddress]: string;
  [FilterFieldsKeys.receivingAddress]: string;
  [FilterFieldsKeys.transactionHash]: string;
}
