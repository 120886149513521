import { ApiResult } from '../../../../api/types';
import { getAssetCustodyTransactionStatusDisplayValue } from '../../../../helper/getAssetCustodyTransactionStatusDisplayValue';
import { getRiskLevelDisplayValue } from '../../../../helper/getRiskLevelDisplayValue';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { te } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const toDisplayAmount = displayAmountCurrying(row.decimals);

      const amount = toDisplayAmount(row.amount);
      const postBalance = toDisplayAmount(row.postBalance);
      const riskLevel = getRiskLevelDisplayValue(row.riskLevel, te);
      const transactionStatus = getAssetCustodyTransactionStatusDisplayValue(
        row.transactionStatus,
        te
      );

      return {
        id: index,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.CustomerNumber]: row.customerNumber,
        [TableColumnKey.ClientId]: row.clientId,
        [TableColumnKey.Currency]: row.currency,
        [TableColumnKey.Amount]: amount,
        [TableColumnKey.PostBalance]: postBalance,
        [TableColumnKey.Decimals]: row.decimals,
        [TableColumnKey.LastLedgerTransactionId]: row.lastLedgerTransactionId,
        [TableColumnKey.FromAddress]: row.fromAddress,
        [TableColumnKey.ToAddress]: row.toAddress,
        [TableColumnKey.TxHash]: row.txHash,
        [TableColumnKey.ReferenceNumber]: row.refNo,
        [TableColumnKey.RiskLevel]: riskLevel,
        [TableColumnKey.TransactionStatus]: transactionStatus,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreatedDate]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedDate]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
