import { GridRenderCellParams } from '@mui/x-data-grid';
import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

interface IProps {
  renderOperationCell: (params: GridRenderCellParams) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationCell } = props;

  const columns = [
    useGenGridCol('operation', {
      renderCell: renderOperationCell,
    }),
    useGenGridCol(TableColumnKey.programName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.rewardCurrency),
    useGenGridCol(TableColumnKey.customerLevel),
    useGenGridCol(TableColumnKey.rewardRate),
    useGenGridCol(TableColumnKey.createdBy, {
      minWidth: COLUMN_WIDTH.CreatedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.creationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedBy),

    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
