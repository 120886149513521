import React, { SetStateAction } from 'react';

import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import ExchangeRateConfigAdjustmentTypeSingleSelection from '../../../common/filterTable/components/Filters/ExchangeRateConfigAdjustmentTypeSingleSelection';
import ExchangeRateFromCurrencySingleSelection from '../../../common/filterTable/components/Filters/ExchangeRateFromCurrencySingleSelection';
import ProgramNameSingleSelection from '../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import { Filter } from '../../../common/filterTable/types';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
  isCollapse?: boolean;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };


  const filters: Filter[] = [
    {
      labelKey: 'creationTime',
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: 'lastModifiedTime',
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: FilterFieldsKeys.programNames,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.programNames]}
          onClear={() => {
            setFields((fields) => ({
              ...fields,
              [FilterFieldsKeys.programNames]: [],
            }))
          }}
          onChange={(value: string[]) => {
            setFields((fields) => ({
              ...fields,
              [FilterFieldsKeys.programNames]: value,
              [FilterFieldsKeys.from]: '',
            }));
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.from,
      filter: (
        <ExchangeRateFromCurrencySingleSelection
          value={fields[FilterFieldsKeys.from]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.from]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.from, e.target.value);
          }}
          programName={fields[FilterFieldsKeys.programNames]}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.adjustmentType,
      filter: (
        <ExchangeRateConfigAdjustmentTypeSingleSelection
          value={fields[FilterFieldsKeys.adjustmentType]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.adjustmentType]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.adjustmentType, e.target.value);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
