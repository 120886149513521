export enum FilterFieldsKeys {
  CreatedDateFrom = 'createdDateFrom',
  CreatedDateTo = 'createdDateTo',
  ProgramName = 'programName',
  CustomerNumber = 'customerNumber',
  Currency = 'currency',
}

export interface FilterFields {
  [FilterFieldsKeys.CreatedDateFrom]: string;
  [FilterFieldsKeys.CreatedDateTo]: string;
  [FilterFieldsKeys.ProgramName]: string;
  [FilterFieldsKeys.CustomerNumber]: string;
  [FilterFieldsKeys.Currency]: string;
}
