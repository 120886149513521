import { ChangeEvent, useState } from 'react';

import { EnumEditCustomerAccountRequestStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { SingleSelection } from '../../../../components';
import { useDatePicker } from '../../../../components/DatePicker';
import { Container, SelectChangeEvent } from '../../../../components/MuiGenerals';
import { MpTextField } from '../../../../components/TextField';
import CollapsibleFilters from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../../features/common/filterTable/types';
import { useTranslation } from '../../../../hooks';
import { useGetProgram } from '../../../../hooks/useProgram';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';

export default function TabRequestFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { EnumAllProgram } = useGetProgram();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const getParams: () => Partial<IsearchParam> = () => {
    const { status: rawStatus, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    const status = rawStatus ? Number(rawStatus) : undefined;

    return {
      ...initZusParams,
      ...rest,
      status,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.requestId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.requestId) })}
          value={fields.requestId}
          onChange={onChange('requestId')}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange('customerNumber')}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <SingleSelection
          className="hidden"
          label={tc('phSelection', { fieldName: t(TK.status) })}
          value={fields.status}
          onChange={onChange('status')}
          enumData={EnumEditCustomerAccountRequestStatus}
          clearSelect={() => setFields((fields) => ({ ...fields, status: '' }))}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
