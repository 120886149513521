import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = () => {
  const { hasPermission } = usePermission();

  const columns = [
    useGenGridCol(TableColumnKey.rewardOrderId, {
      minWidth: COLUMN_WIDTH.RewardOrderId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.programName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.customerNumber, {
      minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.month),
    useGenGridCol(TableColumnKey.rewardBatchId, {
      minWidth: COLUMN_WIDTH.RewardBatchId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.rewardCurrency),
    useGenGridCol(TableColumnKey.rewardAmount),
    useGenGridCol(TableColumnKey.paymentDueDate),
    useGenGridCol(TableColumnKey.qualifiedSpendingAmount),
    useGenGridCol(TableColumnKey.qualifiedRepaymentAmount),
    useGenGridCol(TableColumnKey.rewardRate),
    useGenGridCol(TableColumnKey.exchangeRate),
    useGenGridCol(TableColumnKey.rewardAmountInBillingCurrency),
    useGenGridCol(TableColumnKey.creationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
