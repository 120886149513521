import React, { ReactNode, SetStateAction } from 'react';

import { useGenFilterField } from '../../../../utils/ComponentHelper';
import ResetButton from '../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../common/filterTable/components/Buttons/SearchButton';
import AdjustmentTypeSingleSelection from '../../../common/filterTable/components/Filters/AdjustmentTypeSingleSelection';
import CurrencySingleSelection from '../../../common/filterTable/components/Filters/CurrencySingleSelection';
import CustomerNumberTextField from '../../../common/filterTable/components/Filters/CustomerNumberTextField';
import Filters from '../../../common/filterTable/components/Filters/Filters';
import OrderIdTextField from '../../../common/filterTable/components/Filters/OrderIdTextField';
import ProgramNameSingleSelection from '../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import ReceivingAddressTextField from '../../../common/filterTable/components/Filters/ReceivingAddressTextField';
import SendingAddressTextField from '../../../common/filterTable/components/Filters/SendingAddressTextField';
import TransactionTypeSingleSelection from '../../../common/filterTable/components/Filters/TransactionTypeSingleSelection';
import TxHashTextField from '../../../common/filterTable/components/Filters/TxHashTextField';
import FilterActions from '../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../common/filterTable/layout/FilterGrid';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import { Filter } from '../../../common/filterTable/types';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    { labelKey: 'creationTime', filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: 'lastModifiedTime', filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: FilterFieldsKeys.programNames,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.programNames]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.currency]: '' }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.programNames, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.currency,
      filter: (
        <CurrencySingleSelection
          programName={fields[FilterFieldsKeys.programNames]}
          value={fields[FilterFieldsKeys.currency]}
          onClick={(e) =>
            updateField(FilterFieldsKeys.currency, (e.target as HTMLElement).dataset.value)
          }
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.customerNumber,
      filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.customerNumber]}
          onChange={(e) => updateField(FilterFieldsKeys.customerNumber, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.orderId,
      filter: (
        <OrderIdTextField
          value={fields[FilterFieldsKeys.orderId]}
          onChange={(e) => updateField(FilterFieldsKeys.orderId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.receivingAddress,
      filter: (
        <ReceivingAddressTextField
          value={fields[FilterFieldsKeys.receivingAddress]}
          onChange={(e) => updateField(FilterFieldsKeys.receivingAddress, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.sendingAddress,
      filter: (
        <SendingAddressTextField
          value={fields[FilterFieldsKeys.sendingAddress]}
          onChange={(e) => updateField(FilterFieldsKeys.sendingAddress, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.transactionHash,
      filter: (
        <TxHashTextField
          value={fields[FilterFieldsKeys.transactionHash]}
          onChange={(e) => updateField(FilterFieldsKeys.transactionHash, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.transactionType,
      filter: (
        <TransactionTypeSingleSelection
          value={fields[FilterFieldsKeys.transactionType]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.transactionType]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.transactionType, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.adjustmentMode,
      filter: (
        <AdjustmentTypeSingleSelection
          value={fields[FilterFieldsKeys.adjustmentMode]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.adjustmentMode]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.adjustmentMode, e.target.value);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
