import { EnumRepaymentTransactionType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

export const getRewardQualifiedRepaymentTransactionTypeDisplayValue = (
  type: number,
  translateFunc: (key: string) => string
) => {
  const value = EnumRepaymentTransactionType[type];

  if (!value) return '';

  return translateFunc(`enumConstants.${value}`);
};
