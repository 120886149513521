import { defaultListParams } from '../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../common/filterTable/helpers/cleanParams';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const {
      createdDateFrom,
      createdDateTo,
      lastModifiedTimeFrom,
      lastModifiedTimeTo,
      programNames,
      transactionType,
      adjustmentMode: mode,
      customerNumber,
      currency,
      sendingAddress,
      receivingAddress,
      transactionHash,
      orderId,
    } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom: lastModifiedTimeFrom,
      lastModifiedDateTo: lastModifiedTimeTo,
      programNames,
      currency,
      orderId,
      type: transactionType,
      mode,
      customerNumber,
      fromAddress: sendingAddress,
      toAddress: receivingAddress,
      txHash: transactionHash,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
