export enum TableColumnKey {
  ProgramName = 'programName',
  CustomerNumber = 'customerNumber',
  ClientId = 'clientId',
  Currency = 'currency',
  Amount = 'amount',
  PostBalance = 'postBalance',
  Decimals = 'decimals',
  LastLedgerTransactionId = 'lastLedgerTransactionId',
  FromAddress = 'fromAddress',
  ToAddress = 'toAddress',
  TxHash = 'txHash',
  ReferenceNumber = 'referenceNumber',
  RiskLevel = 'riskLevel',
  TransactionStatus = 'transactionStatus',
  CreatedBy = 'createdBy',
  CreatedDate = 'creationTime',
  LastModifiedDate = 'lastModifiedTime',
}
