import { GridRenderCellParams } from '@mui/x-data-grid';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = ({
  renderOperationsCell,
}: {
  renderOperationsCell: (params: GridRenderCellParams) => React.ReactNode;
}) => {
  const { hasPermission } = usePermission();
  const shouldHideOperationColumn = !hasPermission(FeatureCode.TCSP.TcspAccount.Edit);

  const columns = [
    useGenGridCol(TableColumnKey.operation, {
      renderCell: renderOperationsCell,
      hide: shouldHideOperationColumn,
    }),
    useGenGridCol(TableColumnKey.customerId, {
      minWidth: COLUMN_WIDTH.CustomerId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.programName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.customerNumber, {
      minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.status),
    useGenGridCol(TableColumnKey.latestVersion),
    useGenGridCol(TableColumnKey.createdBy, {
      minWidth: COLUMN_WIDTH.CreatedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.createdDate, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedBy, {
      minWidth: COLUMN_WIDTH.LastModifiedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
