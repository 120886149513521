import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import api from '../../../api';
import { ApiResult } from '../../../api/types';
import { LoadingDialog } from '../../../components';
import { ExportBtn } from '../../../components/Button';
import FilterBarCreateRecordButton from '../../../components/Button/FilterBarCreateRecordButton';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import CreateRecordDialog from '../../../features/cobrandManagement/exchangeRateConfiguration/components/Dialog/CreateRecordDialog';
import EditConfigDialog from '../../../features/cobrandManagement/exchangeRateConfiguration/components/Dialog/EditConfigDialog';
import FilterSection from '../../../features/cobrandManagement/exchangeRateConfiguration/components/FilterSection';
import useGetListParams from '../../../features/cobrandManagement/exchangeRateConfiguration/helpers/useGetListParams';
import useTableColumns from '../../../features/cobrandManagement/exchangeRateConfiguration/helpers/useTableColumns';
import useTableRows from '../../../features/cobrandManagement/exchangeRateConfiguration/helpers/useTableRows';
import {
  FilterFields,
  FilterFieldsKeys,
} from '../../../features/cobrandManagement/exchangeRateConfiguration/types/FilterFields';
import { GetListRes } from '../../../features/cobrandManagement/exchangeRateConfiguration/types/GetListRes';
import { TableRow } from '../../../features/cobrandManagement/exchangeRateConfiguration/types/TableRow';
import EditConfigCell from '../../../features/common/filterTable/components/Cells/EditConfigCell';
import Table from '../../../features/common/filterTable/components/Table';
import { convertToExportRows } from '../../../features/common/filterTable/helpers/convertToExportRows';
import FilterContainer from '../../../features/common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../features/common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../features/common/filterTable/layout/TableContainer';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useTabs from '../../../hooks/useTabs';
import { downloadFiles, getFullApiResponse } from '../../../utils';
import { useZusTranslatePrefixStore } from '../../../zustand/store';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.lastModifiedTimeFrom]: '',
  [FilterFieldsKeys.lastModifiedTimeTo]: '',
  [FilterFieldsKeys.programNames]: [],
  [FilterFieldsKeys.from]: '',
  [FilterFieldsKeys.adjustmentType]: '',
};

// use corresponding api and permission
const translatePrefix = 'exchangeRateConfiguration';

const exportBtnPermission = FeatureCode.CobrandManagement.ExchangeRateConfiguration.Export;
const editRecordPermission = FeatureCode.CobrandManagement.ExchangeRateConfiguration.EditRecord;
const createRecordPermission = FeatureCode.CobrandManagement.ExchangeRateConfiguration.CreateRecord;

const getTableApiFunc =
  api.CobrandManagement.exchangeRateConfiguration.getExchangeRateConfiguration;
const exportApiFunc = api.CobrandManagement.exchangeRateConfiguration.exportTable;

const ExchangeRateConfiguration = () => {
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isEditConfigDialogOpen, setIsEditConfigDialogOpen] = useState(false);
  const [isCreateRecordDialogOpen, setIsCreateRecordDialogOpen] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const columns = useTableColumns({ renderOperationsCell });
  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { t, tc } = useTranslation(translatePrefix);
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const isShowExportBtn = hasPermission(exportBtnPermission);

  function renderOperationsCell({ row }: { row: TableRow }) {
    return (
      <EditConfigCell
        onClick={() => {
          setSelectedRow(row);
          setIsEditConfigDialogOpen(true);
        }}
        isDisabled={false}
        isHide={!hasPermission(editRecordPermission)}
      />
    );
  }

  const handleSettled = () => {
    setIsShowLoading(false);
  };

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;

    setList(data);
  };

  const handleQuery = () => {
    setIsShowLoading(true);
    const { currentTime, ...cleanParams } = listParams;

    return getTableApiFunc(cleanParams);
  };

  useQuery(['getExchangeRateConfiguration', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  const onExport = async () => {
    const params = getListParams(fields);
    const { currentTime, ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      exportApiFunc({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`Exchange Rate Configuration`, exportRows, {});
  };

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.createdDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.createdDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.lastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.lastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const refreshTable = () => {
    setListParams({ ...listParams, currentTime: Date.now() });
  };

  return (
    <FilterTableLayoutContainer>
      <LoadingDialog forceOpen={isShowLoading} />

      <EditConfigDialog
        isOpen={isEditConfigDialogOpen}
        selectedRow={selectedRow}
        closeDialog={() => setIsEditConfigDialogOpen(false)}
        refreshTable={refreshTable}
      />

      <CreateRecordDialog
        isOpen={isCreateRecordDialogOpen}
        closeDialog={() => setIsCreateRecordDialogOpen(false)}
        refreshTable={refreshTable}
        allRecords={list.rows}
      />

      <FilterContainer>
        <FilterSectionActionRow>
          <FilterBarCreateRecordButton
            onClick={() => setIsCreateRecordDialogOpen(true)}
            isShow={hasPermission(createRecordPermission)}
          />
          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>
        <FilterSection
          fields={fields}
          setFields={setFields}
          onSearch={onFilterSearch}
          onReset={onFilterReset}
          DateObj={DateObj}
          isCollapse={isCollapse}
        />
      </FilterContainer>

      <DivideLine />

      <TableContainer>
        <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
        <Tabs>
          <Table
            columns={columns}
            rows={rows}
            rowCount={totalCounts}
            page={page}
            onPageChange={onPageChange}
          />
        </Tabs>
      </TableContainer>
    </FilterTableLayoutContainer>
  );
};

export default ExchangeRateConfiguration;
