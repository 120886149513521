import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

export interface Currency {
  id: string;
  currency: string;
  decimal: number;
}

interface InitState {
  list: Currency[];
}

const initialState: InitState = { list: [] };

export const currencyListSlice = createSlice({
  name: 'currencyList',
  initialState,
  reducers: {
    setCurrencyList: (state, action: PayloadAction<Currency[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setCurrencyList } = currencyListSlice.actions;

export const selectCurrencyList = (state: RootState) => state.currencyList;
export default currencyListSlice.reducer;
