import { create } from 'zustand';

import {
  Answer,
  SourceOfFundCheckedStatus,
  SourceOfWealthCheckedStatus,
} from '../../components/CreateApplicationDialog/types';
import { initAnswer, initSourceOfFund, initSourceOfWealth } from './constants';

interface TcspCreateRequestZusStore {
  customerNumber: string;
  answer: Answer;
  sourceOfWealth: SourceOfWealthCheckedStatus;
  sourceOfFund: SourceOfFundCheckedStatus;
  isCustomerNumberValid: boolean;

  setAnswer: (answer: Answer) => void;
  setCustomerNumber: (customerNumber: string) => void;
  setSourceOfWealth: (sourceOfWealth: SourceOfWealthCheckedStatus) => void;
  setSourceOfFund: (sourceOfFund: SourceOfFundCheckedStatus) => void;
  setIsCustomerNumberValid: (isCustomerNumberValid: boolean) => void;
  reset: () => void;
}

export const useTcspCreateRequestZusStore = create<TcspCreateRequestZusStore>((set) => ({
  // State
  customerNumber: '',
  answer: initAnswer,
  sourceOfWealth: initSourceOfWealth,
  sourceOfFund: initSourceOfFund,
  isCustomerNumberValid: false,

  // Setter
  setCustomerNumber: (customerNumber: string) => set(() => ({ customerNumber })),
  setAnswer: (answer: Answer) => {
    return set((state) => ({ ...state, answer: { ...state.answer, ...answer } }));
  },
  setIsCustomerNumberValid: (isCustomerNumberValid: boolean) => {
    return set(() => ({ isCustomerNumberValid }));
  },
  setSourceOfWealth: (sourceOfWealth: SourceOfWealthCheckedStatus) => {
    return set((state) => ({
      ...state,
      sourceOfWealth: { ...state.sourceOfWealth, ...sourceOfWealth },
    }));
  },
  setSourceOfFund: (sourceOfFund: SourceOfFundCheckedStatus) => {
    return set((state) => ({
      ...state,
      sourceOfFund: { ...state.sourceOfFund, ...sourceOfFund },
    }));
  },
  reset: () => {
    return set((state) => ({
      ...state,
      answer: { ...initAnswer },
      customerNumber: '',
      isCustomerNumberValid: false,
    }));
  },
}));
