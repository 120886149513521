import { useEffect } from 'react';

import api from '../../api';
import { selectAssetsList, setAssetsList } from '../../reducer/assetsSlice';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import { getCurrencyEnum } from './helpers/getCurrencyEnum';
import { getProgramAssets } from './helpers/getProgramAssets';

interface IProps {
  shouldFetch?: boolean;
  programName?: string | string[]
}

const useAssets = (props: IProps = {}) => {
  const { shouldFetch, programName } = props;

  const dispatch = useAppDispatch();
  const { list: assetsList } = useAppSelector(selectAssetsList);

  const getAllAssetCurrenciesEnum = () => {
    if (!assetsList) return {};

    const assetCurrencySet = new Set<string>();
    assetsList.forEach(({ currency }) => assetCurrencySet.add(currency));

    const allAssetCurrenciesEnum = Array.from(assetCurrencySet).reduce(
      (prev, cur) => ({ ...prev, [cur]: cur }),
      {}
    );

    return allAssetCurrenciesEnum;
  };

  const allAssetCurrenciesEnum = getAllAssetCurrenciesEnum();

  const programAssets = getProgramAssets(assetsList, programName);
  const currencyEnum = getCurrencyEnum(programAssets);


  useEffect(() => {
    const updateAssetsList = async () => {
      const res = await api.MerchantPortal.query.getAssetList();

      dispatch(setAssetsList(res));
    };

    if (shouldFetch) {
      updateAssetsList();
    }
  }, [dispatch, shouldFetch]);

  const getAssetDetailByAssetName = (asset: string) =>
    getProgramAssets(assetsList, programName).find(({ currency }) => currency === asset);

  const getCurrencyDecimal = (programName: string, asset: string) => {
    return (
      getProgramAssets(assetsList, programName).find(({ currency }) => currency === asset)
        ?.decimals || 0
    );
  };

  const getAllCurrenciesByProgram = (programName: string) =>
    getProgramAssets(assetsList, programName).map((assetObj) => assetObj.currency);

  return {
    CurrencyEnum: currencyEnum,
    getAllCurrenciesByProgram,
    getAssetDetailByAssetName,
    getCurrencyDecimal,
    AllAssetCurrenciesEnum: allAssetCurrenciesEnum,
  };
};

export default useAssets;
