import { EnumTcspAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const TcspAccountStatusSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('status') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={EnumTcspAccountStatus}
    />
  );
};

export default TcspAccountStatusSingleSelection;
