import { Property } from 'csstype';
import React, { ReactNode } from 'react';

import { Button } from '../../../../components/MuiGenerals';

type IfullScreenDialogBtn = {
  children: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  btnBgColor: Property.BackgroundColor;
};

export default function FullScreenDialogBtn({
  children,
  onClick,
  btnBgColor,
}: IfullScreenDialogBtn) {
  return (
    <Button
      className={'inheritColor'}
      color={'primary'}
      variant={'contained'}
      children={children}
      onClick={onClick}
      style={{
        width: '160px',
        height: '38px',
        marginRight: '12px',
        backgroundColor: btnBgColor,
        color: '#FFFFFF',
        borderRadius: '8px',
      }}
    />
  );
}
