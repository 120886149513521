import { EnumEditCustomerAccountRequestStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { DialogInOne } from '../../../../components';
import { IdialogInOneProps } from '../../../../components/DialogInOne';
import { GridBoxSections } from '../../../../components/Layout';
import { Button } from '../../../../components/MuiGenerals';
import ApprovalProgressDialogContent from '../../../../features/approval/components/ApprovalProgressDialogContent';
import { ApproveHistory } from '../../../../features/approval/types/ApproveHistory';
import { useAlerting, usePermission, useTranslation } from '../../../../hooks';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectProfile } from '../../../../reducer/profileSlice';
import { COLOR_THEME } from '../../../../style/colorTheme';
import { useZusDialogStore } from '../../../../zustand/store';
import {
  apiObj as api,
  IdetailApiRes,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';
import useDetailDialogContent from './useDetailDialogContent';

export default function DialogApproval() {
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();

  const { email } = useAppSelector(selectProfile);

  const { approvalsArr, details } = (zusDialog.meta || {}) as {
    approvalsArr: ApproveHistory[];
    details: IdetailApiRes;
  };

  const closeHandler = () => zusDialog.close();

  const approveBtnClickHandler = async () => {
    const { approvalPermission, id, createdBy } =
      approvalsArr
        .sort(({ seq: seqA }, { seq: seqB }) => seqA - seqB)
        .find(({ status }) => status === EnumEditCustomerAccountRequestStatus.Pending) || {};

    if (approvalPermission && !hasPermission(approvalPermission)) {
      return alerting('warning', tc('alert_no_permission'));
    }

    if (createdBy === email) {
      return alerting('warning', tc('no_same_operator_creator'));
    }

    if (approvalsArr.find(({ approvedBy }) => email === approvedBy)) {
      return alerting('warning', tc('no_same_approver'));
    }

    if (!id) {
      return alerting('error', 'No id');
    }

    const res = await api.approve({ id });

    if (!res) {
      return;
    }

    alerting('success', tc('request_approved'));

    await closeHandler();
    zusParams.refetch();
  };

  const rejectBtnClickHandler = async () => {
    const { id } =
      approvalsArr
        .sort(({ seq: seqA }, { seq: seqB }) => seqA - seqB)
        .find(({ status }) => status === EnumEditCustomerAccountRequestStatus.Pending) || {};

    if (!hasPermission(PermissionKey.Details.Reject)) {
      return alerting('warning', tc('alert_no_permission'));
    }

    if (!id) {
      return alerting('error', 'No id');
    }

    const res = await api.reject({ id });

    if (!res) {
      return;
    }

    alerting('success', tc('request_rejected'));

    await closeHandler();
    zusParams.refetch();
  };

  const { labelElePairArr } = useDetailDialogContent(details);

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.approveOrReject),
    self: {
      open: zusDialog.match('approvalDialog'),
      onClose: closeHandler,
    },
    content: (
      <>
        <h3
          style={{
            color: COLOR_THEME.Layout.GridBoxSection.Text,
          }}
        >
          {t(TK.approvalProgress)}
        </h3>

        <ApprovalProgressDialogContent approveHistory={approvalsArr} />

        <br />

        <GridBoxSections
          sectionHeaderList={[{ headerValue: TK.details }]}
          labelElePairArrList={[labelElePairArr]}
        />
      </>
    ),
    onConfirm: () => {},
    onCancel: () => {},
    isCancelHidden: true,
    isConfirmHidden: true,
    actionButtons: (
      <>
        <Button
          className={'inheritColor'}
          color={'primary'}
          variant={'contained'}
          onClick={approveBtnClickHandler}
        >
          {tc('approve_btn')}
        </Button>
        <Button
          className={'inheritColor'}
          variant={'contained'}
          color={'error'}
          sx={{ backgroundColor: '#E86A62' }}
          onClick={rejectBtnClickHandler}
        >
          {tc('reject_btn')}
        </Button>
        <Button
          onClick={closeHandler}
          className={'inheritColor'}
          variant={'contained'}
          color={'secondary'}
        >
          {tc('cancel_btn')}
        </Button>
      </>
    ),
    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
