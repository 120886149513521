import { convertToExportRows } from './../../features/common/filterTable/helpers/convertToExportRows';
import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

const getAssetCustody = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(MerchantPortal.EptTransaction.assetCustody.getAll, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptTransaction.assetCustody.export, input);
};

export default { getAssetCustody, getExport };
