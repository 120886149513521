import { MultipleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import { useGetProgram } from '../../../../../hooks/useProgram';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const ProgramNameMultipleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { EnumAllProgram } = useGetProgram();

  const { tc } = useTranslation();

  let selectValue = value || []

  if (!Array.isArray(selectValue)) {
    selectValue = [value]
  } 

  return (
    <MultipleSelection
      label={label || tc('phSelection', { fieldName: tc('programName') })}
      value={selectValue}
      onChange={onChange}
      clearSelect={onClear}
      enumData={EnumAllProgram}
      nameFn={(item) => item}
    />
  );
};

export default ProgramNameMultipleSelection;
