import React, { useEffect, useState } from 'react';

import APIs from '../../../../../../api';
import { DialogInOne } from '../../../../../../components';
import { useAlerting, useTranslation } from '../../../../../../hooks';
// import Content from './Content';
import { CreateRecordRowKeys, ICreateRecordFields } from './types';
import { IdialogInOneProps } from '../../../../../../components/DialogInOne';
import Content from './Content';
import useCreateRecordDialogValidation from '../../../helpers/useCreateRecordDialogValidation';
import { safeDivide } from '../../../../../../utils/math';

interface IProps {
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
}

const initFields = {
  [CreateRecordRowKeys.ProgramName]: '',
  [CreateRecordRowKeys.CustomerLevel]: '',
  [CreateRecordRowKeys.RewardRate]: '',
};

const createConfigRecordApiFunc = APIs.CobrandManagement.rewardRateConfiguration.createConfig;

const CreateRecordDialog = (props: IProps) => {
  const { isOpen, closeDialog, refreshTable } = props;

  const [createRecordFields, setCreateRecordFields] = useState<ICreateRecordFields>(initFields);

  const { tc, translate } = useTranslation();
  const { alerting } = useAlerting();
  const { validate } = useCreateRecordDialogValidation({ fields: createRecordFields });

  useEffect(() => {
    setCreateRecordFields(initFields);
  }, [isOpen]);

  const handleCloseDialog = () => {
    closeDialog();
  };

  const checkIsCanProceed = () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return false;
    }

    if (Number(createRecordFields[CreateRecordRowKeys.RewardRate]) > 100) {
      alerting('error', translate('rewardRateConfiguration.createRecordDialog.rewardRateAlert'));
      return false;
    }

    return true;
  };

  const onConfirm = async () => {
    const isCanProceed = checkIsCanProceed();

    if (!isCanProceed) return;

    // call api
    const res = await createConfigRecordApiFunc({
      programName: createRecordFields.programName,
      customerLevel: createRecordFields.customerLevel,
      rewardRate: safeDivide(createRecordFields.rewardRate, 100),
    });

    if (!res) return;

    // alert success + refresh
    alerting('success', translate('rewardRateConfiguration.createRecordDialog.successAlert'));
    handleCloseDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('createRecord'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: (
      <Content
        createRecordFields={createRecordFields}
        setCreateRecordFields={setCreateRecordFields}
      />
    ),
    onConfirm: () => {
      onConfirm();
    },
    onCancel: handleCloseDialog,
    size: 'sm',

    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
};

export default CreateRecordDialog;
