export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',
  CustomerId = 'customerId',
  ProgramNames = 'programNames',
  ApplicationNumber = 'applicationNumber',
  CustomerNumber = 'customerNumber',
  TcspCustomerApplicationType = 'tcspCustomerApplicationType',
  Status = 'status',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.ProgramNames]: string[];
  [FilterFieldsKeys.ApplicationNumber]: string;
  [FilterFieldsKeys.CustomerNumber]: string;
  [FilterFieldsKeys.CustomerId]: string;
  [FilterFieldsKeys.TcspCustomerApplicationType]: string;
  [FilterFieldsKeys.Status]: string;
}
