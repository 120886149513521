import { SingleSelection } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const LevelOfActivityAnticipatedSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t } = useTranslation('tcspAccountApplication');

  const Enum = {
    [t('tcspExpectedTxPerMonth.10OrBelow')]: '0',
    [t('tcspExpectedTxPerMonth.11To20')]: '11',
    [t('tcspExpectedTxPerMonth.21to30')]: '21',
    [t('tcspExpectedTxPerMonth.31To40')]: '31',
    [t('tcspExpectedTxPerMonth.41To50')]: '41',
    [t('tcspExpectedTxPerMonth.51To60')]: '51',
    [t('tcspExpectedTxPerMonth.61To70')]: '61',
    [t('tcspExpectedTxPerMonth.71To80')]: '71',
    [t('tcspExpectedTxPerMonth.81To90')]: '81',
    [t('tcspExpectedTxPerMonth.91OrAbove')]: '91',
  };

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('levelOfActivityAnticipatedTitle') })}
      value={value}
      onChange={onChange}
      clearSelect={onClear}
      enumData={Enum}
      nameFn={(name) => name}
      isNoSorting
    />
  );
};

export default LevelOfActivityAnticipatedSingleSelection;
