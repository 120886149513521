import { Box } from '../../../../../../../components/MuiGenerals';

const FilterWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        paddingBottom: '12px',
        '& .MuiFormControl-root': {
          width: '100%',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default FilterWrapper;
