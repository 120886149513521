import { useEffect, useState } from 'react';

import api from '../../../../../../api';
import { DialogInOne } from '../../../../../../components';
import { IdialogInOneProps } from '../../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import { useZusTranslatePrefixStore } from '../../../../../../zustand/store';
import { TableRow } from '../../../types/TableRow';
import DialogContent from './Content';

type EditRecordDialogProps = {
  isOpen: boolean;
  selectedRow: TableRow | undefined;
  closeDialog: () => void;
  refreshTable: () => void;
};

export default function EditRecordDialog({
  isOpen,
  selectedRow,
  closeDialog,
  refreshTable,
}: EditRecordDialogProps) {
  const [status, setStatus] = useState<number | ''>('');
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t, tc } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();

  const previousStatus = selectedRow?.rawData.status || '';

  useEffect(() => setStatus(previousStatus), [selectedRow]);

  const dialogConfig: IdialogInOneProps = {
    title: tc('edit'),
    self: {
      open: isOpen && !!status,
      onClose: closeDialog,
    },
    content: (
      <DialogContent
        previousStatus={previousStatus}
        status={status}
        setStatus={setStatus}
        displayValues={selectedRow}
      />
    ),
    onConfirm: async () => {
      if (!selectedRow || !status) {
        return alerting('error', 'System Error');
      }

      if (status === Number(selectedRow?.rawData.status)) {
        return alerting('warning', tc('no_changes_made_warning'));
      }

      const res = await api.tcsp.tcspAccount.postUpdateStatus({
        customerNumber: selectedRow?.customerNumber,
        status,
      });

      if (!res) {
        return;
      }

      alerting('success', tc('successful_edit'));
      closeDialog();
      refreshTable();
    },
    onCancel: closeDialog,
    size: 'sm',
  };

  return <DialogInOne {...dialogConfig} />;
}
