import React from 'react';
import { MpTextField } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';

interface IProps {
  value: string | undefined;
  onChange: (value: string) => void;
}
const RemarksTextField = (props: IProps) => {
  const { value, onChange } = props;

  const { tc, t } = useTranslation('tcspAccountApplication');

  return (
    <MpTextField
      label={tc('phInputField', { fieldName: t('remarks') })}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      inputProps={{ maxlength: 256 }}
    />
  );
};

export default RemarksTextField;
