import errorCode from '../../utils/errorCode.json';
import COUNTRY_CODE_LIST from './countryCodeList.json';

const json = {
  noPrefix: {}, // for notice no prefix set when use zustand translate prefix
  errorCode: errorCode.en,
  countryCodes: COUNTRY_CODE_LIST, // Reference: https://github.com/joonhocho/country-calling-code

  menu: {
    version: 'Version',
    homepage: 'Home Page',
    dashboard: 'Welcome to PMP!',
    accountSetting: 'Account Setting',
    creditManagement: 'Credit Management',
    // category
    customerManagement: 'Customer Management',
    tcsp: 'TCSP',
    transaction: 'Transaction',
    // sub-category
    customerList: 'Customer List',
    customerAccount: 'Customer Account',
    customerApplication: 'Customer Application',
    creditAdjustmentRequest: 'Credit Adjustment Request',
    repayment: 'Repayment',
    tcspAccount: 'TCSP Account',
    tcspAccountApplication: 'TCSP Account Application',
    assetCustody: 'Asset Custody',
    tcspTransaction: 'TCSP Transaction',
    repaymentBatch: 'Repayment Batch',
    repaymentSchedule: 'Repayment Schedule',
    cobrandManagement: 'Cobrand Management',
    exchangeRate: 'Exchange Rate',
    exchangeRateConfiguration: 'Exchange Rate Configuration',
    referralCode: 'Referral Code',
    program: 'Program',
    cardBlockRequest: 'Card Block Request',
    assetCustodyWallet: 'Asset Custody Wallet',
    reward: 'Reward',
    statement: 'Statement',
    rewardBatch: 'Reward Batch',
    rewardRecord: 'Reward Record',
    qualifiedRepaymentTransaction: 'Qualified Repayment Transaction',
    qualifiedRepayment: 'Qualified Repayment',
    rewardRateConfiguration: 'Reward Rate Configuration',
    toggleFilterButtonCollapse: 'Collapse',
    toggleFilterButtonExpand: 'Expand',
    toggleBtnCollapse: 'Collapse',
    toggleBtnExpand: 'Expand',
    redeemHistory: 'Redeem History',
  },

  common: {
    welcome: 'Welcome',
    logout: 'Logout',
    confirm: 'Confirm',
    edit: 'Edit',
    cancel: 'Cancel',
    start_date: 'Start Date',
    end_date: 'End Date',
    no_data_export: 'No data for export',
    phSelection: 'Please select %{fieldName}',
    phInputField: 'Please enter %{fieldName}',
    input_field_check_number: 'Please enter valid %{fieldName}',
    phSelectionOptional: 'Please select %{fieldName} (Optional)',
    phInputFieldOptional: 'Please enter %{fieldName} (Optional)',
    search: 'Search',
    reset: 'Reset',
    export: 'Export',
    table: 'Table',
    successCreate: 'Created %{target} Successfully',
    autoLogoutAlert:
      'Automatically logged out because another account is already logged in from same browser',
    logoutAlert: 'You have been logged out',
    alert_no_permission: 'No relevant permission',
    programName: 'Program Name',
    customerId: 'Customer Id',
    status: 'Status',
    customerNumber: 'Customer No.',
    merchantId: 'Merchant Id',
    createdBy: 'Created By',
    creationDate: 'Created Date',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    lastModifiedDate: 'Last Modified Date',
    latestVersion: 'Latest Version',
    applicationNumber: 'Application Number',
    tcspCustomerApplicationType: 'TCSP Customer Application Type',
    txHash: 'Transaction Hash',
    referenceNumber: 'Reference Number',
    currency: 'Currency',
    approvalProgressDialogTitle: 'Approval Progress',
    no_relevant_permission: 'No relevant permission',
    no_same_operator_creator: 'Operator and creator cannot be the same',
    no_same_approver: 'Approver and operator cannot be the same',
    request_approved: 'Request Approved',
    approve_successful: 'Approve Successful',
    request_rejected: 'Request Rejected',
    remarks: 'Remarks',
    confirm_btn: 'Confirm',
    approve_btn: 'Approve',
    reject_btn: 'Reject',
    approve_reject_request_btn: 'Approve/Reject',
    viewDetail: 'View Detail',
    approve_reject_dialog_title: 'Approve/Reject ',
    chainName: 'Chain Name',
    selectSearchLabel: 'Type to search',
    orderId: 'Order Id',
    transactionType: 'Transaction Type',
    amount: 'Amount',
    sendingAddress: 'Sending Address',
    receivingAddress: 'Receiving Address',
    creditAmount: 'Credit Amount',
    exchangeRate: 'Exchange Rate',
    transactionHash: 'Transaction Hash',
    creationTime: 'Creation Time',
    cancel_btn: 'Cancel',
    approveReject: 'Approve/Reject',
    applicationDetails: 'Application Details',
    close: 'Close',
    no_data_found: 'No Data in Database',
    auto: 'Auto',
    create_request: 'Create Request',
    not_allow_to_pick: 'Not allow to pick %{object}',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    create_request_success: 'Created request successfully',
    create_record_success: 'Created record successfully',
    copied: 'Copied!',
    clientId: 'Client Id',
    create_record: 'Create Record',
    adjustmentAmount: 'Adjustment Amount',
    ph_program_name_add_on: 'for viewing options in Asset',
    createRecord: 'Create Record',
    validate_below_zero: '%{fieldName} must be larger than 0',
    validate_zero: '%{fieldName} must not be 0',
    no_changes_made_warning: 'Please modify',
    partnerName: 'Partner Name',
    homeCurrency: 'Home Currency',
    adjustmentMode: 'Adjustment Mode',
    customer_number_not_found: 'Customer No. does not exist',
    successful_edit: 'Edited successfully',
    repaymentCurrency: 'Repayment Currency',
    customerLevel: 'Customer Level',
    rewardRate: 'Reward Rate',
    editRecord: 'Edit Record',
    edit_btn: 'Edit',
    request_record_created: 'Request record created',
    network: 'Network',
    yes: 'Yes',
    no: 'No',
    next: 'next',
    optional_field: '%{fieldName} (Optional)',
    optional: 'Optional',
    no_relevant_record: 'No relevant record exists',
    filter: 'Filter',
  },

  validation: {
    selectRequired: 'Please select %{fieldName}',
    inputRequired: 'Please enter %{fieldName}',
    shouldBeLargerThanZero: '%{fieldName} must be larger than 0',
  },

  component: {
    typeToSearch: 'Type to Search',
    noRecords: 'No relevant record exists',
    itemTotalStart: '',
    itemTotalEnd: ' item(s) in total',
  },

  enumConstants: {
    en: 'English',
    zh_cn: '简中',
    timezone0: '+0',
    timezone1: '+1',
    timezone2: '+2',
    timezone3: '+3',
    timezone4: '+4',
    timezone5: '+5',
    timezone6: '+6',
    timezone7: '+7',
    timezone8: '+8',
    timezone9: '+9',
    timezone10: '+10',
    timezone11: '+11',
    timezone12: '+12',
    timezone13: '-11',
    timezone14: '-10',
    timezone15: '-9',
    timezone16: '-8',
    timezone17: '-7',
    timezone18: '-6',
    timezone19: '-5',
    timezone20: '-4',
    timezone21: '-3',
    timezone22: '-2',
    timezone23: '-1',
    Rejected: 'Rejected',
    Pending: 'Pending',
    Approved: 'Approved',
    Executing: 'Executing',
    onHold: 'On Hold',
    ExecutedSuccess: 'Executed Success',
    ExecutedFailed: 'Executed Failed',
    CustomerWallet: 'Customer Wallet',
    MerchantWallet: 'Merchant Wallet',
    AdjustCredit: 'Adjust Credit',
    Repayment: 'Repayment',
    SetCredit: 'Set Credit',
    CreditIn: 'Credit In',
    DebitOut: 'Debit Out',
    Adjustment: 'Adjustment',
    SetValue: 'Set Value',
    MerchantTx: 'Merchant Transaction',
    CustomerTx: 'Customer Transaction',
    Auto: 'Auto',
    ByRequest: 'By Request',
    assigned: 'Assigned',
    created: 'Created',
    deleted: 'Deleted',
    Failed: 'Failed',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
    Enable: 'Enabled',
    Disable: 'Disabled',
    Blacklisted: 'Blacklisted',
    yes: 'Yes',
    no: 'No',
    Yes: 'Yes',
    No: 'No',
    Mr: 'Mr',
    Mrs: 'Mrs',
    Ms: 'Ms',
    Male: 'Male',
    Female: 'Female',
    SelfOwned: 'Self-Owned',
    Mortgaged: 'Mortgaged - Monthly Mortgage Repayment',
    Rented: 'Rented - Monthly Rent',
    LivingWithRelative: 'Living with Relative',
    Others: 'Others',
    Work: 'For work',
    Expenses: 'For expenses',
    Investment: 'For investment',
    Insurance: 'For insurance payment',
    Single: 'Single',
    Married: 'Married',
    Divorced: 'Divorced',
    Widowed: 'Widowed',
    SeniorManagement: 'Senior Management',
    Vp: 'VP / AVP',
    Management: 'Management Grading',
    Officer: 'Officer Grading',
    Clerk: 'Clerk',
    Admin: 'Office Administration',
    SelfEmployed: 'Self-Employed',
    Fulltime: 'Full-Time Employed',
    Parttime: 'Part-Time Employed',
    Unemployed: 'Unemployed',
    Student: 'Student',
    Housewife: 'Housewife',
    Retired: 'Retired',
    University: 'University or above',
    College: 'Post-Secondary / College',
    Secondary: 'Secondary',
    IdCard: 'ID Card',
    Passport: 'Passport',
    Transport: 'Aerospace or Transport',
    Computer: 'Computer',
    It: 'IT or Telecommunication',
    AgricultureOrFisheries: 'Agriculture & Fisheries',
    Education: 'Education',
    Pharmaceutical: 'Pharmaceutical',
    FoodBeverage: 'Food & Beverage Restaurant',
    HealthCare: 'Health Care',
    Hospitality: 'Hospitality',
    Media: 'News & Media',
    Energy: 'Energy',
    Manufacturing: 'Manufacturing',
    Music: 'Music',
    Banking: 'Banking',
    Finance: 'Insurance or Finance',
    Electronics: 'Electronics',
    ProfessionalServices: 'Professional Services',
    Construction: 'Properties & Construction',
    GovEntities: 'Government Entities',
    Regulations: 'Regulations',
    Bars: 'Bars',
    SnackStore: 'Snack Stores',
    Jewelries: 'Jewelries Retails',
    Retails: 'Precious Metal Retails or Dealing',
    Beauty: 'Beauty Shop',
    Liquor: 'Liquor Stores',
    Cigarette: 'Cigarette Distribution',
    NonGovCharitable: 'Non-Government Charitable Organization',
    WetMarket: 'Wet Market',
    FlowerMarket: 'Flower Market',
    Market: 'Market',
    None: 'None',
    Retry: 'Retry',
    Success: 'Success',
    Onfido: 'Onfido',
    Manual: 'Manual',
    Skip: 'Skip',
    Skipped: 'Skipped',
    Registration: 'Registration',
    CreateAccount: 'Create Account',
    UpdateInfo: 'Update Info',
    UpdateEmailAddress: 'Update Email Address',
    UpdatePhoneNo: 'Update Phone No.',
    Submitted: 'Submitted',
    OnChainDeposit: 'On Chain Deposit',
    API: 'API',
    DeductBalance: 'Deduct Balance',
    DeductBalanceSuccess: 'Deduct Balance Success',
    CreateRepayment: 'Create Repayment',
    RepaymentSuccess: 'Repayment Success',
    ExcecuteedFailed: 'Excecuted Failed',
    Cancelled: 'Cancelled',
    Init: 'Initial',
    Excecuting: 'Excecuting',
    ExcecutedSuccess: 'Excecuted Success',
    FastConfirmedFail: 'Fast Confirmed Fail',
    ConfirmedFail: 'Confirmed Fail',
    Unconfirmed: 'Unconfirmed',
    ConfirmedSuccess: 'Confirmed Success',
    Invalid: 'Invalid',
    FastConfirmedSuccess: 'Fast Confirmed Success',
    NoData: 'No Data',
    NoAssessment: 'No Assessment',
    NoRiskDetected: 'No Risk Detected',
    Info: 'Info',
    Caution: 'Caution',
    Classic: 'Classic',
    TCSP: 'TCSP',
    AssetCustody: 'AssetCustody',
    ManualReview: 'Manual Review',
    ManualSuccess: 'Manual Success',
    ByScheduler: 'By Scheduler',
    Reward: 'Reward',
    RejectedAndReleased: 'Rejected and Released',
    Percentage: 'Percentage',
    AbsoluteValue: 'Absolute Value',
    OverwriteValue: 'Overwrite Value',
    IdentityCard: 'Hong Kong Identity Card',
    DrivingLicence: 'Driving Licence',
    ShareCode: 'Share Code',
    VoterId: 'Voter ID',
    Ssn: 'SSN',
    SocialInsurance: 'Social Insurance',
    TaxId: 'Tax ID',
    InitialCredit: 'Initial Credit',
    UserRequest: 'User Request',
    Verified: 'Verified',
    repayment: 'Repayment',
    reward: 'Reward',
    Completed: 'Completed',
    InProcess: 'In Process',
    Unknown: 'Unknown',
    Critical: 'Critical',
    High: 'High',
    Medium: 'Medium',
    SystemBlock: 'System Block',
    BackofficeBlock: 'Back Office Block',
    CustomerBlock: 'Customer Block',
    LostCard: 'Lost Card',
    blocked: 'Blocked',
    pending: 'Pending',
    released: 'Released',
    replaced: 'Replaced',
    reissued: 'Reissued',
    unknown: 'Unknown',
    Lost: 'Lost',
    Expired: 'Expired',
    Damaged: 'Damaged',
    Replace: 'Replace',
    suspended: 'Suspended',
    fraud: 'Fraud',
    Fraud: 'Fraud',
    lostStolen: 'Lost/Stolen',
    OperationFailed: 'Operation Failed',
    RequestFailed: 'Request Failed',
    Submitting: 'Submitting',
    Unassigned: 'Unassigned',
    loststolen: 'Lost/Stolen',
    Redeem: 'Redeem',

    CustomerRewardWallet: 'Customer Reward Wallet',
    ProgramAgentWallet: 'Program Agent Wallet',
    ProgramAgentRewardWallet: 'Program Agent Reward Wallet',
    DistributorAgentWallet: 'Distributor Agent Wallet',
    DistributorAgentRewardWallet: 'Distributor Agent Reward Wallet',
    DistributorAgentCollateralWallet: 'Distributor Agent Collateral Wallet',

    RepaymentOrderClassicGeneric: 'Cash/Cheque',
    RepaymentOrderFPS: 'FPS',
    RepaymentOrderTcspGeneric: 'TCSP',
    RepaymentOrderSwapCredit: 'Program Agent Credit Swap',
    RepaymentOrderAllocation: 'Distributor Agent Allocation',
    RepaymentOrderRedeem: 'Redeem',
  },

  TcspExpectedTxAmountPerMonth: {
    '10000OrBelow': '10,000 or Below',
    '10001To30000': '10,001 - 30,000',
    '30001To50000': '30,001 - 50,000',
    '50001To100000': '50,001 - 100,000',
    '100001To300000': '100,001 - 300,000',
    '300001To500000': '300,001 - 500,000',
    '500001OrAbove': '500,001 or Above',
  },

  yubiKeyHook: { enter_yubi_key: 'Enter YubiKey' },

  accountSetting: {
    email: 'Login Email',
    timezone: 'Time Zone',
    language: 'Langauge',
  },
  tcspAccount: {
    merchantId: 'Merchant Id',
    customerId: 'Customer Id',
    programName: 'Program Name',
    programNames: 'Program Name',
    customerNumber: 'Customer No.',
    status: 'Status',
    createdBy: 'Created By',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    creationDate: 'Creation Date',
    creationTime: 'Creation Time',
    lastModifiedDate: 'Last Modified Date',
    latestVersion: 'Latest Version',
    operation: 'Operation',
    viewWalletAddressCell: 'View Wallet Address',
    viewWalletAddressDialog: {
      network: 'Network',
      qrCode: 'QR Code',
      walletAddress: 'Wallet Address',
    },
  },

  tcspAccountApplication: {
    merchantId: 'Merchant Id',
    customerId: 'Customer Id',
    programName: 'Program Name',
    programNames: 'Program Name',
    customerNumber: 'Customer No.',
    status: 'Status',
    createdBy: 'Created By',
    creationDate: 'Creation Date',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    lastModifiedDate: 'Last Modified Date',
    creationTime: 'Creation Time',
    latestVersion: 'Latest Version',
    tcspCustomerApplicationType: 'TCSP Customer Application Type',
    customerApplicationVersion: 'Customer Application Version',
    version: 'Version',
    isLatestVersion: 'Is Latest Version',
    questionVersion: 'Question Version',
    applicationNumber: 'Application Number',
    approvalProgress: 'Approval Progress',
    operations: 'Operations',
    // approval
    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
    // application details
    personalInfoTitle: ' Personal Information %{index}',
    title: 'Title',
    firstNameInEng: 'First Name in English',
    lastNameInEng: 'Last Name in English',
    chineseName: 'Chinese Name',
    aliasInEng: 'Alias in English',
    phoneNumber: 'Phone Number',
    email: 'Email',
    identificationDocumentType: 'Identification Document Type',
    identificationNumber: 'Identification Number',
    dateOfIssue: 'Date of Issue',
    dateOfExpiry: 'Date of Expiry',
    dateOfBirth: 'Date of Birth',
    usCitizenship: 'U.S. Citizenship',

    reasonForSettingUpTrustAcc: 'Reason for setting up a trust account in Hong Kong',
    personPurportingToActOn: 'Person purporting to act on behalf of you - PPTA',

    seniorPublicFigureDeclarationTitle: 'Senior Public Figure Declaration',
    seniorPublicFigureDeclaration:
      'I or my immediate family member currently hold(s) / has / have held a position as a senior public figure (individual who is working / has worked in any local or foreign government including judiciary / armed forces / embassies etc.)',
    noSeniorPublicFigureDeclaration:
      'I or my immediate family member currently does /do not hold / have not held any position as senior public figure.',
    member: 'Member %{index}',
    firstName: 'First Name',
    lastName: 'Last Name',
    countryOfGov: 'Country of Government',
    department: 'Department',
    position: 'Position',
    relationshipWithCustomer: 'Source and Origins of Funds to be used in the relationship (SoF)',

    levelOfActivityAnticipatedHeader: 'Level of Activity Anticipated',
    initialAndOngoingSOurceOfCustomerWealth:
      'Initial and Ongoing Sources of Customer’s Wealth or income(SoW)',
    sourceAndOriginOfFunds: ' Source and Origins of Funds to be used in the relationship (SoF)',
    totalVolumeOfAsset: 'Total Volume of Asset in HKD',
    totalVolumeOfAsset2: 'Total Volume of Asset in (HKD)*',
    levelOfActivityAnticipatedTitle: 'Level of Activity Anticipated',
    expectedTransactionAmount: 'Expected Transaction Amount Per Month',
    applicantSignature: 'Applicant’s Signature',
    declarationTermsAndConditions: 'Declaration, and Terms and Conditions',
    wishToEnableAdvancePayment: 'I do not wish to enable Advance Payment.',
    applicationSignature: "Applicant's Signature",
    wishToEnablePrePayment: 'I do not wish to enable Pre-Payment.',
    signatureDate: 'Signature Date',

    reasonForTrustAccount:
      'Placing certain type of assets as collateral for the purpose of PFH’s imagination credit card bills payment or increasing credit limit',

    others: 'Others',
    sourceOfWealth: {
      Others: 'Others',
      Employment: 'Employment',
      SelfEmployment: 'Self-Employment',
      BusinessOwnership: 'Business Ownership',
      LoansGrant: 'Loans/Grant',
      SpouseParentPartner: 'Spouse/Parent/Partner',
      Inheritance: 'Inheritance',
      TrustFund: 'Trust Fund',
      Investment: 'Investment',
      SalesOfVirtualAssets: 'Sales of Virtual Assets',
      PersonalRealEstate: 'Personal Real Estate',
    },

    sourceOfFunds: {
      Others: 'Others',
      BusinessIncome: 'Business Income',
      PersonalSaving: 'Personal Savings',
      Inheritance: 'Inheritance',
      RentalIncome: 'Rental Income',
      Investment: 'Investment',
      SalesOfProperty: 'Sales of Property',
      SalesOfVirtualAssets: 'Sales of Virtual Assets',
      Pension: 'Pension',
      WagesDepositedFromEmployer: 'Wages deposited from employer',
    },

    tcspTotalVolumeOfAsset: {
      Below50000: 'Below 50,000',
      '50000To99999': '50,000 - 99,999',
      '100000To199999': '100,000 - 199,999',
      '200000To499999': '200,000 - 499,999',
      '500000To999999': '500,000 - 999,999',
      '1000000OrAbove': '1,000,000 or Above',
    },

    tcspExpectedTxPerMonth: {
      '10OrBelow': '10 or Below',
      '11To20': '11 - 20',
      '21to30': '21 - 30',
      '31To40': '31 - 40',
      '41To50': '41 - 50',
      '51To60': '51 - 60',
      '61To70': '61 - 70',
      '71To80': '71 - 80',
      '81To90': '81 - 90',
      '91OrAbove': '91 or Above',
    },
    noPersonPurportingToActOn: 'No',
    existedTcspApplicationError: 'Application already existed',
    customerAccountDisabled: 'Customer account is disabled or not existed',
  },

  assetCustody: {
    programName: 'Program Name',
    programNames: 'Program Name',
    customerNumber: 'Customer No.',
    clientId: 'Client Id',
    chainType: 'Chain Type',
    chainId: 'Chain Id',
    assetName: 'Asset Name',
    currency: 'Currency',
    amount: 'Amount',
    postBalance: 'Post Balance',
    decimals: 'Decimals',
    lastLedgerTransactionId: 'Last Ledger Transaction Id',
    fromAddress: 'Sending Address',
    toAddress: 'Receiving Address',
    txHash: 'Transaction Hash',
    referenceNumber: 'Reference Number',
    riskLevel: 'Risk Level',
    transactionStatus: 'Transaction Status',
    createdBy: 'Created By',
    creationDate: 'Creation Date',
    lastModifiedDate: 'Last Modified Date',
    chainName: 'Chain Name',
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
  },

  tcspTransactions: {
    orderId: 'Order Id',
    transactionType: 'Transaction Type',
    adjustmentMode: 'Adjustment Mode',
    amount: 'Amount',
    sendingAddress: 'Sending Address',
    receivingAddress: 'Receiving Address',
    creditAmount: 'Credit Amount',
    exchangeRate: 'Exchange Rate',
    transactionHash: 'Transaction Hash',
    creationTime: 'Creation Time',
    creationDate: 'Creation Date',
    lastModifiedDate: 'Last Modified Date',
    lastModifiedTime: 'Last Modified Time',
    programName: 'Program Name',
    programNames: 'Program Name',
    customerNumber: 'Customer No.',
    currency: 'Currency',
    createdBy: 'Created By',
    currencyOfCreditAmount: 'Currency Of Credit Amount',
  },
  customerList: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    customer_id: 'Customer ID',
    email: 'Email Address',
    phone_country_code: 'Phone Country Code',
    phone_number: 'Phone No.',
    customer_status: 'Customer Status',
    latest_version: 'Latest Version',
    created_by: 'Created By',
    operation: 'Operation',
    update_info: 'Update Info',
    force_password_change: 'Force Password Change',
    update_email_address: 'Update Email Address',
    update_phone_no: 'Update Phone No.',

    personal_details: 'Personal Details',
    title: 'Title',
    first_name: 'First Name',
    last_name: 'Last Name',
    chinese_name: 'Chinese Name',
    alias: 'Alias',
    gender: 'Gender',
    date_of_birth: 'Date of Birth',
    nationality: 'Nationality',
    identification_document_type: 'Identification Document Type',
    identification_document_type_others: 'Identification Document Type (Others)',
    identification_number: 'Identification No.',
    date_of_expiry: 'Date of Expiry',
    date_of_issue: 'Date of Issue',
    us_citizenship: 'U.S. Citizenship',
    marital_status: 'Marital Status',
    educational_status: 'Education Level',
    educational_status_others: 'Education Level (Others)',
    purpose_for_applying: 'Purpose for Applying',
    other_purpose: 'Purpose for Applying (Others)',
    is_authorized_by_you:
      'Is anyone authorized by you to conduct account activities on your behalf through the credit card account?',

    occupation_details: 'Occupation Details',
    employment_status: 'Employment Status',
    company_name: 'Company Name',
    job_title: 'Job Title',
    job_title_others: 'Job Title (Others)',
    business_nature_or_industry: 'Business Nature/Industry',
    other_industry: 'Business Nature/Industry (Others)',
    employment_start_date: 'Employment Start Date',
    office_telephone_number: 'Office Telephone No.',
    monthly_salary_in_hkd: 'Monthly Salary in HKD',
    other_monthly_income: 'Other Monthly Income',
    email_address: 'Email Address',
    phone_no: 'Phone No.',
    application_no: 'Application No.',
    version_no: 'Version No.',
    application_type: 'Application Type',
    referral_code: 'Referral Code',
    program_name: 'Program Name',

    residential_details: 'Residential Details',
    residential_status: 'Residential Status',
    other_status: 'Residential Status (Others)',
    residential_telephone_number: 'Residential Telephone No.',
    resident_address_section: 'Resident Address Section',
    delivery_address_section: 'Card Delivery Section',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    address_line_3: 'Address Line 3',
    postal_code: 'Postal Code',
    city: 'City',
    country: 'Country',
    residential_address_line_1: 'Address Line 1',
    residential_address_line_2: 'Address Line 2',
    residential_address_line_3: 'Address Line 3',
    residential_postal_code: 'Postal Code',
    residential_city: 'City',
    residential_country: 'Country',
    delivery_address_line_1: 'Address Line 1',
    delivery_address_line_2: 'Address Line 2',
    delivery_address_line_3: 'Address Line 3',
    delivery_postal_code: 'Postal Code',
    delivery_city: 'City',
    delivery_country: 'Country',
    residential_address_line_1_warning: 'Resident Address Section Address Line 1',
    residential_postal_code_warning: 'Resident Address Section Postal Code',
    residential_city_warning: 'Resident Address Section City',
    residential_country_warning: 'Resident Address Section Country',
    delivery_address_line_1_warning: 'Card Delivery Section Address Line 1',
    delivery_postal_code_warning: 'Card Delivery Section Postal Code',
    delivery_city_warning: 'Card Delivery Section City',
    delivery_country_warning: 'Card Delivery Section Country',

    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
    customer_number: 'Customer No.',
    assign_customer_number: 'Assign Customer No. & Level',
    alert_no_customer_number: 'Please assign the Customer No. before approved the request',

    idv_details: 'IDV Details',
    manual_verify_idv: 'Manual Review IDV',
    verify: 'Verify',
    success: 'Success',
    fail: 'Fail',
    retry: 'Retry',
    applicant_id: 'Applicant ID (Onfido)',
    workflow_run_id: 'Workflow Run ID (Onfido)',
    retry_idv: 'Retry IDV',
    switch_to_manual_review: 'Switch to Manual Review',
    success_retry_idv: 'Status Changed Successfully',
    success_assign_customer_number: 'Assigned Customer No. Successfully',
    success_manual_verify_idv: 'Manually verified IDV Successfully',
    fail_manual_verify_idv: 'Manually Fail IDV Successfully',
    max_credit_limit: 'Max Credit Limit',
    credit_limit: 'Credit Limit',
    no_permission: 'No Relevant Permission',
    success_manual_review_switch: 'Status Changed Successfully',
    place_of_issue: 'Place Of Issue',
    customer_level: 'Customer Level',
    idv_status: 'IDV Status',
    idv_method: 'IDV Method',
    idv_manually_verified_by: 'IDV Manually Verified By',
    document_status: 'Document Status (Onfido)',
    document_sub_status: 'Document Sub-Status (Onfido)',
    document_verified_by: 'Document Verified By',
    facial_similarity_status: 'Facial Similarity Status (Onfido)',
    facial_similarity_sub_status: 'Facial Similarity Sub-Status (Onfido)',
    facial_similarity_verified_by: 'Facial Similarity Verified By',
    watch_list_status: 'Watch List Status (Onfido)',
    watch_list_sub_status: 'Watch List Sub-Status (Onfido)',
    watch_list_verified_by: 'Watch List Verified By',
    details: 'Details',
    edit_successful_message: 'Created Update Info application successfully',
    created_pending_application_success: 'Created Pending Application Successfully',
    original_phone_number: 'Original Phone No.',
    original_email_address: 'Original Email Address',
    new_email_address: 'New Email Address',
    new_country_code: 'New Country Code',
    new_phone_number: 'New Phone No.',
    create_customer: 'Create Customer',
    invalid_email_warning: 'Invalid email address format',
    create_customer_application: 'Create Customer Application',
    create_application_successful_message: 'Create pending application successfully',
    create_customer_successful_message: 'Created customer successfully',
    id_number_length_warning: 'Identification No. at least 5 characters',
  },
  customerAccount: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    customer_id: 'Customer ID',
    customer_number: 'Customer No.',
    program_name: 'Program Name',
    customer_account_status: 'Customer Account Status',
    max_credit_limit: 'Max. Credit Limit',
    credit_limit: 'Credit Limit',
    created_by: 'Created By',
    customer_level: 'Customer Level',
    edit: 'Edit',
    operation: 'Operation',
    remark: 'Remark',
    customer_account: 'Customer Account',
    request_record: 'Request Record',
    max_credit_limit_alert:
      'Max. Credit Limit for customer account cannot exceed Max. Credit Limit for corresponding program',
    program_agent_id: 'Program Agent ID',
    distributor_agent_id: 'Distributor Agent ID',
  },
  customerAccountRequestRecord: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    request_id: 'Request ID',
    approval_progress: 'Approval Progress',
    customer_number: 'Customer No.',
    status: 'Status',
    operation: 'Operation',
    remark: 'Remark',
    created_by: 'Created By',
    program_name: 'Program Name',
    details: 'Details',
    customer_level: 'Customer Level',
    max_credit_limit: 'Max. Credit Limit',
    customer_account_status: 'Customer Account Status',
    approve_or_reject: 'Approve/Reject',

    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
  },

  customerApplication: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    operation: 'Operation',
    application_no: 'Application No.',
    approval_progress: 'Approval Progress',
    version_no: 'Version No.',
    latest_version: 'Latest Version',
    application_type: 'Application Type',
    program_name: 'Program Name',
    referral_code: 'Referral Code',
    customer_id: 'Customer ID',
    customer_name: 'Customer Name',
    email_address: 'Email Address',
    phone_no: 'Phone No.',
    application_status: 'Application Status',
    kyc_reference_code: 'Reference Code (Onfido)',
    idv_status: 'IDV Status',
    idv_method: 'IDV Method',
    idv_manually_verified_by: 'IDV Manually Verified By',
    document_status: 'Document Status (Onfido)',
    document_sub_status: 'Document Sub-Status (Onfido)',
    document_verified_by: 'Document Verified By',
    facial_similarity_status: 'Facial Similarity Status (Onfido)',
    facial_similarity_sub_status: 'Facial Similarity Sub-Status (Onfido)',
    facial_similarity_verified_by: 'Facial Similarity Verified By',
    watch_list_status: 'Watch List Status (Onfido)',
    watch_list_sub_status: 'Watch List Sub-Status (Onfido)',
    watch_list_verified_by: 'Watch List Verified By',
    created_by: 'Created By',
    details: 'Details',
    program_agent_id: 'Program Agent ID',
    distributor_agent_id: 'Distributor Agent ID',

    personal_details: 'Personal Details',
    title: 'Title',
    first_name: 'First Name',
    last_name: 'Last Name',
    chinese_name: 'Chinese Name',
    alias: 'Alias',
    gender: 'Gender',
    date_of_birth: 'Date of Birth',
    nationality: 'Nationality',
    identification_document_type: 'Identification Document Type',
    identification_document_type_others: 'Identification Document Type (Others)',
    identification_number: 'Identification No.',
    date_of_expiry: 'Date of Expiry',
    date_of_issue: 'Date of Issue',
    us_citizenship: 'U.S. Citizenship',
    marital_status: 'Marital Status',
    educational_status: 'Education Level',
    educational_status_others: 'Education Level (Others)',
    purpose_for_applying: 'Purpose for Applying',
    other_purpose: 'Purpose for Applying (Others)',
    is_authorized_by_you:
      'Is anyone authorized by you to conduct account activities on your behalf through the credit card account?',

    occupation_details: 'Occupation Details',
    employment_status: 'Employment Status',
    company_name: 'Company Name',
    job_title: 'Job Title',
    job_title_others: 'Job Title (Others)',
    business_nature_or_industry: 'Business Nature/Industry',
    other_industry: 'Business Nature/Industry (Others)',
    employment_start_date: 'Employment Start Date',
    office_telephone_number: 'Office Telephone No.',
    monthly_salary_in_hkd: 'Monthly Salary in HKD',
    other_monthly_income: 'Other Monthly Income',

    residential_details: 'Residential Details',
    residential_status: 'Residential Status',
    other_status: 'Residential Status (Others)',
    residential_telephone_number: 'Residential Telephone No.',
    resident_address_section: 'Resident Address Section',
    delivery_address_section: 'Card Delivery Section',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    address_line_3: 'Address Line 3',
    postal_code: 'Postal Code',
    city: 'City',
    country: 'Country',
    residential_address_line_1: 'Address Line 1',
    residential_address_line_2: 'Address Line 2',
    residential_address_line_3: 'Address Line 3',
    residential_postal_code: 'Postal Code',
    residential_city: 'City',
    residential_country: 'Country',
    delivery_address_line_1: 'Address Line 1',
    delivery_address_line_2: 'Address Line 2',
    delivery_address_line_3: 'Address Line 3',
    delivery_postal_code: 'Postal Code',
    delivery_city: 'City',
    delivery_country: 'Country',
    residential_address_line_1_warning: 'Resident Address Section Address Line 1',
    residential_postal_code_warning: 'Resident Address Section Postal Code',
    residential_city_warning: 'Resident Address Section City',
    residential_country_warning: 'Resident Address Section Country',
    delivery_address_line_1_warning: 'Card Delivery Section Address Line 1',
    delivery_postal_code_warning: 'Card Delivery Section Postal Code',
    delivery_city_warning: 'Card Delivery Section City',
    delivery_country_warning: 'Card Delivery Section Country',

    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
    customer_number: 'Customer No.',
    assign_customer_number: 'Assign Customer No. & Level',
    alert_no_customer_number: 'Please assign the Customer No. before approved the request',
    phone_country_code: 'Phone Country Code',

    idv_details: 'IDV Details',
    manual_verify_idv: 'Manual Review IDV',
    verify: 'Verify',
    success: 'Success',
    fail: 'Fail',
    retry: 'Retry',
    applicant_id: 'Applicant ID (Onfido)',
    workflow_run_id: 'Workflow Run ID (Onfido)',
    retry_idv: 'Retry IDV',
    switch_to_manual_review: 'Switch to Manual Review',
    success_retry_idv: 'Status Changed Successfully',
    success_assign_customer_number: 'Assigned Customer No. Successfully',
    success_manual_verify_idv: 'Manually verified IDV Successfully',
    fail_manual_verify_idv: 'Manually Fail IDV Successfully',
    max_credit_limit: 'Max Credit Limit',
    credit_limit: 'Credit Limit',
    no_permission: 'No Relevant Permission',
    success_manual_review_switch: 'Status Changed Successfully',
    place_of_issue: 'Place Of Issue',
    customer_level: 'Customer Level',
    customer_level_helper_text: 'Reward Rate (%{currency}): %{rewardRate}%',
    assigned_customer_number: 'Assigned Customer No.',
    noCustomerLevelFoundAlert: 'No Customer Level',
    onHoldMenuTitle: 'On Hold',
    releaseHoldMenuTitle: 'Release Hold',
    onHoldApplicationSuccessAlert: 'On Hold Application Successfully',
    onHoldApplicationFailedAlert: 'Failed to on hold',
    releaseApplicationSuccessAlert: 'Release Application Successfully',
    releaseApplicationFailedAlert: 'Failed to release',

    same_address_checkbox: 'Same as Residential Address',
    edit_successful_message: 'Created new application for approval',
    edit_application_remarks: 'Edited application',
    id_number_length_warning: 'Identification No. at least 5 characters',
  },

  creditAdjustmentRequest: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    operation: 'Operation',
    orderId: 'Order ID',
    adjustment_mode: 'Adjustment Mode',
    adjustment_type: 'Adjustment Type',
    approval_progress: 'Approval Progress',
    program_name: 'Program Name',
    customer_number: 'Customer No.',
    risk_level: 'Risk Level',
    asset_custody_id: 'Asset Custody ID',
    asset: 'Asset',
    asset_balance_when_requesting: 'Asset Balance When Requesting',
    asset_collateral_amount: 'Asset Collateral Amount',
    exchange_rate: 'Exchange Rate',
    real_time_credit_when_requesting: 'Real Time Credit When Requesting',
    release_credit_amount: 'Release Credit Amount',
    status: 'Status',
    manual_approval_reason: 'Manual Approval Reason',
    created_by: 'Created By',
    details: 'Details',
    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
    create_request: 'Create Request',
    exchange_rate_helper_text: 'Current exchange rate: %{exchangeRate}',
    program_name_helper_text: 'Program name: %{programName}',
    available_balance_helper_text: 'Available balance: %{availableBalance}',
    available_balance_checking: 'Available balance is not enough',
    no_available_asset: 'No available asset',
    estimated_release_credit_amount: 'Estimated Release Credit Amount',
  },
  repaymentBatch: {
    batch_order_id: 'Batch Order ID',
    program_name: 'Program Name',
    customer_number: 'Customer No.',
    asset: 'Asset',
    asset_collateral_amount: 'Asset Collateral Amount',
    asset_repayment_amount: 'Asset Repayment Amount',
    release_credit_amount: 'Release Credit Amount',
    repayment_amount: 'Repayment Amount',
    status: 'Status',
    step: 'Step',
    created_by: 'Created By',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
  },
  repaymentSchedule: {
    available_balance_checking: 'Available reward balance is not enough',
    available_balance_helper_text: 'Available reward balance: %{availableBalance}',
    exchange_rate_helper_text: 'Current exchange rate: %{exchangeRate}',
    no_available_asset: 'No available asset',
    operation: 'Operation',
    details: 'Details',
    batch_order_id: 'Batch Order ID',
    repayment_order_id: 'Repayment Order ID',
    schedule_time: 'Schedule Time',
    approval_progress: 'Approval Progress',
    repayment_mode: 'Repayment Mode',
    repayment_sub_mode: 'Repayment Sub-Mode',
    external_reference: 'External Reference',
    program_name: 'Program Name',
    repayment_type: 'Repayment Type',
    customer_number: 'Customer No.',
    asset: 'Asset',
    asset_collateral_amount: 'Asset Collateral Amount',
    asset_repayment_amount: 'Asset Repayment Amount',
    exchange_rate: 'Exchange Rate',
    release_credit_amount: 'Release Credit Amount',
    credit_repayment_amount: 'Credit Repayment Amount',
    estimated_release_credit_amount: 'Estimated Release Credit Amount',
    status: 'Status',
    credit_adjustment_order_id: 'Credit Adjustment Order ID',
    created_by: 'Created By',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    executed_by: 'Executed By',
    execution_time: 'Execution Time',
    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
    create_request: 'Create Request',
    immediate: 'Immediate',
    date: 'Date',
    time: 'Time',
    program_name_helper_text: 'Program name: %{programName}',
  },

  exchangeRateConfiguration: {
    operation: 'Operation',
    creationDate: 'Creation Date',
    creationTime: 'Creation Time',
    lastModifiedDate: 'Last Modified Date',
    programName: 'Program Name',
    programNames: 'Program Name',
    from: 'From',
    to: 'To',
    exchangeRateOfCurrentDate: 'Exchange Rate of Current Date',
    adjustmentType: 'Adjustment Type',
    adjustmentAmount: 'Adjustment Amount',
    exchangeRateAfterAdjustment: 'Exchange Rate After Adjustment',
    createdBy: 'Created By',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    editDialog: {
      successAlert: 'Edited record successfully',
      noChangeAlert: 'Please modify ',
    },
    createRecordDialog: {
      successAlert: 'Created record successfully',
      duplicateAlert: 'The record already exists',
      negativeAdjustedExchangeRateAlert: 'The Exchange Rate After Adjustment must be larger than 0',
    },
  },

  exchangeRate: {
    trade_date: 'Trade Date',
    last_modified_time: 'Last Modified Time',
    from: 'From',
    create_record: 'Create Record',
    to: 'To',
    exchange_rate: 'Exchange Rate',
    exchange_rate_upper_limit: 'Exchange Rate Upper Limit',
    exchange_rate_lower_limit: 'Exchange Rate Lower Limit',
    operation: 'Operation',
    created_by: 'Created By',
    last_modified_by: 'Last Modified By',
    creation_time: 'Creation Time',
    success_edit_record: 'Edited record successfully',
    trade_date_helper_text:
      'Exchange rate will be effective within %{date} 00:00:00 - 23:59:59 (GMT+0)',
  },

  referralCode: {
    operation: 'Operation',
    referral_code: 'Referral Code',
    program_name: 'Program Name',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    idv_method: 'IDV Method',
    status: 'Status',
    created_by: 'Created By',
    last_modified_by: 'Last Modified By',
    create_record: 'Create Record',
    success_edit_record: 'Edited record successfully',
  },

  program: {
    programName: 'Program Name',
    programNames: 'Program Name',
    programDisplayName: 'Program Display Name',
    partnerName: 'Partner Name',
    homeCurrency: 'Home Currency',
    maxCreditLimit: 'Max Credit Limit',
    defaultCreditLimit: 'Default Credit Limit',
    creditAdjustmentApprovalThreshold: 'Credit Adjustment Approval Threshold',
    repaymentHouseAccount: 'Repayment House Account',
    rewardHouseAccount: 'Reward House Account',
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
  },
  cardBlockRequest: {
    creation_time: 'Creation Time',
    release_time: 'Release Time',
    card_block_id: 'Card Block ID',
    program_name: 'Program Name',
    card_block_type: 'Card Block Type',
    status: 'Status',
    customer_number: 'Customer No.',
    card_number: 'Card No.',
    remarks: 'Remarks',
    e6_block_reason: 'E6 Block Reason',
    memo: 'Memo',
    release_memo: 'Release Memo',
    created_by: 'Created By',
    released_by: 'Released By',
    releasable: 'Releasable',
    customer_remarks: 'Customer Remarks',
    back_office_remarks: 'Back Office Remarks',
    operation: 'Operation',
    release_card_block: 'Release Card Block',
    reissue_new_card: 'Reissue New Card',
    reissue_new_card_title: 'Confirm to reissue new card?',
    release_card_block_success_alert: 'Released successfully',
    reissue_card_success_alert: 'Created new card order successfully',
    pan_last_four: 'PAN (Last 4)',
    reissue_new_card_reason: 'Reissue Reason',
    create_card_block_reason: 'Reason',
  },
  assetCustodyWallet: {
    program_name: 'Program Name',
    customer_number: 'Customer No.',
    client_id: 'Client ID',
    currency: 'Currency',
    balance: 'Balance',
    wallet_type: 'Wallet Type',
    frozen_balance: 'Frozen Balance',
    last_ledger_transaction_id: 'Last Ledger Transaction ID',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
  },
  qualifiedRepaymentTransaction: {
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
    programName: 'Program Name',
    programNames: 'Program Name',
    orderId: 'Order ID',
    customerNumber: 'Customer No.',
    transactionType: 'Transaction Type',
    repaymentCurrency: 'Repayment Currency',
    rewardRate: 'Reward Rate',
    repaymentAmount: 'Repayment Amount',
    repaymentRate: 'Repayment Rate',
    rewardAmount: 'Reward Amount',
    postBalance: 'Post Balance',
  },
  rewardBatch: {
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
    programName: 'Program Name',
    programNames: 'Program Name',
    customerNumber: 'Customer No.',
    status: 'Status',
    rewardBatchId: 'Reward Batch ID',
    statementId: 'Statement ID',
    rewardAmount: 'Reward Amount',
    rewardCurrency: 'Reward Currency',
    month: 'Month',
  },
  qualifiedRepayment: {
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
    programName: 'Program Name',
    programNames: 'Program Name',
    customerNumber: 'Customer No.',
    repaymentCurrency: 'Repayment Currency',
    rewardRate: 'Reward Rate',
    balance: 'Balance',
  },
  rewardRecord: {
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
    programName: 'Program Name',
    programNames: 'Program Name',
    customerNumber: 'Customer No.',
    rewardOrderId: 'Reward Order ID',
    rewardBatchId: 'Reward Batch ID',
    statementId: 'Statement ID',
    rewardAmount: 'Reward Amount',
    rewardCurrency: 'Reward Currency',
    rewardRate: 'Reward Rate',
    qualifiedSpendingAmount: 'Qualified Spending Amount',
    qualifiedRepaymentAmount: 'Qualified Repayment Amount',
    repaymentCurrency: 'Repayment Currency',
    rewardAmountInBillingCurrency: 'Reward Amount in Billing Currency',
    paymentDueDate: 'Payment Due Date',
    exchangeRate: 'Exchange Rate',
    month: 'Month',
    hkd: 'HKD',
  },
  statement: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    program_name: 'Program Name',
    customer_number: 'Customer No.',
    reward_status: 'Reward Status',
    month: 'Month',
    total_rebatable_amount: 'Total Rebatable Amount',
    statement_period_from: 'Statement Period From',
    statement_period_to: 'Statement Period To',
    payment_due_date: 'Payment Due Date',
    customer_level: 'Customer Level',
    reward_currency: 'Reward Currency',
    reward_currency_exchange_rate: 'Reward Currency Exchange Rate',
  },

  rewardRateConfiguration: {
    operation: 'Operation',
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
    programName: 'Program Name',
    programNames: 'Program Name',
    customerLevel: 'Customer Level',
    rewardCurrency: 'Reward Currency',
    rewardRate: 'Reward Rate',
    createdBy: 'Created By',
    lastModifiedBy: 'Last Modified By',
    rewardCurrencyHelperText: 'Reward Currency: %{currency}',
    createRecordDialog: {
      successAlert: 'Created request successfully',
      rewardRateAlert: 'The max. Reward Rate is 100%',
    },
    editRecordDialog: {
      successAlert: 'Edited request successfully',
      rewardRateAlert: 'The max. Reward Rate is 100%',
      noChangeAlert: 'Please modify',
    },
  },
  redeemHistory: {
    programName: 'Program Name',
    customerNumber: 'Customer No.',
    currency: 'Currency',
    redeemAmount: 'Redeem Amount',
    creationTime: 'Creation Time',
  },
};

export default json;

type CustomerListKey = keyof typeof json.customerList;
type CustomerAccountKey = keyof typeof json.customerAccount;
type CustomerApplication = keyof typeof json.customerApplication;

export { type CustomerListKey, type CustomerAccountKey, type CustomerApplication };
