import React, { ReactNode, SetStateAction } from 'react';

import { useGenFilterField } from '../../../../utils/ComponentHelper';
import ResetButton from '../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../common/filterTable/components/Buttons/SearchButton';
import CurrencySingleSelection from '../../../common/filterTable/components/Filters/CurrencySingleSelection';
import Filters from '../../../common/filterTable/components/Filters/Filters';
import ProgramNameSingleSelection from '../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import ReferenceNumberTextField from '../../../common/filterTable/components/Filters/ReferenceNumberTextField';
import TxHashTextField from '../../../common/filterTable/components/Filters/TxHashTextField';
import FilterActions from '../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../common/filterTable/layout/FilterGrid';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import CustomerNumberTextField from '../../../common/filterTable/components/Filters/CustomerNumberTextField';
import ClientIdTextField from '../../../common/filterTable/components/Filters/ClientIdTextField';
import SendingAddressTextField from '../../../common/filterTable/components/Filters/SendingAddressTextField';
import ReceivingAddressTextField from '../../../common/filterTable/components/Filters/ReceivingAddressTextField';
import { Filter } from '../../../common/filterTable/types';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    { labelKey: 'creationTime', filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: 'lastModifiedTime', filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: FilterFieldsKeys.programNames,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.programNames]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.currency]: '' }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.programNames, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.currency,
      filter: (
        <CurrencySingleSelection
          programName={fields[FilterFieldsKeys.programNames]}
          value={fields[FilterFieldsKeys.currency]}
          onClick={(e) =>
            updateField(FilterFieldsKeys.currency, (e.target as HTMLElement).dataset.value)
          }
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.customerNumber,
      filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.customerNumber]}
          onChange={(e) => updateField(FilterFieldsKeys.customerNumber, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.clientId,
      filter: (
        <ClientIdTextField
          value={fields[FilterFieldsKeys.clientId]}
          onChange={(e) => updateField(FilterFieldsKeys.clientId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.fromAddress,
      filter: (
        <SendingAddressTextField
          value={fields[FilterFieldsKeys.fromAddress]}
          onChange={(e) => updateField(FilterFieldsKeys.fromAddress, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.toAddress,
      filter: (
        <ReceivingAddressTextField
          value={fields[FilterFieldsKeys.toAddress]}
          onChange={(e) => updateField(FilterFieldsKeys.toAddress, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.txHash,
      filter: (
        <TxHashTextField
          value={fields[FilterFieldsKeys.txHash]}
          onChange={(e) => updateField(FilterFieldsKeys.txHash, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.referenceNumber,
      filter: (
        <ReferenceNumberTextField
          value={fields[FilterFieldsKeys.referenceNumber]}
          onChange={(e) => updateField(FilterFieldsKeys.referenceNumber, e.target.value)}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
