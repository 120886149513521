import { useEffect, useState } from 'react';

import { DialogInOne } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { Box } from '../../../components/MuiGenerals';
import CancelButton from '../../../features/approval/components/CancelButton';
import { useAlerting, useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import FullScreenDialogBtn from '../CustomerApplication/components/FullScreenDialogBtn';
import OperationDialogContent from '../CustomerApplication/components/OperationDialogContent';
import useOperationDialogContent from '../CustomerApplication/components/useOperationDialogContent';
import {
  apiObj as customerApplicationApi,
  IanswersEditApiParams,
  IgeneralInfoEditApiParams
} from '../CustomerApplication/config';
import {
  apiObj as api,
  IapplicationDetail,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';

const initFields = {
  // programName: '',
  // referralCode: '',
  title: '',
  firstName: '',
  lastName: '',
  chineseName: '',
  alias: '',
  gender: '',
  dateOfBirth: '',
  nationality: '',
  identificationDocumentType: '',
  identificationDocumentTypeOthers: '',
  placeOfIssue: '',
  identificationNumber: '',

  dateOfIssue: '',
  dateOfExpiry: '',
  usCitizenship: '',
  maritalStatus: '',
  educationalStatus: '',
  educationalStatusOthers: '',
  purposeForApplying: '',
  otherPurpose: '',
  isAuthorizedByYou: '',
  employmentStatus: '',
  companyName: '',
  jobTitle: '',
  jobTitleOthers: '',
  businessNatureOrIndustry: '',
  otherIndustry: '',
  employmentStartDate: '',
  officeTelephoneNumber: '',
  monthlySalaryInHkd: '',
  otherMonthlyIncome: '',
  residentialStatus: '',
  otherStatus: '',
  residentialTelephoneNumber: '',

  residentialAddressLine1: '',
  residentialAddressLine2: '',
  residentialAddressLine3: '',
  residentialPostalCode: '',
  residentialCity: '',
  residentialCountry: '',
  deliveryAddressLine1: '',
  deliveryAddressLine2: '',
  deliveryAddressLine3: '',
  deliveryPostalCode: '',
  deliveryCity: '',
  deliveryCountry: '',
};

export default function DialogUpdateInfo() {
  const zusDialog = useZusDialogStore();

  const { alerting } = useAlerting();

  const zusParams = useZusParams();

  const { details = undefined } = (zusDialog.meta || {}) as { details: IapplicationDetail };

  const { t, tc, tcc } = useTranslation(translatePrefix);
  const [isLoading, setIsLoading] = useState(true);

  const {
    labelElePairArrWithoutAddressSection,
    getValidationMessage,
    addressSectionLabelElePairArr,
    isSameAddress,
    setIsSameAddress,
    fields,
    setFields,
  } = useOperationDialogContent<typeof initFields>({
    mode: 'edit',
    applicationAnswers: details?.answers,
    generalDetail: details && {
      ...details,
      kycIdvManuallyVerifiedBy: details.kycIdvManuallyVerifiedBy || '',
      kycRef: {
        applicantId: details.kycRef.applicantId || '',
        workflowRunId: details.kycRef.workflowRunId || '',
      },
    },
    initFields,
  });

  useEffect(() => {
    if (!details) {
      return;
    }

    customerApplicationApi
      .decryptIdNumber({ applicationNumber: details.applicationNumber })
      .then((res) => {
        if (!res) {
          return;
        }

        setFields((prev) => {
          const returnObj = {
            ...prev,
            title: String(details.answers.title),
            firstName: details.firstName,
            lastName: details.lastName,
            chineseName: details.answers.chineseName,
            alias: details.answers.alias,
            gender: details.answers.gender,
            dateOfBirth: details.dateOfBirth,
            nationality: details.answers.nationality,
            identificationDocumentType: String(details.idType),
            identificationDocumentTypeOthers: details.answers.idTypeOthers,
            placeOfIssue: details.idIssuedBy,
            identificationNumber: res.idNumber,

            dateOfIssue: details.answers.idDateOfIssue,
            dateOfExpiry: details.answers.idDateOfExpiry,
            usCitizenship: details.answers.usCitizenship ? 'yes' : 'no',
            maritalStatus: String(details.answers.maritalStatus),
            educationalStatus: String(details.answers.educationLevel),
            educationalStatusOthers: details.answers.educationLevelOthers,
            purposeForApplying: String(details.answers.purposeForApplying),
            otherPurpose: details.answers.purposeForApplyingOthers,
            isAuthorizedByYou: details.answers.authorizedToThirdParty ? 'yes' : 'no',
            employmentStatus: String(details.answers.employmentStatus),
            companyName: details.answers.companyName,
            jobTitle: details.answers.jobTitle ? String(details.answers.jobTitle) : '',
            jobTitleOthers: details.answers.jobTitleOthers,
            businessNatureOrIndustry: details.answers.industry
              ? String(details.answers.industry)
              : '',
            otherIndustry: details.answers.industryOthers,
            employmentStartDate: details.answers.employmentStartDate,
            officeTelephoneNumber: details.answers.officeTelephoneNumber,
            monthlySalaryInHkd: details.answers.monthlySalaryHKD,
            otherMonthlyIncome: details.answers.otherIncomeHKD,
            residentialStatus: String(details.answers.residentialStatus),
            otherStatus: details.answers.residentialStatusOthers,
            residentialTelephoneNumber: details.answers.residentialTelephoneNumber,
            residentialAddressLine1: details.answers.residentialAddressLine1,
            residentialAddressLine2: details.answers.residentialAddressLine2,
            residentialAddressLine3: details.answers.residentialAddressLine3,
            residentialPostalCode: details.answers.residentialPostalCode,
            residentialCity: details.answers.residentialCity,
            residentialCountry: details.answers.residentialCountry,
            deliveryAddressLine1: details.answers.deliveryAddressLine1,
            deliveryAddressLine2: details.answers.deliveryAddressLine2,
            deliveryAddressLine3: details.answers.deliveryAddressLine3,
            deliveryPostalCode: details.answers.deliveryPostalCode,
            deliveryCity: details.answers.deliveryCity,
            deliveryCountry: details.answers.deliveryCountry,
          };

          // if (!hasViewIdNumberPermission) {
          //   delete returnObj.identificationNumber;
          // }

          return returnObj;
        });
      })
      .finally(() => setIsLoading(false));
  }, [details]);

  if (!details || isLoading) return <></>;

  const handleEdit = async () => {
    const {
      firstName,
      lastName,
      // programName,
      placeOfIssue: idIssuedBy,
      identificationDocumentType: idType,
      identificationNumber: idNumber,
      dateOfBirth,
      // referralCode,
      ...rawAnswers
    } = fields;
    const enumNumberFields: (keyof typeof rawAnswers)[] = [
      'businessNatureOrIndustry',
      'educationalStatus',
      'jobTitle',
      'maritalStatus',
      'purposeForApplying',
      'residentialStatus',
      'title',
      'employmentStatus',
    ];
    const enumBooleanFields: (keyof typeof rawAnswers)[] = ['usCitizenship', 'isAuthorizedByYou'];

    const answersEntries = Object.entries(rawAnswers).map(([key, val]) => {
      const typedKey = key as keyof typeof rawAnswers;
      if (enumNumberFields.find((enumKey) => enumKey === typedKey)) {
        if (
          typedKey === 'jobTitle' ||
          typedKey === 'residentialStatus' ||
          typedKey === 'businessNatureOrIndustry'
        ) {
          return val ? [typedKey, Number(val)] : [typedKey, 0];
        }

        return val ? [typedKey, Number(val)] : [typedKey, null];
      }

      if (enumBooleanFields.find((enumKey) => enumKey === typedKey)) {
        return val ? [typedKey, val === 'yes'] : [typedKey, null];
      }

      return [typedKey, val];
    });

    const answers = Object.fromEntries(answersEntries);

    const {
      isAuthorizedByYou: authorizedToThirdParty,
      identificationDocumentTypeOthers: idTypeOthers,
      dateOfIssue: idDateOfIssue,
      dateOfExpiry: idDateOfExpiry,
      educationalStatus: educationLevel,
      educationalStatusOthers: educationLevelOthers,
      otherPurpose: purposeForApplyingOthers,
      businessNatureOrIndustry: industry,
      otherIndustry: industryOthers,
      monthlySalaryInHkd: monthlySalaryHKD,
      otherMonthlyIncome: otherIncomeHKD,
      otherStatus: residentialStatusOthers,
      jobTitle,
      ...rest
    } = answers;

    const generalInfoApiParams: IgeneralInfoEditApiParams = {
      firstName,
      lastName,
      programName: details.programName,
      customerId: details.customerId || '',
      idIssuedBy: idIssuedBy || '***N/A***',
      idType: Number(idType),
      idNumber,
      dateOfBirth,
      referralCode: details.referralCode,
    };

    const answersApiParams: IanswersEditApiParams = {
      authorizedToThirdParty,
      idTypeOthers,
      idDateOfIssue,
      idDateOfExpiry,
      jobTitle,
      educationLevel,
      educationLevelOthers,
      purposeForApplyingOthers,
      industry,
      industryOthers,
      monthlySalaryHKD,
      otherIncomeHKD,
      residentialStatusOthers,
      sameAsResidentialAddress: isSameAddress,
      ...rest,
    };

    const getIsEdited = (checkObject: IgeneralInfoEditApiParams & IanswersEditApiParams) => {
      return Object.entries(checkObject).find(([key, value]) => {
        const typedKey = key as keyof IgeneralInfoEditApiParams & keyof IanswersEditApiParams;
        if (typedKey === 'sameAsResidentialAddress') {
          return false;
        }

        const answersValue = details.answers[typedKey];

        if (answersValue !== undefined) {
          return (
            answersValue !== value &&
            !(
              (value === '' && answersValue === '***N/A***') ||
              (answersValue === '' && value === '***N/A***')
            )
          );
        }

        const generalInfoValue = details[typedKey];

        if (generalInfoValue !== undefined) {
          return (
            generalInfoValue !== value &&
            !(
              (value === '' && generalInfoValue === '***N/A***') ||
              (generalInfoValue === '' && value === '***N/A***')
            )
          );
        }

        return false;
      });
    };

    const isEdited = getIsEdited({ ...generalInfoApiParams, ...answersApiParams });

    if (!isEdited) {
      return alerting('warning', tc('no_changes_made_warning'));
    }

    const validateMessage = getValidationMessage();

    if (validateMessage) {
      return alerting('warning', validateMessage);
    }

    const res = await api.updateInfo({
      applicationNumber: details.applicationNumber,
      ...generalInfoApiParams,
      answers: answersApiParams,
    });

    if (!res) {
      return;
    }

    alerting('success', t(TK.editSuccessfulMessage));

    await handleCloseDialog();
    zusParams.refetch();
  };

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setIsSameAddress(false);
    setFields(initFields);
    setIsLoading(true);
  };

  const dialogConfig: IdialogInOneProps = {
    title: '',
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: handleCloseDialog,
    },
    content: (
      <OperationDialogContent
        labelElePairArrWithoutAddressSection={labelElePairArrWithoutAddressSection}
        addressSectionLabelElePairArr={addressSectionLabelElePairArr}
        isApprovalProgressShown={false}
        isSameAddressBtnEnabled={true}
        isSameAddress={isSameAddress}
        setIsSameAddress={setIsSameAddress}
        isIdvSectionHidden
      />
    ),
    onConfirm: () => { },
    onCancel: () => { },
    size: 'xl',
    isFullScreen: true,
    isCancelHidden: true,
    isConfirmHidden: true,
    dialogActionStyling: {
      padding: /* isDetailsOrManualVerifyIdv ? '8px' : */ '16px 0px 16px 8px',
      borderTop: /* isDetailsOrManualVerifyIdv ? undefined : */ '1px solid #DDE1E3',
    },
    actionButtons: (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          padding: '0 calc(2rem - 8px)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FullScreenDialogBtn
            children={tc('confirm_btn')}
            btnBgColor={'#5183FF'}
            onClick={handleEdit}
          />

          <CancelButton onClick={handleCloseDialog} />
        </Box>
      </Box>
    ),
  };

  return <DialogInOne {...dialogConfig} />;
}
