export enum TableColumnKey {
  programName = 'programName',
  rewardBatchId = 'rewardBatchId',
  customerNumber = 'customerNumber',
  month = 'month',
  rewardCurrency = 'rewardCurrency',
  rewardAmount = 'rewardAmount',
  status = 'status',
  creationTime = 'creationTime',
  lastModifiedTime = 'lastModifiedTime',
}
