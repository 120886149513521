import React, { useEffect, useLayoutEffect, useState } from 'react';

import APIs from '../../../../../../api';
import { DialogInOne } from '../../../../../../components';
import { IdialogInOneProps } from '../../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import Content from './Content';
import { EditRecordRowKeys, IEditRecordFields } from './types';
import { safeDivide, safeMultiply } from '../../../../../../utils/math';

interface IProps {
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  rowData?: any;
}

const initFields = {
  [EditRecordRowKeys.RewardRate]: '',
};

const editConfigRecordApiFunc = APIs.CobrandManagement.rewardRateConfiguration.editConfig;

const EditRecordDialog = (props: IProps) => {
  const { isOpen, closeDialog, refreshTable, rowData } = props;
  const [editRecordFields, setEditRecordFields] = useState<IEditRecordFields>(initFields);

  const { tc, translate } = useTranslation();
  const { alerting } = useAlerting();

  useLayoutEffect(() => {
    setEditRecordFields({
      [EditRecordRowKeys.RewardRate]: safeMultiply(rowData?.rewardRate, 100),
    });
  }, [rowData]);

  const handleCloseDialog = () => {
    closeDialog();
  };

  const getIsNoChange = () => {
    return editRecordFields.rewardRate === rowData?.rewardRate;
  };

  const checkIsCanConfirmProceed = () => {
    const isNoChange = getIsNoChange();

    if (isNoChange) {
      alerting('error', translate('rewardRateConfiguration.editRecordDialog.noChangeAlert'));
      return false;
    }

    if (Number(editRecordFields.rewardRate) > 100) {
      alerting('error', translate('rewardRateConfiguration.editRecordDialog.rewardRateAlert'));
      return false;
    }

    return true;
  };

  const onConfirm = async () => {
    const isCanConfirmProceed = checkIsCanConfirmProceed();

    if (!isCanConfirmProceed) return;

    const res = await editConfigRecordApiFunc({
      programName: rowData?.programName,
      customerLevel: rowData?.customerLevel,
      rewardRate: safeDivide(editRecordFields.rewardRate, 100),
    });

    if (!res) return;

    alerting('success', translate('rewardRateConfiguration.editRecordDialog.successAlert'));
    handleCloseDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('editRecord'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: (
      <Content
        editRecordFields={editRecordFields}
        setEditRecordFields={setEditRecordFields}
        rowData={rowData}
      />
    ),
    onConfirm: () => {
      onConfirm();
    },
    onCancel: handleCloseDialog,
    size: 'sm',

    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
};

export default EditRecordDialog;
