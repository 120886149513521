import { ChangeEvent, useEffect, useState } from 'react';

import { DialogInOne, MpTextFieldNumberOnly } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../components/MuiGenerals';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import { useZusDialogStore } from '../../../zustand/store';
import { apiObj as api, translateKeyObj as TK, translatePrefix, useZusParams } from './config';

interface Ifields {
  exchangeRate: string;
  exchangeRateUpperLimit: string;
  exchangeRateLowerLimit: string;
}

const initFields: Ifields = {
  exchangeRate: '',
  exchangeRateUpperLimit: '',
  exchangeRateLowerLimit: '',
};

export default function DialogOperation() {
  const zusDialog = useZusDialogStore();

  const { tradeDate, from, to, exchangeRate, exchangeRateLowerLimit, exchangeRateUpperLimit } =
    zusDialog.meta;

  // const initFields: Ifields = {
  //   exchangeRate,
  //   exchangeRateUpperLimit,
  //   exchangeRateLowerLimit,
  // };

  const [fields, setFields] = useState<Ifields>(initFields);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setFields((f) => ({
      ...f,
      exchangeRate: exchangeRate || '',
      exchangeRateUpperLimit: exchangeRateUpperLimit || '',
      exchangeRateLowerLimit: exchangeRateLowerLimit || '',
    }));
    setIsReady(!!exchangeRate);
  }, [exchangeRate, exchangeRateUpperLimit, exchangeRateLowerLimit]);

  const { t, te, tc } = useTranslation(translatePrefix);

  const zusParams = useZusParams();

  const { email } = useAppSelector(selectProfile);

  const { validateGridBoxInput } = useGridBoxInputValidation();

  const { alerting } = useAlerting();

  const { hasPermission } = usePermission();

  const onChange =
    (fieldName: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((f) => ({ ...f, [fieldName]: e.target.value }));
    };

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [TK.tradeDate, <Box>{tradeDate}</Box>],
    [TK.from, <Box>{from}</Box>],
    [TK.to, <Box>{to}</Box>],
    [
      TK.exchangeRate,
      <MpTextFieldNumberOnly
        label={tc('phInputField', { fieldName: t(TK.exchangeRate) })}
        value={fields.exchangeRate}
        onChange={onChange('exchangeRate')}
        decimalCount={6}
      />,
    ],

    // [
    //   TK.exchangeRateUpperLimit,
    //   <MpTextFieldNumberOnly
    //     label={tc('phInputField', { fieldName: t(TK.exchangeRateUpperLimit) })}
    //     value={fields.exchangeRateUpperLimit}
    //     onChange={onChange('exchangeRateUpperLimit')}
    //     decimalCount={2}
    //   />,
    // ],

    // [
    //   TK.exchangeRateLowerLimit,
    //   <MpTextFieldNumberOnly
    //     label={tc('phInputField', { fieldName: t(TK.exchangeRateLowerLimit) })}
    //     value={fields.exchangeRateLowerLimit}
    //     onChange={onChange('exchangeRateLowerLimit')}
    //     decimalCount={2}
    //   />,
    // ],
  ];

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />;

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const handleSubmit = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr, [
      // { label: tc('phInputField', { fieldName: t(TK.exchangeRateUpperLimit) }), isSkipped: true },
      // { label: tc('phInputField', { fieldName: t(TK.exchangeRateLowerLimit) }), isSkipped: true },
      { fieldName: TK.exchangeRate, notAllowZeroMode: 'below', isNumberOnlyField: true },
    ]);
    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }
    if (fields.exchangeRate === exchangeRate) {
      return alerting('warning', tc('no_changes_made_warning'));
    }

    const res = await api.editRecord({
      tradeDate,
      fromCurrency: from,
      toCurrency: to,
      exchangeRate: fields.exchangeRate,
    });

    if (!res) {
      return;
    }

    await handleCloseDialog();
    zusParams.refetch();
    alerting('success', t(TK.successEditRecord));
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('edit'),
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,

    size: 'sm',

    dialogActionStyling: { padding: '8px' },
  };

  if (!isReady) {
    return <></>;
  }

  return <DialogInOne {...dialogConfig} />;
}
