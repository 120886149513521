import { Asset } from '../../../api/types';

export const getCurrencyEnum = (list: Asset[]) => {
  if (!list) {
    return {};
  }
  return list.reduce((acc, { displayName, currency }) => {
    return {
      ...acc,
      [currency]: currency,
    };
  }, {});
};
