import React from 'react';
import { useTranslation } from '../../../../hooks';

const SubTitleRow = ({
  applicationNumber,
  customerNumber,
}: {
  applicationNumber: string;
  customerNumber: string;
}) => {
  const { tc } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'space-between',
        padding: '36px 0 0',
        boxSizing: 'border-box',
        fontSize: '16px',
        fontWeight: '700',
      }}
    >
      <div>{tc('applicationDetails')}</div>

      <div>
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {`${tc('applicationNumber')}: `}
          <span style={{ color: '#5183FF', textDecoration: 'underline' }}>{applicationNumber}</span>
        </div>

        <div
          style={{
            marginTop: '24px',
            textAlign: 'right',
          }}
        >
          {`${tc('customerNumber')}: `}
          <span style={{ color: '#5183FF', textDecoration: 'underline' }}>{customerNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default SubTitleRow;
