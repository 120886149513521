import ApproveButton from './ApproveButton';
import CancelButton from './CancelButton';
import RejectButton from './RejectButton';
import RemarksTextArea from './RemarksTextArea';

const ActionButtons = ({
  handleApproveClick,
  handleRejectClick,
  closeDialog,
  remarksInput,
  handleRemarksChange,
}: any) => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '60px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '0 48px',
      }}
    >
      <RemarksTextArea value={remarksInput} onChange={handleRemarksChange} />
      <div>
        <ApproveButton onClick={handleApproveClick} />
        <RejectButton onClick={handleRejectClick} />
        <CancelButton onClick={closeDialog} />
      </div>
    </div>
  );
};

export default ActionButtons;
