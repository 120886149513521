import { ChangeEvent, cloneElement, useState } from 'react';

import {
  EnumAdjustmentMode,
  EnumCreditAdjustmentStatus,
  EnumCreditAdjustmentType,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { GeneralBtn, SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import { Box, Button, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import { MpTextField } from '../../../components/TextField';
import { usePermission, useTranslation } from '../../../hooks';
import useAssets from '../../../hooks/useAssets/useAssets';
import { useGetProgram } from '../../../hooks/useProgram';
import { useGenFilterField } from '../../../utils/ComponentHelper';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogCreateRequest from './DialogCreateRequest';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import { Filter } from '../../../features/common/filterTable/types';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import ProgramNameMultipleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { EnumAllProgram } = useGetProgram();

  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const { CurrencyEnum } = useAssets({ programName: fields.programName });

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const getParams: () => IsearchParam = () => {
    const { asset: assetCurrency, adjustmentMode: mode, adjustmentType: type, programName: programNames, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      ...rest,
      assetCurrency,
      mode,
      type,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filter: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) => setFields((fields) => ({ ...fields, programName: value }))}
          onClear={() => setFields((fields) => ({ ...fields, programName: [], asset: '' }))}
        />
      ),
    },
    {
      labelKey: TK.asset,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.asset) })}
          value={fields.asset}
          onChange={onChange('asset')}
          enumData={CurrencyEnum}
          clearSelect={() => setFields((fields) => ({ ...fields, asset: '' }))}
          nameFn={(name) => name}
        />
      ),
    },
    {
      labelKey: TK.adjustmentMode,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.adjustmentMode) })}
          value={fields.adjustmentMode}
          onChange={onChange('adjustmentMode')}
          enumData={EnumAdjustmentMode}
          clearSelect={() => setFields((fields) => ({ ...fields, adjustmentMode: '' }))}
        />
      ),
    },
    {
      labelKey: TK.adjustmentType,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.adjustmentType) })}
          value={fields.adjustmentType}
          onChange={onChange('adjustmentType')}
          enumData={EnumCreditAdjustmentType}
          clearSelect={() => setFields((fields) => ({ ...fields, adjustmentType: '' }))}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange('customerNumber')}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.status) })}
          value={fields.status}
          onChange={onChange('status')}
          enumData={EnumCreditAdjustmentStatus}
          clearSelect={() => setFields((fields) => ({ ...fields, status: '' }))}
        />
      ),
    },
  ];

  const openCreateRequestDialog = () => zusDialog.open('createRequestDialog');

  return (
    <Container disableGutters maxWidth={false}>
      <>
        <DialogCreateRequest />
        <FilterSectionActionRow>
          {hasPermission(PermissionKey.CreateRequest) && (
            <Button variant="outlined" onClick={openCreateRequestDialog}>
              {t(TK.createRequest)}
            </Button>
          )}

          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>

        <CollapsibleFilters
          filters={filter}
          isCollapse={isCollapse}
          onSearch={onSearch}
          onReset={onReset}
        />
      </>
    </Container>
  );
}
