import React from 'react';
import { QRCode } from 'react-qrcode-logo';

import { Network } from '../../../../../../api/types';
import pfhLogo from '../../../../../../assets/icons/pfh-logo.jpg';
import CopyableText from '../../../../../../components/CopyableText';
import { useTranslation } from '../../../../../../hooks';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../common/dialogs/layout/contentLayout';
import NetworkSingleSelection from '../../../../../common/filterTable/components/Filters/NetworkSingleSelection';

interface IProps {
  programName: string;
  network: Network;
  setNetwork: (network: Network) => void;
  walletAddress: string;
}
const QR_CODE_SIZE = 300;

const Content = (props: IProps) => {
  const { programName, network, setNetwork, walletAddress } = props;

  const { t } = useTranslation('tcspAccount');

  const isHaveWalletAddress = walletAddress && walletAddress.length > 0;

  return (
    <Container>
      <Row>
        <RowHeader>{t('viewWalletAddressDialog.network')}</RowHeader>
        <RowContent>
          <NetworkSingleSelection
            programName={programName}
            value={network as any}
            onChange={(e) => {
              setNetwork(JSON.parse(e.target.value) as unknown as Network);
            }}
            onClear={() => setNetwork({} as Network)}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('viewWalletAddressDialog.qrCode')}</RowHeader>
        <RowContent>
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            {isHaveWalletAddress && (
              <QRCode
                value={walletAddress}
                size={QR_CODE_SIZE}
                eyeRadius={24}
                fgColor="#5069FA"
                bgColor="#F8FAFF"
                logoImage={pfhLogo}
                logoWidth={QR_CODE_SIZE * 0.3}
                ecLevel={'H'}
              />
            )}
          </div>
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('viewWalletAddressDialog.walletAddress')}</RowHeader>
        <RowContent>
          <CopyableText text={walletAddress} />
        </RowContent>
      </Row>
    </Container>
  );
};

export default Content;
