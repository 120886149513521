import { useState } from 'react';
import { useRedirect } from 'react-admin';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Box, Button, Typography } from '@mui/material';

import { forceLogout } from '../../api/axiosInstance';
import { OpSettingComponent } from '../../assets/icons';
import { useAutoLogout, useIsActive, useTranslation, useYubikeyDetail } from '../../hooks';
import useAssets from '../../hooks/useAssets/useAssets';
import useChains from '../../hooks/useChains';
import { useFetchProgram } from '../../hooks/useProgram';
import { useAppSelector } from '../../reducer/hooks';
import { selectProfile } from '../../reducer/profileSlice';
import { selectStuff } from '../../reducer/stuffSlice';
import { COLOR_THEME } from '../../style/colorTheme';
import { enumMapping, importantStyle } from '../../utils';
import { enumTimezone } from '../../utils/constant';
import { Content, ContentWithSidebar } from '../../utils/styling';
import { LanguageSelection } from '../../views/User/AccountSetting';
import { OpIconButtonAdjustable } from '../Button';
import Menu from './Menu';
import SideMenu from './SideMenu';

const Enum = {
  timezones: enumMapping(enumTimezone),
};
const sx: Record<string, any> = {
  AppBarSpan: {
    display: 'flex',
    backgroundColor: 'white',
  },
  SideBarParent: {
    minHeight: '100vh',
    zIndex: 2,
  },
  topRightBar: {
    position: 'absolute',
    height: '28px',
    top: '1rem',
    right: '.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& Button.MuiButton-text': {
      fontSize: '1rem',
    },
  },
  topLeftBar: {
    position: 'absolute',
    top: '1rem',
    left: '345px',
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    padding: '6px 8px',
  },
  pageName: {
    position: 'absolute',
    top: '4.5rem',
    left: '345px',
    padding: '6px 8px',
  },
  email: {
    color: COLOR_THEME.Layout.LayoutBar.Text,
    fontSize: '1rem',
  },
  logoutBtn: {
    height: '100%',
    color: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Text,
    backgroundColor: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Background,
    borderRadius: '30px 29.5px 29.5px 30px',
    padding: '.3rem 7px .3rem 10px',
    boxShadow: 'none',
    marginRight: '8px',
    '>span': {
      marginRight: '4px',
    },
    svg: { color: importantStyle(COLOR_THEME.Layout.LayoutBar.LogoutBtn.Text) },
  },
  appName: {
    fontSize: '1rem',
  },
} as const;

export default function LayoutBar(props: any) {
  const { t, te, tc } = useTranslation('menu');
  const [isSideMenuCollapse, setIsSideMenuCollapse] = useState(false);

  const { appName, email, timezone } = useAppSelector(selectProfile);
  const { viewPermission } = useAppSelector(selectStuff);

  const redirect = useRedirect();

  useAutoLogout();
  useIsActive();
  useAssets({ shouldFetch: true });
  useChains({ shouldFetch: true });
  useFetchProgram();
  useYubikeyDetail(); // first time call for cache
  // useAccountSetting() // only call once

  const logoutFn = () => {
    forceLogout('', { from: 'menu-button' });
  };

  const timezoneDisplay = `GMT${te(Enum.timezones[timezone])}`;

  const onCollapseClick = () => {
    setIsSideMenuCollapse(!isSideMenuCollapse);
  };

  const getTopMenuHeaderStyle = () => {
    return {
      left: isSideMenuCollapse ? '106px' : '345px',
      transition: 'left 0.3s 0.1s cubic-bezier(0.0, 0.0, 0.2, 1)',
    };
  };

  // adapt to side menu position
  const topMenuHeaderStyle = getTopMenuHeaderStyle();

  return (
    <ContentWithSidebar>
      <Box style={{ ...sx.SideBarParent }}>
        <SideMenu isCollapse={isSideMenuCollapse} onCollapseClick={onCollapseClick}>
          <Menu />
        </SideMenu>
      </Box>
      <Box style={{ ...sx.topLeftBar, ...topMenuHeaderStyle }}>
        <Typography variant="caption" sx={sx.appName}>
          {appName}
        </Typography>
      </Box>
      <Box sx={sx.topRightBar}>
        <LanguageSelection />
        <Box
          onClick={() => redirect('/accountSetting')}
          sx={{
            padding: '.3rem .7rem',
            backgroundColor: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Background,
            color: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Text,
            borderRadius: '.5rem',
            cursor: 'pointer',
            height: '100%',
          }}
        >
          {timezoneDisplay}
        </Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ marginRight: '4px' }}>{tc('welcome')},</Box>
          <Box sx={sx.email}>{email}</Box>
          <Box sx={{ marginLeft: '8px' }}>
            <OpIconButtonAdjustable
              title={''}
              size={'1rem'}
              SvgElement={OpSettingComponent}
              onClick={() => redirect('/accountSetting')}
              sxSVG={{
                fill: COLOR_THEME.Layout.LayoutBar.SecondaryText,
                path: {
                  fill: COLOR_THEME.Layout.LayoutBar.SecondaryText,
                },
              }}
            />
          </Box>
        </Typography>
        <Button
          variant="contained"
          startIcon={<PowerSettingsNewIcon />}
          onClick={logoutFn}
          sx={sx.logoutBtn}
        >
          {tc('logout')}
        </Button>
      </Box>
      <div style={{ ...sx.pageName, ...topMenuHeaderStyle }}>
        <Typography variant="h6" sx={{ color: importantStyle(COLOR_THEME.Layout.LayoutBar.Text) }}>
          {viewPermission && t(viewPermission)}
        </Typography>
      </div>
      <Content>{props.children}</Content>
    </ContentWithSidebar>
  );
}
