import { Answer } from '../../components/CreateApplicationDialog/types';

export const initAnswer: Answer = {
  sourceOfFunds: undefined,
  totalAssetHKD: '',
  sourceOfWealth: undefined,
  enablePrePayment: undefined,
  expectedTxPerMonth: '',
  obtainPersonalInfo: undefined,
  trustAccountReason: undefined,
  trustAccountReasonOthers: '',
  sourceOfFundsOthers: '',
  enableAdvancePayment: undefined,
  sourceOfWealthOthers: '',
  authorizedToThirdParty: undefined,
  expectedTxAmountPerMonth: '',
  seniorPublicFigureDeclaration: false,
  remarks: '',
};

export const initSourceOfWealth = {
  others: false,
  employment: false,
  selfEmployment: false,
  businessOwnership: false,
  loansGrant: false,
  spouseParentPartner: false,
  inheritance: false,
  trustFund: false,
  investment: false,
  salesOfVirtualAssets: false,
  personalRealEstate: false,
};

export const initSourceOfFund = {
  others: false,
  businessIncome: false,
  personalSaving: false,
  inheritance: false,
  rentalIncome: false,
  investment: false,
  salesOfProperty: false,
  salesOfVirtualAssets: false,
  pension: false,
  wagesDepositedFromEmployer: false,
};
