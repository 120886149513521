import { ChangeEvent, RefObject } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import {
  containsOnlyInteger,
  containsOnlyNumbers,
  containsOnlySignedInteger,
  containsOnlySignedNumbers,
} from '../../utils';
import { inputCharacterMaxLength } from '../../utils/constant';

export type MpTextFieldNumberProps = TextFieldProps & {
  innerRef?: RefObject<HTMLDivElement>;
  maxNumber?: number;
  noCheckMaxNumber?: boolean;
  integerOnly?: boolean;
  decimalCount?: number;
  allowSignedNumber?: 'onlyNegative' | 'all';
};

export default function MpTextFieldNumberOnly(props: MpTextFieldNumberProps) {
  const {
    innerRef,
    onChange,
    maxNumber = Number.MAX_SAFE_INTEGER,
    noCheckMaxNumber,
    integerOnly = false,
    decimalCount,
    allowSignedNumber,
    ...rest
  } = props;

  const newProps = {
    ...rest,
    ref: innerRef,
    inputProps: { maxLength: inputCharacterMaxLength, ...props.inputProps },
  };

  const getNumberCheckingFn: () => (str: string) => boolean = () => {
    if (allowSignedNumber) {
      const isOnlyNegative = allowSignedNumber === 'onlyNegative';

      return integerOnly
        ? containsOnlySignedInteger(isOnlyNegative)
        : containsOnlySignedNumbers(isOnlyNegative);
    }

    return integerOnly ? containsOnlyInteger : containsOnlyNumbers;
  };

  const numberCheckingFn = getNumberCheckingFn();

  const onChangeOnlyNumber = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!numberCheckingFn(e.target.value)) {
      return;
    }

    const inputDecimalCount = e.target.value.split('.')[1]?.length;

    if (decimalCount && inputDecimalCount > decimalCount) {
      return;
    }

    if (!noCheckMaxNumber && Number(e.target.value) > maxNumber) {
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  return <TextField {...newProps} onChange={onChangeOnlyNumber} />;
}
