import { subHours } from 'date-fns';
import { ChangeEvent, cloneElement, useState } from 'react';

import { EnumRewardStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

import { GeneralBtn, SingleSelection } from '../../../components';
import TimePicker, { useDatePicker } from '../../../components/DatePicker';
import { Box, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import { MpTextField } from '../../../components/TextField';
import { useTranslation } from '../../../hooks';
import { useGetProgram } from '../../../hooks/useProgram';
import { useGenFilterField } from '../../../utils/ComponentHelper';
import { customSx } from '../../../utils/styling';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import { Filter } from '../../../features/common/filterTable/types';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import ProgramNameMultipleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { EnumAllProgram } = useGetProgram();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const getParams: () => IsearchParam = () => {
    const { rewardStatus, month, programName: programNames, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    const monthStr = month
      ? subHours(month, month?.getTimezoneOffset() / 60)
          .toISOString()
          .split('-')
          .slice(0, 2)
          .join('')
      : '';

    return {
      ...initZusParams,
      ...rest,
      month: monthStr || '',
      rewardStatus,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    { labelKey: TK.creationTime, filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: TK.lastModifiedTime, filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) => setFields((fields) => ({ ...fields, programName: value }))}
          onClear={() => setFields((fields) => ({ ...fields, programName: [] }))}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange('customerNumber')}
        />
      ),
    },
    {
      labelKey: TK.rewardStatus,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.rewardStatus) })}
          value={fields.rewardStatus}
          onChange={onChange('rewardStatus')}
          enumData={EnumRewardStatus}
          clearSelect={() => setFields((fields) => ({ ...fields, rewardStatus: '' }))}
        />
      ),
    },
    {
      labelKey: TK.month,
      filter: (
        <TimePicker
          label={tc('phSelection', { fieldName: t(TK.month) })}
          timeValue={fields.month}
          setTimeValue={(month: Date | null) => setFields((f) => ({ ...f, month }))}
          type={'date'}
          views={['year', 'month']}
          disableFuture
          customInputFormat="yyyy-MM"
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
