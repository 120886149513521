import { Transactions } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'repaymentSchedule';
const exportFileName = 'Repayment Schedule';

const PermissionKey = Transactions.RepaymentSchedule;

interface Ifields {
  batchOrderId: string;
  repaymentOrderId: string;
  repaymentMode: string;
  programName: string[];
  repaymentSubMode: string;
  repaymentType: string;
  customerNumber: string;
  asset: string;
  status: string;
  creditAdjustmentOrderId: string;
}

const initFields: Ifields = {
  batchOrderId: '',
  repaymentOrderId: '',
  repaymentMode: '',
  programName: [],
  repaymentSubMode: '',
  repaymentType: '',
  customerNumber: '',
  asset: '',
  status: '',
  creditAdjustmentOrderId: '',
};

interface IsearchParam extends IdateFilter {
  batchOrderId: string;
  orderId: string;
  mode: string;
  programNames: string[];
  type: string;
  customerNumber: string;
  assetCurrency: string;
  status: string;
  creditAdjustmentOrderId: string;
  scheduleDateFrom: string;
  scheduleDateTo: string;
  executedDateFrom: string;
  executedDateTo: string;
}

const translateKeyObj = {
  details: 'details',
  operation: 'operation',
  batchOrderId: 'batch_order_id',
  repaymentOrderId: 'repayment_order_id',
  scheduleTime: 'schedule_time',
  approvalProgress: 'approval_progress',
  repaymentMode: 'repayment_mode',
  repaymentSubMode: 'repayment_sub_mode',
  externalReference: 'external_reference',
  programName: 'program_name',
  repaymentType: 'repayment_type',
  customerNumber: 'customer_number',
  asset: 'asset',
  assetCollateralAmount: 'asset_collateral_amount',
  assetRepaymentAmount: 'asset_repayment_amount',
  exchangeRate: 'exchange_rate',
  releaseCreditAmount: 'release_credit_amount',
  creditRepaymentAmount: 'credit_repayment_amount',
  estimatedReleaseCreditAmount: 'estimated_release_credit_amount',
  status: 'status',
  creditAdjustmentOrderId: 'credit_adjustment_order_id',
  createdBy: 'created_by',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  executedBy: 'executed_by',
  executionTime: 'execution_time',
  createRequest: 'create_request',
  immediate: 'immediate',
  availableBalanceHelperText: 'available_balance_helper_text',
  exchangeRateHelperText: 'exchange_rate_helper_text',
  noAvailableAsset: 'no_available_asset',
  availableBalanceChecking: 'available_balance_checking',
} as const;

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  createClassicRequest: (params: IcreateClassicRequestParams, config?: any) => Promise<any>;
  createRedeemRequest: (params: IcreateRedeemRequestParams, config?: any) => Promise<any>;
  approve: (
    params: { id: string; customerNumber?: string; remarks?: string },
    config?: any
  ) => Promise<any>;
  reject: (
    params: { id: string; customerNumber?: string; remarks?: string },
    config?: any
  ) => Promise<any>;
  customerProgram: (
    params: {
      customerNumber: string;
    },
    config?: any
  ) => Promise<string>;
  rewardBalance: (
    params: {
      customerNumber: string;
    },
    config?: any
  ) => Promise<any>;
} = {
  export: api.Transaction.repaymentSchedule.getExport,
  table: api.Transaction.repaymentSchedule.getAll,
  createClassicRequest: api.Transaction.repaymentSchedule.postCreateClassicRequest,
  createRedeemRequest: api.Transaction.repaymentSchedule.postCreateRedeemRequest,
  approve: api.Transaction.repaymentSchedule.postApprove,
  reject: api.Transaction.repaymentSchedule.postReject,
  customerProgram: api.MerchantPortal.customer.getCustomerProgram,
  rewardBalance: api.MerchantPortal.query.getRewardBalance,
} as const;

interface ItableApiRes {
  id: number;
  merchantId: number;
  orderId: string;
  type: number;
  creditAdjustmentOrderId: string;
  customerNumber: string;
  assetCurrency: string;
  assetCurrencyDecimals: number;
  assetCollateralAmount: string;
  assetRepaymentAmount: string;
  exchangeRate: string;
  creditCurrency: string;
  creditCurrencyDecimals: number;
  creditReleasedAmount: string;
  creditRepaymentAmount: string;
  status: number;
  manualApprovalReason?: any;
  approvalsRequired?: any;
  approvalsCount?: any;
  scheduleDate: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  executedBy?: any;
  executedDate?: any;
  programName: string;
  batchOrderId?: any;
  mode: number;
  repaymentOrderType: number;
  externalRefNum: string;
}

type IrewardBalanceApiRes = {
  rewardCurrency: string;
  rewardBalance: string;
  rewardCurrencyDecimals: number;
};

type IcreateRedeemRequestParams = {
  programName: string;
  customerNumber: string;
  redeemAmount: string;
  redeemCurrency: string;
  redeemCurrencyDecimals: number;
  scheduleDate: string | null;
};
type IcreateClassicRequestParams = {
  programName: string;
  customerNumber: string;
  creditRepaymentAmount: string;
  creditCurrency: string;
  scheduleDate: string | null;
};

export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
  type IcreateClassicRequestParams,
  type IcreateRedeemRequestParams,
  type IrewardBalanceApiRes,
};
