import { OpHidden, OpShow } from '../assets/icons';
import useDecryptIdNumber from '../hooks/useDecryptIdNumber';
import { importantStyle } from '../utils';
import { OpIconButton } from './Button';
import { Box } from './MuiGenerals';

interface Iprops {
  permissionKey: string;
  apiFn: () => Promise<any>;
}

export default function TogglableIdNumber({ permissionKey, apiFn }: Iprops) {
  const { toggleIdNumberVisibility, idNumberObject, hasDecryptPermission } = useDecryptIdNumber(
    permissionKey,
    apiFn
  );

  return (
    <Box sx={{ display: 'flex', height: '14.5px' }}>
      <Box sx={{ width: importantStyle('max-content') }}>
        {idNumberObject.isHidden ? '********' : idNumberObject.value}
      </Box>

      {hasDecryptPermission && (
        <OpIconButton
          sxBox={{
            width: importantStyle('1.4rem'),
            marginLeft: importantStyle('12px'),
          }}
          title={''}
          svgUrl={idNumberObject.isHidden ? OpHidden : OpShow}
          onClick={toggleIdNumberVisibility}
        />
      )}
    </Box>
  );
}
