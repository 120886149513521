import { MpTextField } from '../../../../components';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../features/common/dialogs/layout/contentLayout';
import { useAlerting, useTranslation } from '../../../../hooks';
import { useZusDialogStore } from '../../../../zustand/store';
import { translateKeyObj as TK, translatePrefix } from '../config';

interface IDialogPhoneUpdateContent {
  fields: any;
  setFields: any;
}

const DialogEmailUpdateContent: React.FC<IDialogPhoneUpdateContent> = ({ fields, setFields }) => {
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();

  return (
    <Container>
      <Row>
        <RowHeader>{t(TK.customerId)}</RowHeader>
        <RowContent>{zusDialog.meta.customerId}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(TK.originalEmailAddress)}</RowHeader>
        <RowContent>{zusDialog.meta.email}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t(TK.newEmailAddress)}</RowHeader>
        <RowContent>
          <MpTextField
            value={fields.newEmail}
            label={`${tc('phInputField', { fieldName: t(TK.newEmailAddress) })}`}
            onChange={(e) => {
              setFields({ ...fields, newEmail: e.target.value });
            }}
          />
        </RowContent>
      </Row>
    </Container>
  );
};

export default DialogEmailUpdateContent;
