import { OpView } from '../../../assets/icons';
import { OpIconButton } from '../../../components';
import { useTranslation } from '../../../hooks';

interface IProps {
  onClick: () => void;
  isDisabled: boolean;
  isHide?: boolean;
  title?: string;
}

const OperationDetailCell = (props: IProps) => {
  const { onClick, isDisabled, isHide, title } = props;
  const { tc } = useTranslation();

  if (isHide) return <></>;

  return (
    <div>
      <OpIconButton
        title={title || tc('viewDetail')}
        svgUrl={OpView}
        onClick={onClick}
        size="1.5rem"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default OperationDetailCell;
