import { SingleSelection } from '../../../../../components';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import useAssets from '../../../../../hooks/useAssets/useAssets';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const RewardRepaymentCurrencySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { AllAssetCurrenciesEnum } = useAssets();

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('repaymentCurrency') })}
      value={value}
      clearSelect={onClear}
      onChange={onChange}
      enumData={AllAssetCurrenciesEnum}
      nameFn={(item) => item}
    />
  );
};

export default RewardRepaymentCurrencySingleSelection;
