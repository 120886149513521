import { TCSP } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'assetCustodyWallet';
const exportFileName = 'Asset Custody Wallet';

const PermissionKey = TCSP.AssetCustodyWallet;

interface Ifields {
  programName: string[];
  customerNumber: string;
  clientId: string;
  currency: string;
  lastLedgerTransactionId: string;
  walletType: string;
}

const initFields: Ifields = {
  programName: [],
  customerNumber: '',
  clientId: '',
  currency: '',
  lastLedgerTransactionId: '',
  walletType: '',
};

interface IsearchParam extends IdateFilter {
  programNames: string[];
  customerNumber: string;
  clientId: string;
  currency: string;
  lastLedgerTransactionId: string;
  walletType: string;
}

const translateKeyObj = {
  programName: 'program_name',
  customerNumber: 'customer_number',
  clientId: 'client_id',
  currency: 'currency',
  balance: 'balance',
  frozenBalance: 'frozen_balance',
  lastLedgerTransactionId: 'last_ledger_transaction_id',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  walletType: 'wallet_type',
} as const;

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
} = Object.freeze({
  export: api.tcsp.assetCustodyWallet.getExport,
  table: api.tcsp.assetCustodyWallet.getAll,
});

interface ItableApiRes {
  id: number;
  merchantId: number;
  lastLedgerTransactionId: number;
  programName: string;
  customerNumber: string;
  clientId: string;
  currency: string;
  balance: string;
  frozenBalance: string;
  decimals: number;
  walletType: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}

export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
};
