import React from 'react';

import { MpTextField } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const ApplicationNumberTextField = (props: IProps) => {
  const { label, value, onChange } = props;
  const { tc } = useTranslation();

  return (
    <MpTextField
      label={label || tc('phInputField', { fieldName: tc('applicationNumber') })}
      value={value}
      onChange={onChange}
    />
  );
};

export default ApplicationNumberTextField;
