import { EnumTcspAccountApplicationStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

interface IProps {
  approvalsCount: number;
  approvalsRequired: number;
  applicationStatus: number;
}

const getIsAutoApprove = (props: IProps) => {
  const { approvalsCount, approvalsRequired, applicationStatus } = props;

  const isTxApproved = applicationStatus === EnumTcspAccountApplicationStatus.Approved;

  return isTxApproved && approvalsCount === 0 && approvalsRequired === 0;
};

export default getIsAutoApprove;
