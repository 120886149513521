import { Box } from '../../../../components/MuiGenerals';

export const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        '.MuiInputLabel-shrink': {
          display: 'none',
        },
        '.MuiFormControl-root': {
          width: '100%',
        },
        // width: '80%',
      }}
    >
      {children}
    </Box>
  );
};

export const Row = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        marginBottom: '24px',
        fontSize: '14px',
      }}
    >
      {children}
    </div>
  );
};

export const RowHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        width: '160px',
        minWidth: '160px',
        marginRight: '32px',
        wordBreak: 'break-word',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'flex-start',
      }}
    >
      {children}
    </div>
  );
};

export const RowContent = ({ children }: { children: React.ReactNode }) => {
  return <div style={{ fontWeight: 'bold', width: '100%' }}>{children}</div>;
};
