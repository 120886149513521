import { EnumRewardBatchStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

export default function getRewardStatusTypeDisplayValue(
  type: number | string,
  translateFunc: (key: string) => string
) {
  if (typeof type === 'string') {
    type = parseInt(type);
    if (isNaN(type)) {
      return '';
    }
  }

  const value = EnumRewardBatchStatus[type];

  if (!value) {
    return '';
  }

  const translateKey = value === 'Unassigned' ? 'pending' : value;

  return translateFunc(`enumConstants.${translateKey}`);
}
