import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/pmpLogo/logo.png';
import text from '../assets/pmpLogo/text.png';

const PMPLogo = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100px',
        height: '42px',
        cursor: 'pointer',
      }}
      onClick={() => navigate('/')}
    >
      <img src={logo} alt="logo" />
      <div style={{ marginTop: '8px' }}>
        <img src={text} alt="logo2" style={{ width: '100%', height: '20px' }} />
      </div>
    </div>
  );
};

export default PMPLogo;
