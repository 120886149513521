import React, { SetStateAction } from 'react';
import { IEditRecordFields } from './types';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../common/dialogs/layout/contentLayout';
import { useTranslation } from '../../../../../../hooks';
import ProgramNameSingleSelection from '../../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import CustomerLevelSingleSelection from '../../../../../common/filterTable/components/Filters/CustomerLevelSingleSelection';
import RewardRateTextField from '../../../../../common/filterTable/components/Filters/RewardRateTextField';
import { TableRow } from '../../../types/TableRow';
import { TableColumnKey } from '../../../types/TableColumnKeys';

interface IProps {
  editRecordFields: IEditRecordFields;
  setEditRecordFields: React.Dispatch<SetStateAction<IEditRecordFields>>;
  rowData?: TableRow;
}

const Content = (props: IProps) => {
  const { editRecordFields, setEditRecordFields, rowData } = props;

  const { t } = useTranslation('rewardRateConfiguration');

  return (
    <Container>
      <Row>
        <RowHeader>{t('programName')}</RowHeader>
        <RowContent>{rowData?.[TableColumnKey.programName]}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t('customerLevel')}</RowHeader>
        <RowContent>{rowData?.[TableColumnKey.customerLevel]}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t('rewardRate')}</RowHeader>
        <RowContent>
          <RewardRateTextField
            value={editRecordFields.rewardRate}
            onChange={(e) =>
              setEditRecordFields({ ...editRecordFields, rewardRate: e.target.value })
            }
          />
        </RowContent>
      </Row>
    </Container>
  );
};

export default Content;
