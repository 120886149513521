import { Dispatch, memo, SetStateAction } from 'react';

import { GridBoxSections } from '../../../../components/Layout';
import { Checkbox, InputLabel } from '../../../../components/MuiGenerals';
import ApprovalProgressDialogContent from '../../../../features/approval/components/ApprovalProgressDialogContent';
import { ApproveHistory } from '../../../../features/approval/types/ApproveHistory';
import { useTranslation } from '../../../../hooks';
import { COLOR_THEME, PALETTE } from '../../../../style/colorTheme';
import { translateKeyObj as TK, translatePrefix } from '../config';

export default memo(function OperationDialogContent(
  props: {
    labelElePairArrWithoutAddressSection: Array<[string, JSX.Element]>[];
    addressSectionLabelElePairArr: Array<[string, JSX.Element]>[];
    isIdvSectionHidden?: boolean;
  } & (
    | {
        isSameAddressBtnEnabled: true;
        isSameAddress: boolean;
        setIsSameAddress: Dispatch<SetStateAction<boolean>>;
      }
    | { isSameAddressBtnEnabled: false }
  ) &
    (
      | { isApprovalProgressShown: true; customer_application_approvals: ApproveHistory[] }
      | { isApprovalProgressShown: false }
    )
) {
  const {
    isApprovalProgressShown,
    labelElePairArrWithoutAddressSection,
    addressSectionLabelElePairArr,
    isSameAddressBtnEnabled,
    isIdvSectionHidden,
  } = props;

  const sectionHeaderList: { headerValue: string; isSubtitle?: boolean }[] = [
    { headerValue: TK.details },
    { headerValue: TK.personalDetails },
    { headerValue: TK.occupationDetails },
    { headerValue: TK.residentialDetails },
    { headerValue: TK.idvDetails },
  ];
  const { t } = useTranslation(translatePrefix);

  return (
    <>
      {isApprovalProgressShown && (
        <>
          <h3 style={{ color: COLOR_THEME.Views.CustomerApplication.DialogOperation.Text }}>
            {t(TK.approvalProgress)}
          </h3>

          <ApprovalProgressDialogContent
            approveHistory={props.customer_application_approvals || []}
          />
          <br />
        </>
      )}

      <GridBoxSections
        sectionHeaderList={isIdvSectionHidden ? sectionHeaderList.slice(0, -1) : sectionHeaderList}
        labelElePairArrList={labelElePairArrWithoutAddressSection}
        containerSx={{ padding: '16px 24px', backgroundColor: '#ECEEF0' }}
        dividingLineColor={'#DDE1E3'}
        columnCount={3}
        isAlignColumn
        subSectionList={[
          {
            mainSectionHeaderValue: TK.residentialDetails,
            GridBoxSubSections: (
              <GridBoxSections
                sectionHeaderList={[
                  { headerValue: TK.residentAddressSection, isSubtitle: true },
                  {
                    headerValue: TK.deliveryAddressSection,
                    isSubtitle: true,
                    endAdornment: isSameAddressBtnEnabled && (
                      <InputLabel
                        sx={{
                          marginLeft: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          color: PALETTE.SecondaryText,
                          fontWeight: 'normal',
                          userSelect: 'none',
                        }}
                      >
                        <Checkbox
                          size={'small'}
                          sx={{ padding: 0, marginRight: '4px' }}
                          value={props.isSameAddress}
                          onChange={() =>
                            props.setIsSameAddress((state) => {
                              console.log(!state);
                              return !state;
                            })
                          }
                        />
                        {t(TK.sameAddressCheckbox)}
                      </InputLabel>
                    ),
                  },
                ]}
                labelElePairArrList={addressSectionLabelElePairArr}
                dividingLineColor={'#DDE1E3'}
                columnCount={3}
                isAlignColumn
              />
            ),
          },
        ]}
      />

      <br />
    </>
  );
});
