import React, { KeyboardEvent, SetStateAction, useRef, useState } from 'react';

import ListSubheader from '@mui/material/ListSubheader';
import { GridSearchIcon } from '@mui/x-data-grid';

import { MpTextField } from '../../../../../components';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import useAssets from '../../../../../hooks/useAssets/useAssets';

function SearchField(props: any) {
  const { searchLabel, setKeyword } = props;
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Escape') {
      // Prevents autoselecting item while typing (default Select behaviour)
      e.stopPropagation();
    }
  };
  return (
    <ListSubheader>
      <MpTextField
        size="small"
        autoFocus
        placeholder={searchLabel}
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start" children={<GridSearchIcon />} />,
        }}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </ListSubheader>
  );
}

interface IProps {
  programName: string | string[]
  label?: string;
  value: any;
  onClick: (e: any) => void;
}

const CurrencySingleSelection = (props: IProps) => {
  const { programName, label, value, onClick } = props;

  const [keyword, setKeyword] = useState('');

  const selectionRef = useRef<HTMLDivElement>(null);

  const { tc } = useTranslation();
  const { CurrencyEnum } = useAssets({ programName });

  const searchLabel = tc('selectSearchLabel');

  const menuItems = Object.keys(CurrencyEnum).map((assetName, index) => {
    if (!assetName.toLowerCase().includes(keyword.toLowerCase())) {
      return [];
    }

    return (
      <MenuItem key={index} value={assetName}>
        {assetName}
      </MenuItem>
    );
  });

  return (
    <FormControl>
      <InputLabel>{label || tc('phSelection', { fieldName: tc('currency') })}</InputLabel>
      <Select
        value={value || ''}
        onClick={onClick}
        ref={selectionRef}
        onClose={() => {
          setKeyword('');
          const focusedDivElement = selectionRef.current?.querySelector('div') as HTMLDivElement;
          setTimeout(() => focusedDivElement.blur(), 150);
        }}
      >
        <SearchField searchLabel={searchLabel} setKeyword={setKeyword} />
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default CurrencySingleSelection;
