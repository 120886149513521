import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Asset } from '../api/types';
import { RootState } from './store';

interface InitState {
  list: Asset[];
}

const initialState: InitState = { list: [] };

export const assetsListSlice = createSlice({
  name: 'assetsSlice',
  initialState,
  reducers: {
    setAssetsList: (state, action: PayloadAction<Asset[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setAssetsList } = assetsListSlice.actions;

export const selectAssetsList = (state: RootState) => state.assets;
export default assetsListSlice.reducer;
