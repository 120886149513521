import { isNumber } from 'lodash';
import { useRef } from 'react';

import { SxProps } from '@mui/system';

import { useTranslation } from '../../hooks';
import { sortAlphabetically } from '../../utils';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '../MuiGenerals';

const translatePrefix = 'enumConstants';
export default function SingleSelection(p: {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  onOpen?: () => void;
  enumData: Record<string, any>;
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string) => string;
  clearSelect: () => void;
  sx?: { formControl?: SxProps; select?: SxProps };
  isNoSorting?: boolean;
  disabled?: boolean;
  disabledItemList?: string[];
  className?: string;
}) {
  function isSelfFn<T>(arr: T): T {
    return arr;
  }
  const { t } = useTranslation(translatePrefix);
  const selectionRef = useRef<HTMLDivElement>(null);

  const defaultArrayFn = p.arrayFn ? p.arrayFn : isSelfFn;
  const enumObjEntries = Object.entries(p.enumData);
  const isValueNumber = enumObjEntries.some(([_key, value]) => isNumber(value));
  const displayEnumArr = isValueNumber
    ? enumObjEntries
        .filter(([_key, value]) => isNumber(value))
        .map(([key, value]) => [key, String(value)])
    : enumObjEntries;
  const selectionArray = defaultArrayFn(
    p.isNoSorting ? displayEnumArr : sortAlphabetically(displayEnumArr)
  );

  const sx = p.sx || {
    formControl: {
      width: '100%',
    },
    select: {},
  };
  return (
    <FormControl sx={sx.formControl}>
      {p.label && <InputLabel disabled={p.disabled}>{p.label}</InputLabel>}
      <Select
        ref={selectionRef}
        onClose={() => {
          const focusedDivElement = selectionRef.current?.querySelector('div') as HTMLDivElement;
          setTimeout(() => focusedDivElement.blur(), 150);
        }}
        name={p.label}
        value={p.value}
        onChange={p.onChange}
        onOpen={p.onOpen}
        sx={sx.select}
        disabled={p.disabled}
        className={p.className}
        MenuProps={{ sx: { height: '320px' } }}
      >
        {selectionArray.map(([name, value], i) => {
          const isDisabledItem = p.disabledItemList?.includes(name);
          return (
            <MenuItem
              key={i}
              value={value}
              onClick={p.clearSelect}
              className={p.className}
              disabled={isDisabledItem}
            >
              {p?.nameFn ? p.nameFn(name) : t(name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
