export enum CreateRecordRowKeys {
  ProgramName = 'programName',
  From = 'from',
  To = 'to',
  ExchangeRateOfCurrentDate = 'exchangeRateOfCurrentDate',
  AdjustmentType = 'adjustmentType',
  AdjustmentAmount = 'adjustmentAmount',
  ExchangeRateAfterAdjustment = 'exchangeRateAfterAdjustment',
}

export interface ICreateRecordFields {
  [CreateRecordRowKeys.ProgramName]: string;
  [CreateRecordRowKeys.From]: string;
  [CreateRecordRowKeys.To]: string;
  [CreateRecordRowKeys.AdjustmentType]: string;
  [CreateRecordRowKeys.AdjustmentAmount]: string;
}
