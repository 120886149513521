import { EnumTcspAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

export const getTcspAccountStatusDisplayValue = (
  status: number,
  translateFunc: (val: any) => string
) => {
  const value = EnumTcspAccountStatus[status];

  if (!value) return '';

  return translateFunc(value);
};
