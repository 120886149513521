import getIsAutoApprove from './getIsAutoApprove';

interface GetCellTextProps {
  approvalsCount: number;
  approvalsRequired: number;
  applicationStatus: number;
  translateFunc: (key: any) => string;
  // for overriding auto message
  autoTextTranslateKey?: string;
}

const getDisplayApprovalProgress = (props: GetCellTextProps) => {
  const {
    approvalsCount,
    approvalsRequired,
    applicationStatus,
    translateFunc,
    autoTextTranslateKey = 'auto',
  } = props;

  const isAuto = getIsAutoApprove({ approvalsCount, approvalsRequired, applicationStatus });

  const approvalCountDisplay = approvalsCount ?? '0';

  const approvalRequiredDisplay = approvalsRequired ?? '0';

  if (isAuto) {
    return translateFunc(autoTextTranslateKey);
  }
  return `${approvalCountDisplay}/${approvalRequiredDisplay}`;
};

export default getDisplayApprovalProgress;
