import { useState } from 'react';

import { EnumApprovalStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { DialogInOne } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box, Button } from '../../../components/MuiGenerals';
import ApprovalProgressDialogContent from '../../../features/approval/components/ApprovalProgressDialogContent';
import { ApproveHistory } from '../../../features/approval/types/ApproveHistory';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import { COLOR_THEME } from '../../../style/colorTheme';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';
import DialogRemarks from './DialogRemarks';

export default function DialogOperation() {
  const zusDialog = useZusDialogStore();

  const { t, te, tc } = useTranslation(translatePrefix);

  const { hasPermission } = usePermission();

  const zusParams = useZusParams();

  const [remarksDialogMode, setRemarksDialogMode] = useState<'close' | 'approve' | 'reject'>(
    'close'
  );

  const { alerting } = useAlerting();

  const { email: opeartingEmail } = useAppSelector(selectProfile);

  const { row = {} } = zusDialog.meta;

  const rawApprovalArr = row?.rawData?.repayment_schedule_approvals as ApproveHistory[] | undefined;
  const sortedApprovalArr = rawApprovalArr?.sort((a, b) => a.seq - b.seq);
  const firstPending = sortedApprovalArr?.find(
    ({ status }) => status === EnumApprovalStatus.Pending
  );

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [TK.batchOrderId, <Box>{row[TK.batchOrderId]}</Box>],
    [TK.repaymentOrderId, <Box>{row[TK.repaymentOrderId]}</Box>],
    [TK.scheduleTime, <Box>{row[TK.scheduleTime]}</Box>],
    [TK.repaymentMode, <Box>{row[TK.repaymentMode]}</Box>],
    [TK.programName, <Box>{row[TK.programName]}</Box>],
    [TK.repaymentType, <Box>{row[TK.repaymentType]}</Box>],
    [TK.customerNumber, <Box>{row[TK.customerNumber]}</Box>],
    [TK.asset, <Box>{row[TK.asset]}</Box>],
    [TK.assetCollateralAmount, <Box>{row[TK.assetCollateralAmount]}</Box>],
    [TK.assetRepaymentAmount, <Box>{row[TK.assetRepaymentAmount]}</Box>],
    [TK.exchangeRate, <Box>{row[TK.exchangeRate]}</Box>],
    [TK.releaseCreditAmount, <Box>{row[TK.releaseCreditAmount]}</Box>],
    [TK.creditRepaymentAmount, <Box>{row[TK.creditRepaymentAmount]}</Box>],
    [TK.createdBy, <Box>{row[TK.createdBy]}</Box>],
    [TK.creationTime, <Box>{row[TK.creationTime]}</Box>],
    [TK.lastModifiedTime, <Box>{row[TK.lastModifiedTime]}</Box>],
    [TK.executedBy, <Box>{row[TK.executedBy]}</Box>],
    [TK.executionTime, <Box>{row[TK.executionTime]}</Box>],
  ];

  const dialogContent = (
    <>
      <h3 style={{ color: COLOR_THEME.Views.CreditAdjustmentRequest.DialogOperation.Text }}>
        {t(TK.approvalProgress)}
      </h3>

      <ApprovalProgressDialogContent approveHistory={rawApprovalArr || []} />

      <br />

      <h3 style={{ color: COLOR_THEME.Views.CreditAdjustmentRequest.DialogOperation.Text }}>
        {t(TK.details)}
      </h3>

      <GridBox
        labelElePairArr={labelElePairArr}
        containerSx={{ padding: '16px 24px', backgroundColor: '#ECEEF0' }}
        dividingLineColor={'#DDE1E3'}
        columnCount={1}
      />

      <br />
    </>
  );

  const handleCloseDialog = () => {
    zusDialog.close();
  };

  const handleApproveReject = (mode: 'approve' | 'reject', remarks: string) => async () => {
    const isApprove = mode === 'approve';
    const apiFn = isApprove ? api.approve : api.reject;
    const successMessage = isApprove ? tc('request_approved') : tc('request_rejected');

    const id = firstPending?.id || '-1';

    const res = await apiFn({ id, remarks });

    if (!res) {
      return;
    }

    alerting('success', successMessage);
    setRemarksDialogMode('close');
    handleCloseDialog();
    zusParams.refetch();
  };

  const handleClickingApproveBtn = () => {
    const firstPendingPermission = firstPending?.approvalPermission;

    if (!firstPendingPermission || !rawApprovalArr) {
      return alerting('error', 'System Error');
    }

    if (!hasPermission(firstPendingPermission)) {
      return alerting('error', tc('no_relevant_permission'));
    }

    if (row[TK.createdBy] === opeartingEmail) {
      return alerting('error', tc('no_same_operator_creator'));
    }

    if (rawApprovalArr.find(({ approvedBy }) => approvedBy === opeartingEmail)) {
      return alerting('error', tc('no_same_approver'));
    }
    setRemarksDialogMode('approve');
  };

  const handleClickingRejectBtn = () => {
    if (!hasPermission(PermissionKey.Reject)) {
      return alerting('error', tc('no_relevant_permission'));
    }

    setRemarksDialogMode('reject');
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('approve_reject_dialog_title'),
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: () => { },
    onCancel: () => { },

    size: 'md',
    isCancelHidden: true,
    isConfirmHidden: true,
    dialogActionStyling: { padding: '8px' },

    actionButtons: (
      <>
        <Button
          className={'inheritColor'}
          color={'primary'}
          variant={'contained'}
          onClick={handleClickingApproveBtn}
        >
          {tc('approve_btn')}
        </Button>
        <Button
          className={'inheritColor'}
          variant={'contained'}
          color={'error'}
          sx={{ backgroundColor: '#E86A62' }}
          onClick={handleClickingRejectBtn}
        >
          {tc('reject_btn')}
        </Button>
        <Button
          className={'inheritColor'}
          variant={'contained'}
          color={'secondary'}
          onClick={handleCloseDialog}
        >
          {tc('cancel_btn')}
        </Button>
      </>
    ),
  };

  if (!zusDialog.match('operationDialog')) {
    return <></>;
  }
  return (
    <>
      <DialogInOne {...dialogConfig} />
      {remarksDialogMode !== 'close' && (
        <DialogRemarks
          handleClose={() => setRemarksDialogMode('close')}
          handleSubmit={(remarks: string) => handleApproveReject(remarksDialogMode, remarks)}
        />
      )}
    </>
  );
}
