// import assetsReducer from "./assetsSlice";
// import chainsReducer from "./chainsSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';

import alerterReducer from './alerterSlice';
import currencyListReducer from './currencyListSlice';
import merchantListReducer from './merchantListSlice';
import partnerListReducer from './partnerListSlice';
import profileReducer from './profileSlice';
import programListReducer from './programListSlice';
import stuffReducer from './stuffSlice';
import assetsReducer from './assetsSlice';
import chainsReducer from './chainsSlice';

const STORE_VERSION = Number(process.env.REACT_APP_STORE_VERSION || '0');

export const PERSIST_STORE_KEY = 'root';

const persistConfig = {
  key: PERSIST_STORE_KEY,
  version: STORE_VERSION,
  storage, //save in localStorage
  whitelist: ['profile' /* "assets", "chains" */], // profile and visitor will be persisted
};

const reducers = combineReducers({
  alerter: alerterReducer,
  profile: profileReducer,
  stuff: stuffReducer,
  currencyList: currencyListReducer,
  merchantList: merchantListReducer,
  partnerList: partnerListReducer,
  programList: programListReducer,
  assets: assetsReducer,
  chains: chainsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
