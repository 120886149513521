import addHours from 'date-fns/addHours';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { SxProps } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPickerView } from '@mui/x-date-pickers/CalendarPicker';
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { useTranslation } from '../hooks';
import { store } from '../reducer/store';
import { searchEndOfDay, searchEndOfSecond, toDBTime } from '../utils';
import { dateInFormat, dateTimeInFormat } from '../utils/config';
import { Box } from './MuiGenerals';
import { MpTextField } from './TextField';

interface PropsFaceBase {
  label?: string;
  timeValue: Date | null;
  minEndTime?: any;
  maxStartTime?: any;
  isLimitingTime?: boolean;
  allowHourOnly?: boolean;
  disableFuture?: boolean;
  inputLabel?: string;
  disabled?: boolean;
  setTimeValue: React.Dispatch<React.SetStateAction<Date | null>> | ((value: Date | null) => void);
  helperText?: string;
  helperTextSx?: CSSProperties;
  customInputFormat?: string;
  defaultCalendarMonth?: Date;
  sx?: SxProps;
}

type DateType = {
  type: 'date';
  views?: CalendarPickerView[];
};
type DateTimeType = {
  type: 'dateTime';
  views?: CalendarOrClockPickerView[];
};

type PropsFace = PropsFaceBase & (DateType | DateTimeType);

export default function TimePicker(props: PropsFace) {
  const {
    type,
    label,
    setTimeValue,
    timeValue = null,
    minEndTime,
    maxStartTime,
    isLimitingTime,
    allowHourOnly,
    views,
    disableFuture = false,
    disabled,
    helperText,
    helperTextSx,
    customInputFormat = null,
    defaultCalendarMonth,
    sx,
  } = props;
  const [timeShow, setTimeShow] = useState<Date | null | undefined>(timeValue);
  const [isClockView, setIsClockView] = useState(false);

  useEffect(() => {
    setTimeShow(timeValue);
  }, [timeValue]);
  const hasViews = views && views.length > 0;
  const dateViews = hasViews && type === 'date' ? views : (['day'] as CalendarPickerView[]);
  const dateTimeViews =
    hasViews && type === 'dateTime'
      ? views
      : (['day', 'hours', 'minutes', 'seconds'] as CalendarOrClockPickerView[]);
  const handleChange = (newValue: Date | null) => {
    if (allowHourOnly) {
      newValue?.setMinutes(0);
      newValue?.setSeconds(0);
      setTimeShow(newValue);
    }

    if (type === 'dateTime') {
      const isAM = (newValue?.getHours() || 0) < 12;
      const amBtn = document.querySelector('.MuiClock-amButton');
      const amBtnText = amBtn?.querySelector('.MuiTypography-caption');
      const pmBtn = document.querySelector('.MuiClock-pmButton');
      const pmBtnText = pmBtn?.querySelector('.MuiTypography-caption');

      if (isAM) {
        if (pmBtnText?.classList.contains('Mui-selected')) {
          pmBtnText?.classList.remove('Mui-selected');
        }
        if (!amBtnText?.classList.contains('Mui-selected')) {
          amBtnText?.classList.add('Mui-selected');
        }
      } else {
        if (amBtnText?.classList.contains('Mui-selected')) {
          amBtnText?.classList.remove('Mui-selected');
        }
        if (!pmBtnText?.classList.contains('Mui-selected')) {
          pmBtnText?.classList.add('Mui-selected');
        }
      }
    }

    setTimeShow(newValue);
  };

  const onAccept = () => {
    if (!timeShow) return;
    setTimeValue(timeShow);
  };

  useEffect(() => {
    const amBtn = document.querySelector('.MuiClock-amButton');
    const amBtnText = amBtn?.querySelector('.MuiTypography-caption');
    amBtnText?.classList.add('Mui-selected');
  }, [isClockView]);

  const selectionRef = useRef<HTMLInputElement>(null);

  return (
    <Box sx={{ position: 'relative', '>div': { width: '100%' }, ...sx }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {type === 'date' && (
          <MobileDatePicker
            inputRef={selectionRef}
            onClose={() => {
              const focusedDivElement = selectionRef.current;
              setTimeout(() => focusedDivElement?.blur(), 200);
            }}
            views={dateViews}
            disableFuture={disableFuture}
            label={label}
            inputFormat={customInputFormat || dateInFormat}
            value={timeShow}
            onAccept={onAccept}
            onChange={handleChange}
            minDate={minEndTime}
            maxDate={maxStartTime}
            disabled={disabled}
            renderInput={(params) => <MpTextField {...params} />}
            defaultCalendarMonth={defaultCalendarMonth}
          />
        )}
        {type === 'dateTime' && (
          <MobileDateTimePicker
            inputRef={selectionRef}
            onClose={() => {
              const focusedDivElement = selectionRef.current;
              setTimeout(() => focusedDivElement?.blur(), 200);
            }}
            onViewChange={(view) =>
              view === 'hours' || view === 'minutes' || view === 'seconds'
                ? setIsClockView(true)
                : setIsClockView(false)
            }
            views={dateTimeViews}
            disableFuture={disableFuture}
            label={label}
            inputFormat={customInputFormat || dateTimeInFormat}
            shouldDisableTime={(value, view) => {
              if (!allowHourOnly) {
                return false;
              }
              if (view === 'minutes' || view === 'seconds') {
                if (value) {
                  return true;
                }
              }
              return false;
            }}
            value={timeShow}
            onAccept={onAccept}
            onChange={handleChange}
            minDateTime={minEndTime}
            maxDateTime={maxStartTime}
            minTime={isLimitingTime ? minEndTime : undefined}
            maxTime={isLimitingTime ? maxStartTime : undefined}
            disabled={disabled}
            renderInput={(params) => <MpTextField sx={sx} {...params} />}
          />
        )}
      </LocalizationProvider>
      {helperText && (
        <FormHelperText
          children={helperText}
          sx={{ position: 'absolute', bottom: -15, left: 0, zIndex: 99 }}
        />
      )}
    </Box>
  );
}

type maybeDate = Date | null;
interface useDatePickerFace {
  start: {
    defaultDate: maybeDate;
    label?: String;
  };
  end: {
    defaultDate: maybeDate;
    label?: String;
  };
  isTimezoneConvert: boolean;
  isEndOfSecond: boolean;
}
//to put default date
//useDatePicker({ start: { defaultDate: new Date() } })
export function useDatePicker(inputConfig?: Partial<useDatePickerFace>) {
  const defaultConfig = {
    start: {
      defaultDate: null,
      label: 'Time Start',
    },
    end: {
      defaultDate: null,
      label: 'Time End',
    },
    isTimezoneConvert: true,
    isEndOfSecond: true,
  };
  const config = { ...defaultConfig, ...inputConfig };
  const { t } = useTranslation('common');
  const [dateStart, setDateStart] = useState<maybeDate>(config.start.defaultDate);
  const [dateEnd, setDateEnd] = useState<maybeDate>(config.end.defaultDate);
  const clearDate = () => {
    setDateStart(null);
    setDateEnd(null);
  };
  const resetDate = () => {
    setDateStart(config.start.defaultDate);
    setDateEnd(config.end.defaultDate);
  };
  const hyphenStyle = {
    verticalAlign: 'bottom',
    fontSize: '2rem',
    margin: '0 10px',
  };
  const DateRangePicker = useMemo(
    () => (p: any) =>
      (
        <Box
          // className="datePickerObj"
          sx={{
            display: 'flex',
            width: '100%',
            '>div': {
              width: '50%',
            },
          }}
        >
          <TimePicker
            type={p.type}
            label={p.startLabel ?? t('start_date')}
            timeValue={dateStart}
            setTimeValue={setDateStart}
            maxStartTime={dateEnd}
          />
          <span style={hyphenStyle}> - </span>
          <TimePicker
            type={p.type}
            label={p.endLabel ?? t('end_date')}
            timeValue={dateEnd}
            setTimeValue={setDateEnd}
            minEndTime={dateStart}
          />
        </Box>
      ),
    [dateStart, dateEnd]
  );
  const localUTCdiff = Number(store.getState().profile.timezone) * -1 || 0;
  const usedUTCDiff = config.isTimezoneConvert ? localUTCdiff : 0;
  const searchEndOfTime = config.isEndOfSecond ? searchEndOfSecond : searchEndOfDay;

  const startTime = toDBTime(dateStart && addHours(dateStart, usedUTCDiff));
  const endTime = toDBTime(dateEnd && searchEndOfTime(addHours(dateEnd, usedUTCDiff)));
  const output = {
    start: startTime ? startTime.slice(0, -4) + '000Z' : '',
    end: endTime ? endTime.slice(0, -4) + '999Z' : '',
  };

  return {
    Picker: DateRangePicker,
    start: output.start,
    end: output.end,
    clearDate,
    resetDate,
  };
}
