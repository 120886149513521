import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.OrderId, {
      minWidth: COLUMN_WIDTH.OrderId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.ProgramName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.TransactionType),
    useGenGridCol(TableColumnKey.AdjustmentMode),
    useGenGridCol(TableColumnKey.CustomerNumber, {
      minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Amount),
    useGenGridCol(TableColumnKey.SendingAddress, {
      minWidth: COLUMN_WIDTH.TxAddress.MinWidth,
    }),
    useGenGridCol(TableColumnKey.ReceivingAddress, {
      minWidth: COLUMN_WIDTH.TxAddress.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CurrencyOfCreditAmount),
    useGenGridCol(TableColumnKey.CreditAmount),
    useGenGridCol(TableColumnKey.ExchangeRate),
    useGenGridCol(TableColumnKey.TransactionHash, {
      minWidth: COLUMN_WIDTH.TxHash.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CreatedBy, {
      minWidth: COLUMN_WIDTH.CreatedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
