import './index.css';

import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useRef, useState } from 'react';

import Autocomplete, {
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
} from '@mui/material/Autocomplete';

import { COLOR_THEME } from '../../style/colorTheme';
import { importantStyle } from '../../utils';
import { Box } from '../MuiGenerals';
import { MpTextField } from '../TextField';

// [IMPORTANT] For "renderInput", pass {...params} (ref and inputProps) on input component to prevent error
// Omit renderInput from props, will be handled inside of wrapper component
type MUIAutoCompleteProps<T> = Omit<
  AutocompleteProps<T, undefined, undefined, undefined>,
  'renderInput'
>;

interface CustomAutoCompleteProps {
  label: string;
  value: string;
  // Length limit of options list
  optionsLengthLimit?: number;
  // The length of the input value that triggers the autocomplete to expand
  triggerLength?: number;
  disabled?: boolean;
  setOptions: Dispatch<SetStateAction<any[]>>;
  noOptionText: string;
  onInputChange: (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => Promise<void> | void;
}

const AutoComplete = <T,>(props: MUIAutoCompleteProps<T> & CustomAutoCompleteProps) => {
  const {
    onInputChange,
    onClose,
    options,
    setOptions,
    label,
    value,
    optionsLengthLimit,
    triggerLength,
    disabled,
    noOptionText,
  } = props;

  const OPTIONS_LENGTH_LIMIT = optionsLengthLimit || 10;
  const TRIGGER_LENGTH = triggerLength || 4;

  const handledNullishValue = value ?? '';

  const [shouldAutocompleteExpand, setShouldAutocompleteExpand] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (handledNullishValue) {
      return;
    }

    setOptions([]);
  }, [handledNullishValue]);

  const handleInputChange = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // Prevent the autocomplete from expanding if the input value is less than the trigger length
    if (value.length >= TRIGGER_LENGTH) {
      setShouldAutocompleteExpand(true);
    } else {
      setShouldAutocompleteExpand(false);
    }

    if (onInputChange) {
      if (!shouldShowNoOptionText) {
        setIsLoading(true);
      }
      await onInputChange(event, value, reason);
      setIsLoading(false);
    }
  };

  const handleClose = (event: SyntheticEvent<Element, Event>, reason: AutocompleteCloseReason) => {
    setShouldAutocompleteExpand(false);

    if (onClose) {
      onClose(event, reason);
    }
  };

  const handleOpen = (event: SyntheticEvent<Element, Event>) => {
    if (handledNullishValue.length < TRIGGER_LENGTH) return;

    setShouldAutocompleteExpand(true);
  };

  const getValidOptions = (options: Readonly<Array<T>>) => {
    const validOptions = [...options];

    if (options.length > OPTIONS_LENGTH_LIMIT) {
      return validOptions.slice(0, OPTIONS_LENGTH_LIMIT);
    }

    return validOptions;
  };

  const validOptions = getValidOptions(options);

  const inputRef = useRef<HTMLDivElement>(null);

  const shouldShowNoOptionText = !isLoading && shouldAutocompleteExpand && !validOptions.length;

  function NoOptionTextComponent() {
    return (
      <Box
        sx={{
          position: 'absolute',
          height: 'content',
          width: inputRef.current?.clientWidth,
          backgroundColor: importantStyle(COLOR_THEME.MUI.MuiInputBase.Background),
          color: COLOR_THEME.MUI.MuiInputBase.Text,
          borderBottom: 'solid 1.8px rgba(0, 0, 0, 0.24)',
          borderRadius: '4px',
          fontSize: '0.7857142857142857rem',
          zIndex: 99,
        }}
      >
        <Box sx={{ padding: '14px 16px' }}>{noOptionText}</Box>
      </Box>
    );
  }

  return (
    <>
      <Autocomplete
        open={shouldAutocompleteExpand}
        onOpen={handleOpen}
        onClose={handleClose}
        options={validOptions}
        renderInput={(params) => (
          <Box>
            <MpTextField
              innerRef={inputRef}
              label={label}
              value={handledNullishValue}
              {...params}
            />
            {shouldShowNoOptionText && <NoOptionTextComponent />}
          </Box>
        )}
        disableClearable={!handledNullishValue}
        onInputChange={handleInputChange}
        freeSolo
        disabled={disabled}
        inputValue={handledNullishValue}
      />
    </>
  );
};

export default AutoComplete;
