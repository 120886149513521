import React from 'react';

const RowItem = ({ title, children }: { title: string; children: React.ReactNode | string }) => {
  return (
    <div style={{ display: 'flex', marginBottom: '24px', fontSize: '12px' }}>
      <div
        style={{
          width: '160px',
          minWidth: '160px',
          wordBreak: 'break-word',
          textAlign: 'left',
          marginRight: '24px',
          color: '#798797',
        }}
      >
        {`${title}`}
      </div>
      <div style={{ color: '#262E40', fontWeight: '700', whiteSpace: 'pre-wrap' }}>{children}</div>
    </div>
  );
};

export default RowItem;
