import { GridRenderCellParams } from '@mui/x-data-grid';
import { TCSP } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

interface IProps {
  renderApprovalProgressCell: (params: GridRenderCellParams) => React.ReactNode;
  renderOperationsCell: (params: GridRenderCellParams) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderApprovalProgressCell, renderOperationsCell } = props;
  const { hasPermission } = usePermission();

  const hasApprovePermission = hasPermission(TCSP.TcspAccountApplication.Details.Approve.prefix);
  const hasRejectPermission = hasPermission(TCSP.TcspAccountApplication.Details.Reject);
  const hasViewDetailPermission = hasPermission(TCSP.TcspAccountApplication.Details.prefix);

  const columns = [
    useGenGridCol(TableColumnKey.Operations, {
      renderCell: renderOperationsCell,
      hide: !hasApprovePermission && !hasRejectPermission && !hasViewDetailPermission,
    }),
    useGenGridCol(TableColumnKey.ApplicationNumber, {
      minWidth: COLUMN_WIDTH.ApplicationNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.ApprovalProgress, {
      renderCell: renderApprovalProgressCell,
      minWidth: COLUMN_WIDTH.ApprovalProgress.MinWidth,
    }),
    useGenGridCol(TableColumnKey.Status),
    useGenGridCol(TableColumnKey.ProgramName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CustomerId, {
      minWidth: COLUMN_WIDTH.CustomerId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CustomerNumber, {
      minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.TcspCustomerApplicationType),
    useGenGridCol(TableColumnKey.CustomerApplicationVersion),
    useGenGridCol(TableColumnKey.Version),
    useGenGridCol(TableColumnKey.IsLatestVersion),
    useGenGridCol(TableColumnKey.CreatedBy),
    useGenGridCol(TableColumnKey.CreatedDate, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedDate, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
