import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

const getPrograms = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(MerchantPortal.EptCobrandManagement.MerchantProgram.getAll, input);
};

const exportTable = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptCobrandManagement.MerchantProgram.export, input);
};

export default { getPrograms, exportTable };
