import {
  EptCustomerManagement,
  EptQuery
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import {
  IapplicableProgram,
  IdetailsApiRes,
  ItableApiRes
} from '../../views/CustomerManagement/CustomerApplication/config';
import axios from '../axiosInstance';

const endPoint = {
  ...EptCustomerManagement.customerAccountApplication,
  applicableProgram: EptQuery.customer.applicableProgram,
};

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}
async function getDetail(params: { customerApplicationId: string }, config?: any) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.details, input);
}
async function getDecryptIdNumber(params: { applicationNumber: string }, config?: any) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.idNumber, input);
}
async function postApprove(
  params: { id: string | number; customerNumber?: string; remarks?: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.approve, input);
}
async function postReject(
  params: { id: string | number; customerNumber?: string; remarks?: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.reject, input);
}
async function postAssignCustomerNumber(
  params: {
    applicationNumber: string;
    customerNumber: string;
    customerLevel: string;
    programName: string;
    referralCode: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.assignCustomerNumber, input);
}

async function postSwitchManualReview(params: { applicationNumber: string }, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.kycManualReview, input);
}

async function postManualVerifyIdv(
  params: { applicationNumber: string; remarks: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.kycManualSuccess, input);
}

async function postManualFailIdv(
  params: { applicationNumber: string; remarks: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.kycFailed, input);
}

async function postRetryIdv(params: { applicationNumber: string; remarks: string }, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.kycRetry, input);
}

async function getApplicableProgram(
  params: { customerId: string; includePending: boolean },
  config?: any
) {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, IapplicableProgram[]>(endPoint.applicableProgram, input);
}

async function postResubmit(
  params: Partial<ItableApiRes> & {
    answers: Partial<IdetailsApiRes>;
    rejectApplicationRemark: string;
  },
  config?: any
) {
  const input = {
    ...params,
    questionVersion: 1,
    ...config,
  };
  return await axios.post(endPoint.resubmit, input);
}

async function holdApplication(params: { applicationNumber: string }, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.onHold, input);
}

async function releaseApplicationHold(params: { applicationNumber: string }, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.releaseOnHold, input);
}

export default {
  getAll,
  getExport,
  getDetail,
  getDecryptIdNumber,
  postApprove,
  postReject,
  postAssignCustomerNumber,
  postSwitchManualReview,
  postManualVerifyIdv,
  postManualFailIdv,
  postRetryIdv,
  holdApplication,
  releaseApplicationHold,
  postResubmit,
  getApplicableProgram,
};
