import { ApiResult } from '../../../../api/types';
import { getTcspApplicationStatusDisplayValue } from '../../../../helper/getTcspApplicationStatusDisplayValue';
import { getTcspCustomerApplicationTypeDisplayValue } from '../../../../helper/getTcspCustomerApplicationTypeDisplayValue';
import { useTranslation } from '../../../../hooks';
import { toDisplayTime } from '../../../../utils';
import getDisplayApprovalProgress from '../../../approval/helpers/getDisplayApprovalProgress';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const tcspCustomerApplicationType = getTcspCustomerApplicationTypeDisplayValue(row.type, te);
      const status = getTcspApplicationStatusDisplayValue(row.status, te);
      const isLatestVersion = tb(row.isLatestVersion);

      const approvalProgressDisplayValue = getDisplayApprovalProgress({
        approvalsCount: row.approvalsCount,
        approvalsRequired: row.approvalsRequired,
        applicationStatus: row.status,
        translateFunc: tc,
      });

      return {
        id: index,
        [TableColumnKey.ApplicationNumber]: row.applicationNumber,
        [TableColumnKey.ApprovalProgress]: approvalProgressDisplayValue,
        [TableColumnKey.Status]: status,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.CustomerId]: row.customerId,
        [TableColumnKey.CustomerNumber]: row.customerNumber,
        [TableColumnKey.TcspCustomerApplicationType]: tcspCustomerApplicationType,
        [TableColumnKey.CustomerApplicationVersion]: row.customerApplicationVersion,
        [TableColumnKey.Version]: row.version,
        [TableColumnKey.IsLatestVersion]: isLatestVersion,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreatedDate]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedDate]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
