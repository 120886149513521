import { useState } from 'react';

import { DialogInOne, MpTextField } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { useTranslation } from '../../../hooks';
import { translatePrefix } from './config';

export default function DialogRemarks(props: {
  handleClose: () => void;
  handleSubmit: (remarks: string) => () => Promise<void>;
}) {
  const { t, tc } = useTranslation(translatePrefix);
  const { handleClose, handleSubmit } = props;

  const [remarks, setRemarks] = useState('');

  const dialogConfig: IdialogInOneProps = {
    title: tc('remarks'),
    self: {
      open: true,
      onClose: handleClose,
    },
    size: 'sm',
    content: (
      <GridBox
        columnCount={1}
        labelElePairArr={[
          [
            'remarks',
            <MpTextField
              label={tc('phInputFieldOptional', { fieldName: tc('remarks') })}
              value={remarks}
              onChange={(e) => setRemarks(e.currentTarget.value)}
            />,
          ],
        ]}
      />
    ),
    onConfirm: handleSubmit(remarks),
    onCancel: handleClose,
    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
