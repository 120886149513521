import { useZusTranslatePrefixStore } from '../zustand/store';
import useTranslation from './useTranslation';

export default function useListMappingTransform(mode: 'table' | 'export') {
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);
  const listMappingTransform = (item: any, index: number) => {
    const initValue = mode === 'table' ? { id: index + 1 } : {};
    const reduceFn = (acc: any, [key, value]: [string, any]) => {
      const newObjKey = mode === 'table' ? key : t(key);
      return { ...acc, [newObjKey]: value };
    };
    return item.reduce(reduceFn, initValue);
  };
  return listMappingTransform;
}
