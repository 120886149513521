export const ChainDisplayNames = {
  AVAXC: { key: 'AVAXC', name: 'Avalanche C-Chain' },
  FUJIC: { key: 'FUJIC', name: 'Fuji C-chain' },
  ADA: { key: 'ADA', name: 'Cardano' },
  ADAT: { key: 'ADAT', name: 'Cardano Testnet' },
  LTC: { key: 'LTC', name: 'Litecoin' },
  LTCT: { key: 'LTCT', name: 'Litecoin Testnet' },
  SOL: { key: 'SOL', name: 'Solana' },
  SOLD: { key: 'SOLD', name: 'Solana Devnet' },
  BTC: { key: 'BTC', name: 'Bitcoin' },
  ETH: { key: 'ETH', name: 'Ethereum' },
  RIN: { key: 'RIN', name: 'Rinkeby' },
  BSC: { key: 'BSC', name: 'Binance' },
  BSCT: { key: 'BSCT', name: 'Binance Testnet' },
  XRPT: { key: 'XRPT', name: 'Ripple Testnet' },
  MATIC: { key: 'MATIC', name: 'Polygon' },
  MUM: { key: 'MUM', name: 'Mumbai' },
  SEP: { key: 'SEP', name: 'Sepolia' },
  TRX: { key: 'TRX', name: 'Tron' },
  XRP: { key: 'XRP', name: 'Ripple' },
  OKC: { key: 'OKC', name: 'OKX Chain' },
  OKCT: { key: 'OKCT', name: 'OKX Chain Testnet' },
  BCH: { key: 'BCH', name: 'Bitcoin Cash' },
  BCHT: { key: 'BCHT', name: 'Bitcoin Cash Testnet' },
  DOGE: { key: 'DOGE', name: 'Dogecoin' },
  DOGET: { key: 'DOGET', name: 'Dogecoin Testnet' },
};
