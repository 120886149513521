export enum EditRecordRowKeys {
  CustomerId = 'customerId',
  ProgramName = 'programName',
  CustomerNumber = 'customerNumber',
  LatestVersion = 'latestVersion',
  Status = 'status',
}

export interface IEditRecordFields {
  [EditRecordRowKeys.CustomerId]: string;
  [EditRecordRowKeys.ProgramName]: string;
  [EditRecordRowKeys.CustomerNumber]: string;
  [EditRecordRowKeys.LatestVersion]: string | number;
  [EditRecordRowKeys.Status]: string;
}
