import './index.css';

import React from 'react';

import { COLOR_THEME } from '../../../style/colorTheme';
import ToggleButton from './components/ToggleButton';

interface IProps {
  children: React.ReactNode;
  isCollapse: boolean;
  onCollapseClick?: () => void;
}

const SideMenu = ({ children, isCollapse, onCollapseClick }: IProps) => {
  const addClassNameIfIsCollapse = (className: string) => {
    return isCollapse ? className : '';
  };

  return (
    <div className={`side-menu-container ${addClassNameIfIsCollapse('instant-collapse')}`}>
      <div
        className={`side-menu ${addClassNameIfIsCollapse('collapsed')}`}
        style={{
          background: COLOR_THEME.Menu.Background,
        }}
      >
        <ToggleButton onCollapseClick={onCollapseClick} isCollapse={isCollapse} />

        <div
          className={`content ${addClassNameIfIsCollapse('fade-out')}`}
          style={{
            background: COLOR_THEME.Menu.Background,
            transitionDelay: isCollapse ? '0s' : '0.3s',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
