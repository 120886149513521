import BigNumber from 'bignumber.js';

export const safeAdd = (num1: number | string, num2: number | string) => {
  return BigNumber(num1).plus(num2).toString();
};

export const safeMultiply = (num1: number | string, num2: number | string) => {
  return BigNumber(num1).multipliedBy(num2).toString();
};

export const safeDivide = (num1: number | string, num2: number | string) => {
  return BigNumber(num1).dividedBy(num2).toString();
};

export const roundDown = (value: number | string, decimal: number = 2) => {
  const multiplier = Math.pow(10, decimal);

  return String(safeDivide(Math.floor(Number(safeMultiply(value, multiplier))), multiplier));
};
