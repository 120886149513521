import { SourceOfFundCheckedStatus } from '../types';

const SourceOfFundBinaryRepresentation = {
  others: 1,
  businessIncome: 2,
  personalSaving: 4,
  inheritance: 8,
  rentalIncome: 16,
  investment: 32,
  salesOfProperty: 64,
  salesOfVirtualAssets: 128,
  pension: 256,
  wagesDepositedFromEmployer: 512,
};

export const createSourceOfFundBitWiseRepresentation = (
  sourceOfFund: SourceOfFundCheckedStatus
) => {
  let result = 0;

  Object.keys(sourceOfFund).forEach((key: string) => {
    if (sourceOfFund[key as keyof SourceOfFundCheckedStatus]) {
      const binaryValue =
        SourceOfFundBinaryRepresentation[key as keyof typeof SourceOfFundBinaryRepresentation];

      result += binaryValue;
    }
  });

  return result;
};
