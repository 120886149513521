import { EnumAdjustmentMode } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

export const getAdjustmentModeDisplayValue = (
  mode: number,
  translateFunc: (val: any) => string
) => {
  const value = EnumAdjustmentMode[mode];

  if (!value) return '';

  return translateFunc(value);
};
