import React from 'react';
import { DialogInOne } from '../../../components';
import { useTranslation } from '../../../hooks';
import CancelButton from './CancelButton';

interface IProps {
  title?: string;
  isOpen: boolean;
  customerNumber: string;
  closeDialog: () => void;
  children?: React.ReactNode;
}

const CustomTitle = ({ title, customerNumber }: { title: string; customerNumber: string }) => {
  const { tc } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>{title}</div>
      <div
        style={{
          fontSize: '16px',
          fontWeight: '700',
        }}
      >
        {`${tc('customerNumber')}: `}
        <span style={{ color: '#5183FF', textDecoration: 'underline' }}>{customerNumber}</span>
      </div>
    </div>
  );
};

const ViewDetailDialog = (props: IProps) => {
  const { isOpen, title, closeDialog, children, customerNumber } = props;

  const { tc } = useTranslation();

  const dialogConfig = {
    title: title || tc('viewDetail'),
    customTitle: <CustomTitle title={title || tc('viewDetail')} customerNumber={customerNumber} />,
    self: {
      open: isOpen,
      onClose: () => {
        closeDialog();
      },
    },
    onConfirm: () => {},
    onCancel: () => {
      closeDialog();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: 'xl' as any,
    isFullScreen: true,
    isCancelHidden: true,
    content: <>{children && children}</>,
    actionButtons: (
      <div style={{ marginTop: '4px', marginBottom: '4px' }}>
        <CancelButton onClick={closeDialog} />
      </div>
    ),
  };

  return <DialogInOne {...dialogConfig}></DialogInOne>;
};

export default ViewDetailDialog;
