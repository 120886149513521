import { ChangeEvent, RefObject } from 'react';

import { TextFieldProps } from '@mui/material/TextField';

import {
  lettersAndSpacesWithSpecialChar,
  onlyLettersAndSpaces
} from '../../utils';
import MpTextField from './MpTextField';

type MpTextFieldProps = TextFieldProps & {
  innerRef?: RefObject<HTMLDivElement>;
};
export default function MpTextFieldEnglishOnly(
  props: MpTextFieldProps & { isAllowedSpecialChar?: boolean }
) {
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const checkingFn = props.isAllowedSpecialChar
      ? lettersAndSpacesWithSpecialChar
      : onlyLettersAndSpaces;
    if (!checkingFn(e.target.value)) {
      return;
    }

    props.onChange?.(e);
  };

  return <MpTextField {...props} onChange={onChange} />;
}
