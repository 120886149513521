import React, { ReactNode } from 'react';

import { Container } from '../../../../components/MuiGenerals';

const TableContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Container maxWidth={false} disableGutters>
      {children}
    </Container>
  );
};

export default TableContainer;
