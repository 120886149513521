import BigNumber from 'bignumber.js';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { EnumAdjustmentMode } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import {
  DialogInOne,
  MpTextField,
  MpTextFieldNumberOnly,
  SingleSelection
} from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../components/MuiGenerals';
import MpTextFieldWithEndAdornment from '../../../components/TextField/MpTextFieldWithEndAdornment';
import { useAlerting, useTranslation } from '../../../hooks';
import useAssets from '../../../hooks/useAssets/useAssets';
import useExchangeRate from '../../../hooks/useExchangeRate';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { useGetProgram } from '../../../hooks/useProgram';
import {
  amountDivideDecimals,
  bigNumStrMulitpleDecimals,
  calculateBigNumbers,
  compareBigNumbers,
  isEmptyObject,
  roundDownTruncate
} from '../../../utils';
import { ASSET_BALANCE_DP } from '../../../utils/config';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';

interface Ifield {
  adjustmentMode: string;
  programName: string;
  customerNumber: string;
  asset: string;
  assetCollateralAmount: string;
  releaseCreditAmount: string;
}

const initFields: Ifield = {
  adjustmentMode: '',
  programName: '',
  customerNumber: '',
  asset: '',
  assetCollateralAmount: '',
  releaseCreditAmount: '',
};

const initAvailableAssets = { enum: {}, assetBalanceObj: {} };

export default function DialogCreateRequest() {
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const zusParams = useZusParams();

  const [fields, setFields] = useState<Ifield>(initFields);

  const { EnumProgramCurrency } = useGetProgram();
  const { validateGridBoxInput } = useGridBoxInputValidation();

  const { getAssetDetailByAssetName } = useAssets({
    programName: fields.programName,
  });

  const [availableAssets, setAvailableAssets] = useState<{
    enum: Record<string, string>;
    assetBalanceObj: Record<string, { balance: string; decimals: number }>;
  }>(initAvailableAssets);

  const [isFetching, setIsFetching] = useState(false);

  const { alerting } = useAlerting();

  const isTcspMode = Number(fields.adjustmentMode) === EnumAdjustmentMode.TCSP;

  const creditCurrency = EnumProgramCurrency[fields.programName];

  const tcspDefaultReleaseAmount = '0.00';

  const { exchangeRate } = useExchangeRate({
    programName: fields.programName,
    fromCurrency: fields.asset,
    toCurrency: creditCurrency,
  });

  const availableBalance = useMemo(
    () =>
      fields.asset && !isEmptyObject(availableAssets.assetBalanceObj)
        ? availableAssets.assetBalanceObj[fields.asset]
        : undefined,
    [fields.asset]
  );

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const handleAdjustmentModeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    onChange('adjustmentMode')(e);
    setFields((f) => ({
      ...f,
      releaseCreditAmount:
        Number(f.adjustmentMode) === EnumAdjustmentMode.Classic ? '' : tcspDefaultReleaseAmount,
      asset: '',
      assetCollateralAmount: '',
    }));
  };

  const setTcspReleaseAmount = () =>
    setFields((f) => {
      const productAssetAmountAndExchangeRate = calculateBigNumbers(
        'multiply',
        f.assetCollateralAmount || 0,
        exchangeRate || 1
      );

      const releaseCreditAmount = productAssetAmountAndExchangeRate
        ? roundDownTruncate(productAssetAmountAndExchangeRate.toFixed(), 2, {
            fillDecimals: true,
          })
        : tcspDefaultReleaseAmount;

      return {
        ...f,
        releaseCreditAmount,
      };
    });

  const handleAssetCollateralAmountChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    onChange('assetCollateralAmount')(e);
    setTcspReleaseAmount();
  };

  const timeoutIDRef = useRef<NodeJS.Timeout>();

  const fetchProgramNameAndAsset = async (customerNumber: string) => {
    setFields((f) => ({ ...f, asset: '' }));
    setAvailableAssets(initAvailableAssets);

    if (!customerNumber) {
      return setFields((f) => ({
        ...f,
        programName: '',
        releaseCreditAmount: isTcspMode ? tcspDefaultReleaseAmount : '',
      }));
    }
    const programNameRes = await api.customerProgram({
      customerNumber,
    });

    if (!programNameRes) {
      setFields((f) => ({
        ...f,
        programName: '',
        releaseCreditAmount: isTcspMode ? tcspDefaultReleaseAmount : '',
      }));
      return alerting('warning', tc('customer_number_not_found'));
    }

    setFields((f) => ({ ...f, programName: programNameRes }));

    if (!isTcspMode) {
      return;
    }

    const assetBalancesRes = await api.assetBalances({
      customerNumber,
      includeReward: false,
    });

    if (!assetBalancesRes) {
      return;
    }

    assetBalancesRes.forEach(({ decimals, frozenBalance, balance, currency }) => {
      const isBalanceGreaterThanZero = compareBigNumbers('greater', balance, frozenBalance);
      if (!isBalanceGreaterThanZero) {
        return;
      }

      const rawAvailableAsset = amountDivideDecimals(
        calculateBigNumbers('minus', balance, frozenBalance)?.toFixed() || '',
        decimals
      );

      const roundDownedAvailableBalance = roundDownTruncate(rawAvailableAsset, ASSET_BALANCE_DP);

      if (Number(roundDownedAvailableBalance) === 0) {
        return;
      }

      setAvailableAssets((prev) => ({
        enum: { ...prev.enum, [currency]: currency },
        assetBalanceObj: {
          ...prev.assetBalanceObj,
          [currency]: {
            balance: `${roundDownedAvailableBalance} ${currency}`,
            decimals,
          },
        },
      }));
    });
  };

  const handleAssetOpen = () =>
    isEmptyObject(availableAssets.enum) &&
    fields.customerNumber &&
    !isFetching &&
    alerting('warning', t('no_available_asset'));

  const handleAssetChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    setFields((f) => ({ ...f, asset: e.target.value /* assetCollateralAmount: '' */ }));
  };

  const handleCustomerNumberChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    clearTimeout(timeoutIDRef.current);
    setIsFetching(false);

    onChange('customerNumber')(e);

    setIsFetching(true);
    const timeoutID = setTimeout(async () => {
      await fetchProgramNameAndAsset(e.target.value);
      setIsFetching(false);
    }, 300);
    timeoutIDRef.current = timeoutID;
  };

  useEffect(() => {
    if (fields.adjustmentMode !== String(EnumAdjustmentMode.TCSP)) {
      return;
    }
    fetchProgramNameAndAsset(fields.customerNumber);
  }, [fields.adjustmentMode]);

  useEffect(() => {
    if (!isTcspMode) {
      return;
    }

    if (!exchangeRate) {
      return setFields((f) => ({
        ...f,
        assetCollateralAmount: '',
        releaseCreditAmount: tcspDefaultReleaseAmount,
      }));
    }

    setTcspReleaseAmount();
  }, [exchangeRate]);

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.adjustmentMode,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.adjustmentMode) })}
        value={fields.adjustmentMode}
        onChange={handleAdjustmentModeChange}
        enumData={EnumAdjustmentMode}
        clearSelect={() => {}}
      />,
    ],
    [
      TK.customerNumber,
      <Box sx={{ position: 'relative', '>div': { width: '100%' } }}>
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={handleCustomerNumberChange}
        />
        {fields.programName && (
          <FormHelperText
            children={t('program_name_helper_text', { programName: fields.programName })}
            sx={{ position: 'absolute', bottom: -15, left: 0, zIndex: 99 }}
          />
        )}
      </Box>,
    ],
    [
      TK.asset,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.asset) })}
        value={fields.asset}
        onOpen={handleAssetOpen}
        onChange={handleAssetChange}
        enumData={availableAssets.enum || {}}
        clearSelect={() => {}}
        nameFn={(name) => name}
        disabled={!fields.programName}
      />,
    ],
    [
      TK.assetCollateralAmount,
      <Box sx={{ position: 'relative', '>div': { width: '100%' } }}>
        <MpTextFieldNumberOnly
          label={tc('phInputField', { fieldName: t(TK.assetCollateralAmount) })}
          value={fields.assetCollateralAmount}
          onChange={handleAssetCollateralAmountChange}
          disabled={!exchangeRate}
          decimalCount={2}
        />
        {availableAssets.assetBalanceObj[fields.asset] && (
          <FormHelperText
            children={t('available_balance_helper_text', {
              availableBalance: availableAssets.assetBalanceObj[fields.asset].balance,
            })}
            sx={{ position: 'absolute', bottom: -15, left: 0, zIndex: 99 }}
          />
        )}
        {exchangeRate && (
          <FormHelperText
            children={t('exchange_rate_helper_text', { exchangeRate })}
            sx={{ position: 'absolute', bottom: -26, left: 0, zIndex: 99 }}
          />
        )}
      </Box>,
    ],
    [
      isTcspMode ? TK.estimatedReleaseCreditAmount : TK.releaseCreditAmount,
      <MpTextFieldWithEndAdornment
        mode={'number'}
        label={tc('phInputField', { fieldName: t(TK.releaseCreditAmount) })}
        value={fields.releaseCreditAmount}
        onChange={onChange('releaseCreditAmount')}
        endAdornmentNode={creditCurrency}
        noCheckMaxNumber
        disabled={isTcspMode || !fields.adjustmentMode || !fields.programName}
        decimalCount={2}
        allowSignedNumber="onlyNegative"
      />,
    ],
  ];

  const filteredLabelElePairArr = labelElePairArr.filter(([fieldName, _ele]) => {
    const isAssetField = fieldName === TK.asset;

    const isAssetColltateralField = fieldName === TK.assetCollateralAmount;

    const isTcspFields = isAssetField || isAssetColltateralField;

    return isTcspMode || !isTcspFields;
  });

  const dialogContent = (
    <Box sx={{ marginBottom: '15px' }}>
      <GridBox labelElePairArr={filteredLabelElePairArr} columnCount={1} />
    </Box>
  );

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setAvailableAssets(initAvailableAssets);
    setFields(initFields);
  };

  const handleSubmit = async () => {
    const assetCustomerMessageNoAvailableAsset =
      isEmptyObject(availableAssets.enum) && fields.customerNumber
        ? t('no_available_asset')
        : undefined;

    const assetCustomMessage = !fields.programName
      ? tc('customer_number_not_found')
      : assetCustomerMessageNoAvailableAsset;

    const releaseCreditAmountCustomMessage = !fields.programName
      ? tc('customer_number_not_found')
      : undefined;

    const invalidMessage = validateGridBoxInput(filteredLabelElePairArr, [
      {
        fieldName: TK.asset,
        customMessage: assetCustomMessage,
      },
      {
        fieldName: TK.assetCollateralAmount,
        notAllowZeroMode: 'below',
        isNumberOnlyField: true,
      },
      {
        fieldName: isTcspMode ? TK.estimatedReleaseCreditAmount : TK.releaseCreditAmount,
        notAllowZeroMode: 'exact',
        customMessage: releaseCreditAmountCustomMessage,
        isNumberOnlyField: true,
      },
    ]);
    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const availableBalanceWithoutAsset = availableBalance?.balance.split(' ')[0];

    const isAssetAmountLargerThanAvailableBalance =
      availableBalanceWithoutAsset &&
      BigNumber(fields.assetCollateralAmount).isGreaterThan(
        BigNumber(availableBalanceWithoutAsset)
      );

    if (isAssetAmountLargerThanAvailableBalance) {
      return alerting('warning', t('available_balance_checking'));
    }

    const {
      customerNumber,
      adjustmentMode,
      asset: assetCurrency,
      assetCollateralAmount: rawAssetCollateralAmount,
      releaseCreditAmount: creditReleasedAmount,
    } = fields;

    const assetCurrencyDecimals = Number(getAssetDetailByAssetName(assetCurrency)?.decimals);

    const assetCollateralAmount = bigNumStrMulitpleDecimals(
      rawAssetCollateralAmount,
      assetCurrencyDecimals
    );

    const mode = Number(adjustmentMode);

    const res = await api.createRequest(
      isTcspMode
        ? {
            customerNumber,
            mode,
            assetCollateralAmount,
            assetCurrencyDecimals,
            assetCurrency,
          }
        : {
            customerNumber,
            mode,
            creditReleasedAmount,
            creditCurrency,
          }
    );
    if (!res) {
      return;
    }
    await handleCloseDialog();
    zusParams.refetch();
    alerting('success', tc('create_request_success'));
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('create_request'),
    self: {
      open: zusDialog.match('createRequestDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,
    size: 'sm',
    dialogActionStyling: { padding: '8px' },
  };

  return (
    <>
      <DialogInOne {...dialogConfig} />
    </>
  );
}
