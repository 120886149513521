import { EnumRewardBatchStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { SingleSelection } from '../../../../../components/Selection';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const RewardBatchStatusSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t, te } = useTranslation('rewardBatch');

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('status') })}
      value={value}
      clearSelect={onClear}
      onChange={onChange}
      enumData={EnumRewardBatchStatus}
    />
  );
};

export default RewardBatchStatusSelection;
