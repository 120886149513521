import { EptCustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import {
  IdetailsApiRes,
  IeditApiParams,
} from '../../views/CustomerManagement/CustomerApplication/config';
import {
  IapplicationDetail,
  IoperationBtnsStatus,
} from '../../views/CustomerManagement/CustomerList/config';
import axios from '../axiosInstance';

const endPoint = {
  ...EptCustomerManagement.customer,
};

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

async function getAllowEdit(params: { customerId: string }, config?: any) {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, IoperationBtnsStatus>(endPoint.allowEditApplicationDetail, input);
}
async function getApplicationDetail(params: { customerId: string }, config?: any) {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, IapplicationDetail>(endPoint.applicationDetail, input);
}

async function postUpdateInfo(
  params: { applicationNumber: string } & IeditApiParams,
  config?: any
) {
  const input = {
    ...params,
    questionVersion: 1,
    ...config,
  };

  return await axios.post(endPoint.editApplicationDetail, input);
}
async function postCreateCustomer(
  params: { email: string; phoneCountryCode: string; phoneNumber: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.createCustomer, input);
}
async function postCreateCustomerApplication(
  params: {
    firstName: string;
    lastName: string;
    programName: string;
    customerId: string;
    idIssuedBy: string;
    idType: number;
    idNumber: string;
    dateOfBirth: string;
    referralCode: string;
    answers: Partial<IdetailsApiRes>;
  },
  config?: any
) {
  const input = {
    ...params,
    questionVersion: 1,
    ...config,
  };

  return await axios.post(endPoint.createCustomerApplication, input);
}

async function postUpdateCustomerEmail(
  params: { customerId: string; email: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.updateEmailAddress, input);
}

async function postUpdateCustomerPhone(
  params: { customerId: string; phoneCountryCode: string; phoneNumber: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.updatePhoneNo, input);
}

export default {
  getAll,
  getExport,
  getAllowEdit,
  getApplicationDetail,
  postUpdateInfo,
  postCreateCustomer,
  postCreateCustomerApplication,
  postUpdateCustomerEmail,
  postUpdateCustomerPhone,
};
