import { SetStateAction } from 'react';

import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { useTranslation } from '../../../../../../hooks';
import ExchangeRateConfigAdjustmentAmountTextField from '../../../../../common/filterTable/components/Filters/ExchangeRateConfigAdjustmentAmountTextField';
import ExchangeRateConfigAdjustmentTypeSingleSelection from '../../../../../common/filterTable/components/Filters/ExchangeRateConfigAdjustmentTypeSingleSelection';
import ExchangeRateConfigToCurrencySingleSelection from '../../../../../common/filterTable/components/Filters/ExchangeRateConfigToCurrencySingleSelection';
import ExchangeRateFromCurrencySingleSelection from '../../../../../common/filterTable/components/Filters/ExchangeRateFromCurrencySingleSelection';
import ProgramNameSingleSelection from '../../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';

import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../common/dialogs/layout/contentLayout';
import { CreateRecordRowKeys, ICreateRecordFields } from './types';

interface IProps {
  createRecordFields: ICreateRecordFields;
  setCreateRecordFields: React.Dispatch<SetStateAction<ICreateRecordFields>>;
  exchangeRateOfCurrentDate: any;
  adjustedExchangeRate: any;
}

const Content = (props: IProps) => {
  const {
    createRecordFields,
    setCreateRecordFields,
    exchangeRateOfCurrentDate,
    adjustedExchangeRate,
  } = props;

  const { t } = useTranslation('exchangeRateConfiguration');

  const adjustedExchangeRateDisplay = adjustedExchangeRate ?? '-';

  const isAdjustmentAmountAllowSignedNumber =
    Number(createRecordFields[CreateRecordRowKeys.AdjustmentType]) ===
    EnumMerchantExchangeRateAdjustType.AbsoluteValue;

  const updateField = (fieldName: string, value: any) => {
    setCreateRecordFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  return (
    <Container>
      <Row>
        <RowHeader>{t(CreateRecordRowKeys.ProgramName)}</RowHeader>
        <RowContent>
          <ProgramNameSingleSelection
            value={createRecordFields[CreateRecordRowKeys.ProgramName]}
            onChange={(e) => {
              setCreateRecordFields((fields) => {
                return {
                  ...fields,
                  [CreateRecordRowKeys.ProgramName]: e.target.value,
                  [CreateRecordRowKeys.From]: '',
                };
              });
            }}
            onClear={() => {
              updateField(CreateRecordRowKeys.ProgramName, '');
            }}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t(CreateRecordRowKeys.From)}</RowHeader>
        <RowContent>
          <ExchangeRateFromCurrencySingleSelection
            programName={createRecordFields[CreateRecordRowKeys.ProgramName]}
            value={createRecordFields[CreateRecordRowKeys.From]}
            onChange={(e) => {
              updateField(CreateRecordRowKeys.From, e.target.value);
            }}
            onClear={() => {
              updateField(CreateRecordRowKeys.From, '');
            }}
            isDisabled={createRecordFields[CreateRecordRowKeys.ProgramName] === ''}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t(CreateRecordRowKeys.To)}</RowHeader>
        <RowContent>
          <ExchangeRateConfigToCurrencySingleSelection
            value={createRecordFields[CreateRecordRowKeys.To]}
            onChange={(e) => {
              updateField(CreateRecordRowKeys.To, e.target.value);
            }}
            onClear={() => {
              updateField(CreateRecordRowKeys.To, '');
            }}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t(CreateRecordRowKeys.ExchangeRateOfCurrentDate)}</RowHeader>
        <RowContent>{exchangeRateOfCurrentDate}</RowContent>
      </Row>

      <Row>
        <RowHeader>{t(CreateRecordRowKeys.AdjustmentType)}</RowHeader>
        <RowContent>
          <ExchangeRateConfigAdjustmentTypeSingleSelection
            value={createRecordFields[CreateRecordRowKeys.AdjustmentType]}
            onChange={(e) => {
              setCreateRecordFields((fields) => {
                return {
                  ...fields,
                  [CreateRecordRowKeys.AdjustmentType]: e.target.value,
                  [CreateRecordRowKeys.AdjustmentAmount]: '',
                };
              });
            }}
            onClear={() => {
              updateField(CreateRecordRowKeys.AdjustmentType, '');
            }}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t(CreateRecordRowKeys.AdjustmentAmount)}</RowHeader>
        <RowContent>
          <ExchangeRateConfigAdjustmentAmountTextField
            value={createRecordFields[CreateRecordRowKeys.AdjustmentAmount]}
            onChange={(e) => {
              updateField(CreateRecordRowKeys.AdjustmentAmount, e.target.value);
            }}
            isAllowSignedNumber={isAdjustmentAmountAllowSignedNumber}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t(CreateRecordRowKeys.ExchangeRateAfterAdjustment)}</RowHeader>
        <RowContent>{adjustedExchangeRateDisplay}</RowContent>
      </Row>
    </Container>
  );
};

export default Content;
