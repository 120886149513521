export enum TableColumnKey {
  OrderId = 'orderId',
  ProgramName = 'programName',
  TransactionType = 'transactionType',
  AdjustmentMode = 'adjustmentMode',
  CustomerNumber = 'customerNumber',
  Currency = 'currency',
  Amount = 'amount',
  SendingAddress = 'sendingAddress',
  ReceivingAddress = 'receivingAddress',
  CurrencyOfCreditAmount = 'currencyOfCreditAmount',
  CreditAmount = 'creditAmount',
  ExchangeRate = 'exchangeRate',
  TransactionHash = 'transactionHash',
  CreatedBy = 'createdBy',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',
}
