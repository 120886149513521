import { COLOR_THEME } from '../../style/colorTheme';
import { Box } from '../MuiGenerals';
import DivideLine from './DivideLine';

function FilterTable(props: { filter: JSX.Element; table: JSX.Element }) {
  const { filter, table } = props;
  return (
    <Box
      sx={{
        backgroundColor: COLOR_THEME.Layout.FilterTable.Background,
        paddingTop: '2rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}
    >
      {filter}
      <DivideLine />
      {table}
    </Box>
  );
}

export default FilterTable;
