import { useEffect, useMemo, useState } from 'react';

import api from '../api';
import { roundDownTruncate } from '../utils';
import { EXCHANGE_RATE_DP } from '../utils/config';

export default function useExchangeRate(params: {
  programName: string;
  fromCurrency: string;
  toCurrency: string;
}) {
  const {
    // customerNumber,
    programName,
    fromCurrency,
    toCurrency,
  } = params;
  const [exchangeRateArr, setExchangeRateArr] = useState<
    {
      merchantId: number;
      programName: string;
      homeCurrency: string;
      tradeDate: string;
      fromCurrency: string;
      toCurrency: string;
      exchangeRate: string;
      adjustType: number;
      adjustAmount: string;
      adjustedExchangeRate: string;
    }[]
  >();
  // const [prevCustomerNumber, setPrevCustomerNumber] = useState('');

  // useEffect(() => {
  //   debounce(() => {
  //     setPrevCustomerNumber(customerNumber);
  //   }, 1_000)();
  // }, [customerNumber]);

  useEffect(() => {
    if (
      // !customerNumber ||
      !programName ||
      !fromCurrency ||
      !toCurrency
      // || prevCustomerNumber !== customerNumber
    ) {
      return setExchangeRateArr(undefined);
    }
    const fetch = async () => {
      const res = await api.MerchantPortal.query.getExchangeRate(params);
      if (!res) {
        return setExchangeRateArr(undefined);
      }
      setExchangeRateArr(res);
    };
    fetch();
  }, [
    programName,
    fromCurrency,
    toCurrency,
    // , prevCustomerNumber, customerNumber
  ]);

  const exchangeRate = useMemo(
    () =>
      exchangeRateArr &&
      roundDownTruncate(exchangeRateArr[0]?.adjustedExchangeRate || 1, EXCHANGE_RATE_DP),
    [exchangeRateArr]
  );

  return { exchangeRate };
}
