import { useState } from 'react';

import { EnumCustomerAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../../../api';
import { LoadingDialog } from '../../../../../components';
import DialogInOne, { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../hooks';
import { useZusTranslatePrefixStore } from '../../../../../zustand/store';
import { useTcspCreateRequestZusStore } from '../../helpers/useTcspCreateRequestZusStore';
import { TableRow } from '../../types/TableRow';
import { TCSP_DEFAULT_SIGNATURE } from './constants/tcspDefaultSignature';
import Content from './Content';
import useAnswerValidation from './helpers/useAnswerValidation';

type CreateApplicationDialogProps = {
  isOpen: boolean;
  selectedRow: TableRow | undefined;
  closeDialog: () => void;
  refreshTable: () => void;
};

export default function CreateApplicationDialog({
  isOpen,
  selectedRow,
  closeDialog,
  refreshTable,
}: CreateApplicationDialogProps) {
  const {
    answer,
    setAnswer,
    customerNumber,
    setCustomerNumber,
    isCustomerNumberValid,
    setIsCustomerNumberValid,
    sourceOfWealth,
    setSourceOfWealth,
    sourceOfFund,
    setSourceOfFund,
    reset,
  } = useTcspCreateRequestZusStore((state) => state);

  const [isCreateApplicationLoading, setIsCreateApplicationLoading] = useState<boolean>(false);
  const [isCheckCustomerNumberLoading, setIsCheckCustomerNumberLoading] = useState<boolean>(false);

  const { alerting } = useAlerting();
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t, tc, translate } = useTranslation(translatePrefix);
  const { validate } = useAnswerValidation({ answer, customerNumber });

  const isLoading = isCreateApplicationLoading || isCheckCustomerNumberLoading;

  const handleCloseDialog = () => {
    reset();
    closeDialog();
  };

  const validateCustomerNumberOnConfirm = async () => {
    // Customer Number is required
    const isCustomerNumberEmpty = !customerNumber || customerNumber === '';

    if (isCustomerNumberEmpty) {
      return alerting(
        'error',
        translate('validation.inputRequired', {
          fieldName: t('customerNumber'),
        })
      );
    }

    // API call to check if application existed
    setIsCheckCustomerNumberLoading(true);
    const res = await APIs.tcsp.tcspAccountApplication.checkCustomerNumber({ customerNumber });
    setIsCheckCustomerNumberLoading(false);

    if (!res) return;

    // customer account is disabled or not existed
    if (res.customerAccountStatus !== EnumCustomerAccountStatus.Enable)
      return alerting('error', t('customerAccountDisabled'));

    // tcsp application already existed
    if (res.tcspAccountStatus !== null) return alerting('error', t('existedTcspApplicationError'));

    // proceed next step
    return setIsCustomerNumberValid(true);
  };

  const submitTcspApplicationForm = async () => {
    const { allErrors } = validate();

    if (allErrors && allErrors.length > 0) {
      return alerting('error', allErrors[0]);
    }

    setIsCreateApplicationLoading(true);

    const payload = {
      customerNumber,
      answers: {
        ...answer,
        signature: TCSP_DEFAULT_SIGNATURE,
        signatureDate: new Date().toISOString().split('T')[0],
      },
    };

    const res = await APIs.tcsp.tcspAccountApplication.createApplication(payload);

    setIsCreateApplicationLoading(false);

    if (!res) return;

    alerting('success', tc('create_request_success'));
    handleCloseDialog();
    refreshTable();
  };

  const handleConfirm = async () => {
    // First Step: Validate customer number. It checks:
    // - If TCSP application already existed
    // - If customer number is existed and enabled
    if (!isCustomerNumberValid) {
      return validateCustomerNumberOnConfirm();
    }

    // Second Step: Validate and submit TCSP form
    submitTcspApplicationForm();
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('create_request'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: (
      <Content
        answer={answer}
        setAnswer={setAnswer}
        sourceOfWealth={sourceOfWealth}
        setSourceOfWealth={setSourceOfWealth}
        sourceOfFund={sourceOfFund}
        setSourceOfFund={setSourceOfFund}
        customerNumber={customerNumber}
        setCustomerNumber={setCustomerNumber}
        isCustomerNumberValid={isCustomerNumberValid}
      />
    ),
    onConfirm: handleConfirm,
    confirmButtonText: isCustomerNumberValid ? tc('confirm') : tc('next'),
    onCancel: handleCloseDialog,
    size: 'lg',
  };

  return (
    <>
      <LoadingDialog forceOpen={isLoading} />
      <DialogInOne {...dialogConfig} />
    </>
  );
}
