import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import useAssets from '../../../../../hooks/useAssets/useAssets';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
  programName: string | string[]
  isDisabled?: boolean;
}

const ExchangeRateFromCurrencySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear, programName, isDisabled } = props;
  const { t, tc } = useTranslation('exchangeRateConfiguration');
  const { CurrencyEnum } = useAssets({ programName });

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('from') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={CurrencyEnum || {}}
      nameFn={(item) => item}
      disabled={isDisabled}
    />
  );
};

export default ExchangeRateFromCurrencySingleSelection;
