import cardBlockRequest from './cardBlockRequest';
import customerAccount from './customerAccount';
import customerApplication from './customerApplication';
import customerList from './customerList';
import editCustomerAccountRequest from './editCustomerAccountRequest';

export default {
  customerAccount,
  customerList,
  customerApplication,
  cardBlockRequest,
  editCustomerAccountRequest,
};
