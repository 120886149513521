import { DialogInOne } from '../../../components';
import DialogCloseBtn from '../../../components/Button/DialogCloseBtn';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import ApprovalProgressDialogContent from '../../../features/approval/components/ApprovalProgressDialogContent';
import { useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import { translateKeyObj as TK, translatePrefix } from './config';

export default function DialogApprovalProgress() {
  const zusDialog = useZusDialogStore();

  const { t } = useTranslation(translatePrefix);

  const { customer_application_approvals = [] } = zusDialog.meta;

  const dialogContent = (
    <ApprovalProgressDialogContent approveHistory={customer_application_approvals} />
  );

  const handleCloseDialog = () => zusDialog.close();

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.approvalProgress),
    self: {
      open: zusDialog.match('approvalProgressDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: () => {},
    onCancel: () => {},

    isConfirmHidden: true,
    isCancelHidden: true,
    actionButtons: <DialogCloseBtn handleClose={handleCloseDialog} />,

    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
