export enum TableColumnKey {
  programName = 'programName',
  orderId = 'orderId',
  customerNumber = 'customerNumber',
  transactionType = 'transactionType',
  repaymentCurrency = 'repaymentCurrency',
  repaymentAmount = 'repaymentAmount',
  repaymentRate = 'repaymentRate',
  rewardAmount = 'rewardAmount',
  postBalance = 'postBalance',
  creationTime = 'creationTime',
  lastModifiedTime = 'lastModifiedTime',
}
