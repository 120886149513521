import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { useTranslation } from '../../../../hooks';
import useValidation from '../../../../hooks/useValidation';
import { ValidationConfigProps, ValidationType } from '../../../../hooks/useValidation/types';
import { largerThanZeroValidator } from '../../../../utils/validators/largerThanZeroValidator';
import { requiredValidator } from '../../../../utils/validators/requiredValidator';
import {
  CreateRecordRowKeys,
  ICreateRecordFields,
} from '../components/Dialog/CreateRecordDialog/types';

interface IProps {
  fields: ICreateRecordFields;
}

const useExchangeRateConfigCreateRecordValidation = (props: IProps) => {
  const { fields } = props;

  const { t, translate } = useTranslation('exchangeRateConfiguration');

  const isEnableAdjustmentAmountLargerThanZeroChecking =
    Number(fields.adjustmentType) === EnumMerchantExchangeRateAdjustType.OverwriteValue ||
    Number(fields.adjustmentType) === EnumMerchantExchangeRateAdjustType.Percentage;

  const validationConfig: ValidationConfigProps = [
    {
      key: 'programName',
      value: fields[CreateRecordRowKeys.ProgramName],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('programName'),
          })
        ),
      ],
    },
    {
      key: 'from',
      value: fields[CreateRecordRowKeys.From],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('from'),
          })
        ),
      ],
    },
    {
      key: 'to',
      value: fields[CreateRecordRowKeys.To],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('to'),
          })
        ),
      ],
    },
    {
      key: 'adjustmentType',
      value: fields.adjustmentType,
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('adjustmentType'),
          })
        ),
      ],
    },
    {
      key: 'adjustmentAmount',
      value: fields.adjustmentAmount,
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('adjustmentAmount'),
          })
        ),
        largerThanZeroValidator(
          translate('validation.shouldBeLargerThanZero', {
            fieldName: t('adjustmentAmount'),
          }),
          isEnableAdjustmentAmountLargerThanZeroChecking
        ),
      ],
    },
  ];

  const validationTypeSortingKeys = [ValidationType.Required, ValidationType.LargerThanZero];

  return useValidation(validationConfig, validationTypeSortingKeys);
};

export default useExchangeRateConfigCreateRecordValidation;
