import { subDays } from 'date-fns';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../../../api';
import { ApiResult } from '../../../../api/types';
import { useZusDialogStore } from '../../../../zustand/store';
import { defaultListParams } from '../../../common/filterTable/constants/defaultListParams';
import {
  CreateRecordRowKeys,
  ICreateRecordFields,
} from '../components/Dialog/CreateRecordDialog/types';
import { GetBaseExchangeRateListRes } from '../types/GetBaseExchangeRateListRes';

interface IProps {
  createRecordFields: ICreateRecordFields;
}

const useBaseExchangeRateQuery = ({ createRecordFields }: IProps) => {
  const [exchangeRateList, setList] = useState<ApiResult<GetBaseExchangeRateListRes>>({
    rows: [],
    count: 0,
  });

  const zusDialog = useZusDialogStore();

  const getExchangeRateOfCurrentDate = () => {
    if (
      !exchangeRateList ||
      exchangeRateList.rows.length === 0 ||
      !createRecordFields[CreateRecordRowKeys.From]
    )
      return '-';

    return exchangeRateList.rows[0].exchangeRate;
  };

  const handleSettled = () => zusDialog.closeExtra();

  const handleSuccess = (data: ApiResult<GetBaseExchangeRateListRes>) => {
    if (!data) return;

    setList(data);
  };

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const fromCurrency = createRecordFields[CreateRecordRowKeys.From];
    const toCurrency = createRecordFields[CreateRecordRowKeys.To];

    if (!fromCurrency || !toCurrency) return null;

    return APIs.CobrandManagement.exchangeRate.getAll({
      ...defaultListParams,
      fromCurrency,
      toCurrency,
      tradeDate: new Date().toISOString().split('T')[0],
    });
  };

  useQuery(
    [
      'getBaseExchangeRate',
      createRecordFields[CreateRecordRowKeys.From],
      createRecordFields[CreateRecordRowKeys.To],
    ],
    handleQuery,
    {
      onSettled: handleSettled,
      onSuccess: handleSuccess,
    }
  );

  const exchangeRateOfCurrentDate = getExchangeRateOfCurrentDate();

  return { exchangeRateOfCurrentDate };
};

export default useBaseExchangeRateQuery;
