import { ReactNode } from 'react';

import { Box } from '../../../../components/MuiGenerals';
import { customSx } from '../../../../utils/styling';

const FilterTableLayoutContainer = ({
  children,
  isCollapse,
}: {
  children: ReactNode;
  isCollapse?: boolean;
}) => {
  return (
    <Box
      sx={{
        ...customSx.layoutBox,
        paddingTop: isCollapse ? '12px' : '2rem',
      }}
    >
      {children}
    </Box>
  );
};

export default FilterTableLayoutContainer;
