import { DialogInOne } from '../../../../components';
import { IdialogInOneProps } from '../../../../components/DialogInOne';
import { GridBox } from '../../../../components/Layout';
import { useTranslation } from '../../../../hooks';
import { useZusDialogStore } from '../../../../zustand/store';
import {
  IdetailApiRes,
  translateKeyObj as TK,
  translatePrefix
} from './config';
import useDetailDialogContent from './useDetailDialogContent';

export default function DialogDetails() {
  const handleClose = () => zusDialog.close();

  const { t, tc } = useTranslation(translatePrefix);

  const zusDialog = useZusDialogStore();
  const { details } = (zusDialog.meta || {}) as { details: IdetailApiRes | undefined };

  const { labelElePairArr } = useDetailDialogContent(details);

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.details),
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: handleClose,
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm: () => {},
    isConfirmHidden: true,
    onCancel: handleClose,
  };

  if (!details) <></>;

  return <DialogInOne {...dialogConfig} />;
}
