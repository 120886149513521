import { styled } from '@mui/material';

import { useTranslation } from '../../../hooks';
import { Button, DialogActions } from '../../MuiGenerals';
import { CSSProperties } from 'react';

const StyledDialogActions = styled(DialogActions)(
  () => `
    padding: 6px 0;
    boxShadow: '0px -2px 8px 0px #00000026';
  `
);

interface ActionSectionProps {
  actionButtons?: React.ReactNode;
  isConfirmHidden?: boolean;
  isCancelHidden?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  dialogActionStyling?: CSSProperties;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const ActionSection = (props: ActionSectionProps) => {
  const {
    actionButtons,
    isConfirmHidden,
    isCancelHidden,
    onConfirm,
    onCancel,
    dialogActionStyling,
    cancelButtonText,
    confirmButtonText,
  } = props;

  const { tc } = useTranslation();

  const cancelBtnText = cancelButtonText || tc('cancel');
  const confirmBtnText = confirmButtonText || tc('confirm');

  return (
    <StyledDialogActions sx={dialogActionStyling}>
      {actionButtons}
      {!isConfirmHidden && (
        <Button
          className={'inheritColor'}
          color={'primary'}
          variant={'contained'}
          children={confirmBtnText}
          onClick={onConfirm}
        />
      )}
      {!isCancelHidden && (
        <div style={{ padding: '0 12px' }}>
          <Button
            className={'inheritColor'}
            color={'secondary'}
            variant={'contained'}
            // children={tc('cancel')}
            children={cancelBtnText}
            onClick={onCancel}
          />
        </div>
      )}
    </StyledDialogActions>
  );
};

export default ActionSection;
