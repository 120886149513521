import { EptCustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';

const { editCustomerAccountRequest: endPoint } = EptCustomerManagement;

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

async function getApprovalDetail(params: { id: string | number }, config?: any) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.approvalDetail, input);
}
async function getDetail(params: { id: string | number }, config?: any) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.detail, input);
}

async function postApprove(params: { id: string | number }, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.approve, input);
}
async function postReject(params: { id: string | number }, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.reject, input);
}

export default { getAll, getExport, getApprovalDetail, getDetail, postApprove, postReject };
