import { ApiResult } from '../../../../api/types';
import { getAdjustmentModeDisplayValue } from '../../../../helper/getAdjustmentModeDisplayValue';
import { getTcspTransactionTypeDisplayValue } from '../../../../helper/getTcspTransactionTypeDisplayValue';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const toCryptoDisplayAmount = displayAmountCurrying(row.decimals);
      const toCreditDisplayAmount = displayAmountCurrying(0, 2);

      const transactionType = getTcspTransactionTypeDisplayValue(row.type, te);
      const adjustmentMode = getAdjustmentModeDisplayValue(row.mode, te);

      const amount = toCryptoDisplayAmount(row.amount);
      const creditAmount = toCreditDisplayAmount(row.creditAmount);

      const creationTime = toDisplayTime(row.createdDate);
      const lastModifiedTime = toDisplayTime(row.lastModifiedDate);

      return {
        id: index,
        [TableColumnKey.OrderId]: row.orderId,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.TransactionType]: transactionType,
        [TableColumnKey.AdjustmentMode]: adjustmentMode,
        [TableColumnKey.CustomerNumber]: row.customerNumber,
        [TableColumnKey.Currency]: row.currency,
        [TableColumnKey.Amount]: amount,
        [TableColumnKey.SendingAddress]: row.fromAddress,
        [TableColumnKey.ReceivingAddress]: row.toAddress,
        [TableColumnKey.CurrencyOfCreditAmount]: row.creditCurrency,
        [TableColumnKey.CreditAmount]: creditAmount,
        [TableColumnKey.ExchangeRate]: row.exchangeRate,
        [TableColumnKey.TransactionHash]: row.txHash,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreationTime]: creationTime,
        [TableColumnKey.LastModifiedTime]: lastModifiedTime,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
