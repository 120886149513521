import { useTranslation } from '../../../../../hooks';
import Header from '../../../../approval/components/DetailView/Header';
import { TcspApplicationAnswer } from '../../types/GetListRes';
import DeclarationTermsAndConditions from './Section/DeclarationTermsAndConditions';
import LevelOfActivityAnticipated from './Section/LevelOfActivityAnticipated';
// import PersonalInfo1 from './Section/PersonalInfo1';
import PersonalInfo2 from './Section/PersonalInfo2';
import PersonalInfo3 from './Section/PersonalInfo3';

const TcspApplicationDetail = ({ data }: { data: TcspApplicationAnswer }) => {
  const { t } = useTranslation('tcspAccountApplication');

  if (!data) return <></>;

  return (
    <div style={{ marginTop: '24px' }}>
      {/* <Header title={t('personalInfoTitle')} />
      <PersonalInfo1 data={data} /> */}

      {/* Reason for setting up a trust account in Hong Kong / PPTA */}
      <Header title={t('personalInfoTitle', { index: '1' })} />
      <PersonalInfo2 data={data} />

      {/* Senior Public Figure Declaration */}
      <Header title={t('personalInfoTitle', { index: '2' })} />
      <PersonalInfo3 data={data} />

      <Header title={t('levelOfActivityAnticipatedHeader')} />
      <LevelOfActivityAnticipated data={data} />

      <Header title={t('declarationTermsAndConditions')} />
      <DeclarationTermsAndConditions data={data} />
    </div>
  );
};

export default TcspApplicationDetail;
