import { GridRenderCellParams } from '@mui/x-data-grid';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types/';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

interface IProps {
  renderOperationsCell: (params: GridRenderCellParams) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationsCell } = props;

  const { hasPermission } = usePermission();

  const shouldHideOperationColumn = !hasPermission(
    FeatureCode.CobrandManagement.ExchangeRateConfiguration.EditRecord
  );

  const columns = [
    useGenGridCol(TableColumnKey.operation, {
      renderCell: renderOperationsCell,
      hide: shouldHideOperationColumn,
    }),
    useGenGridCol(TableColumnKey.programName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.from, {}),
    useGenGridCol(TableColumnKey.to, {}),
    useGenGridCol(TableColumnKey.exchangeRateOfCurrentDate, {}),
    useGenGridCol(TableColumnKey.adjustmentType, {}),
    useGenGridCol(TableColumnKey.adjustmentAmount, {}),
    useGenGridCol(TableColumnKey.exchangeRateAfterAdjustment, {}),
    useGenGridCol(TableColumnKey.createdBy, {
      minWidth: COLUMN_WIDTH.CreatedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.createdDate, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedBy),
    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
