import { addHours } from 'date-fns';
import { ChangeEvent, useEffect, useState } from 'react';

import { SingleSelection } from '../../../components';
import TimePicker, { useDatePicker } from '../../../components/DatePicker';
import { Button, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import { convertDateOrDatetimeToDbFormat } from '../../../helper';
import { usePermission, useTranslation } from '../../../hooks';
import useAssets from '../../../hooks/useAssets/useAssets';
import { useZusDialogStore } from '../../../zustand/store';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogCreateRecord from './DialogCreateRecord';

const hoursOffSetFromGMT0 = new Date().getTimezoneOffset() / 60;

const currentDateGMT0 = addHours(new Date(), hoursOffSetFromGMT0);

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>({ ...initFields, tradeDate: currentDateGMT0 });

  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { AllAssetCurrenciesEnum } = useAssets();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const getParams: () => IsearchParam = () => {
    const { tradeDate: rawTradeDate, from: fromCurrency } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    // const tradeDateFrom = rawTradeDate
    //   ? convertDateOrDatetimeToDbFormat(subDays(rawTradeDate, 1), 'date')
    //   : '';
    // const tradeDateTo = rawTradeDate ? convertDateOrDatetimeToDbFormat(rawTradeDate, 'date') : '';
    const tradeDate = rawTradeDate ? convertDateOrDatetimeToDbFormat(rawTradeDate, 'date') : '';

    return {
      ...initZusParams,
      fromCurrency,
      // tradeDateFrom,
      // tradeDateTo,
      tradeDate,
      createdDateFrom,
      createdDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };

  useEffect(() => onSearch(), []);

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.tradeDate,
      filter: (
        <TimePicker
          label={tc('phSelection', { fieldName: t(TK.tradeDate) })}
          type={'date'}
          timeValue={fields.tradeDate}
          setTimeValue={(value: Date | null) =>
            setFields((fields) => ({ ...fields, tradeDate: value }))
          }
        />
      ),
    },
    {
      labelKey: TK.from,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.from) })}
          value={fields.from}
          onChange={onChange('from')}
          enumData={AllAssetCurrenciesEnum}
          clearSelect={() => setFields((f) => ({ ...f, from: '' }))}
          nameFn={(name) => name}
        />
      ),
    },
  ];

  const openCreateRecordDialog = () => zusDialog.open('createRecordDialog');

  return (
    <Container disableGutters maxWidth={false}>
      <>
        <DialogCreateRecord />
        <FilterSectionActionRow>
          {hasPermission(PermissionKey.CreateRecord) && (
            <Button variant="outlined" onClick={openCreateRecordDialog}>
              {t(TK.createRecord)}
            </Button>
          )}
          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>
      </>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
