import { EptTransaction } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import {
  IcreateClassicRequestParams,
  IcreateRedeemRequestParams
} from '../../views/Transaction/RepaymentSchedule/config';
import axios from '../axiosInstance';

const endPoint = {
  ...EptTransaction.repaymentSchedule,
};

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

async function postCreateClassicRequest(params: IcreateClassicRequestParams, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.createClassicRequest, input);
}
async function postCreateRedeemRequest(params: IcreateRedeemRequestParams, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.createRedeemRequest, input);
}
async function postApprove(
  params: { id: string; customerNumber?: string; remarks?: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.approve, input);
}
async function postReject(
  params: { id: string; customerNumber?: string; remarks?: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.reject, input);
}

export default {
  getAll,
  getExport,
  postCreateClassicRequest,
  postCreateRedeemRequest,
  postApprove,
  postReject,
};
