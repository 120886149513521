import { ChangeEvent, cloneElement, useState } from 'react';

import { EnumCustomerStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import { Box, Button, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import { MpTextField } from '../../../components/TextField';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import { usePermission, useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogCreateCustomer from './DialogCreateCustomer';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const getParams: () => IsearchParam = () => {
    const { customerStatus: status, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      ...rest,
      status,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    { labelKey: TK.creationTime, filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: TK.lastModifiedTime, filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: TK.customerId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerId) })}
          value={fields.customerId}
          onChange={onChange('customerId')}
        />
      ),
    },
    {
      labelKey: TK.emailAddress,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.emailAddress) })}
          value={fields.email}
          onChange={onChange('email')}
        />
      ),
    },
    {
      labelKey: TK.phoneNumber,
      filter: (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <MpTextField
            label={t(TK.phoneCountryCode)}
            value={fields.phoneCountryCode}
            onChange={onChange('phoneCountryCode')}
            sx={{ marginRight: '8px' }}
          />

          <MpTextField
            label={t(TK.phoneNumber)}
            value={fields.phoneNumber}
            onChange={onChange('phoneNumber')}
            sx={{ marginLeft: '8px' }}
          />
        </Box>
      ),
    },
    {
      labelKey: TK.customerStatus,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.customerStatus) })}
          value={fields.customerStatus}
          onChange={onChange('customerStatus')}
          enumData={EnumCustomerStatus}
          clearSelect={() => setFields((fields) => ({ ...fields, customerStatus: '' }))}
        />
      ),
    },
  ];

  const openCreateRequestDialog = () => {
    zusDialog.open('createRequestDialog');
  };

  return (
    <Container disableGutters maxWidth={false}>
      {/* {hasPermission(PermissionKey.CreateCustomer) && ( */}
      <>
        <DialogCreateCustomer />

        <FilterSectionActionRow>
          <Button variant="outlined" onClick={openCreateRequestDialog}>
            {t(TK.createCustomer)}
          </Button>
          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>
      </>
      {/* )} */}

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
