import React, { ReactNode } from 'react';

const Section = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        backgroundColor: '#ECEEF0',
        width: '100%',
        margin: '8px 0',
      }}
    >
      <div style={{ display: 'flex' }}>{children}</div>
    </div>
  );
};

export default Section;
