import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import { toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { te } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const createDate = toDisplayTime(row.creationTime);
      const lastModifiedTime = toDisplayTime(row.lastModifiedTime);

      return {
        id: index,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.customerNumber]: row.customerNumber,
        [TableColumnKey.balance]: `${row.balance} HKD`,
        [TableColumnKey.creationTime]: createDate,
        [TableColumnKey.lastModifiedTime]: lastModifiedTime,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
