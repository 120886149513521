import { SingleSelection } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const TotalVolumeOfAssetSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t } = useTranslation('tcspAccountApplication');

  const Enum = {
    [t('tcspTotalVolumeOfAsset.Below50000')]: '0',
    [t('tcspTotalVolumeOfAsset.50000To99999')]: '50000',
    [t('tcspTotalVolumeOfAsset.100000To199999')]: '100000',
    [t('tcspTotalVolumeOfAsset.200000To499999')]: '200000',
    [t('tcspTotalVolumeOfAsset.500000To999999')]: '500000',
    [t('tcspTotalVolumeOfAsset.1000000OrAbove')]: '1000000',
  };

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('totalVolumeOfAsset2') })}
      value={value}
      onChange={onChange}
      clearSelect={onClear}
      enumData={Enum}
      nameFn={(name) => name}
      isNoSorting
    />
  );
};

export default TotalVolumeOfAssetSingleSelection;
