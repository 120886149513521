import { SingleSelection } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const ExpectedTransactionAmountPerMonthSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, translate, t } = useTranslation('tcspAccountApplication');

  const Enum = {
    [translate('TcspExpectedTxAmountPerMonth.10000OrBelow')]: '0',
    [translate('TcspExpectedTxAmountPerMonth.10001To30000')]: '10001',
    [translate('TcspExpectedTxAmountPerMonth.30001To50000')]: '30001',
    [translate('TcspExpectedTxAmountPerMonth.50001To100000')]: '50001',
    [translate('TcspExpectedTxAmountPerMonth.100001To300000')]: '100001',
    [translate('TcspExpectedTxAmountPerMonth.300001To500000')]: '300001',
    [translate('TcspExpectedTxAmountPerMonth.500001OrAbove')]: '500001',
  };

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('expectedTransactionAmount') })}
      value={value}
      onChange={onChange}
      clearSelect={onClear}
      enumData={Enum}
      nameFn={(name) => name}
      isNoSorting
    />
  );
};

export default ExpectedTransactionAmountPerMonthSingleSelection;
