import { ErrorInfo, ValidatorResponse } from './../types';
import { ValidatorsResponse } from '../types';

export const executeValidators = (props: {
  key: string;
  value: any;
  validators: Array<(key: string, value: any) => ValidatorResponse>;
}): ValidatorsResponse => {
  const { key, value, validators } = props;
  const errors: ErrorInfo[] = [];

  for (let i = 0; i < validators.length; i++) {
    const validator = validators[i];
    const { isValid, errorInfo } = validator(key, value);

    if (!isValid) {
      errors.push(errorInfo);
    }
  }

  return { isValid: errors.length === 0, errorInfo: errors };
};
