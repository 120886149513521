import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.ProgramName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CustomerNumber, {
      minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.ClientId, {
      minWidth: COLUMN_WIDTH.ClientId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Amount),
    useGenGridCol(TableColumnKey.PostBalance),
    useGenGridCol(TableColumnKey.Decimals),
    useGenGridCol(TableColumnKey.LastLedgerTransactionId),
    useGenGridCol(TableColumnKey.FromAddress, {
      minWidth: COLUMN_WIDTH.TxAddress.MinWidth,
    }),
    useGenGridCol(TableColumnKey.ToAddress, {
      minWidth: COLUMN_WIDTH.TxAddress.MinWidth,
    }),
    useGenGridCol(TableColumnKey.TxHash, {
      minWidth: COLUMN_WIDTH.TxHash.MinWidth,
    }),
    useGenGridCol(TableColumnKey.ReferenceNumber, {
      minWidth: COLUMN_WIDTH.ReferenceNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.RiskLevel, {
      minWidth: COLUMN_WIDTH.RiskLevel.MinWidth,
    }),
    useGenGridCol(TableColumnKey.TransactionStatus, {
      minWidth: COLUMN_WIDTH.TransactionStatus.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CreatedBy, {
      minWidth: COLUMN_WIDTH.CreatedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CreatedDate, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedDate, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
