import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

const getExchangeRateConfiguration = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  // return await axios.get('exchangeRateConfig', input);
  return await axios.get(MerchantPortal.EptCobrandManagement.ProgramExchangeRate.getAll, input);
};

const exportTable = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptCobrandManagement.ProgramExchangeRate.export, input);
};

const editConfig = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(MerchantPortal.EptCobrandManagement.ProgramExchangeRate.edit, input);
};

const createConfig = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(MerchantPortal.EptCobrandManagement.ProgramExchangeRate.create, input);
};

export default { getExchangeRateConfiguration, exportTable, editConfig, createConfig };
