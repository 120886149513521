import React, { SetStateAction, useState } from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import APIs from '../../../../../../api';
import { useTranslation } from '../../../../../../hooks';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../common/dialogs/layout/contentLayout';
import CustomerLevelSingleSelection from '../../../../../common/filterTable/components/Filters/CustomerLevelSingleSelection';
import ProgramNameSingleSelection from '../../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import RewardRateTextField from '../../../../../common/filterTable/components/Filters/RewardRateTextField';
import { CreateRecordRowKeys, ICreateRecordFields } from './types';

interface IProps {
  createRecordFields: ICreateRecordFields;
  setCreateRecordFields: React.Dispatch<SetStateAction<ICreateRecordFields>>;
}

interface IHelperTextProps {
  isShow?: boolean;
  text: string;
}
const ProgramNameRewardCurrencyHelperText = (props: IHelperTextProps) => {
  const { isShow = false, text } = props;

  if (!isShow) return <></>;

  return (
    <div style={{ marginBottom: '12px' }}>
      <FormHelperText
        children={text}
        sx={{
          position: 'absolute',
          fontSize: '12px',
        }}
      />
    </div>
  );
};

const Content = (props: IProps) => {
  const { createRecordFields, setCreateRecordFields } = props;

  const [rewardCurrencyHelperText, setRewardCurrencyHelperText] = useState<string>('');

  const { t } = useTranslation('rewardRateConfiguration');

  const getSingleRewardCurrencyHelperText = (currency: string) => {
    return t('rewardCurrencyHelperText', { currency });
  };

  const handleProgramNameChange = async (e: any) => {
    const programNameInput = e.target.value;

    setCreateRecordFields({
      ...createRecordFields,
      programName: programNameInput,
      customerLevel: '',
    });

    // call reward currency api
    const res = await APIs.MerchantPortal.query.getRewardCurrencyByProgramName({
      programName: programNameInput,
    });

    if (!res || res.length === 0) {
      return setRewardCurrencyHelperText('');
    }

    // update helper text
    const helperText = getSingleRewardCurrencyHelperText(res[0].rewardCurrency);
    setRewardCurrencyHelperText(helperText);
  };

  return (
    <Container>
      <Row>
        <RowHeader>{t('programName')}</RowHeader>
        <RowContent>
          <div style={{ position: 'relative' }}>
            <ProgramNameSingleSelection
              value={createRecordFields.programName}
              onChange={handleProgramNameChange}
              onClear={() => {
                setRewardCurrencyHelperText('');
                setCreateRecordFields({
                  ...createRecordFields,
                  programName: '',
                  customerLevel: '',
                });
              }}
            />
            <ProgramNameRewardCurrencyHelperText
              isShow={!!rewardCurrencyHelperText}
              text={rewardCurrencyHelperText}
            />
          </div>
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t('customerLevel')}</RowHeader>
        <RowContent>
          <CustomerLevelSingleSelection
            value={createRecordFields.customerLevel}
            programName={createRecordFields.programName}
            onChange={(e) => {
              setCreateRecordFields({
                ...createRecordFields,
                customerLevel: e.target.value,
              });
            }}
            onClear={() => {
              setCreateRecordFields({
                ...createRecordFields,
                customerLevel: '',
              });
            }}
          />
        </RowContent>
      </Row>

      <Row>
        <RowHeader>{t('rewardRate')}</RowHeader>
        <RowContent>
          <RewardRateTextField
            value={createRecordFields.rewardRate}
            onChange={(e) => {
              setCreateRecordFields({
                ...createRecordFields,
                rewardRate: e.target.value,
              });
            }}
          />
        </RowContent>
      </Row>
    </Container>
  );
};

export default Content;
