import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CustomPagination, NoRowsOverlay } from '../../../../../components/Layout';
import { dataGridDefaults } from '../../../../../utils/constant';

interface TableProps {
  rows: any[];
  columns: GridColDef[];
  page: number;
  onPageChange: (page: number) => void;
  rowCount: number;
}

const Table = (props: TableProps) => {
  const { rows, columns, page, onPageChange, rowCount } = props;

  return (
    <DataGrid
      {...dataGridDefaults}
      rows={rows}
      rowCount={rowCount}
      columns={columns}
      page={page}
      onPageChange={onPageChange}
      components={{
        NoRowsOverlay,
        Footer: CustomPagination,
      }}
      componentsProps={{
        footer: { totalRecords: rowCount },
      }}
    />
  );
};

export default Table;
