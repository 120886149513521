import { useEffect, useState } from 'react';
import { useLocaleState, useTranslate } from 'react-admin';

import api from '../../api';
import { IportalUserYubiKeyStatus, Itimezone } from '../../api/types';
import { GeneralBtn, SingleSelection } from '../../components';
import { Ilocale } from '../../components/i18nProvider';
import { Box, Container, SelectChangeEvent } from '../../components/MuiGenerals';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import { selectProfile, setAccountSetting } from '../../reducer/profileSlice';
import { COLOR_THEME } from '../../style/colorTheme';
import { enumMapping, importantStyle } from '../../utils';
import { enumLanguage, enumPortalUserYubiKeyStatus, enumTimezone } from '../../utils/constant';
import { customSx } from '../../utils/styling';
import { useZusTranslatePrefixStore } from '../../zustand/store';

const translatePrefix = 'accountSetting';
const Enum = {
  timezones: enumMapping(enumTimezone),
  yubikeyStatus: enumMapping(enumPortalUserYubiKeyStatus),
};

interface Ifields {
  email: string;
  yubikey_status: IportalUserYubiKeyStatus;
  timezone: Itimezone;
  lang: keyof typeof enumLanguage;
}
const initFields = {
  email: '',
  yubikey_status: enumPortalUserYubiKeyStatus.Unbound,
  timezone: enumTimezone.timezone8,
  lang: enumLanguage.en,
} as const;
const sx = {
  Box: {
    display: 'grid',
    gridTemplateColumns: 'minmax(8rem, max-content) minmax(10rem, max-content)',
    lineHeight: '3rem',
    columnGap: '1rem',
    marginBottom: '1rem',
  },
};
export default function AccountSetting() {
  const dispatch = useAppDispatch();
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { email, timezone, lang } = useAppSelector(selectProfile);
  // const yubikeyDetail = useYubikeyDetail();
  // const yubikey_status_value = String(
  //   yubikeyDetail?.yubi_key_status
  // ) as IportalUserYubiKeyStatus;

  // const yubikey_status =
  //   Object.entries(Enum.yubikeyStatus).find(
  //     ([key, _value]) => key === yubikey_status_value
  //   ) && Enum.yubikeyStatus[yubikey_status_value];
  const [, setLocale] = useLocaleState();
  const [fields, setFields] = useState<Ifields>({
    ...initFields,
    email,
    timezone,
    lang,
  });
  const [inView, setInView] = useState(true);
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setFields((fields: any) => ({ ...fields, [type]: value }));
  };
  const onSave = async () => {
    const res = await api.MerchantPortal.profile.upsertUserProfile({
      lang: fields.lang,
      timezone: fields.timezone,
    });
    if (!res) return;
    setLocale(fields.lang);
    dispatch(setAccountSetting({ lang: fields.lang, timezone: fields.timezone }));
    setInView(true);
  };

  const onCancel = () => {
    setFields({ ...fields, email, timezone, lang });
    setInView(true);
  };

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <Box sx={sx.Box}>
          <span>{t('email')}</span>
          <span>{email}</span>
          {/* <span>{t("yubikey_status")}</span>
          <span>{yubikey_status && te(yubikey_status)}</span> */}
          {inView && (
            <>
              <span>{t('timezone')}</span>
              <span>GMT{te(Enum.timezones[timezone])}</span>
              <span>{t('language')}</span>
              <span>{te(lang)}</span>
            </>
          )}
          {!inView && (
            <>
              <span>{t('timezone')}</span>
              <SingleSelection
                sx={{ select: { lineHeight: '0.45em' } }}
                value={fields.timezone}
                onChange={onSelectChange('timezone')}
                enumData={enumTimezone}
                arrayFn={(arr) => arr.sort((a, b) => Number(a[1]) - Number(b[1]))}
                nameFn={(name) => `GMT${te(name)}`}
                clearSelect={() => {}}
              />
              <span>{t('language')}</span>
              <SingleSelection
                sx={{ select: { lineHeight: '0.45em' } }}
                value={fields.lang}
                onChange={onSelectChange('lang')}
                // enumData={enumLanguage}
                // TODO: [pmp] use real enum
                enumData={{ en: 'en' }}
                clearSelect={() => {}}
              />
            </>
          )}
        </Box>
        {inView && <GeneralBtn onClick={() => setInView(false)} label="edit" />}
        {!inView && <GeneralBtn onClick={onSave} label="confirm" />}
        {!inView && (
          <GeneralBtn
            onClick={onCancel}
            label="cancel"
            color="warning"
            rest={{ style: { marginLeft: '40px' } }}
          />
        )}
      </Container>
    </Box>
  );
}

export function LanguageSelection() {
  const profile = useAppSelector(selectProfile);
  const timezone = profile.timezone;
  const dispatch = useAppDispatch();
  const [, setLocale] = useLocaleState();
  const onSelectChange = (type: string) => async (e: SelectChangeEvent<string>) => {
    if (type !== 'lang') return;
    const lang = e.target.value as Ilocale;
    const res = await api.MerchantPortal.profile.upsertUserProfile({ lang, timezone });
    if (!res) return;
    dispatch(setAccountSetting({ lang, timezone }));
    setLocale(lang);
  };
  /**
     
    border-radius: .5rem
    background-color: rgba(0,0,0,0)
     */
  const propSx = {
    select: {
      backgroundColor: COLOR_THEME.Views.AccountSetting.LanguageSelection.Background,
      '& .MuiSelect-select': {
        height: '100%',
      },
    },
    formControl: {
      marginTop: 0,
      marginBottom: 0,

      height: '100%',
      '& .MuiInputBase-root': {
        backgroundColor: importantStyle(
          COLOR_THEME.Views.AccountSetting.LanguageSelection.Background
        ),
        color: COLOR_THEME.Views.AccountSetting.LanguageSelection.Text,
        '>svg': { color: importantStyle(COLOR_THEME.Views.AccountSetting.LanguageSelection.Text) },
        borderRadius: '.5rem',

        '>div': {
          paddingTop: '.3rem',
          paddingBottom: '.3rem',
        },
      },
      '& .MuiInputBase-root:before': {
        content: 'none',
      },
      '& .MuiInputBase-root:after': {
        content: 'none',
      },
    },
  };

  return (
    <SingleSelection
      value={profile.lang}
      onChange={onSelectChange('lang')}
      // enumData={enumLanguage}
      // TODO: [pmp] use real enum
      enumData={{ en: 'en' }}
      nameFn={(name) => {
        if (name === 'en') return 'English';
        return '简中';
      }}
      clearSelect={() => {}}
      sx={propSx}
      className={'languageSelection'}
    />
  );
}
