const PALETTE = {
  Primary: '#1A2B55',
  Secondary: '#E2E8FE',
  SecondaryVariant: '#F2F4FF',

  ButtonPrimary: '#578FFE',
  ButtonSecondary: '#7F9AC4',
  Button3: '#798797',

  PrimaryText: '#5183FF',
  SecondaryText: '#000000',
  Button3Text: '#FFFFFF',

  Accent1: '#F8FAFF',

  White: '#FFFFFF',
};

const COLOR_THEME = {
  Page: {
    Background: PALETTE.SecondaryVariant,
    Text: PALETTE.SecondaryText,
  },
  Menu: {
    Background: PALETTE.Primary,
    HoverItem: PALETTE.Primary,
    Expanded: {
      Text: PALETTE.Button3Text,
    },
    Collapsed: {
      Text: PALETTE.Button3Text,
    },
  },
  MUI: {
    Primary: {
      Main: PALETTE.ButtonPrimary,
      Text: PALETTE.Button3Text,
    },
    Secondary: {
      Main: PALETTE.ButtonSecondary,
      Text: PALETTE.Button3Text,
    },
    Warning: {
      Main: PALETTE.Accent1,
      Text: PALETTE.SecondaryText,
    },
    Info: {
      Main: PALETTE.SecondaryText,
      Text: PALETTE.SecondaryText,
    },
    MuiList: {
      Text: PALETTE.PrimaryText,
      EventText: PALETTE.Button3Text,
      ListItem: {
        Background: PALETTE.Secondary,
        Text: PALETTE.SecondaryText,
      },
      FocusVisible: {
        Selected: {
          Text: PALETTE.Button3Text,
        },
      },
    },
    MuiInputBase: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Secondary,
    },
    MuiCalendarPicker: {
      Background: PALETTE.Secondary,
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
      MuiPickerDayRoot: {
        Background: PALETTE.Secondary,
        Selected: {
          Background: PALETTE.Accent1,
        },
      },
    },
    MuiPopper: {
      Background: PALETTE.Secondary,
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiDataGrid: {
      Text: PALETTE.SecondaryText,
      Column: {
        Headers: {
          Background: PALETTE.Secondary,
        },
      },
      Row: {
        Background: PALETTE.Accent1,
        Cell: {
          Border: PALETTE.Secondary,
        },
      },
      Footer: {
        Background: PALETTE.Secondary,
      },
    },
    MuiPaginationItem: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Accent1,
    },
    MuiPaper: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Accent1,
    },
    MuiClockPicker: {
      Text: PALETTE.White,
      SecondaryText: PALETTE.SecondaryText,

      Clock: {
        Background: PALETTE.Secondary,
        // Background: PALETTE.Accent1,
      },
    },
    MuiTabs: {
      PrimaryText: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    RaMenuItemLink: {
      Text: PALETTE.PrimaryText,
    },
    MuiDateTimePickerTabs: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiDialog: {
      Text: PALETTE.SecondaryText,
    },
    MuiDialogActions: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiFormLabel: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiTypography: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiButtonBase: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiSvgIcon: {
      Text: PALETTE.SecondaryText,
    },
    MuiInputLabel: {
      Text: PALETTE.SecondaryText,
    },
    MuiInputAdornment: {
      Text: PALETTE.SecondaryText,
    },
  },
  CustomSx: {
    LayoutBox: {
      Background: PALETTE.Secondary,
    },
    DatagridContainer: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Secondary,
    },
    GridFilter: { Background: PALETTE.Secondary },
    FilterB: { Background: PALETTE.Secondary },
    TableRow: { Background: PALETTE.Secondary },
    ComponentHelper: {
      AddressCell: {
        Text: PALETTE.PrimaryText,
      },
    },
  },
  Layout: {
    DialogWithOuterBox: {
      Background: PALETTE.Secondary,
    },
    CustomPagination: {
      Background: PALETTE.Secondary,
      Text: PALETTE.SecondaryText,
      Selected: {
        Text: PALETTE.PrimaryText,
      },
    },
    FilterTable: {
      Background: PALETTE.Secondary,
    },
    NoRowsOverlay: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Accent1,
    },
    LayoutBar: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
      LogoutBtn: {
        Text: PALETTE.Button3Text,
        Background: PALETTE.Button3,
      },
      Dropdown: {
        Background: PALETTE.Secondary,
        Text: PALETTE.SecondaryText,
      },
    },
    GridBoxSection: {
      Text: PALETTE.PrimaryText,
    },
  },
  Views: {
    CustomerApplication: {
      DialogOperation: {
        Text: PALETTE.PrimaryText,
      },
    },
    CreditAdjustmentRequest: {
      DialogOperation: {
        Text: PALETTE.PrimaryText,
      },
    },
    AccountSetting: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
      LanguageSelection: {
        Background: PALETTE.Button3,
        Text: PALETTE.Button3Text,
      },
    },
  },
  Components: {
    LoadingProgressDialog: {
      Text: PALETTE.SecondaryText,
    },
    UseTabs: {
      Text: PALETTE.SecondaryText,
    },
    ExportBtn: {
      HoverBorderColor: PALETTE.SecondaryText,
      HoverBackground: PALETTE.Button3,
    },
  },
};

export { PALETTE, COLOR_THEME };
