import { Asset } from '../../../api/types';

export const getProgramAssets = (list: Asset[], programName?: string | string[]) => {
  if (!list) {
    return [];
  }

  if (Array.isArray(programName)) {
    return list.filter((asset) => programName.includes(asset.programName) )
  }
  
  return list.filter((asset) => asset.programName === programName);
  
};
